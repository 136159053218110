/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import { styled } from '@mui/system';
import {
  TextField,
  InputAdornment,
  Button,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { IoSearchOutline } from 'react-icons/io5';
import { AiFillPlusCircle } from 'react-icons/ai';

import TagUpdate from './TagUpdate';
import Content from '../../../Components/BasicComponents/Content';
import TitleComponent from '../../../Components/BasicComponents/TitleComponent';

const SearchContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  height: '55px',
  maxWidth: '150px',
  marginLeft: '15px',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    marginLeft: '0px',
    marginTop: '20px',
    height: '35px',
  },
}));

const StyledCreateButton = styled(Button)(({ theme }) => ({
  height: '55px',
  fontSize: '18px',
  fontWeight: 'bold',
  marginTop: '20px',
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
  [theme.breakpoints.down('sm')]: {
    height: '35px',
  },
}));

type Props = {
  error: boolean,
  errorMessage: string,
  isLoading: boolean,
  isLive: boolean,
  setError: (bool: boolean) => void,
  loadTagDetails: (tagId: string) => void,
  loadByPreEncode: (encode: string) => void,
  setActivationCode: (code: string) => void,
};

function TagSearch(props: Props) {
  const {
    error,
    errorMessage,
    isLoading,
    isLive,
    loadTagDetails,
    loadByPreEncode,
    setError,
    setActivationCode,
  } = props;

  const [startCreate, setStartCreate] = useState<boolean>(false);
  const [tagID, setTagID] = useState<string>('');
  const [encode, setEncode] = useState<string>('');
  const [useEncode, setUseEncode] = useState<boolean>(false);

  const permissions = ['loyalty:tags:create', 'loyalty:tags:all'];
  const canCreate = permissions.some((condition) => localStorage.getItem('userScopes')?.includes(condition));

  return (
    <Content>
      <TitleComponent text="Search by Pre-Encoded Number" />
      <SearchContainer>
        <TextField
          autoComplete="new-password"
          fullWidth
          id="tag-search"
          label="Pre-Encoded Number"
          variant="outlined"
          color="primary"
          value={encode}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            autoComplete: 'off',
            startAdornment: (
              <InputAdornment position="start">
                <IoSearchOutline />
              </InputAdornment>
            ),
          }}
          error={error && encode !== ''}
          helperText={error && encode !== '' ? errorMessage : ''}
          onChange={(event) => {
            if (error) setError(false);
            setEncode(event.target.value);
          }}
          onKeyDown={(key) => {
            if (key.key === 'Enter') loadByPreEncode(encode);
          }}
        />

        <StyledLoadingButton
          loading={isLoading}
          color="primary"
          disabled={encode === ''}
          variant="contained"
          fullWidth
          onClick={() => {
            loadByPreEncode(encode);
          }}
        >
          <Typography variant="body2">SEARCH</Typography>
        </StyledLoadingButton>
      </SearchContainer>

      <StyledCreateButton
        aria-label="create"
        variant="contained"
        fullWidth
        startIcon={<AiFillPlusCircle />}
        onClick={() => {
          setUseEncode(true);
          setStartCreate(true);
        }}
        disabled={!canCreate || canCreate === undefined}
      >
        <Typography variant="body2">CREATE WITH ENCODED NUMBER</Typography>
      </StyledCreateButton>

      <TitleComponent text="Search by Tag ID" />
      <SearchContainer>
        <TextField
          autoComplete="new-password"
          fullWidth
          id="tag-search"
          label="Tag ID"
          variant="outlined"
          color="primary"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            autoComplete: 'off',
            startAdornment: (
              <InputAdornment position="start">
                <IoSearchOutline />
              </InputAdornment>
            ),
          }}
          value={tagID}
          error={error && tagID !== ''}
          helperText={error && tagID !== '' ? errorMessage : ''}
          onChange={(event) => {
            if (error) setError(false);
            setTagID(event.target.value);
          }}
          onKeyDown={(key) => {
            if (key.key === 'Enter') loadTagDetails(tagID);
          }}
        />

        <StyledLoadingButton
          loading={isLoading}
          color="primary"
          disabled={tagID === ''}
          variant="contained"
          fullWidth
          onClick={() => {
            loadTagDetails(tagID);
          }}
        >
          <Typography variant="body2">SEARCH</Typography>
        </StyledLoadingButton>
      </SearchContainer>

      <StyledCreateButton
        aria-label="create"
        variant="contained"
        fullWidth
        startIcon={<AiFillPlusCircle />}
        onClick={() => {
          setUseEncode(false);
          setStartCreate(true);
        }}
        disabled={!canCreate || canCreate === undefined}
      >
        <Typography variant="body2">CREATE WITH TAG-ID</Typography>
      </StyledCreateButton>

      {startCreate
        ? (
          <TagUpdate
            isCreateTag
            loadTagDetails={loadTagDetails}
            isLive={isLive}
            useEncode={useEncode}
            tagId=""
            setOpenFlow={setStartCreate}
            setActivationCode={setActivationCode}
          />
        )
        : null}
    </Content>
  );
}

export default TagSearch;
