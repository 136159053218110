import React, { useEffect, useState } from 'react';

import {
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Checkbox,
  TextField,
  Button,
  Dialog,
  Grid,
  Typography,
  MenuItem,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { styled } from '@mui/system';
import { GiCancel, GiConfirmed } from 'react-icons/gi';
import dayjs, { Dayjs } from 'dayjs';
import ErrorDialog from '../../../Components/BasicComponents/ErrorDialog';
import ConfirmDialog from '../../../Components/BasicComponents/ConfirmDialog';
import { ReturnedSchemeData } from '../../../Services/SchemeService';
import PromoService, { TagPromo, ReturnedTagPromo, TagPromoById } from '../../../Services/PromoService';

const StyledTitle = styled(Typography, {})({
  alignSelf: 'center',
  paddingTop: '25px',
  paddingBottom: '10px',
});

const StyledConfirm = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const StyledCancel = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.white,
  backgroundColor: theme.palette.warning.main,
  '&:hover': {
    backgroundColor: theme.palette.warning.main,
  },
}));

type Props = {
  businessData: any,
  schemeData: ReturnedSchemeData | undefined,
  isCreate: boolean,
  open: boolean,
  partnerId: string,
  setOpen: (toggle: boolean) => void,
  schemeId: string,
  cancelCreate: () => void,
  tagPromoData?: ReturnedTagPromo,
  setSingleTagPromo: (tagPromo: TagPromoById) => void,
  setTagParam: (id: string) => void,
};

type DaySelector = {
  'Monday': boolean,
  'Tuesday': boolean,
  'Wednesday': boolean,
  'Thursday': boolean,
  'Friday': boolean,
  'Saturday': boolean,
  'Sunday': boolean
};

function TagPromoEdit(props: Props) {
  const {
    businessData,
    schemeData,
    isCreate,
    open,
    partnerId,
    setOpen,
    schemeId,
    cancelCreate,
    tagPromoData,
    setSingleTagPromo,
    setTagParam,
  } = props;

  const now = dayjs(Date.now());

  const [type, setType] = useState<string>('');
  const [criteria, setCriteria] = useState<string>('');
  const [amount, setAmount] = useState<string>('');
  const [rewardsRemaining, setRewardsRemaining] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [startTime, setStartTime] = useState<Dayjs>(now);
  const [endTime, setEndTime] = useState<Dayjs>(now);
  const [rewardDetails, setRewardDetails] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [voucherInformation, setVoucherInformation] = useState<string>('');
  const [usePopup, setUsePopup] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [confirmDisabled, setConfirmDisabled] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [days, setDays] = useState<DaySelector>({
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    Sunday: false,
  });

  useEffect(() => {
    if (tagPromoData) {
      if (tagPromoData.promoType === 'stamp' || tagPromoData.promoType === 'voucher') setType(tagPromoData.promoType);
      if (tagPromoData.criteria) setCriteria(tagPromoData.criteria);
      if (tagPromoData.promoType === 'stamp' && tagPromoData.amount) {
        setAmount(tagPromoData.amount.toString());
      }
      if (tagPromoData.vouchersRemaining) {
        setRewardsRemaining(tagPromoData.vouchersRemaining.toString());
      }
      if (tagPromoData.rewardsRemaining) {
        setRewardsRemaining(tagPromoData.rewardsRemaining.toString());
      }
      if (tagPromoData.locationIds) setLocation(tagPromoData.locationIds);
      if (tagPromoData.description) setDescription(tagPromoData.description);
      if (tagPromoData.startTime) {
        const miliStamp = tagPromoData.startTime.toString();
        const secondStamp = parseInt(miliStamp.slice(0, -3), 10);
        setStartTime(dayjs.unix(secondStamp));
      }
      if (tagPromoData.endTime) {
        const miliStamp = tagPromoData.endTime.toString();
        const secondStamp = parseInt(miliStamp.slice(0, -3), 10);
        setEndTime(dayjs.unix(secondStamp));
      }
      if (tagPromoData.voucherEarnedModal) {
        setUsePopup(true);
        if (tagPromoData.voucherEarnedModal.title) setTitle(tagPromoData.voucherEarnedModal.title);
        if (tagPromoData.voucherEarnedModal.rewardDetails) {
          setRewardDetails(tagPromoData.voucherEarnedModal.rewardDetails);
        }
        if (tagPromoData.voucherEarnedModal.voucherInformation) {
          setVoucherInformation(tagPromoData.voucherEarnedModal.voucherInformation);
        }
      }
      if (tagPromoData.availabilityDays) {
        let newDay = { ...days };
        tagPromoData.availabilityDays.forEach((day) => {
          newDay = {
            ...newDay,
            [day]: true,
          };
        });
        setDays(newDay);
      }
    }
  }, [tagPromoData]);

  const handleClose = () => {
    setOpen(false);
    setType('');
    setCriteria('');
    setAmount('');
    setRewardsRemaining('');
    setLocation('');
    setDescription('');
    setStartTime(now);
    setEndTime(now);
    setRewardDetails('');
    setTitle('');
    setVoucherInformation('');
    setUsePopup(false);
    setError(false);
    cancelCreate();
  };

  const loadTagPromo = async (promoId: string) => {
    try {
      const newPromo = await PromoService.getTagPromoById(promoId);
      setTagParam(promoId);
      setSingleTagPromo(newPromo);
      setOpen(false);
    } catch (e:any) {
      setOpenError(true);
      setErrorMessage(e.message);
    }
  };

  const checkInputs = ():boolean => {
    if (type === '') return false;
    if (type === 'stamp' && (amount === '' || amount === null)) return false;
    if (criteria === '') return false;
    if (endTime <= now) return false;
    if (endTime <= startTime) return false;
    if (usePopup && (title === '' || rewardDetails === '' || voucherInformation === '')) return false;
    return true;
  };

  const handleEdit = async () => {
    setConfirmDisabled(true);
    const continueEdit = await checkInputs();
    setError(!continueEdit);
    if (continueEdit) {
      let newTagPromo: TagPromo = {
        type,
        criteria,
        startTime: dayjs(startTime).valueOf(),
        endTime: dayjs(endTime).valueOf(),
        schemeId,
        partnerId,
      };

      if (type === 'stamp') {
        newTagPromo = {
          ...newTagPromo,
          amount: parseInt(amount, 10),
        };
      }
      if (rewardsRemaining) {
        newTagPromo = {
          ...newTagPromo,
          rewardsRemaining: parseInt(rewardsRemaining, 10),
        };
      } else {
        newTagPromo = {
          ...newTagPromo,
          rewardsRemaining: null,
        };
      }
      if (location !== '') {
        newTagPromo = {
          ...newTagPromo,
          location,
        };
      }
      if (description !== '') {
        newTagPromo = {
          ...newTagPromo,
          description,
        };
      }
      if (usePopup) {
        newTagPromo = {
          ...newTagPromo,
          voucherEarnedModal: {
            companyLogo: businessData['Basic Fields'].Logo,
            title,
            rewardDetails,
            voucherInformation,
          },
        };
      }

      const availabilityDays:string[] = [];
      Object.entries(days).forEach((day) => {
        if (day[1]) availabilityDays.push(day[0]);
      });

      if (availabilityDays.length > 0) {
        newTagPromo = {
          ...newTagPromo,
          availabilityDays,
        };
      }

      if (!isCreate && tagPromoData && tagPromoData.promotionId) {
        try {
          await PromoService.updateTagPromo(tagPromoData.promotionId, newTagPromo);
          loadTagPromo(tagPromoData.promotionId);
        } catch (e:any) {
          setOpenError(true);
          setErrorMessage(e.message);
        }
      } else {
        try {
          const newPromo = await PromoService.createTagPromo(newTagPromo);
          loadTagPromo(newPromo.promotionId);
        } catch (e:any) {
          setOpenError(true);
          setErrorMessage(e.message);
        }
      }
    }
    setConfirmDisabled(false);
  };

  const handleClosed = (event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick' && 'escapeKeyDown') return;
    handleClose();
  };

  return (
    <Dialog
      aria-label="tag-promo-dialog"
      onClose={(event, reason) => handleClosed(event, reason)}
      open={open}
      fullWidth
      maxWidth="md"
      sx={{ zIndex: '1100' }}
    >
      {isCreate ? (
        <StyledTitle variant="h1">Create New Tag Promo</StyledTitle>
      ) : (
        <StyledTitle variant="h1">{`Edit Tag Promo for ${schemeId}`}</StyledTitle>
      )}

      <DialogContent>
        <Grid container spacing={4}>

          <Grid item xs={12}>
            <Divider><Typography variant="body2">Required Information</Typography></Divider>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              select
              InputLabelProps={{ shrink: true }}
              id="type-select"
              label="Promo Type"
              variant="outlined"
              color="primary"
              value={type || 'none'}
              error={error && type === ''}
              onChange={(event) => {
                setType(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
            >
              <MenuItem key={0} value="none" disabled>Select one of the below</MenuItem>
              <MenuItem key={1} value="stamp">Stamp</MenuItem>
              <MenuItem key={2} value="voucher">Voucher</MenuItem>
            </TextField>
          </Grid>

          {type === 'stamp' ? (
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="promo-type-entry"
                label="Amount of Stamps added"
                variant="outlined"
                color="primary"
                value={amount}
                error={error && amount === ''}
                InputLabelProps={{ shrink: true }}
                inputProps={{ pattern: '[1-9]*', autoComplete: 'off' }}
                onChange={(event) => {
                  setAmount(event.target.value.replace(/\D+/g, ''));
                }}
              />
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <Typography variant="body2">Optional : Number of uses</Typography>
            <Typography variant="body1">
              You can set a specific amount of times this promotion can be redeemed.
              If left empty there is no number limit on how many times this promotion can be used.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="Rewards-remaining-entry"
              label="Number of Uses"
              variant="outlined"
              color="primary"
              value={rewardsRemaining}
              error={error && rewardsRemaining === ''}
              InputLabelProps={{ shrink: true }}
              inputProps={{ pattern: '[1-9]*', autoComplete: 'off' }}
              onChange={(event) => {
                setRewardsRemaining(event.target.value.replace(/\D+/g, ''));
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">Criteria Options :</Typography>
            <Typography variant="body1">
              First Stamp : Activates promo for first time customers who have never stamped on that scheme before
            </Typography>
            <Typography variant="body1">
              First Use : Activates promo only once for any customer
            </Typography>
            <Typography variant="body1">
              Unlimited : Activates promo every time a customer taps
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              select
              InputLabelProps={{ shrink: true }}
              id="type-select"
              label="Redemption Criteria"
              variant="outlined"
              color="primary"
              value={criteria || 'none'}
              error={error && criteria === ''}
              onChange={(event) => {
                setCriteria(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
            >
              <MenuItem key={0} value="none" disabled>Select one of the below</MenuItem>
              <MenuItem key={1} value="firstStamp">First Stamp</MenuItem>
              <MenuItem key={2} value="firstUse">First Use</MenuItem>
              <MenuItem key={3} value="unlimited">Unlimited</MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <Grid container item spacing={2} xs={12} sx={{ marginTop: '10px' }}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">Start Date & Time of Promotion</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <DateTimePicker
              value={startTime}
              onChange={(value) => {
                if (value !== null) setStartTime(value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="body2">End Date & Time of Promotion</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <DateTimePicker
              value={endTime}
              onChange={(value) => {
                if (value !== null) setEndTime(value);
              }}
              slotProps={{
                textField: {
                  error: error && (endTime <= startTime),
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider><Typography variant="body2">Optional Information</Typography></Divider>
          </Grid>

          {schemeData?.locations && schemeData.locations.length > 0 ? (
            <Grid container item spacing={2} xs={12}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">Select a Location</Typography>
                <Typography variant="body1">
                  If no location is selected, the promotion will work at all locations
                </Typography>
              </Grid>

              <Grid item container xs={12} sm={6} alignContent="center">
                <FormGroup>
                  {schemeData?.locations.filter((key) => Object.keys(businessData.Locations).includes(key))
                    .map((loc) => (
                      <FormControlLabel
                        key={loc}
                        control={(
                          <Checkbox
                            checked={location === loc}
                            onChange={() => {
                              if (location === loc) setLocation('');
                              else {
                                setLocation(loc);
                              }
                            }}
                          />
                        )}
                        label={`${loc} : ${businessData.Locations[loc].Address}`}
                      />
                    ))}
                </FormGroup>
              </Grid>
            </Grid>
          ) : null}

          <Grid container item spacing={2} xs={12}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Set a Specific Day</Typography>
              <Typography variant="body1">
                If no day is selected, the promotion will always be active.
                Otherwise it will only function on selected days.
              </Typography>
            </Grid>

            <Grid item container xs={12} sm={6} alignContent="center">
              <FormGroup>
                {Object.entries(days).map((day) => (
                  <FormControlLabel
                    key={day[0]}
                    control={(
                      <Checkbox
                        checked={day[1]}
                        onChange={() => {
                          const key = day[0];
                          const newDay = {
                            ...days,
                            [key]: !day[1],
                          };
                          setDays(newDay);
                        }}
                      />
                    )}
                    label={`${day[0]}`}
                  />
                ))}
              </FormGroup>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">Description Text</Typography>
            <Typography variant="body1">
              This description is for internal use only. It will not be displayed on the app.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              sx={{ marginTop: '10px' }}
              fullWidth
              id="description-entry"
              label="Promo Description"
              variant="outlined"
              color="primary"
              value={description}
              InputLabelProps={{ shrink: true }}
              inputProps={{ autoComplete: 'off' }}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={2} xs={12} sx={{ marginTop: '10px' }}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">Customized Pop-up</Typography>
            <Typography variant="body1">
              You can customize the pop-up shown on the app when a user activates a promotion
            </Typography>
          </Grid>
          <Grid container item xs={12} sm={6} alignContent="center">
            <FormControlLabel
              key="pop-up-toggle"
              control={
                <Checkbox checked={usePopup} onChange={() => setUsePopup(!usePopup)} />
              }
              label="Use custom pop-up"
            />
          </Grid>
        </Grid>

        {usePopup ? (
          <Grid container item xs={12} spacing={2} sx={{ marginTop: '10px' }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="title-entry"
                label="Promo Pop-up Title"
                variant="outlined"
                color="primary"
                value={title}
                InputLabelProps={{ shrink: true }}
                inputProps={{ autoComplete: 'off' }}
                onChange={(event) => {
                  setTitle(event.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                sx={{ marginTop: '10px' }}
                fullWidth
                id="information-entry"
                label="Promo Pop-up Voucher Information"
                variant="outlined"
                color="primary"
                value={voucherInformation}
                InputLabelProps={{ shrink: true }}
                inputProps={{ autoComplete: 'off' }}
                onChange={(event) => {
                  setVoucherInformation(event.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                sx={{ marginTop: '10px' }}
                fullWidth
                id="reward-entry"
                label="Promo Pop-up Reward Details"
                variant="outlined"
                color="primary"
                value={rewardDetails}
                InputLabelProps={{ shrink: true }}
                inputProps={{ autoComplete: 'off' }}
                onChange={(event) => {
                  setRewardDetails(event.target.value);
                }}
              />
            </Grid>
          </Grid>
        ) : null}
      </DialogContent>

      <DialogActions>
        <StyledCancel
          variant="contained"
          fullWidth
          startIcon={<GiCancel />}
          onClick={() => handleClose()}
        >
          <Typography variant="body2">CANCEL</Typography>
        </StyledCancel>
        <StyledConfirm
          variant="contained"
          fullWidth
          startIcon={<GiConfirmed />}
          onClick={() => {
            if (checkInputs()) setOpenConfirm(true);
            else setError(true);
          }}
        >
          <Typography variant="body2">CONFIRM</Typography>
        </StyledConfirm>
      </DialogActions>

      <ConfirmDialog
        open={openConfirm}
        handleCancel={() => setOpenConfirm(false)}
        handleConfirm={handleEdit}
        dialogTitle={isCreate ? `Create new Tag Promotion ${schemeId}` : `Save Changes for ${schemeId}`}
        dialogText={isCreate
          ? 'Are you sure you wish to save this new Promotion ?'
          : 'Are you sure you wish to save these changes to this existing promotion ?'}
        disabled={confirmDisabled}
      />

      <ErrorDialog
        dialogTitle={errorMessage}
        open={openError}
        handleClose={() => {
          setOpenError(false);
          setErrorMessage('');
        }}
      />
    </Dialog>
  );
}

export default TagPromoEdit;
