const AUTH_API_URL = process.env.REACT_APP_AUTH_URL;

export type AuthData = {
  email: string;
  userAdmin?: any;
  token?: string;
  name?: string;
  errorMsg?: string;
};

const signIn = async (email: string, password: string): Promise<AuthData> => new Promise((resolve, reject) => {
  fetch(`${AUTH_API_URL}/v1/auth/login?type=admin`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      password,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.token) {
        localStorage.setItem('userToken', data.token);
        localStorage.setItem('userScopes', data.scopes);
        localStorage.setItem('userType', data.userType);

        resolve(data.token);
      } else if (data.statusCode === 400) {
        reject(data.message);
      }
    })
    .catch((error) => {
      reject(error);
    });
});

const resetPassword = async (email: string, userType: string) => new Promise((resolve, reject) => {
  fetch(`${AUTH_API_URL}/v1/auth/passwordReset?type=${userType}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.message === 'Password reset email sent') resolve(data);
      else reject(data);
    })
    .catch((error) => {
      reject(error);
    });
});

const logout = () => {
  localStorage.removeItem('userToken');
  localStorage.removeItem('userScopes');
};

const createAccount = async (
  email: string,
  password: string,
  displayName: string,
): Promise<any> => new Promise((resolve, reject) => {
  fetch(`${AUTH_API_URL}/v1/auth/register?type=admin`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      password,
      displayName,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const AuthService = {
  createAccount,
  logout,
  signIn,
  resetPassword,
};

export default AuthService;
