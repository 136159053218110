import React, { useState, useEffect } from 'react';

import { styled } from '@mui/system';
import {
  Card,
  CardContent,
  Collapse,
  IconButton,
  IconButtonProps,
  Grid,
  Typography,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import { BusinessPurchaseOption } from '../../../Services/MarketPlaceService';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  fontSize: '30px',
  color: theme.palette.common.white,
}));

const CardTitle = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary.main,
  height: '40px',
  [theme.breakpoints.down('sm')]: {
    height: '50px',
  },
}));

const CardTitleText = styled(Typography)(({ theme }) => ({
  alignSelf: 'center',
  color: theme.palette.common.white,
}));

const ExpandedContent = styled(CardContent)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  paddingLeft: '50px',
  paddingRight: '50px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
}));

type Props = {
  option: BusinessPurchaseOption,
};

function PurchaseOptionCard(props: Props) {
  const {
    option,
  } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [totalPrice, setTotalPrice] = useState<string>('');

  const HandleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    let total = option['Unit Price'] * option.Amount;
    if (option.Discount) total -= (total * (option.Discount / 100));
    setTotalPrice(total.toFixed(2));
  }, []);

  return (
    <Card key={option.productId}>
      <CardTitle>
        <CardTitleText variant="h4">{`${option.Title} x${option.Amount}`}</CardTitleText>
        <ExpandMore
          expand={isExpanded}
          onClick={HandleExpandClick}
          aria-expanded={isExpanded}
          aria-label="show more"
        >
          <MdExpandMore />
        </ExpandMore>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Title :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>{option.Title}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Reward Text :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">{option.Reward}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Scheme :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">{option.Scheme}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Amount :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>{option.Amount}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Price Per Voucher :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">{option['Unit Price'].toFixed(2)}</Typography>
            </Grid>

            {option.Discount ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Discount :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{`${option.Discount}%`}</Typography>
                </Grid>
              </>
            ) : null}

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Total Price :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">{totalPrice}</Typography>
            </Grid>

          </Grid>
        </ExpandedContent>
      </Collapse>
    </Card>
  );
}

export default PurchaseOptionCard;
