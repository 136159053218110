/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import NoPermissionPage from '../../Components/BasicComponents/NoPermissionPage';
import SchemeSearch from './components/SchemeSearch';
import SchemeDetails from './components/SchemeDetails';
import SchemeService, { ReturnedSchemeData } from '../../Services/SchemeService';
import BusinessService from '../../Services/BusinessService';

function SchemePage() {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasFoundSchemes, setHasFoundSchemes] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [schemeData, setSchemeData] = useState<ReturnedSchemeData[]>([]);
  const [businessData, setBusinessData] = useState({});
  const [partnerId, setPartnerId] = useState<string>('');
  const [schemeQueryParam, setSchemeQueryParam] = useSearchParams();
  const [reRoute, setReRoute] = useState<boolean>(false);
  const [brokenSchemeIds, setBrokenSchemeIds] = useState<string[]>([]);

  const permissions = ['loyalty:schemes:all', 'loyalty:schemes:read'];
  const canView = permissions.some((condition) => localStorage.getItem('userScopes')?.includes(condition));

  const navigate = useNavigate();

  const loadSchemeDetails = async (id:string) => {
    setIsLoading(true);
    setSchemeQueryParam({ partnerId: id });
    try {
      const foundSchemes:any = await SchemeService.getSchemeByBusiness(id);
      const businessDetails: any = await BusinessService.getBusiness(id);
      setBusinessData(businessDetails);
      setPartnerId(id);
      let i = 0;
      const brokenSchemes = [];
      const specificSchemes = [];
      while (i < foundSchemes.length) {
        try {
          const scheme = await SchemeService.getSpecificScheme(id, foundSchemes[i].schemeId);
          let j = 0;
          while (j < scheme.schemeImages.length) {
            const cacheBustValue = encodeURIComponent(new Date().getTime());
            scheme.schemeImages[j] = `${scheme.schemeImages[j]}&version=${cacheBustValue}`;
            j += 1;
          }
          specificSchemes.push(scheme);
          i += 1;
        } catch (e) {
          // scheme incorrectly set up , so skip and try the rest
          brokenSchemes.push(foundSchemes[i].schemeId);
          i += 1;
        }
      }
      setBrokenSchemeIds(brokenSchemes);

      if (specificSchemes.length === 0) {
        throw new Error(brokenSchemes.length === 0
          ? 'No schemes found'
          : `Schemes with the following IDs are broken and cant be viewed. 
          Please get in contact with the tech team. \n${brokenSchemes}`);
      }

      setSchemeData(specificSchemes);
      setHasFoundSchemes(true);
    } catch (e:any) {
      setError(true);
      if (e.message) setErrorMessage(e.message);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const goBack = () => {
    setError(false);
    setHasFoundSchemes(false);
    setSchemeQueryParam(undefined);
    setSchemeData([]);
    if (reRoute) navigate(`/businesses?partnerId=${partnerId}`);
    setReRoute(false);
  };

  useEffect(() => {
    const schemeParam = schemeQueryParam.get('partnerId');
    if (schemeParam) {
      setReRoute(true);
      loadSchemeDetails(schemeParam);
    }
  }, []);

  if (!canView) {
    return <NoPermissionPage />;
  }
  if (hasFoundSchemes) {
    return (
      <SchemeDetails
        businessData={businessData}
        schemeData={schemeData}
        goBack={goBack}
        partnerId={partnerId}
        loadSchemeDetails={loadSchemeDetails}
        brokenSchemeIds={brokenSchemeIds}
      />

    );
  }
  return (
    <SchemeSearch
      error={error}
      errorMessage={errorMessage}
      isLoading={isLoading}
      loadSchemeDetails={loadSchemeDetails}
      setError={setError}
    />
  );
}

export default SchemePage;
