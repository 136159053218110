/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';

import { AiFillIdcard } from 'react-icons/ai';
import { FaStoreAlt } from 'react-icons/fa';
import { GrMultiple } from 'react-icons/gr';
import { MdConfirmationNumber } from 'react-icons/md';

import EntryDialog from '../../../Components/BasicComponents/EntryDialog';
import AddStampDialog from './AddStampDialog';

import BusinessService from '../../../Services/BusinessService';
import SchemeService, { ReturnedSchemeData } from '../../../Services/SchemeService';

type Props = {
  uid: string,
  openAddStamps: boolean,
  setOpenAddStamps: (toggle: boolean) => void,
  updateUser: (id: string, user: {}) => void,
};

function AddStamps(props: Props) {
  const {
    uid,
    openAddStamps,
    setOpenAddStamps,
    updateUser,
  } = props;

  const [partnerId, setPartnerId] = useState<string>('');
  const [entryError, setEntryError] = useState<boolean>(false);
  const [entryErrorMessage, setEntryErrorMessage] = useState<string>('');
  const [schemeOptions, setSchemeOptions] = useState<string[]>([]);
  const [businessData, setBusinessData] = useState<any>({});
  const [businessLoading, setBusinessLoading] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [schemeData, setSchemeData] = useState<ReturnedSchemeData>();

  const searchBusiness = async () => {
    setBusinessLoading(true);
    try {
      const business:any = await BusinessService.getBusiness(partnerId);
      if (business.Schemes && Object.keys(business.Schemes).length > 0) {
        setBusinessData(business);
        setSchemeOptions(Object.keys(business.Schemes));
        setOpenAddStamps(false);
        setOpenDialog(true);
      } else {
        setEntryError(true);
        setEntryErrorMessage('There are no Schemes tied to this business');
      }
    } catch (e:any) {
      setEntryError(true);
      setEntryErrorMessage(e.message);
    }
    setBusinessLoading(false);
  };

  const cancelCreate = () => {
    setOpenDialog(false);
    setBusinessData({});
    setSchemeData(undefined);
    setPartnerId('');
    setOpenAddStamps(false);
  };

  return (
    <>
      <EntryDialog
        dialogTitle="Business ID"
        dialogText="Please enter in the business ID of the store you want to add stamps"
        handleCancel={() => {
          cancelCreate();
        }}
        handleConfirm={() => searchBusiness()}
        open={openAddStamps}
        setValue={setPartnerId}
        value={partnerId}
        valueIcon={<FaStoreAlt />}
        error={entryError}
        setError={setEntryError}
        errorMessage={entryErrorMessage}
        loading={businessLoading}
      />

      {openDialog ? (
        <AddStampDialog
          uid={uid}
          partnerId={partnerId}
          businessData={businessData}
          openDialog={openDialog}
          onClose={cancelCreate}
          schemeOptions={schemeOptions}
          updateUser={updateUser}
        />
      ) : null}

    </>
  );
}

export default AddStamps;
