/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';

import { styled } from '@mui/system';
import {
  Divider,
  Card,
  CardContent,
  Grid,
  Typography,
  Collapse,
  IconButton,
  IconButtonProps,
  Link,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';

import { SentNotification } from '../../../Services/NotificationService';

const CardTitle = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary.main,
  height: '40px',
  [theme.breakpoints.down('sm')]: {
    height: '50px',
  },
}));

const CardTitleText = styled(Typography)(({ theme }) => ({
  alignSelf: 'center',
  color: theme.palette.common.white,
}));

const ExpandedContent = styled(CardContent)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  paddingLeft: '20px',
  paddingRight: '20px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
}));

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  fontSize: '30px',
  color: theme.palette.common.white,
}));

type Props = {
  notificationId: string,
  sentData: SentNotification,
};

function ApprovedCard(props: Props) {
  const {
    notificationId,
    sentData,
  } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const HandleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card key={notificationId}>
      <CardTitle>
        <CardTitleText variant="h4">{sentData.businessId}</CardTitleText>
        <ExpandMore
          expand={isExpanded}
          onClick={HandleExpandClick}
          aria-expanded={isExpanded}
          aria-label="show more"
        >
          <MdExpandMore />
        </ExpandMore>
      </CardTitle>
      <ExpandedContent>
        <Grid container spacing={2}>

          {!isExpanded && sentData.successfulDeliveries ? (
            <>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">Successful Deliveries:</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">{sentData.successfulDeliveries}</Typography>
              </Grid>
            </>
          ) : null}

          {!isExpanded && sentData.openedNotifications ? (
            <>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">Number of Opened Notifications:</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">{sentData.openedNotifications}</Typography>
              </Grid>
            </>
          ) : null}

          <Grid item xs={12} sm={6}>
            <Typography variant="body2">Title :</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">{sentData.notification.title}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="body2">Text :</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">{sentData.notification.body}</Typography>
          </Grid>

          {sentData.notification.data ? (
            <>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">Link Type :</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">{sentData.notification.data.type}</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="body2">Link Button Text :</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">{sentData.notification.data.webLinkButtonTitle}</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="body2">Link :</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Link
                  style={{ color: 'blue' }}
                  href={sentData.notification.data.link}
                  target="_blank"
                  rel="noreferrer"
                  underline="hover"
                >
                  {sentData.notification.data.link}
                </Link>
              </Grid>
            </>
          ) : null}
        </Grid>

        {sentData.notification.usersEmail ? (
          <>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Email :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">{sentData.notification.usersEmail}</Typography>
            </Grid>
          </>
        ) : null}

        <Collapse in={isExpanded}>
          <Grid container item spacing={2} sx={{ marginTop: '5px' }}>

            <Grid item xs={12}>
              <Divider><Typography variant="body2">Notification Reach</Typography></Divider>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Successful Deliveries :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">{sentData.successfulDeliveries}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Number of Opened Notifications:</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">{sentData.openedNotifications}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Failed Deliveries :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">{sentData.failedDeliveries}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Total Deliveries :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">{sentData.failedDeliveries}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider><Typography variant="body2">Data</Typography></Divider>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body2">Number of Users :</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">{sentData.numUsers}</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body2">Date Created :</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">{sentData.createdDateTime}</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body2">Notification ID :</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">{notificationId}</Typography>
            </Grid>

            {sentData.criteria ? (
              <>
                <Grid item xs={12}>
                  <Divider><Typography variant="body2">Criteria</Typography></Divider>
                </Grid>

                <Grid container item spacing={2}>
                  {sentData.criteria.schemeIds ? (
                    <>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="body2">Scheme IDs :</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {sentData.criteria.schemeIds.map((scheme) => (
                          <Typography variant="body1" key={scheme}>{scheme}</Typography>
                        ))}
                      </Grid>
                    </>
                  ) : null}

                  {sentData.criteria.locationIds ? (
                    <>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="body2">Locations :</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {sentData.criteria.locationIds.map((location) => (
                          <Typography variant="body1" key={location}>{location}</Typography>
                        ))}
                      </Grid>
                    </>
                  ) : null}

                  {sentData.criteria.usagePercentile ? (
                    <>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="body2">Usage Percent :</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="body1">{sentData.criteria.usagePercentile}</Typography>
                      </Grid>
                    </>
                  ) : null}

                  {sentData.criteria.noUsageInPastXDays ? (
                    <>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="body2">Has not used business in X days :</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="body1">{sentData.criteria.noUsageInPastXDays}</Typography>
                      </Grid>
                    </>
                  ) : null}

                  {sentData.criteria.age?.startAge ? (
                    <>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="body2">Start Age :</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="body1">{sentData.criteria.age.startAge}</Typography>
                      </Grid>
                    </>
                  ) : null}

                  {sentData.criteria.age?.endAge ? (
                    <>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="body2">End Age :</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="body1">{sentData.criteria.age.endAge}</Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </>
            ) : null}
          </Grid>
        </Collapse>
      </ExpandedContent>
    </Card>
  );
}

export default ApprovedCard;
