import React from 'react';

import { styled } from '@mui/system';

import { TbKeyOff } from 'react-icons/tb';
import Content from './Content';

const StyledDiv = styled('div', {})({
  display: 'flex',
  flexDirection: 'column',
  lineHeight: '24px',
  alignItems: 'center',
});

const IconSpan = styled('span', {})({
  fontSize: '48px',
  marginTop: '30px',
});

const DescriptionSpan = styled('span', {})({
  marginTop: '30px',
});

const TitleSpan = styled('span')(({ theme }) => ({
  fontSize: '36px',
  fontWeight: 'bold',
  color: theme.palette.warning.main,
}));

function NoPermissionPage() {
  return (
    <Content>
      <StyledDiv>
        <TitleSpan>Access Denied</TitleSpan>
        <IconSpan><TbKeyOff /></IconSpan>
        <DescriptionSpan>
          You do not have the right Permissions to view this page.
          Please get in contact with an internal admin if you require access.
        </DescriptionSpan>
      </StyledDiv>
    </Content>
  );
}

export default NoPermissionPage;
