import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  TextField,
  MenuItem,
} from '@mui/material';
import { styled } from '@mui/system';

import { PurchaseOption } from '../../../Services/MarketPlaceService';

const OptionGrid = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  marginTop: '20px',
  paddingBottom: '20px',
  paddingTop: '20px',
  border: '1px solid',
  borderColor: theme.palette.primary.main,
  borderRadius: '5px',
}));

type Props = {
  options: PurchaseOption[],
  setPurchaseOptionArray: (option: PurchaseOption[]) => void,
  index: number,
  schemeOptions: any,
  error: boolean,
};

function PurchaseOptionEntry(props: Props) {
  const {
    options,
    setPurchaseOptionArray,
    index,
    schemeOptions,
    error,
  } = props;

  const [totalPrice, setTotalPrice] = useState<string>('');
  const [priceDisplay, setPriceDisplay] = useState<string>(options[index]['Unit Price'].toString());
  const [discountDisplay, setDiscountDisplay] = useState<string>(options[index].Discount.toString());

  const decimalRegex = /^\d*\.?\d{0,2}$/;

  const handleAmount = (entryAmount: string) => {
    const setAmount = entryAmount.replace(/\D+/g, '');
    let amount = parseInt(setAmount, 10);
    if (Number.isNaN(amount)) {
      amount = 0;
    }
    const newOptions = [...options];
    newOptions[index].Amount = amount;
    setPurchaseOptionArray(newOptions);
  };

  const handlePrice = (inputValue: string) => {
    if (decimalRegex.test(inputValue) || inputValue === '') {
      setPriceDisplay(inputValue);
      let price = Number(Number(inputValue).toFixed(2));
      if (Number.isNaN(price)) {
        price = 0.00;
      }
      const newOptions = [...options];
      newOptions[index]['Unit Price'] = price;
      setPurchaseOptionArray(newOptions);
    }
  };

  const handleDiscount = (entryDiscount: string) => {
    if (decimalRegex.test(entryDiscount) || entryDiscount === '') {
      setDiscountDisplay(entryDiscount);
      let price = Number(Number(entryDiscount).toFixed(2));
      if (Number.isNaN(price)) {
        price = 0.00;
      }
      const newOptions = [...options];
      newOptions[index].Discount = price;
      setPurchaseOptionArray(newOptions);
    }
  };

  useEffect(() => {
    let total = options[index]['Unit Price'] * options[index].Amount;
    if (options[index].Discount) total -= (total * (options[index].Discount / 100));
    setTotalPrice(total.toFixed(2));
  }, [options]);

  return (
    <OptionGrid>
      <Grid container spacing={2} sx={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <Grid item container xs={12} justifyContent="center">
          <Typography variant="body2">{`Voucher Option ${index + 1}`}</Typography>
        </Grid>

        <Grid item container xs={12} justifyContent="center">
          <Typography variant="body2">Total Price</Typography>
          <Typography variant="body1">{`  :     ${totalPrice}`}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2">Title</Typography>
          <Typography variant="body1">
            This is the title text of the vouchers
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            id="price-entry"
            label="Title"
            variant="outlined"
            color="primary"
            value={options[index].Title}
            error={error && options[index].Title === ''}
            InputLabelProps={{ shrink: true }}
            onChange={(event) => {
              const newOptions = [...options];
              newOptions[index].Title = event.target.value;
              setPurchaseOptionArray(newOptions);
            }}
            InputProps={{
              autoComplete: 'off',
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2">Amount of Vouchers</Typography>
          <Typography variant="body1">
            This is the amount of vouchers added
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            id="amount-entry"
            label="Amount"
            variant="outlined"
            color="primary"
            value={options[index].Amount}
            error={error && options[index].Amount === 0}
            InputLabelProps={{ shrink: true }}
            onChange={(event) => {
              handleAmount(event.target.value);
            }}
            InputProps={{
              autoComplete: 'off',
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2">Total Discount</Typography>
          <Typography variant="body1">
            This is the discount percentage applied to the final total
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            id="discount-entry"
            label="Discount"
            variant="outlined"
            color="primary"
            value={discountDisplay}
            error={error && options[index].Discount === 0}
            InputLabelProps={{ shrink: true }}
            onChange={(event) => {
              handleDiscount(event.target.value);
            }}
            InputProps={{
              autoComplete: 'off',
              inputProps: {
                inputMode: 'decimal',
                pattern: '^\\d*\\.?\\d{0,2}$',
              },
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2">Voucher Price</Typography>
          <Typography variant="body1">
            This is price per single voucher, not the final total
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            id="price-entry"
            label="Unit Price"
            variant="outlined"
            color="primary"
            value={priceDisplay}
            error={error && options[index]['Unit Price'] === 0}
            InputLabelProps={{ shrink: true }}
            onChange={(event) => {
              handlePrice(event.target.value);
            }}
            InputProps={{
              autoComplete: 'off',
              inputProps: {
                inputMode: 'decimal',
                pattern: '^\\d*\\.?\\d{0,2}$',
              },
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2">Scheme</Typography>
          <Typography variant="body1">
            Select which scheme this vouchers will be applied too
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            id="terms-entry"
            label="Scheme"
            variant="outlined"
            color="primary"
            select
            value={options[index].Scheme}
            error={error && options[index].Scheme === ''}
            InputLabelProps={{ shrink: true }}
            onChange={(event) => {
              const newOptions = [...options];
              newOptions[index].Scheme = event.target.value;
              setPurchaseOptionArray(newOptions);
            }}
            InputProps={{
              autoComplete: 'off',
            }}
          >
            {Object.keys(schemeOptions).map((option) => (
              <MenuItem key={option} value={option}>{`${option} - ${schemeOptions[option].Reward}`}</MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </OptionGrid>
  );
}

export default PurchaseOptionEntry;
