/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';

import { styled } from '@mui/system';
import {
  Card,
  CardContent,
  Collapse,
  IconButton,
  IconButtonProps,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import { FaEdit } from 'react-icons/fa';

import PurchaseOptionCard from './PurchaseOptionCard';

import MarketPlaceService, { BusinessMarketPlace } from '../../../Services/MarketPlaceService';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  fontSize: '30px',
  color: theme.palette.common.white,
}));

const CardTitle = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary.main,
  height: '40px',
  [theme.breakpoints.down('sm')]: {
    height: '50px',
  },
}));

const CardTitleText = styled(Typography)(({ theme }) => ({
  alignSelf: 'center',
  color: theme.palette.common.white,
}));

const ExpandedContent = styled(CardContent)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  paddingLeft: '50px',
  paddingRight: '50px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
}));

const StyledEditButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

type Props = {
  schemeOptions: string[],
  marketPlace: BusinessMarketPlace,
  canEdit: boolean,
  editMarketPlace: () => void,
};

function MarketPlaceCard(props: Props) {
  const {
    schemeOptions,
    marketPlace,
    canEdit,
    editMarketPlace,
  } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const HandleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card key={marketPlace['Stripe Account']}>
      <CardTitle>
        <CardTitleText variant="h4">Market Place</CardTitleText>
        <div>
          <Tooltip title="Edit Market Place Listing" placement="bottom">
            <StyledEditButton disabled={!canEdit} size="small" onClick={editMarketPlace}>
              <FaEdit />
            </StyledEditButton>
          </Tooltip>
          <ExpandMore
            expand={isExpanded}
            onClick={HandleExpandClick}
            aria-expanded={isExpanded}
            aria-label="show more"
          >
            <MdExpandMore />
          </ExpandMore>
        </div>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          <Grid container spacing={2}>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Description :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>{marketPlace.Description}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Terms :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>{marketPlace.Terms}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Currency :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">{marketPlace.Currency}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Allows Gift Vouchers :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                {marketPlace['Has Gift Vouchers'] === true ? 'Yes' : 'No'}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Stripe Connect ID :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">{marketPlace['Stripe Account']}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Amount of Options :</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                {marketPlace['Purchase Options'].filter((option) => option !== null).length}
              </Typography>
            </Grid>

            {marketPlace['Purchase Options'] && marketPlace['Purchase Options']
              .filter((option) => option !== null)
              .map((option, index) => (
                <Grid item xs={12} key={index}>
                  <PurchaseOptionCard option={option} />
                </Grid>
              ))}
          </Grid>
        </ExpandedContent>
      </Collapse>
    </Card>
  );
}

export default MarketPlaceCard;
