import React from 'react';

import { Button, Typography } from '@mui/material';
import { styled } from '@mui/system';

const StyledButton = styled(Button)(({ theme }) => ({
  height: '45px',
  backgroundColor: theme.palette.secondary.main,
  [theme.breakpoints.down('sm')]: {
    height: '35px',
  },
}));

type Props = {
  link: string,
  icon?: JSX.Element,
  text: string,
};

function IconLinkButton(props: Props) {
  const { link, icon, text } = props;

  return (
    <StyledButton
      aria-label="link"
      fullWidth
      variant="contained"
      href={link}
      startIcon={icon}
    >
      <Typography variant="h4">{text}</Typography>
    </StyledButton>
  );
}

export default IconLinkButton;
