import React, { useState } from 'react';

import { styled } from '@mui/system';
import {
  Card,
  CardContent,
  Collapse,
  Divider,
  IconButton,
  IconButtonProps,
  Grid,
  Typography,
  Tooltip,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import { FaEdit } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import SavingChangesDialog from '../../../Components/BasicComponents/SavingChangesDialog';
import ErrorDialog from '../../../Components/BasicComponents/ErrorDialog';
import Toggle from '../../../Components/BasicComponents/Toggle';
import GoogleMapContainer from '../../../Components/BasicComponents/GoogleMapContainer';

import LocationService from '../../../Services/LocationService';
import TagService from '../../../Services/TagService';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  fontSize: '30px',
  color: theme.palette.common.white,
}));

const CardTitle = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary.main,
  height: '40px',
  [theme.breakpoints.down('sm')]: {
    height: '50px',
  },
}));

const CardTitleText = styled(Typography)(({ theme }) => ({
  alignSelf: 'center',
  color: theme.palette.common.white,
}));

const ExpandedContent = styled(CardContent)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  paddingLeft: '50px',
  paddingRight: '50px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
}));

const StyledEditButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

const StyledDeleteButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  marginRight: '20px',
  '&:hover': {
    color: theme.palette.warning.main,
  },
}));

const ButtonBox = styled('div', {})({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignContent: 'center',
});

const StatusBox = styled('div')(({ theme }) => ({
  display: 'flex',
  paddingLeft: '10px',
  paddingRight: '10px',
  marginRight: '20px',
  justifyContent: 'center',
  borderRadius: '10px',
  backgroundColor: theme.palette.common.white,
}));

type Props = {
  partnerLive: boolean,
  partnerId: string,
  locationId: string,
  address: string,
  hourList: {
    [key:string]: string,
  },
  lat: number,
  long: number,
  shortAddress: string,
  googlePlaceId?: string,
  nfcData?: {
    id: string,
    letter?: string,
    scheme: string
    isLive: boolean,
    configError?: boolean,
  } | undefined,
  startDelete: (id:string) => void,
  startEdit: (id:string) => void,
  isLive: boolean,
  loadBusinessDetails: (partnerId:string) => void,
};

function LocationDetailsCard(props: Props) {
  const {
    partnerLive,
    partnerId,
    locationId,
    address,
    hourList,
    lat,
    long,
    googlePlaceId,
    shortAddress,
    nfcData,
    startDelete,
    startEdit,
    isLive,
    loadBusinessDetails,
  } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [saveTitle, setSaveTitle] = useState<string>('');
  const [saveChanges, setSaveChanges] = useState<boolean>(false);
  const [errorTitle, setErrorTitle] = useState<string>('');
  const [error, setError] = useState<boolean>(false);

  const HandleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const permissions = ['loyalty:partners:update', 'loyalty:partners:all'];
  const canEdit = permissions.some((condition) => localStorage.getItem('userScopes')?.includes(condition));

  const sortedDates = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const activate = async () => {
    setSaveTitle(`Activating Location ${locationId}`);
    setSaveChanges(true);
    try {
      await LocationService.activateLocation(partnerId, locationId);
      await loadBusinessDetails(partnerId);
    } catch (e:any) {
      setErrorTitle(e.message);
      setError(true);
    }
    setSaveChanges(false);
  };

  const deactivate = async () => {
    setSaveTitle(`Deactivating Location ${locationId}`);
    setSaveChanges(true);
    try {
      await LocationService.deactivateLocation(partnerId, locationId);
      await loadBusinessDetails(partnerId);
    } catch (e:any) {
      setErrorTitle(e.message);
      setError(true);
    }
    setSaveChanges(false);
  };

  const activateTag = async (tagId: string) => {
    setSaveTitle(`Activating Tag ${tagId}`);
    setSaveChanges(true);
    try {
      await TagService.activateTag(tagId);
      await loadBusinessDetails(partnerId);
    } catch (e:any) {
      setErrorTitle(e.message);
      setError(true);
    }
    setSaveChanges(false);
  };

  const deactivateTag = async (tagId: string) => {
    setSaveTitle(`Deactivating Tag ${tagId}`);
    setSaveChanges(true);
    try {
      await TagService.deactivateTag(tagId);
      await loadBusinessDetails(partnerId);
    } catch (e:any) {
      setErrorTitle(e.message);
      setError(true);
    }
    setSaveChanges(false);
  };

  return (
    <Card key={locationId}>
      <CardTitle>
        <CardTitleText variant="h4">{`${locationId} : ${shortAddress}`}</CardTitleText>

        <ButtonBox>
          <StatusBox>
            <Toggle
              activeText="Live"
              disabledText="Not Live"
              isLive={isLive}
              disabled={!partnerLive}
              activateAction={activate}
              deactivateAction={deactivate}
            />
          </StatusBox>

          <Tooltip title="Delete this Location" placement="bottom">
            <StyledDeleteButton disabled={!canEdit} size="small" onClick={() => startDelete(locationId)}>
              <AiFillDelete />
            </StyledDeleteButton>
          </Tooltip>

          <Tooltip title="Edit this Location" placement="bottom">
            <StyledEditButton disabled={!canEdit} size="small" onClick={() => startEdit(locationId)}>
              <FaEdit />
            </StyledEditButton>
          </Tooltip>

          <ExpandMore
            expand={isExpanded}
            onClick={HandleExpandClick}
            aria-expanded={isExpanded}
            aria-label="show more"
          >
            <MdExpandMore />
          </ExpandMore>
        </ButtonBox>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2">ID :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">{locationId}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Address :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">{address}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Short Address :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">{shortAddress}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Opening Hours :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              {sortedDates.map((day) => (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <div key={day}>
                  {hourList[day] === '0:00-0:00'
                  || hourList[day] === '00:00-00:00'
                  || hourList[day] === '23:59-0:00'
                  || hourList[day] === '23:59-00:00'
                    ? (
                      <Typography variant="body1">{`${day} : Closed`}</Typography>
                    ) : (
                      <Typography variant="body1">{`${day} : ${hourList[day]}`}</Typography>
                    )}
                </div>
              ))}
            </Grid>

            <Grid item xs={12}>
              <Divider light />
            </Grid>

            <Grid container item xs={12}>
              <Typography variant="body2">Map Information</Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider light />
            </Grid>

            <Grid item xs={12}>
              <GoogleMapContainer lat={lat} lng={long} zoom={17} enableMapOptions={false} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Longitude :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">{long}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Latitude :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">{lat}</Typography>
            </Grid>

            {googlePlaceId ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Google Place ID :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{googlePlaceId}</Typography>
                </Grid>
              </>
            ) : null}

            <Grid item xs={12}>
              <Divider light />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2">NFC Tag Information</Typography>
            </Grid>

            {nfcData ? (Object.entries(nfcData).map((nfc: any) => (

              <Grid container item spacing={1} xs={12} key={nfc[0]}>
                <Grid item xs={12}>
                  <Divider light />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Tag ID :</Typography>
                </Grid>

                {nfc[1].configError ? (
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">
                      {nfc[0]}
                    </Typography>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant="body1"
                      component={Link}
                      to={`/tags?tagId=${nfc[0]}&partnerId=${partnerId}`}
                      sx={{ color: 'blue' }}
                    >
                      {nfc[0]}
                    </Typography>
                  </Grid>
                )}

                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Scheme ID :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{nfc[1].Scheme}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Letter :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{nfc[1].Letter ? nfc[1].Letter : 'Not Set'}</Typography>
                </Grid>

                {!nfc[1].configError ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2">Status :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Toggle
                        activeText="Live"
                        disabledText="Not Live"
                        disabled={!partnerLive}
                        isLive={nfc[1].isLive}
                        activateAction={() => activateTag(nfc[0])}
                        deactivateAction={() => deactivateTag(nfc[0])}
                      />
                    </Grid>
                  </>
                ) : null}

                {nfc[1].configError ? (
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      sx={{ color: 'red' }}
                    >
                      Tag is not setup/configured correctly. Please reconfigure this tag through the Tags section.
                    </Typography>
                  </Grid>
                ) : null}
              </Grid>
            ))) : (
              <>
                <Grid item xs={12}>
                  <Divider light />
                </Grid>
                <Grid item container justifyContent="center">
                  <Typography variant="body2">No Tags currently set up for this Location</Typography>
                </Grid>
              </>
            ) }

          </Grid>
        </ExpandedContent>
      </Collapse>

      <SavingChangesDialog
        dialogTitle={saveTitle}
        open={saveChanges}
        saving
      />

      <ErrorDialog
        open={error}
        dialogTitle={errorTitle}
        handleClose={() => {
          setError(false);
          setErrorTitle('');
        }}
      />
    </Card>
  );
}

export default LocationDetailsCard;
