/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';

import { styled } from '@mui/system';
import {
  Grid,
  TablePagination,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { HiDocumentDownload } from 'react-icons/hi';
import { AiOutlineSearch } from 'react-icons/ai';
import dayjs from 'dayjs';
import Content from '../../../Components/BasicComponents/Content';
import TitleComponent from '../../../Components/BasicComponents/TitleComponent';
import BackButton from '../../../Components/BasicComponents/BackButton';
import ConfirmDialog from '../../../Components/BasicComponents/ConfirmDialog';
import CodePromoCard from './CodePromoCard';
import ErrorDialog from '../../../Components/BasicComponents/ErrorDialog';
import CodePromoEdit from './CodePromoEdit';
import ValidateEpoch from '../../../util/validateEpoch';

import PromoService, { CodePromos, CodePromo } from '../../../Services/PromoService';
import BusinessService from '../../../Services/BusinessService';

const fileDownload = require('js-file-download');

const ButtonRow = styled('div', {})({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const StyledSearch = styled(TextField)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.common.white,
  width: '400px',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  height: '45px',
  width: '220px',
  backgroundColor: theme.palette.secondary.main,
  position: 'inherit',
  [theme.breakpoints.down('sm')]: {
    height: '35px',
    width: '90px',
  },
}));

const SearchRow = styled('div', {})({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
});

type Props = {
  goBack: () => void,
  codePromoData: CodePromos,
  codePromoAmount: number,
  openError: boolean,
  setOpenError: (toggle: boolean) => void,
  errorMessage: string,
  setErrorMessage: (message: string) => void,
  codeParam: string | null,
  setCodeParam: (param: string) => void,
  loadAllCodes: () => void,
  setCodePromoData: (codePromo: CodePromos) => void,
  isBatch: boolean,
};

function CardPromoDetails(props: Props) {
  const {
    goBack,
    codePromoData,
    codePromoAmount,
    openError,
    setOpenError,
    errorMessage,
    setErrorMessage,
    codeParam,
    setCodeParam,
    loadAllCodes,
    setCodePromoData,
    isBatch,
  } = props;

  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [selectedCode, setSelectedCode] = useState<string>('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(8);
  const [searchText, setSearchText] = useState<string>('');
  const [amount, setAmount] = useState<number>(codePromoAmount);
  const [businessData, setBusinessData] = useState<any>({});
  const [partnerId, setPartnerId] = useState<string>('');
  const [schemeId, setSchemeId] = useState<string>('');
  const [selectedCodePromoData, setSelectedCodePromoData] = useState<CodePromo | undefined>();
  const [openEdit, setOpenEdit] = useState<boolean>(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startDelete = (codeId: string) => {
    setSelectedCode(codeId);
    setOpenDelete(true);
  };

  const startEdit = async (codePromoId: string, promoData: CodePromo) => {
    setSelectedCode(codePromoId);
    try {
      const pId = promoData['Chain ID'];
      const business = await BusinessService.getBusiness(pId);
      setAmount(codePromoAmount);
      setSearchText('');
      setPartnerId(pId);
      setSchemeId(promoData.Scheme);
      setBusinessData(business);
      setSelectedCodePromoData(promoData);
      setOpenEdit(true);
    } catch (e: any) {
      setOpenError(true);
      setErrorMessage(e.message);
    }
  };

  const cancelEdit = () => {
    setOpenEdit(false);
    setBusinessData({});
    setPartnerId('');
    setSelectedCodePromoData(undefined);
  };

  const deleteCode = async () => {
    try {
      await PromoService.deleteCodePromo(selectedCode);
      setOpenDelete(false);
      if (codeParam !== null) goBack();
      else {
        loadAllCodes();
      }
    } catch (e:any) {
      setOpenError(true);
      setErrorMessage(e.message);
    }
  };

  const downloadFile = () => {
    const keys = Object.keys(codePromoData)
      .filter((key) => codePromoData[key].Store.toUpperCase().includes(searchText.toUpperCase()));

    const headings: string[] = [
      'Code',
      'Is Valid',
      'Invalid Reason',
      'Business ID',
      'Scheme ID',
      'Business Name',
      'Type',
      'Amount Added',
      'Uses Remaining',
      'Created At',
      'Expires On',
      'Pop-up Title',
      'Pop-up Text',
    ];

    const rows: string[][] = [headings];

    let storeName = '';

    keys.forEach((key) => {
      let invalidReason = '';
      let businessName = '';
      let couponAmount = '';
      let chainId = '';
      let remaining = '';
      let validCode = true;
      let createdAt = '';
      let expiry = '';
      let type = '';
      let scheme = '';

      const validTypes = ['S', 'V', 'stamp', 'voucher'];
      const now = dayjs(Date.now()).valueOf();

      if (codePromoData[key].Amount === undefined) {
        invalidReason += ' Missing Amount';
        couponAmount = 'Not Set';
        validCode = false;
      } else {
        couponAmount = codePromoData[key].Amount.toString();
      }
      if (codePromoData[key].Scheme === undefined) {
        invalidReason += ' Missing Scheme ID';
        scheme = 'Not Set';
        validCode = false;
      } else {
        scheme = codePromoData[key].Scheme;
      }
      if (codePromoData[key]['Chain ID'] === undefined) {
        invalidReason += ' Missing Store ID';
        chainId = 'Not Set';
        validCode = false;
      } else {
        chainId = codePromoData[key]['Chain ID'];
      }
      if (codePromoData[key].Store === undefined) {
        invalidReason += ' Missing Store Name';
        businessName = 'Not set';
        validCode = false;
      } else {
        businessName = codePromoData[key].Store;
      }
      if (codePromoData[key].Type === undefined) {
        invalidReason += ' Missing Type';
        type = 'Not Set';
        validCode = false;
      }
      if (!validTypes.includes(codePromoData[key].Type)) {
        invalidReason += ' Invalid Type';
        type = codePromoData[key].Type;
        validCode = false;
      } else {
        type = codePromoData[key].Type;
        if (type === 'S') type = 'stamp';
        if (type === 'V') type = 'voucher';
      }
      if (codePromoData[key].Remaining === undefined) {
        invalidReason += ' Missing Store Name';
        remaining = 'Not Set';
        validCode = false;
      } else {
        remaining = codePromoData[key].Remaining.toString();
      }
      if (codePromoData[key].createdAt === undefined) {
        createdAt = 'Not Set';
      } else {
        const num = codePromoData[key].createdAt !== undefined ? codePromoData[key].createdAt : 1;
        createdAt = new Date(ValidateEpoch(num!)).toLocaleString();
      }
      if (codePromoData[key]['Expiry Timestamp'] === undefined) {
        invalidReason += ' Missing Expiry Date';
        expiry = 'Not Set';
        validCode = false;
      } else {
        expiry = new Date(ValidateEpoch(codePromoData[key]['Expiry Timestamp'])).toLocaleString();
      }
      if (codePromoData[key]['Expiry Timestamp'] <= now) {
        invalidReason += ' Coupon Expired';
        validCode = false;
      }

      if (searchText !== '' && storeName === '') {
        storeName = codePromoData[key].Store.split(' ').join('_');
      }
      let popupTitle = codePromoData[key]['Pop Up']?.Title;
      if (popupTitle === undefined || popupTitle === null) popupTitle = 'Not Set';
      let popupText = codePromoData[key]['Pop Up']?.['Main Text'];
      if (popupText === undefined || popupText === null) popupText = 'Not Set';

      const valid = validCode ? 'Yes' : 'No';

      const subRow: string[] = [
        key,
        valid,
        invalidReason,
        chainId,
        scheme,
        businessName,
        type,
        couponAmount,
        remaining,
        createdAt,
        expiry,
        popupTitle,
        popupText,
      ];
      rows.push(subRow);
    });

    const csv = rows.map((row) => row.map((r) => JSON.stringify(r))).join('\r\n');

    if (searchText === '') {
      fileDownload(csv, `${amount}_codes.csv`);
    } else if (isBatch) {
      fileDownload(csv, `${storeName}_${amount}_${codePromoData[0].Type}_codes.csv`);
    } else {
      fileDownload(csv, `${storeName}_${amount}_codes.csv`);
    }
  };

  useEffect(() => {
    if (isBatch) downloadFile();
  }, []);

  return (
    <Content>

      <ButtonRow>
        <BackButton onClick={goBack} />

        <StyledButton
          aria-label="download"
          variant="contained"
          startIcon={<HiDocumentDownload />}
          onClick={downloadFile}
        >
          <Typography variant="body2">Download Codes</Typography>
        </StyledButton>
      </ButtonRow>

      <SearchRow>
        <StyledSearch
          id="search-entry"
          label="Search by Business Name"
          variant="filled"
          color="primary"
          value={searchText}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            autoComplete: 'off',
            startAdornment: (
              <InputAdornment position="start">
                <AiOutlineSearch />
              </InputAdornment>
            ),
          }}
          onChange={(event) => {
            setSearchText(event.target.value.toUpperCase());
            const keys = Object.keys(codePromoData)
              .filter((key) => codePromoData[key].Store.toUpperCase().includes(event.target.value.toUpperCase()));
            setAmount(keys.length);
          }}
        />
      </SearchRow>

      <TitleComponent text="Code Promos" />
      <Grid container spacing={2}>
        {Object.keys(codePromoData)
          .filter((key) => key !== 'OldPromos' && (codePromoData[key].Store.toUpperCase()).includes(searchText))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((key) => (
            <Grid item xs={12} key={key}>
              <CodePromoCard
                codePromoId={key}
                codePromoData={codePromoData[key]}
                deleteCode={startDelete}
                startEdit={startEdit}
              />
            </Grid>
          ))}
      </Grid>

      <ConfirmDialog
        open={openDelete}
        isDelete
        handleCancel={() => {
          setOpenDelete(false);
          setSelectedCode('');
        }}
        handleConfirm={deleteCode}
        dialogTitle={`Delete Coupon : ${selectedCode}`}
        dialogText={`Are you sure you want to delete this coupon: ${selectedCode} ?
          Warning this action can not be reversed`}
      />

      {codePromoAmount > 8 ? (
        <TablePagination
          rowsPerPageOptions={[8, 16, 32]}
          component="div"
          count={amount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}

      <CodePromoEdit
        key={new Date().getTime()}
        businessData={businessData}
        isCreate={false}
        open={openEdit}
        partnerId={partnerId}
        setOpen={setOpenEdit}
        cancelCreate={cancelEdit}
        codePromoData={selectedCodePromoData}
        setCodePromoData={setCodePromoData}
        setCodeParam={setCodeParam}
        schemeId={schemeId}
        createBatch={false}
        batchAmount={0}
        couponCode={selectedCode}
      />

      <ErrorDialog
        dialogTitle={errorMessage}
        open={openError}
        handleClose={() => {
          setOpenError(false);
          setErrorMessage('');
        }}
      />
    </Content>
  );
}

export default CardPromoDetails;
