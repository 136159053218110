import React from 'react';

import PaginationTable from '../../../Components/BasicComponents/PaginationTable';

type Props = {
  voucherData: string[][];
};

function VoucherTable(props :Props) {
  const { voucherData } = props;

  const headers = [
    'Store Name',
    'Chain ID',
    'Scheme ID',
    'Scheme Max Stamps',
    'Current Stamps',
    'Current Vouchers',
    'Current Promo Vouchers',
    'Life Time Stamps',
    'Life Time Vouchers',
  ];

  const title = 'Stamps and Vouchers';

  return (
    <PaginationTable tableItems={voucherData} tableHeaders={headers} title={title} />
  );
}

export default VoucherTable;
