import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import NoPermissionPage from '../../Components/BasicComponents/NoPermissionPage';
import TagSearch from './components/TagSearch';
import TagDetails from './components/TagDetails';

import TagService from '../../Services/TagService';
import SchemeService from '../../Services/SchemeService';

function TagsPage() {
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [activationCode, setActivationCode] = useState<string>('');
  const [hasFoundTag, setHasFoundTag] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tagData, setTagData] = useState({});
  const [schemeData, setSchemeData] = useState({});
  const [pId, setPId] = useState<string>('');
  const [isTagLive, setIsTagLive] = useState<boolean>(false);
  const [partnerReroute, setPartnerReroute] = useState<string>('');
  const [tagQueryParam, setTagQueryParam] = useSearchParams();

  const permissions = ['loyalty:tags:all', 'loyalty:tags:read'];
  const canView = permissions.some((condition) => localStorage.getItem('userScopes')?.includes(condition));

  const navigate = useNavigate();

  // Will first retrieve tag data from NFCDev , then try retrieve data from NFC.
  // If tag is in NFCDev but not in NFC then the tag is not live

  const retryOnlyProduction = async (tagID:string) => {
    try {
      const foundTag:any = await TagService.getTag(tagID);
      const { partnerId } = foundTag;
      const scheme:any = await SchemeService.getSpecificScheme(partnerId, foundTag.schemeId);
      setPId(partnerId);
      setTagData(foundTag);
      setSchemeData(scheme);
      setIsTagLive(true);
      setHasFoundTag(true);
    } catch (e:any) {
      setError(true);
      if (e.message) setErrorMessage(e.message);
    }
  };

  const loadTagDetails = async (tagID:string) => {
    setTagQueryParam({ tagId: tagID });
    setIsLoading(true);
    try {
      const foundTag:any = await TagService.getTag(tagID, true);
      const { partnerId } = foundTag;
      const scheme:any = await SchemeService.getSpecificScheme(partnerId, foundTag.schemeId);
      setPId(partnerId);
      setTagData(foundTag);
      setSchemeData(scheme);
      setIsTagLive(foundTag.tagIsLive);
      setHasFoundTag(true);
    } catch (e:any) {
      retryOnlyProduction(tagID);
    }
    setIsLoading(false);
  };

  const loadByPreEncode = async (encode:string) => {
    setIsLoading(true);
    try {
      const encodeTag = await TagService.getTagIdByPreEncode(encode);
      loadTagDetails(encodeTag.tagId);
    } catch (e:any) {
      setIsLoading(false);
      setError(true);
      if (e.message) setErrorMessage(e.message);
    }
  };

  const goBack = () => {
    setError(false);
    setHasFoundTag(false);
    setTagQueryParam(undefined);
    setTagData({});
    setActivationCode('');
    setIsTagLive(false);
    if (partnerReroute !== '') navigate(`/businesses?partnerId=${partnerReroute}`);
    setPartnerReroute('');
  };

  useEffect(() => {
    const tagParam = tagQueryParam.get('tagId');
    const partnerId = tagQueryParam.get('partnerId');
    if (partnerId) setPartnerReroute(partnerId);
    if (tagParam) loadTagDetails(tagParam);
  }, []);

  if (!canView) return <NoPermissionPage />;
  if (hasFoundTag) {
    return (
      <TagDetails
        goBack={goBack}
        loadTagDetails={loadTagDetails}
        isTagLive={isTagLive}
        schemeData={schemeData}
        tagData={tagData}
        activationCode={activationCode}
        partnerId={pId}
      />
    );
  }
  return (
    <TagSearch
      error={error}
      errorMessage={errorMessage}
      isLoading={isLoading}
      isLive={isTagLive}
      loadTagDetails={loadTagDetails}
      loadByPreEncode={loadByPreEncode}
      setError={setError}
      setActivationCode={setActivationCode}
    />
  );
}

export default TagsPage;
