/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';

import { styled } from '@mui/system';
import {
  Card,
  CardContent,
  Collapse,
  IconButton,
  IconButtonProps,
  Grid,
  Typography,
  Tooltip,
  Divider,
  Link,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import { FaEdit } from 'react-icons/fa';
import { OurFood } from '../../../Services/BusinessService';
import OurFoodEdit from './OurFoodEdit';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  fontSize: '30px',
  color: theme.palette.common.white,
}));

const CardTitle = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary.main,
  height: '40px',
  [theme.breakpoints.down('sm')]: {
    height: '50px',
  },
}));

const CardTitleText = styled(Typography)(({ theme }) => ({
  alignSelf: 'center',
  color: theme.palette.common.white,
}));

const ExpandedContent = styled(CardContent)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  paddingLeft: '50px',
  paddingRight: '50px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
}));

const StyledCreateButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

const StyledImage = styled('img', {})({
  height: 'auto',
  width: 'auto',
  marginBottom: '15px',
  marginRight: '20px',
  border: '1px solid',
  borderColor: 'black',
  borderRadius: '5px',
});

const ImageContainer = styled('div', {})({
  display: 'flex',
  flexDirection: 'row',
  overflowX: 'auto',
  height: '350px',
});

type Props = {
  foodData: OurFood | undefined,
  loadBusinessDetails: (id: string) => void,
  partnerId: string,
};

function OurFoodCard(props: Props) {
  const {
    foodData,
    loadBusinessDetails,
    partnerId,
  } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);

  const HandleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };
  const permissions = ['loyalty:partners:all', 'loyalty:partners:create'];
  const canCreate = permissions.some((condition) => localStorage.getItem('userScopes')?.includes(condition));

  return (
    <Card key="food">
      <CardTitle>
        <CardTitleText variant="h4">Our Food</CardTitleText>
        <div>
          <Tooltip title="Edit Our Food" placement="bottom">
            <StyledCreateButton disabled={!canCreate} size="small" onClick={() => setOpenEdit(true)}>
              <FaEdit />
            </StyledCreateButton>
          </Tooltip>
          <ExpandMore
            expand={isExpanded}
            onClick={HandleExpandClick}
            aria-expanded={isExpanded}
            aria-label="show more"
          >
            <MdExpandMore />
          </ExpandMore>
        </div>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          <Grid container spacing={2}>
            {foodData ? (
              <>
                <Grid item xs={6}>
                  <Typography variant="body2">Description Text : </Typography>
                </Grid>

                {foodData.Description ? (
                  <Grid item xs={6}>
                    <Typography variant="body1">{`${foodData.Description}`}</Typography>
                  </Grid>
                ) : (
                  <Grid item xs={6}>
                    <Typography variant="body1">Not Set</Typography>
                  </Grid>
                )}

                <Grid item xs={6}>
                  <Typography variant="body2">Menu Link : </Typography>
                </Grid>

                {foodData.Menu ? (
                  <Grid item xs={6}>
                    <Link
                      style={{ color: 'blue' }}
                      href={`${foodData.Menu}`}
                      target="_blank"
                      rel="noreferrer"
                      underline="hover"
                    >
                      {`${foodData.Menu}`}
                    </Link>
                  </Grid>
                ) : (
                  <Grid item xs={6}>
                    <Typography variant="body1">Not Set</Typography>
                  </Grid>
                )}

                <Grid item xs={6}>
                  <Typography variant="body2">Order Here Link : </Typography>
                </Grid>

                {foodData['Order Link'] ? (
                  <Grid item xs={6}>
                    <Link
                      style={{ color: 'blue' }}
                      href={`${foodData['Order Link']}`}
                      target="_blank"
                      rel="noreferrer"
                      underline="hover"
                    >
                      {`${foodData['Order Link']}`}
                    </Link>
                  </Grid>
                ) : (
                  <Grid item xs={6}>
                    <Typography variant="body1">Not Set</Typography>
                  </Grid>
                )}

                <Grid item xs={6}>
                  <Typography variant="body2">Images : </Typography>
                </Grid>

                {foodData.Images ? (
                  <Grid item xs={12}>
                    <ImageContainer>
                      {foodData.Images?.filter((notNull) => notNull).map((image) => (
                        <StyledImage src={image} alt={image} key={`Our-Food-${image}`} />
                      ))}
                    </ImageContainer>
                  </Grid>
                ) : (
                  <Grid item xs={6}>
                    <Typography variant="body1">No Images Uploaded</Typography>
                  </Grid>
                )}
              </>
            ) : (
              <Grid item container xs={12} justifyContent="center">
                <Typography variant="body1">Not Configured</Typography>
              </Grid>
            )}
          </Grid>
        </ExpandedContent>
      </Collapse>

      <OurFoodEdit
        key={new Date().getTime()}
        loadBusinessDetails={loadBusinessDetails}
        partnerId={partnerId}
        setOpen={setOpenEdit}
        open={openEdit}
        foodData={foodData}
      />
    </Card>
  );
}

export default OurFoodCard;
