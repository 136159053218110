import React, { useState, useEffect } from 'react';

import { styled } from '@mui/system';
import {
  Card,
  CardContent,
  Collapse,
  IconButton,
  IconButtonProps,
  Grid,
  Typography,
  Tooltip,
  InputAdornment,
  TextField,
  Button,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import { AiOutlineSearch } from 'react-icons/ai';
import { HiDocumentDownload } from 'react-icons/hi';
import Content from '../../../Components/BasicComponents/Content';
import BackButton from '../../../Components/BasicComponents/BackButton';
import { ReturnedTagPromo, TagPromos, TagPromoById } from '../../../Services/PromoService';
import ValidateEpoch from '../../../util/validateEpoch';
import TagPromoDetails from './TagPromoDetails';

const fileDownload = require('js-file-download');

const ButtonRow = styled('div', {})({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const SearchRow = styled('div', {})({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
});

const StyledSearch = styled(TextField)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.common.white,
  marginBottom: '30px',
  width: '400px',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  height: '45px',
  width: '280px',
  backgroundColor: theme.palette.secondary.main,
  position: 'inherit',
  [theme.breakpoints.down('sm')]: {
    height: '35px',
    width: '90px',
  },
}));

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  fontSize: '30px',
  color: theme.palette.common.white,
}));

const CardTitle = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary.main,
  height: '40px',
  [theme.breakpoints.down('sm')]: {
    height: '50px',
  },
}));

const CardTitleText = styled(Typography)(({ theme }) => ({
  alignSelf: 'center',
  color: theme.palette.common.white,
}));

const ExpandedContent = styled(CardContent)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  paddingLeft: '50px',
  paddingRight: '50px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
}));

const StyledDownloadButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

const StyledAmount = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: 'bold',
}));

const StyledAmountContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  backgroundColor: theme.palette.secondary.main,
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px',
  width: '55px',
  height: '30px',
  borderRadius: '5px',
  marginRight: '15px',
}));

const ButtonContainer = styled('div', {})({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginLeft: 'auto',
});

type Props = {
  goBack: () => void,
  tagPromoData: TagPromos,
  setSingleTagPromo: (tagPromo: TagPromoById) => void,
  setTagParam: (id: string) => void,
  openError: boolean,
  setOpenError: (toggle: boolean) => void,
  errorMessage: string,
  setErrorMessage: (message: string) => void,
  tagParam: string | null,
  loadAllTags: () => void,
};

function TagPromoAll(props: Props) {
  const {
    goBack,
    tagPromoData,
    setSingleTagPromo,
    setTagParam,
    tagParam,
    openError,
    setOpenError,
    errorMessage,
    setErrorMessage,
    loadAllTags,
  } = props;

  const [searchText, setSearchText] = useState<string>('');
  const [activePromos, setActivePromos] = useState<ReturnedTagPromo[]>(tagPromoData.activePromos);
  const [activeAmount, setActiveAmount] = useState<number>(tagPromoData.activePromos.length);
  const [activeExpand, setActiveExpand] = useState<boolean>(false);
  const [expiredPromos, setExpiredPromos] = useState<ReturnedTagPromo[]>(tagPromoData.expiredPromos);
  const [expiredAmount, setExpiredAmount] = useState<number>(tagPromoData.expiredPromos.length);
  const [expiredExpand, setExpiredExpand] = useState<boolean>(false);
  const [notStartedPromos, setNotStartedPromos] = useState<ReturnedTagPromo[]>(tagPromoData.notStartedPromos);
  const [notStartedAmount, setNotStartedAmount] = useState<number>(tagPromoData.notStartedPromos.length);
  const [notStartedExpand, setNotStartedExpand] = useState<boolean>(false);
  const [usedPromos, setUsedPromos] = useState<ReturnedTagPromo[]>(tagPromoData.rewardsExceededPromos);
  const [usedAmount, setUsedAmount] = useState<number>(tagPromoData.rewardsExceededPromos.length);
  const [usedExpand, setUsedExpand] = useState<boolean>(false);

  const handleFilter = (search: string) => {
    setSearchText(search.toUpperCase());
    const active = tagPromoData.activePromos.filter((promo) => promo.partnerId !== null
    && promo.partnerId.toUpperCase()
      .includes(search.toUpperCase()));
    setActivePromos(active);
    setActiveAmount(active.length);

    const expired = tagPromoData.expiredPromos.filter((promo) => promo.partnerId !== null
    && promo.partnerId.toUpperCase()
      .includes(search.toUpperCase()));
    setExpiredPromos(expired);
    setExpiredAmount(expired.length);

    const notStarted = tagPromoData.notStartedPromos.filter((promo) => promo.partnerId !== null
    && promo.partnerId.toUpperCase()
      .includes(search.toUpperCase()));
    setNotStartedPromos(notStarted);
    setNotStartedAmount(notStarted.length);

    const used = tagPromoData.rewardsExceededPromos.filter((promo) => promo.partnerId !== null
    && promo.partnerId.toUpperCase()
      .includes(search.toUpperCase()));
    setUsedPromos(used);
    setUsedAmount(used.length);
  };

  const downloadFile = (
    categories: Array<'activePromos' | 'expiredPromos' | 'notStartedPromos' | 'rewardsExceededPromos'>,
  ) => {
    const headings: string[] = [
      'Status',
      'Date Created',
      'Tag Promo ID',
      'Business ID',
      'Scheme ID',
      'Type',
      'Stamps Added',
      'Uses Remaining',
      'Criteria',
      'Available Days',
      'Description',
      'Location Specific',
      'Start Date',
      'Expiry Date',
      'Pop-up Title',
      'Pop-up Info',
      'Pop-up Details',
      'Number of Claimed Vouchers',
      'Date of first Claim',
      'Date of last Claim',
      'Claims on Start Day of Promo',
      'Claims on End Day of Promo',
      'Number of Redeemed Vouchers',
      'Date of first Redemption',
      'Date of last Redemption',
      'Redemptions on End Day of Promo',
      'New Users Entire Promo',
      'New Users Day 7',
      'New Users Day 14',
      'New Users Day 21',
      'New Users Day 30',
    ];

    const rows: string[][] = [headings];

    categories.forEach((category) => {
      const filteredPromos = tagPromoData[category].filter(
        (key) => key.partnerId !== null && (key.partnerId.toUpperCase()).includes(searchText),
      );

      filteredPromos.forEach((promo) => {
        let createdTime = 'Not available';
        const { status } = promo;
        const tagPromoId = promo.promotionId;
        const businessId = promo.partnerId;
        const scheme = promo.schemeId;
        const type = promo.promoType;
        let amountStamps = '';
        let rewardsRemaining = '';
        const { criteria } = promo;
        let days = 'All';
        const description = promo.description ? promo.description : '';
        const location = promo.locationIds ? promo.locationIds : '';
        const startDate = new Date(ValidateEpoch(promo.startTime)).toLocaleString();
        const endDate = new Date(ValidateEpoch(promo.endTime)).toLocaleString();
        let title = '';
        let info = '';
        let details = '';
        let claimedVouchers = '';
        let dateFirstClaim = '';
        let dateLastClaim = '';
        let claimsOnStart = '';
        let claimsOnEnd = '';
        let redeemedVouchers = '';
        let dateFirstRedeem = '';
        let dateLastRedeem = '';
        let redeemOnEnd = '';
        let newUsersTotal = '0';
        let newUsers7 = '0';
        let newUsers14 = '0';
        let newUsers21 = '0';
        let newUsers30 = '0';

        if (promo.createTime) {
          createdTime = new Date(ValidateEpoch(promo.createTime)).toLocaleString();
        }
        if (promo.promoType === 'stamp' && promo.amount) {
          amountStamps = promo.amount.toString();
        }
        if (promo.vouchersRemaining) {
          rewardsRemaining = promo.vouchersRemaining.toString();
        }
        if (promo.rewardsRemaining) {
          rewardsRemaining = promo.rewardsRemaining.toString();
        }
        if (promo.availabilityDays && promo.availabilityDays.length > 0) {
          days = promo.availabilityDays.join(', ');
        }
        if (promo.voucherEarnedModal) {
          title = promo.voucherEarnedModal.title ? title = promo.voucherEarnedModal.title : '';
          info = promo.voucherEarnedModal.voucherInformation ? promo.voucherEarnedModal.voucherInformation : '';
          details = promo.voucherEarnedModal.rewardDetails ? promo.voucherEarnedModal.rewardDetails : '';
        }
        if (promo.analytics.claimedVouchers > 0) {
          claimedVouchers = promo.analytics.claimedVouchers.toString();
          if (promo.analytics.firstClaimTimestamp) {
            dateFirstClaim = promo.analytics.firstClaimTimestamp;
          }
          if (promo.analytics.lastClaimTimestamp) {
            dateLastClaim = promo.analytics.lastClaimTimestamp;
          }
        }
        if (promo.analytics.claimsOnStart) {
          claimsOnStart = promo.analytics.claimsOnStart.toString();
        }
        if (promo.analytics.claimsOnExpiry) {
          claimsOnEnd = promo.analytics.claimsOnExpiry.toString();
        }
        if (promo.analytics.redeemedVouchers && promo.analytics.redeemedVouchers > 0) {
          redeemedVouchers = promo.analytics.redeemedVouchers.toString();
          if (promo.analytics.firstRedeemTimestamp) {
            dateFirstRedeem = promo.analytics.firstRedeemTimestamp;
          }
          if (promo.analytics.lastRedeemTimestamp) {
            dateLastRedeem = promo.analytics.lastRedeemTimestamp;
          }
        }
        if (promo.analytics.redemptionOnExpiry) {
          redeemOnEnd = promo.analytics.redemptionOnExpiry.toString();
        }
        if (promo.analytics.newUsersEntirePromoPeriod) {
          newUsersTotal = promo.analytics.newUsersEntirePromoPeriod.toString();
        }
        if (promo.analytics.newUsers7days) {
          newUsers7 = promo.analytics.newUsers7days.toString();
        }
        if (promo.analytics.newUsers14days) {
          newUsers14 = promo.analytics.newUsers14days.toString();
        }
        if (promo.analytics.newUsers21days) {
          newUsers21 = promo.analytics.newUsers21days.toString();
        }
        if (promo.analytics.newUsers30days) {
          newUsers30 = promo.analytics.newUsers30days.toString();
        }

        const subRow: string[] = [
          status,
          createdTime,
          tagPromoId,
          businessId,
          scheme,
          type,
          amountStamps,
          rewardsRemaining,
          criteria,
          days,
          description,
          location,
          startDate,
          endDate,
          title,
          info,
          details,
          claimedVouchers,
          dateFirstClaim,
          dateLastClaim,
          claimsOnStart,
          claimsOnEnd,
          redeemedVouchers,
          dateFirstRedeem,
          dateLastRedeem,
          redeemOnEnd,
          newUsersTotal,
          newUsers7,
          newUsers14,
          newUsers21,
          newUsers30,
        ];
        rows.push(subRow);
      });
      rows.push([]);
    });
    const csv = rows.map((row) => row.map((r) => JSON.stringify(r))).join('\r\n');

    fileDownload(csv, 'tag_promos.csv');
  };

  useEffect(() => {
    handleFilter(searchText);
  }, [tagPromoData]);

  return (
    <Content>
      <ButtonRow>
        <BackButton onClick={goBack} />

        <StyledButton
          aria-label="download"
          variant="contained"
          startIcon={<HiDocumentDownload />}
          onClick={() => downloadFile(['activePromos', 'expiredPromos', 'notStartedPromos', 'rewardsExceededPromos'])}
        >
          <Typography variant="body2">Download All Promos</Typography>
        </StyledButton>
      </ButtonRow>
      <SearchRow>
        <StyledSearch
          id="search-entry"
          label="Filter by Business Id"
          variant="filled"
          color="primary"
          value={searchText}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            autoComplete: 'off',
            startAdornment: (
              <InputAdornment position="start">
                <AiOutlineSearch />
              </InputAdornment>
            ),
          }}
          onChange={(event) => handleFilter(event.target.value)}
        />
      </SearchRow>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card key={1}>
            <CardTitle>
              <CardTitleText variant="h4">Active Promos</CardTitleText>
              <ButtonContainer>
                <StyledAmountContainer>
                  <StyledAmount>{activeAmount}</StyledAmount>
                </StyledAmountContainer>

                {activePromos && activePromos.length > 0 ? (
                  <Tooltip
                    title="Download Active Promos"
                    placement="bottom"
                  >
                    <StyledDownloadButton
                      size="small"
                      onClick={() => downloadFile(['activePromos'])}
                    >
                      <HiDocumentDownload />
                    </StyledDownloadButton>
                  </Tooltip>
                ) : null}

                <ExpandMore
                  expand={activeExpand}
                  onClick={() => setActiveExpand(!activeExpand)}
                  aria-expanded={activeExpand}
                  aria-label="show more"
                >
                  <MdExpandMore />
                </ExpandMore>
              </ButtonContainer>
            </CardTitle>
            <Collapse in={activeExpand}>
              <ExpandedContent>
                {activePromos.length > 0 ? (
                  <TagPromoDetails
                    goBack={goBack}
                    tagPromoData={activePromos}
                    tagPromoAmount={activeAmount}
                    setSingleTagPromo={setSingleTagPromo}
                    setTagParam={setTagParam}
                    openError={openError}
                    setOpenError={setOpenError}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    tagParam={tagParam}
                    loadAllTags={loadAllTags}
                  />
                ) : (
                  <Typography>No Active Promos</Typography>
                )}
              </ExpandedContent>
            </Collapse>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card key={2}>
            <CardTitle>
              <CardTitleText variant="h4">Promos that have not yet Started</CardTitleText>
              <ButtonContainer>
                <StyledAmountContainer>
                  <StyledAmount>{notStartedAmount}</StyledAmount>
                </StyledAmountContainer>

                {notStartedPromos && notStartedPromos.length > 0 ? (
                  <Tooltip title="Download not started Promos" placement="bottom">
                    <StyledDownloadButton
                      disabled={!notStartedPromos || notStartedPromos.length < 1}
                      size="small"
                      onClick={() => downloadFile(['notStartedPromos'])}
                    >
                      <HiDocumentDownload />
                    </StyledDownloadButton>
                  </Tooltip>
                ) : null}

                <ExpandMore
                  expand={notStartedExpand}
                  onClick={() => setNotStartedExpand(!notStartedExpand)}
                  aria-expanded={notStartedExpand}
                  aria-label="show more"
                >
                  <MdExpandMore />
                </ExpandMore>
              </ButtonContainer>
            </CardTitle>
            <Collapse in={notStartedExpand}>
              <ExpandedContent>
                {notStartedPromos.length > 0 ? (
                  <TagPromoDetails
                    goBack={goBack}
                    tagPromoData={notStartedPromos}
                    tagPromoAmount={notStartedAmount}
                    setSingleTagPromo={setSingleTagPromo}
                    setTagParam={setTagParam}
                    openError={openError}
                    setOpenError={setOpenError}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    tagParam={tagParam}
                    loadAllTags={loadAllTags}
                  />
                ) : (
                  <Typography>No Promos awaiting start date</Typography>
                )}
              </ExpandedContent>
            </Collapse>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card key={1}>
            <CardTitle>
              <CardTitleText variant="h4">Promos with no more Rewards</CardTitleText>
              <ButtonContainer>
                <StyledAmountContainer>
                  <StyledAmount>{usedAmount}</StyledAmount>
                </StyledAmountContainer>

                {usedPromos && usedPromos.length > 0 ? (
                  <Tooltip title="Download used Promos" placement="bottom">
                    <StyledDownloadButton
                      disabled={!usedPromos || usedPromos.length < 1}
                      size="small"
                      onClick={() => downloadFile(['rewardsExceededPromos'])}
                    >
                      <HiDocumentDownload />
                    </StyledDownloadButton>
                  </Tooltip>
                ) : null}

                <ExpandMore
                  expand={usedExpand}
                  onClick={() => setUsedExpand(!usedExpand)}
                  aria-expanded={usedExpand}
                  aria-label="show more"
                >
                  <MdExpandMore />
                </ExpandMore>
              </ButtonContainer>
            </CardTitle>
            <Collapse in={usedExpand}>
              <ExpandedContent>
                {usedPromos.length > 0 ? (
                  <TagPromoDetails
                    goBack={goBack}
                    tagPromoData={usedPromos}
                    tagPromoAmount={usedAmount}
                    setSingleTagPromo={setSingleTagPromo}
                    setTagParam={setTagParam}
                    openError={openError}
                    setOpenError={setOpenError}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    tagParam={tagParam}
                    loadAllTags={loadAllTags}
                  />
                ) : (
                  <Typography>No used Promos</Typography>
                )}
              </ExpandedContent>
            </Collapse>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card key={1}>
            <CardTitle>
              <CardTitleText variant="h4">Expired Promos</CardTitleText>
              <ButtonContainer>
                <StyledAmountContainer>
                  <StyledAmount>{expiredAmount}</StyledAmount>
                </StyledAmountContainer>

                {expiredPromos && expiredPromos.length > 0 ? (
                  <Tooltip title="Download expired Promos" placement="bottom">
                    <StyledDownloadButton
                      size="small"
                      onClick={() => downloadFile(['expiredPromos'])}
                    >
                      <HiDocumentDownload />
                    </StyledDownloadButton>
                  </Tooltip>
                ) : null}

                <ExpandMore
                  expand={expiredExpand}
                  onClick={() => setExpiredExpand(!expiredExpand)}
                  aria-expanded={expiredExpand}
                  aria-label="show more"
                >
                  <MdExpandMore />
                </ExpandMore>
              </ButtonContainer>
            </CardTitle>
            <Collapse in={expiredExpand}>
              <ExpandedContent>
                {expiredPromos.length > 0 ? (
                  <TagPromoDetails
                    goBack={goBack}
                    tagPromoData={expiredPromos}
                    tagPromoAmount={expiredAmount}
                    setSingleTagPromo={setSingleTagPromo}
                    setTagParam={setTagParam}
                    openError={openError}
                    setOpenError={setOpenError}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    tagParam={tagParam}
                    loadAllTags={loadAllTags}
                  />
                ) : (
                  <Typography>No expired Promos</Typography>
                )}
              </ExpandedContent>
            </Collapse>
          </Card>
        </Grid>
      </Grid>
    </Content>
  );
}

export default TagPromoAll;
