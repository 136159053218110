import React, { useState } from 'react';

import { styled } from '@mui/system';
import {
  Divider,
  TextField,
  InputAdornment,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { IoSearchOutline } from 'react-icons/io5';

import Content from '../../../Components/BasicComponents/Content';
import TitleComponent from '../../../Components/BasicComponents/TitleComponent';
import BusinessService from '../../../Services/BusinessService';
import BusinessButton from './BusinessButton';

const SearchContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  height: '55px',
  maxWidth: '150px',
  marginLeft: '15px',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    marginLeft: '0px',
    marginTop: '20px',
    height: '35px',
  },
}));

const StyledDivider = styled(Divider, {})({
  paddingTop: '20px',
});

type Props = {
  error: boolean,
  errorMessage: string,
  isLoading: boolean,
  setError: (bool: boolean) => void,
  loadBusinessDetails: (tagId: string) => void,
};

function BusinessSearch(props: Props) {
  const {
    error, errorMessage, isLoading, loadBusinessDetails, setError,
  } = props;

  const [partnerId, setPartnerId] = useState<string>('');
  const [searchError, setSearchError] = useState<boolean>(false);
  const [searchErrorMessage, setSearchErrorMessage] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);
  const [searchData, setSearchData] = useState([]);
  const [hasSearched, setHasSearched] = useState<boolean>(false);

  const fuzzySearch = async () => {
    setIsSearchLoading(true);
    try {
      const searchResults:any = await BusinessService.fuzzyBusinessSearch(searchQuery);

      // fuzzy search can return the same business in multiple locations leading
      // too 'duplicates' appearing in the options. This removes any duplicates with
      // the same parent id
      const filteredSearchResults = searchResults
        .filter((store:any, index: any, array: any[]) => array
          .findIndex((s:any) => s.parentChain === store.parentChain) === index);

      setSearchData(filteredSearchResults);
      setIsSearchLoading(false);
    } catch (e:any) {
      setError(true);
      if (e.message) setSearchErrorMessage(e.message);
      setIsSearchLoading(false);
    }
  };

  return (
    <Content>
      <TitleComponent text="Search for a Specific Business" />
      <SearchContainer>
        <TextField
          fullWidth
          id="business-search"
          label="Business/Partner ID"
          variant="outlined"
          color="primary"
          InputLabelProps={{ shrink: true }}
          value={partnerId}
          InputProps={{
            autoComplete: 'off',
            startAdornment: (
              <InputAdornment position="start">
                <IoSearchOutline />
              </InputAdornment>
            ),
          }}
          error={error}
          helperText={error ? errorMessage : ''}
          onChange={(event) => {
            if (error) setError(false);
            setPartnerId(event.target.value.toUpperCase());
          }}
          onKeyDown={(key) => {
            if (key.key === 'Enter') loadBusinessDetails(partnerId);
          }}
        />

        <StyledLoadingButton
          loading={isLoading}
          color="primary"
          disabled={partnerId === ''}
          variant="contained"
          fullWidth
          onClick={() => {
            loadBusinessDetails(partnerId);
          }}
        >
          <Typography variant="body2">SEARCH</Typography>
        </StyledLoadingButton>
      </SearchContainer>

      <TitleComponent text="General Search for a Business Name" />
      <SearchContainer>
        <TextField
          fullWidth
          id="business-search"
          label="Business Name"
          variant="outlined"
          color="primary"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            autoComplete: 'off',
            startAdornment: (
              <InputAdornment position="start">
                <IoSearchOutline />
              </InputAdornment>
            ),
          }}
          helperText={searchError ? searchErrorMessage : ''}
          onChange={(event) => {
            if (searchError) setSearchError(false);
            if (hasSearched) setHasSearched(false);
            setSearchQuery(event.target.value);
          }}
          onKeyDown={(key) => {
            if (key.key === 'Enter') {
              setHasSearched(true);
              fuzzySearch();
            }
          }}
        />

        <StyledLoadingButton
          loading={isSearchLoading}
          color="primary"
          disabled={searchQuery === ''}
          variant="contained"
          fullWidth
          onClick={() => {
            setHasSearched(true);
            fuzzySearch();
          }}
        >
          <Typography variant="body2">SEARCH</Typography>
        </StyledLoadingButton>
      </SearchContainer>

      {searchData && hasSearched ? <StyledDivider light /> : null }
      {searchData && hasSearched ? (
        searchData?.filter((value, index, self) => self.indexOf(value) === index)
          .map((store, index) => (
            <BusinessButton key={index} storeData={store} onClick={loadBusinessDetails} />
          ))
      ) : (
        null
      )}
    </Content>
  );
}

export default BusinessSearch;
