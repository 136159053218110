import React from 'react';

import { Button, Typography } from '@mui/material';
import { styled } from '@mui/system';

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: '15px',
  height: '45px',
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.down('sm')]: {
    height: '35px',
  },
}));

type Props = {
  onClick: (id:string) => void;
  storeData: any,
};

function BusinessButton(props: Props) {
  const { onClick, storeData } = props;

  return (
    <StyledButton
      aria-label="back"
      variant="contained"
      fullWidth
      onClick={() => onClick(storeData.parentChain)}
    >
      <Typography variant="body2">{`${storeData.name} : ${storeData.id.split('-')[0]}`}</Typography>
    </StyledButton>
  );
}

export default BusinessButton;
