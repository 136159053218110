import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
  Grid,
} from '@mui/material';
import { styled } from '@mui/system';

const StyledDialog = styled(DialogContent, {})({
  height: '160px',
  display: 'flex',
  alignItems: 'center',
});

const StyledTitle = styled(DialogTitle, {})({
  fontWeight: 'bold',
  alignSelf: 'center',
});

type Props = {
  dialogTitle: string,
  open: boolean,
  saving?: boolean,
};

function SavingChangesDialog(props: Props) {
  const {
    dialogTitle, open, saving,
  } = props;

  return (
    <Dialog
      aria-label="confirm-dialog"
      open={open}
      fullWidth
      maxWidth="sm"
      sx={{ zIndex: '7000' }}
    >
      <StyledTitle>{dialogTitle}</StyledTitle>
      <StyledDialog>
        <Grid container spacing={4}>
          {saving ? (
            <Grid item container xs={12} justifyContent="center">
              <Typography variant="body1">
                Saving changes, this may take a few moments
              </Typography>
            </Grid>
          ) : null}
          <Grid item container xs={12} justifyContent="center">
            <CircularProgress />
          </Grid>
        </Grid>
      </StyledDialog>
    </Dialog>
  );
}

export default SavingChangesDialog;
