/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';

import {
  Grid,
  Button,
  Typography,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/system';
import {
  FaRegUserCircle,
  FaEnvelope,
  FaCalendarAlt,
  FaMobile,
  FaTransgenderAlt,
  FaTicketAlt,
  FaStamp,
  FaRegIdBadge,
  FaSignInAlt,
} from 'react-icons/fa';
import { IoNotifications } from 'react-icons/io5';
import { RiChatNewFill } from 'react-icons/ri';
import { BsFillPersonPlusFill } from 'react-icons/bs';
import ValidateEpoch from '../../../util/validateEpoch';
import Content from '../../../Components/BasicComponents/Content';
import TitleComponent from '../../../Components/BasicComponents/TitleComponent';
import IconWithText from '../../../Components/BasicComponents/IconWithText';
import ActivityTable from './ActivityTable';
import VoucherTable from './VoucherTable';
import BackButton from '../../../Components/BasicComponents/BackButton';
import EditButton from '../../../Components/BasicComponents/EditButton';
import UserEditDialog from './UserEditDialog';
import AddStamps from './AddStamps';

const StyledGrid = styled(Grid)(({ theme }) => ({
  marginBottom: '20px',
  paddingLeft: '15px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '0px',
  },
}));

const ButtonRow = styled('div', {})({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const ButtonRow2 = styled('div', {})({
  display: 'flex',
  flexDirection: 'row',
});

const StyledCreateButton = styled(Button)(({ theme }) => ({
  height: '45px',
  marginRight: '15px',
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
  [theme.breakpoints.down('sm')]: {
    height: '35px',
  },
}));

type Props = {
  goBack: () => void,
  updateUser: (id: string, user: {}) => void,
  userData: any,
  userId: string,
  walletData: any,
};

function UserDetails(props: Props) {
  const {
    goBack,
    userData,
    updateUser,
    userId,
    walletData,
  } = props;

  const [storeData, setStoreData] = useState<string[][]>([]);
  const [totalVouchers, setTotalVouchers] = useState<number>(0);
  const [totalStamps, setTotalStamps] = useState<number>(0);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openAddStamps, setOpenAddStamps] = useState<boolean>(false);

  const permissions = ['loyalty:account:all', 'loyalty:account:update'];
  const addStampsPermissions = ['loyalty:wallet:admin', 'wallet:admin'];
  const canEdit = permissions.some((condition) => localStorage.getItem('userScopes')?.includes(condition));
  const canAddStamps = addStampsPermissions.some(
    (condition) => localStorage.getItem('userScopes')?.includes(condition),
  );

  const hasInteractionLog: boolean = userData['Interaction Log'] !== undefined;

  useEffect(() => {
    if (walletData.wallets !== undefined) {
      const wallet = walletData.wallets;
      const summary: string[][] = [];
      let stampTotal = 0;
      let voucherTotal = 0;

      let i = 0;
      while (i < wallet.length) {
        let j = 0;
        while (j < wallet[i].schemes.length) {
          const allVouchers = Math.floor(wallet[i].schemes[j].stampcard.lifetimeCount
            / wallet[i].schemes[j].max);

          let promoVouchers = 0;
          if (wallet[i].vouchers.length > 0) {
            let k = 0;
            while (k < wallet[i].vouchers.length) {
              if (wallet[i].vouchers[k].category === 'Promo') promoVouchers += 1;
              k += 1;
            }
          }

          const temp = [
            wallet[i].pocket.name,
            wallet[i].chainId,
            wallet[i].schemes[j].schemeId,
            wallet[i].schemes[j].max.toString(),
            wallet[i].schemes[j].stampcard.count.toString(),
            wallet[i].vouchers.length.toString(),
            promoVouchers.toString(),
            wallet[i].schemes[j].stampcard.lifetimeCount.toString(),
            allVouchers.toString(),
          ];

          stampTotal += wallet[i].schemes[j].stampcard.lifetimeCount;
          voucherTotal += allVouchers;

          summary.push(temp);
          j += 1;
        }
        i += 1;
      }

      setStoreData(summary);
      setTotalVouchers(voucherTotal);
      setTotalStamps(stampTotal);
    }
  }, [walletData]);

  return (
    <Content>
      <ButtonRow>
        <BackButton onClick={goBack} />
        <ButtonRow2>
          <Tooltip
            title={canAddStamps ? 'Add stamps to this user' : 'Do not have permissions to add stamps'}
            placement="bottom"
          >
            <StyledCreateButton
              aria-label="add-stamps"
              variant="contained"
              fullWidth
              startIcon={<RiChatNewFill />}
              onClick={() => setOpenAddStamps(true)}
              disabled={!canAddStamps}
            >
              <Typography variant="body2">Add Stamps</Typography>
            </StyledCreateButton>
          </Tooltip>
          <EditButton disabled={!canEdit} onClick={() => setOpenEdit(!openEdit)} />
        </ButtonRow2>
      </ButtonRow>

      <TitleComponent text="User Info" />

      {/* each one of these is a group of Icon and some user detail */}
      <StyledGrid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <IconWithText description="User Id" icon={<FaRegIdBadge />} text={userId} />
        </Grid>

        {userData.ProviderTypes && userData.ProviderTypes.length > 0 ? (
          <Grid item xs={12} sm={6}>
            <IconWithText
              description="Sign in Method"
              icon={<FaSignInAlt />}
              text={userData.ProviderTypes.join(', ')}
            />
          </Grid>
        ) : (
          <Grid item xs={12} sm={6}>
            <IconWithText
              description="Sign in Method"
              icon={<FaSignInAlt />}
              text="Not currently set"
            />
          </Grid>
        )}

        {userData['Account Created'] ? (
          <Grid item xs={12} sm={6}>
            <IconWithText
              description="Account Created"
              icon={<BsFillPersonPlusFill />}
              text={new Date(ValidateEpoch(userData['Account Created'])).toLocaleString()}
            />
          </Grid>
        ) : null}

        <Grid item xs={12} sm={6}>
          <IconWithText description="Name" icon={<FaRegUserCircle />} text={userData.Name} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <IconWithText description="Email" icon={<FaEnvelope />} text={userData.Email} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <IconWithText description="Date of Birth" icon={<FaCalendarAlt />} text={userData['Date of Birth']} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <IconWithText
            description="Gender"
            icon={<FaTransgenderAlt />}
            text={userData.Gender === 'rather' ? 'rather not say' : userData.Gender}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <IconWithText
            description="Send Offers"
            icon={<IoNotifications />}
            text={userData['Send Offers'] === true ? 'Yes' : 'No'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <IconWithText description="Total Vouchers Earned" icon={<FaTicketAlt />} text={String(totalVouchers)} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <IconWithText description="Total Stamps Earned" icon={<FaStamp />} text={String(totalStamps)} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <IconWithText description="App Version" icon={<FaMobile />} text={userData['App Version']} />
        </Grid>
      </StyledGrid>

      {walletData !== undefined && walletData.wallets.length > 0 ? (
        <VoucherTable voucherData={storeData} />
      ) : null}
      {/* Stamps and Vouchers table and interaction log table */}
      {hasInteractionLog ? (
        <ActivityTable activityLog={userData['Interaction Log']} />
      ) : (
        <div>No Stamps Yet</div>
      )}

      <UserEditDialog
        dateOfBirth={userData['Date of Birth']}
        gender={userData.Gender}
        name={userData.Name}
        sendOffers={userData['Send Offers']}
        open={openEdit}
        setOpen={setOpenEdit}
        updateUser={updateUser}
        userId={userId}
      />

      <AddStamps
        uid={userId}
        openAddStamps={openAddStamps}
        setOpenAddStamps={setOpenAddStamps}
        updateUser={updateUser}
      />

    </Content>
  );
}

export default UserDetails;
