import React, { useState } from 'react';

import { styled } from '@mui/system';
import {
  Card,
  CardContent,
  Collapse,
  IconButton,
  IconButtonProps,
  Grid,
  Typography,
  Tooltip,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import { BsPlusSquareFill } from 'react-icons/bs';

import ErrorDialog from '../../../Components/BasicComponents/ErrorDialog';
import LocationService from '../../../Services/LocationService';
import LocationDetailsCard from './LocationDetailsCard';
import ConfirmDialog from '../../../Components/BasicComponents/ConfirmDialog';
import LocationEdit from './LocationEdit';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  fontSize: '30px',
  color: theme.palette.common.white,
}));

const CardTitle = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary.main,
  height: '40px',
  [theme.breakpoints.down('sm')]: {
    height: '50px',
  },
}));

const CardTitleText = styled(Typography)(({ theme }) => ({
  alignSelf: 'center',
  color: theme.palette.common.white,
}));

const ExpandedContent = styled(CardContent)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  paddingLeft: '50px',
  paddingRight: '50px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
}));

const StyledCreateButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

type Props = {
  isLive: boolean,
  loadBusinessDetails: (id: string) => void,
  partnerId: string,
  locations: any | undefined,
  nfcList: any | undefined,
};

function LocationCard(props: Props) {
  const {
    isLive,
    loadBusinessDetails,
    partnerId,
    locations,
    nfcList,
  } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [selectedLocationId, setSelectedLocationId] = useState<string>('');
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');

  const HandleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const startCreate = () => {
    setIsCreate(true);
    setOpenEdit(true);
  };

  const startEdit = (id:string) => {
    setSelectedLocationId(id);
    setIsCreate(false);
    setOpenEdit(true);
  };

  const startDelete = (id:string) => {
    setSelectedLocationId(id);
    setOpenDelete(true);
  };

  const deleteLocation = async () => {
    setOpenDelete(false);
    try {
      await LocationService.deleteLocation(partnerId, selectedLocationId);
      loadBusinessDetails(partnerId);
    } catch (e:any) {
      setErrorText(e.message);
      setOpenErrorDialog(true);
    }
  };

  const handleCancel = () => {
    setOpenDelete(false);
    setOpenEdit(false);
    setIsCreate(false);
    setSelectedLocationId('');
  };

  const permissions = ['loyalty:partners:all', 'loyalty:partners:create'];
  const canCreate = permissions.some((condition) => localStorage.getItem('userScopes')?.includes(condition));

  return (
    <Card key="locations">
      <CardTitle>
        <CardTitleText variant="h4">Business Locations</CardTitleText>
        <div>
          <Tooltip title="Create a new Location" placement="bottom">
            <StyledCreateButton disabled={!canCreate} size="small" onClick={startCreate}>
              <BsPlusSquareFill />
            </StyledCreateButton>
          </Tooltip>
          <ExpandMore
            expand={isExpanded}
            onClick={HandleExpandClick}
            aria-expanded={isExpanded}
            aria-label="show more"
          >
            <MdExpandMore />
          </ExpandMore>
        </div>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          {locations !== undefined ? (
            <Grid container spacing={2}>
              {Object.entries(locations).map((location: any) => (
                <Grid item xs={12} key={location[0]}>
                  <LocationDetailsCard
                    partnerLive={isLive}
                    partnerId={partnerId}
                    locationId={location[0]}
                    address={location[1].Address}
                    hourList={location[1].HoursList}
                    lat={location[1].Lat}
                    long={location[1].Long}
                    shortAddress={location[1]['Short Address']}
                    googlePlaceId={location[1]['Google Place ID'] ? location[1]['Google Place ID'] : undefined}
                    nfcData={nfcList ? nfcList[location[0]] : undefined}
                    startEdit={startEdit}
                    startDelete={startDelete}
                    isLive={location[1].isLive}
                    loadBusinessDetails={loadBusinessDetails}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid item container justifyContent="center">
              <Typography variant="body2">No Locations set up for this business</Typography>
            </Grid>
          )}
        </ExpandedContent>
      </Collapse>

      <ErrorDialog
        open={openErrorDialog}
        dialogTitle=""
        dialogText={errorText}
        handleClose={() => {
          setOpenErrorDialog(false);
          setErrorText('');
        }}
      />

      <ConfirmDialog
        open={openDelete}
        dialogTitle={`Delete Location : ${selectedLocationId}`}
        dialogText="Are you sure you wish to delete this location?
          Any tags associated with this location will no longer function"
        handleConfirm={deleteLocation}
        handleCancel={handleCancel}
      />

      {openEdit ? (
        <LocationEdit
          key={new Date().getTime()}
          loadBusinessDetails={loadBusinessDetails}
          partnerId={partnerId}
          locationId={selectedLocationId}
          locationData={isCreate ? undefined : locations[selectedLocationId]}
          open={openEdit}
          setOpen={setOpenEdit}
          isCreate={isCreate}
        />
      ) : null}
    </Card>
  );
}

export default LocationCard;
