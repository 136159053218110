import React, { useState } from 'react';

import { styled } from '@mui/system';
import {
  TextField,
  InputAdornment,
  Button,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { IoSearchOutline } from 'react-icons/io5';
import { AiFillPlusCircle, AiFillTag } from 'react-icons/ai';
import { MdRedeem } from 'react-icons/md';

import SavingChangesDialog from '../../../Components/BasicComponents/SavingChangesDialog';
import Content from '../../../Components/BasicComponents/Content';
import TitleComponent from '../../../Components/BasicComponents/TitleComponent';
import PromoService, {
  CodePromos,
  TagPromos,
  TagPromoById,
} from '../../../Services/PromoService';
import PromoCreate from './PromoCreate';

const SearchContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  height: '55px',
  maxWidth: '150px',
  marginLeft: '15px',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    marginLeft: '0px',
    marginTop: '20px',
    height: '35px',
  },
}));

const StyledGetAllButton = styled(Button)(({ theme }) => ({
  height: '55px',
  fontSize: '18px',
  fontWeight: 'bold',
  marginTop: '20px',
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
  [theme.breakpoints.down('sm')]: {
    height: '35px',
  },
}));

const StyledCreateButton = styled(Button)(({ theme }) => ({
  height: '55px',
  fontSize: '18px',
  fontWeight: 'bold',
  marginTop: '20px',
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
  [theme.breakpoints.down('sm')]: {
    height: '35px',
  },
}));

type Props = {
  setTagPromoData: (data: TagPromos) => void,
  setSingleTagPromo: (data: TagPromoById) => void,
  setCodePromoData: (data: any) => void,
  openCreate: boolean,
  setOpenCreate: (toggle: boolean) => void,
  setTagParam: (tagId: string) => void,
  setCodeParam: (codeId: string) => void,
  setIsBatch: (toggle: boolean) => void,
};

function PromoSearch(props: Props) {
  const {
    setTagPromoData,
    setSingleTagPromo,
    setCodePromoData,
    openCreate,
    setOpenCreate,
    setTagParam,
    setCodeParam,
    setIsBatch,
  } = props;

  const [tagPromoId, setTagPromoId] = useState<string>('');
  const [codePromoId, setCodePromoId] = useState<string>('');
  const [tagError, setTagError] = useState<boolean>(false);
  const [tagErrorMessage, setTagErrorMessage] = useState<string>('');
  const [codeError, setCodeError] = useState<boolean>(false);
  const [codeErrorMessage, setCodeErrorMessage] = useState<string>('');
  const [isTagLoading, setIsTagLoading] = useState<boolean>(false);
  const [isCodeLoading, setIsCodeLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isTagPromo, setIsTagPromo] = useState<boolean>(false);

  const loadTagPromo = async (promoId: string) => {
    setIsTagLoading(true);
    try {
      const promo:TagPromoById = await PromoService.getTagPromoById(promoId);
      setTagParam(tagPromoId);
      setSingleTagPromo(promo);
    } catch (e:any) {
      setTagError(true);
      setTagErrorMessage(e.message);
    }
    setIsTagLoading(false);
  };

  const loadAllTagPromos = async () => {
    setLoading(true);
    try {
      const promo:TagPromos = await PromoService.getAllTagPromoData();
      setTagPromoData(promo);
    } catch (e:any) {
      setTagError(true);
      setTagErrorMessage(e.message);
    }
    setLoading(false);
  };

  const loadCodePromo = async (codeId: string) => {
    setIsCodeLoading(true);
    try {
      const promo:CodePromos = await PromoService.getCodePromoById(codeId);
      setCodeParam(codeId);
      setCodePromoData(promo);
    } catch (e:any) {
      setCodeError(true);
      setCodeErrorMessage(e.message);
    }
    setIsCodeLoading(false);
  };

  const loadAllCodePromos = async () => {
    setLoading(true);
    try {
      const promo:CodePromos = await PromoService.getAllCodePromoData();
      setCodePromoData(promo);
    } catch (e:any) {
      setCodeError(true);
      setCodeErrorMessage(e.message);
    }
    setLoading(false);
  };

  return (
    <Content>
      <TitleComponent text="Search for Specific Tag Promo" />
      <SearchContainer>
        <TextField
          fullWidth
          id="tag-promo-search"
          label="Promotion ID"
          variant="outlined"
          color="primary"
          value={tagPromoId}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            autoComplete: 'off',
            startAdornment: (
              <InputAdornment position="start">
                <IoSearchOutline />
              </InputAdornment>
            ),
          }}
          helperText={tagError ? tagErrorMessage : ''}
          onChange={(event) => {
            if (tagError) setTagError(false);
            setTagPromoId(event.target.value.toUpperCase());
          }}
          onKeyDown={(key) => {
            if (key.key === 'Enter') loadTagPromo(tagPromoId);
          }}
        />

        <StyledLoadingButton
          loading={isTagLoading}
          color="primary"
          disabled={tagPromoId === ''}
          variant="contained"
          fullWidth
          onClick={() => {
            loadTagPromo(tagPromoId);
          }}
        >
          <Typography variant="body2">SEARCH</Typography>
        </StyledLoadingButton>
      </SearchContainer>

      <StyledGetAllButton
        aria-label="Get All Tag Promos"
        variant="contained"
        fullWidth
        startIcon={<AiFillTag />}
        onClick={loadAllTagPromos}
      >
        <Typography variant="body2">Get All Tag Promos</Typography>
      </StyledGetAllButton>

      <StyledCreateButton
        aria-label="tag-promo-create"
        variant="contained"
        fullWidth
        startIcon={<AiFillPlusCircle />}
        onClick={() => {
          setIsTagPromo(true);
          setOpenCreate(true);
        }}
      >
        <Typography variant="body2">Create New Tag Promo</Typography>
      </StyledCreateButton>

      <TitleComponent text="Search for Specific Promo Code" />
      <SearchContainer>
        <TextField
          fullWidth
          id="code-promo-search"
          label="Promotion Code"
          variant="outlined"
          color="primary"
          value={codePromoId}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            autoComplete: 'off',
            startAdornment: (
              <InputAdornment position="start">
                <IoSearchOutline />
              </InputAdornment>
            ),
          }}
          helperText={codeError ? codeErrorMessage : ''}
          onChange={(event) => {
            if (codeError) setCodeError(false);
            setCodePromoId(event.target.value.toUpperCase());
          }}
          onKeyDown={(key) => {
            if (key.key === 'Enter') loadCodePromo(codePromoId);
          }}
        />

        <StyledLoadingButton
          loading={isCodeLoading}
          color="primary"
          disabled={codePromoId === ''}
          variant="contained"
          fullWidth
          onClick={() => {
            loadCodePromo(codePromoId);
          }}
        >
          <Typography variant="body2">SEARCH</Typography>
        </StyledLoadingButton>
      </SearchContainer>

      <StyledGetAllButton
        aria-label="promo-code-get"
        variant="contained"
        fullWidth
        startIcon={<MdRedeem />}
        onClick={() => loadAllCodePromos()}
      >
        <Typography variant="body2">Get All Code Promos</Typography>
      </StyledGetAllButton>

      <StyledCreateButton
        aria-label="promo-code-create"
        variant="contained"
        fullWidth
        startIcon={<AiFillPlusCircle />}
        onClick={() => {
          setIsTagPromo(false);
          setOpenCreate(true);
        }}
      >
        <Typography variant="body2">Create New Code Promo</Typography>
      </StyledCreateButton>

      <SavingChangesDialog
        open={loading}
        dialogTitle="Loading Promos..."
      />

      <PromoCreate
        openCreate={openCreate}
        setOpenCreate={setOpenCreate}
        setSingleTagPromo={setSingleTagPromo}
        setTagParam={setTagParam}
        isTagPromo={isTagPromo}
        setCodePromoData={setCodePromoData}
        setCodeParam={setCodeParam}
        setIsBatch={setIsBatch}
      />

    </Content>
  );
}

export default PromoSearch;
