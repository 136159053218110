/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import {
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Checkbox,
  TextField,
  Button,
  Dialog,
  Grid,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/system';
import { GiCancel, GiConfirmed } from 'react-icons/gi';
import { FaQuestionCircle } from 'react-icons/fa';
import { RiUserFill } from 'react-icons/ri';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';

import SavingChangesDialog from '../../../Components/BasicComponents/SavingChangesDialog';
import ErrorDialog from '../../../Components/BasicComponents/ErrorDialog';
import ConfirmDialog from '../../../Components/BasicComponents/ConfirmDialog';
import IconWithText from '../../../Components/BasicComponents/IconWithText';

import NotificationService, { Criteria, CalculatedReach, NotificationData } from '../../../Services/NotificationService';

const StyledTitle = styled(Typography, {})({
  alignSelf: 'center',
  paddingTop: '25px',
  paddingBottom: '10px',
});

const StyledConfirm = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const StyledCancel = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.white,
  backgroundColor: theme.palette.warning.main,
  '&:hover': {
    backgroundColor: theme.palette.warning.main,
  },
}));

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

type Props = {
  businessData: any,
  open: boolean,
  partnerId: string,
  setOpen: (toggle: boolean) => void,
  cancelCreate: () => void,
  getPending: () => void,
};

function NotificationCreate(props: Props) {
  const {
    businessData,
    open,
    partnerId,
    setOpen,
    cancelCreate,
    getPending,
  } = props;

  const now = dayjs(Date.now());

  const [title, setTitle] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [reachLoading, setReachLoading] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [locationsArray, setLocationsArray] = useState<string[]>([]);
  const [schemeArray, setSchemeArray] = useState<string[]>([]);
  const [usageInDays, setUsageInDays] = useState<string>('');
  const [usagePercent, setUsagePercent] = useState<string>('');
  const [startAge, setStartAge] = useState<string>('');
  const [endAge, setEndAge] = useState<string>('');
  const [estimatedReach, setEstimatedReach] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [scheduledTime, setScheduledTime] = useState<Dayjs>(now);
  const [usersEmail, setUsersEmail] = useState<string>('');
  const [webLink, setWebLink] = useState<string>('');
  const [linkText, setLinkText] = useState<string>('');

  const handleClose = () => {
    setOpenConfirm(false);
    setOpen(false);
    setReachLoading(false);
    setTitle('');
    setBody('');
    setStartAge('');
    setEndAge('');
    setUsersEmail('');
    setWebLink('');
    setLinkText('');
    cancelCreate();
  };

  const parsePercent = (amount: string) => {
    const setAmount = amount.replace(/\D+/g, '');
    const number = parseInt(setAmount, 10);
    if (number < 1) {
      setUsagePercent('');
    } else if (number > 99) {
      setUsagePercent('99');
    } else {
      setUsagePercent(setAmount);
    }
  };

  const parseDays = (amount: string) => {
    const setAmount = amount.replace(/\D+/g, '');
    const number = parseInt(setAmount, 10);
    if (number < 1) {
      setUsageInDays('');
    } else {
      setUsageInDays(setAmount);
    }
  };

  const parseStartAge = (amount: string) => {
    const setAmount = amount.replace(/\D+/g, '');
    const number = parseInt(setAmount, 10);
    if (number < 1) {
      setStartAge('');
    } else {
      setStartAge(setAmount);
    }
  };

  const parseEndAge = (amount: string) => {
    const setAmount = amount.replace(/\D+/g, '');
    const number = parseInt(setAmount, 10);
    if (number < 1) {
      setEndAge('');
    } else {
      setEndAge(setAmount);
    }
  };

  const parseCriteria = (): Criteria => {
    let criteria: Criteria = {};

    if (schemeArray.length > 0) {
      criteria = {
        ...criteria,
        schemeIds: schemeArray,
      };
    }
    if (locationsArray.length > 0) {
      criteria = {
        ...criteria,
        locationIds: locationsArray,
      };
    }
    if (usageInDays !== '') {
      criteria = {
        ...criteria,
        noUsageInPastXDays: parseInt(usageInDays, 10),
      };
    }
    if (usagePercent !== '') {
      criteria = {
        ...criteria,
        usagePercentile: parseInt(usagePercent, 10),
      };
    }
    if (startAge !== '' && endAge !== '') {
      criteria = {
        ...criteria,
        age: {
          startAge,
          endAge,
        },
      };
    }

    return criteria;
  };

  const estimateReach = async () => {
    setReachLoading(true);
    try {
      const criteria = await parseCriteria();
      const res: CalculatedReach = await NotificationService.estimateReach(partnerId, criteria);
      if (!res.calculatedReach) setEstimatedReach('0');
      else setEstimatedReach(res.calculatedReach);
    } catch (e:any) {
      console.log(e.message);
      setEstimatedReach('0');
    }
    setReachLoading(false);
  };

  const createNotification = async () => {
    setOpenConfirm(false);
    setLoading(true);
    try {
      const criteria = await parseCriteria();
      const sendTime = dayjs(scheduledTime).valueOf().toString();
      let newNotification: NotificationData = {
        body,
        title,
        header: title,
      };
      if (webLink !== '' && linkText !== '') {
        newNotification = {
          ...newNotification,
          data: {
            link: webLink,
            type: 'web',
            webLinkButtonTitle: linkText,
          },
        };
      }
      if (usersEmail !== '') {
        newNotification = { ...newNotification, usersEmail };
      }

      await NotificationService.createNotification(partnerId, newNotification, criteria, sendTime).then(() => {
        handleClose();
        getPending();
      });
    } catch (e: any) {
      console.log(e.message);
    }
    setLoading(false);
  };

  const parseConfirmText = ():string => {
    let text = `Notification :\nTitle = ${title}\nBody = ${body}\n`;
    if (
      usageInDays !== ''
      || usagePercent !== ''
      || usageInDays !== ''
      || (startAge !== '' && endAge !== '')
      || schemeArray.length > 0
      || locationsArray.length > 0
    ) {
      let criteria = '\nCriteria :\n';
      if (usagePercent !== '') {
        criteria += `Percent of Users = ${usagePercent}\n`;
      }
      if (usageInDays !== '') {
        criteria += `Has not stamped in X days = ${usageInDays}\n`;
      }
      if (startAge !== '' && endAge !== '') {
        criteria += `Start Age = ${startAge}\nEnd Age = ${endAge}\n`;
      }
      if (schemeArray.length > 0) {
        criteria += 'Schemes = ';
        criteria += schemeArray.join(' ');
        criteria += '\n';
      }
      if (locationsArray.length > 0) {
        criteria += 'Locations = ';
        criteria += locationsArray.join(' ');
        criteria += '\n';
      }

      text += criteria;
    } else {
      text += '\nCriteria : None \n';
    }

    return text;
  };

  const handleClosed = (event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick' && 'escapeKeyDown') return;
    handleClose();
  };

  return (
    <Dialog
      aria-label="notification-dialog"
      onClose={(event, reason) => handleClosed(event, reason)}
      open={open}
      fullWidth
      maxWidth="md"
      sx={{ zIndex: '1100' }}
    >
      <StyledTitle variant="h1">
        {`Create Notification for ${businessData['Basic Fields']['Chain Name']}`}
      </StyledTitle>

      <DialogContent>
        <Grid container spacing={4}>

          <Grid item xs={12}>
            <Divider><Typography variant="body2">Required Information</Typography></Divider>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="notification-title-entry"
              label="Notification Title"
              variant="outlined"
              color="primary"
              value={title}
              InputLabelProps={{ shrink: true }}
              inputProps={{ autoComplete: 'off' }}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="notification-body-entry"
              label="Notification Text"
              variant="outlined"
              color="primary"
              value={body}
              InputLabelProps={{ shrink: true }}
              inputProps={{ autoComplete: 'off' }}
              onChange={(event) => {
                setBody(event.target.value);
              }}
            />
          </Grid>

          <Grid container item spacing={2} xs={6}>
            <Grid item xs={12} sm={12}>
              <Typography variant="body2">Scheduled Time</Typography>
              <Typography variant="body1">
                This is the time the notification will be sent. After approval it will still only send at this set time.
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <DateTimePicker
              value={scheduledTime}
              onChange={(value) => {
                if (value !== null) setScheduledTime(value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider><Typography variant="body2">Optional Email Updates</Typography></Divider>
          </Grid>

          <Grid item container xs={12} alignContent="center" justifyContent="center">
            <Typography variant="body1">
              If you enter in an email below this email will receive updates on when the notification has been approved and then sent out.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="email-entry"
              label="Email Address"
              variant="outlined"
              color="primary"
              value={usersEmail}
              InputLabelProps={{ shrink: true }}
              inputProps={{ autoComplete: 'off' }}
              onChange={(event) => {
                setUsersEmail(event.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider><Typography variant="body2">Optional Link</Typography></Divider>
          </Grid>

          <Grid item container xs={12} alignContent="center" justifyContent="center">
            <Typography variant="body1">
              This will allow you to attach a web-link and customize the link button text attached to the notification that will appear in the in app message inbox.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="link-entry"
              label="URL"
              variant="outlined"
              color="primary"
              value={webLink}
              InputLabelProps={{ shrink: true }}
              inputProps={{ autoComplete: 'off' }}
              onChange={(event) => {
                setWebLink(event.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="button-entry"
              label="Button Text"
              variant="outlined"
              color="primary"
              value={linkText}
              InputLabelProps={{ shrink: true }}
              inputProps={{ autoComplete: 'off' }}
              onChange={(event) => {
                setLinkText(event.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider><Typography variant="body2">Optional Criteria</Typography></Divider>
          </Grid>

          <Grid item container xs={12} alignContent="center" justifyContent="center">
            <Typography variant="body1">
              These criteria are optional but allow you to narrow down and select a specific sub-sect of users.
            </Typography>
          </Grid>

          <Grid container item spacing={2} xs={12}>
            <Grid item xs={12} sm={12}>
              <Typography variant="body2">Top X Percent of Users</Typography>
              <Typography variant="body1">
                This will select the the top X percent of users. I.g :  75 will target the top 75% of the total user base.
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="notification-percent-entry"
              label="Percent"
              variant="outlined"
              color="primary"
              value={usagePercent}
              InputLabelProps={{ shrink: true }}
              inputProps={{ autoComplete: 'off' }}
              onChange={(event) => {
                parsePercent(event.target.value);
              }}
            />
          </Grid>

          <Grid container item spacing={2} xs={12}>
            <Grid item xs={12} sm={12}>
              <Typography variant="body2">Has not Stamped in X days</Typography>
              <Typography variant="body1">
                This will target users who have previously stamped in this store but have not stamped in X or greater number of days.
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="notification-days-entry"
              label="Days"
              variant="outlined"
              color="primary"
              value={usageInDays}
              InputLabelProps={{ shrink: true }}
              inputProps={{ autoComplete: 'off' }}
              onChange={(event) => {
                parseDays(event.target.value);
              }}
            />
          </Grid>

          <Grid container item spacing={2} xs={12}>
            <Grid item xs={12} sm={12}>
              <Typography variant="body2">Age Range</Typography>
              <Typography variant="body1">
                This will target users within the set age range.
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="notification-start-age-entry"
              label="Start Age"
              variant="outlined"
              color="primary"
              value={startAge}
              error={startAge === '' && endAge !== ''}
              InputLabelProps={{ shrink: true }}
              inputProps={{ autoComplete: 'off' }}
              onChange={(event) => {
                parseStartAge(event.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="notification-end-age-entry"
              label="End Age"
              variant="outlined"
              color="primary"
              value={endAge}
              error={startAge !== '' && endAge === ''}
              InputLabelProps={{ shrink: true }}
              inputProps={{ autoComplete: 'off' }}
              onChange={(event) => {
                parseEndAge(event.target.value);
              }}
            />
          </Grid>

          <Grid container item spacing={2} xs={12}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Select Locations</Typography>
              <Typography variant="body1">
                Multiple locations can be selected. If no location is selected, the notification will work at all locations
              </Typography>
            </Grid>

            <Grid item container xs={6} sm={6} alignContent="center">
              <FormGroup>
                {Object.keys(businessData.Locations).map((location) => (
                  <FormControlLabel
                    key={location}
                    control={(
                      <Checkbox
                        checked={locationsArray.includes(location)}
                        onChange={() => {
                          const tempLocations = [...locationsArray];
                          if (locationsArray.includes(location)) {
                            const index = tempLocations.indexOf(location);
                            tempLocations[index] = tempLocations[tempLocations.length - 1];
                            tempLocations.pop();
                          } else {
                            tempLocations.push(location);
                          }
                          setLocationsArray(tempLocations);
                        }}
                      />
                    )}
                    label={`${location} : ${businessData.Locations[location].Address}`}
                  />
                ))}
              </FormGroup>
            </Grid>
          </Grid>

          <Grid container item spacing={2} xs={12}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Select Schemes</Typography>
              <Typography variant="body1">
                Multiple Schemes can be selected. If no scheme is selected, the notification will work for all schemes under this business
              </Typography>
            </Grid>

            <Grid item container xs={6} sm={6} alignContent="center">
              <FormGroup>
                {Object.keys(businessData.Schemes).map((scheme) => (
                  <FormControlLabel
                    key={scheme}
                    control={(
                      <Checkbox
                        checked={schemeArray.includes(scheme)}
                        onChange={() => {
                          const tempSchemes = [...schemeArray];
                          if (schemeArray.includes(scheme)) {
                            const index = tempSchemes.indexOf(scheme);
                            tempSchemes[index] = tempSchemes[tempSchemes.length - 1];
                            tempSchemes.pop();
                          } else {
                            tempSchemes.push(scheme);
                          }
                          setSchemeArray(tempSchemes);
                        }}
                      />
                    )}
                    label={`${scheme}`}
                  />
                ))}
              </FormGroup>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider><Typography variant="body2">Estimate User Reach</Typography></Divider>
          </Grid>

          <Grid item container xs={12} alignContent="center" justifyContent="center">
            <Typography variant="body1">
              This will give you an approximation of the users you could potentially reach.
              Warning this may take some time to complete and this is an estimated number.
            </Typography>
          </Grid>

          {estimatedReach !== '' ? (
            <Grid item container xs={12} alignContent="center" justifyContent="center">
              <IconWithText icon={<RiUserFill />} description="Estimated Users" text={estimatedReach} />
            </Grid>
          ) : null}

          <Grid item container xs={12} alignContent="center" justifyContent="center">
            <StyledLoadingButton
              variant="contained"
              loading={reachLoading}
              fullWidth
              startIcon={<FaQuestionCircle />}
              onClick={() => {
                estimateReach();
              }}
            >
              <Typography variant="body2">ESTIMATE</Typography>
            </StyledLoadingButton>
          </Grid>

        </Grid>
      </DialogContent>

      <DialogActions>
        <StyledCancel
          variant="contained"
          fullWidth
          startIcon={<GiCancel />}
          onClick={() => handleClose()}
        >
          <Typography variant="body2">CANCEL</Typography>
        </StyledCancel>
        <StyledConfirm
          variant="contained"
          fullWidth
          startIcon={<GiConfirmed />}
          disabled={
            reachLoading
            || title === ''
            || body === ''
            || (startAge !== '' && endAge === '')
            || (startAge === '' && endAge !== '')
            || scheduledTime <= now
          }
          onClick={() => {
            setOpenConfirm(true);
          }}
        >
          <Typography variant="body2">CONFIRM</Typography>
        </StyledConfirm>
      </DialogActions>

      <SavingChangesDialog
        open={loading}
        dialogTitle="Creating Notification"
        saving
      />

      <ConfirmDialog
        open={openConfirm}
        handleCancel={() => setOpenConfirm(false)}
        handleConfirm={() => createNotification()}
        dialogTitle={`Create Notification for ${businessData['Basic Fields']['Chain Name']}`}
        dialogText={parseConfirmText()}
      />

      <ErrorDialog
        dialogTitle={errorMessage}
        open={openError}
        handleClose={() => {
          setOpenError(false);
          setErrorMessage('');
        }}
      />
    </Dialog>
  );
}

export default NotificationCreate;
