import React from 'react';

import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const StyledHeader = styled(Typography, {})({
  paddingTop: '15px',
  paddingBottom: '15px',
});

type Props = {
  text: string,
};

function TitleComponent(props: Props) {
  const { text } = props;

  return (
    <StyledHeader variant="h1">{text}</StyledHeader>
  );
}

export default TitleComponent;
