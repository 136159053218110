const API_URL = process.env.REACT_APP_API_URL;
// const API_URL = 'http://localhost:3000';

export type LocationBilling = {
  locationId: string,
  stripeId: string,
  stripeSubscription: string,
  subscriptionId: string,
  billingStatus: string,
  billingType: string | null,
  emailAddress: string | null,
  activationEpoch: number,
  country: string,
};

export type OurEthos = {
  Description: string
  Link: string
};

export type OurStory = {
  Description: string
  Images: string[] | null
};

export type OurTeam = {
  Description: string
  Images: string[] | null
};

export type OurSpace = {
  Description: string
  Images: string[] | null
};

export type OurFood = {
  Description: string
  Images: string[] | null
  Menu: string
  'Order Link': string
};

export interface PremiumFields {
  'Cover Images'?: string[] | null
  'Our Ethos'?: OurEthos
  'Our Food'?: OurFood
  'Our Space'?: OurSpace
  'Our Story'?: OurStory
  'Our Team'?: OurTeam
}

type BasicFields = {
  Category: string,
  'Chain Name': string,
  Cover: string,
  Description: string,
  Logo: string,
  'Social Links': Object
};

type Info = {
  'Add Stamp': string,
  'Color 1': string | undefined,
  'Color 2': string | undefined,
  Option: string,
  Other: boolean,
  Reward: string,
  'Stamps Max': string,
  'Extra Files': string[],
};

export type AdditionalInfo = {
  'Stamp Cards': Info[]
};

type ImageType =
  | 'logo'
  | 'cover'
  | 'stampcard'
  | 'story'
  | 'space'
  | 'ethos'
  | 'team'
  | 'food';

type UploadImageResponse = {
  url: string;
  adminFolderURL: string;
  statusCode: number;
};

type UploadImageBatchResponse = UploadImageResponse[];

const getBusiness = (partnerId:string) => new Promise<any>((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/partners/${partnerId}/admin`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const fuzzyBusinessSearch = (query:string) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/partners/discovery/fuzzySearchDev?query=${query}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const deactivateBusiness = (
  partnerId:string,
) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/partners/deactivate/${partnerId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const activateBusiness = (
  partnerId:string,
  makeLocationsLive: boolean,
) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/partners/activate/${partnerId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ makeLocationsLive, isAdmin: true }),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const updateBusiness = (
  partnerId:string,
  basicFields?:BasicFields,
  premiumFields?: PremiumFields,
) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/partners/${partnerId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ basicFields, premiumFields }),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const uploadImage = (
  partnerId:string,
  file: File,
  fileDestination: 'logo' | 'cover' | 'stampcard',
  schemeId?: string,
  cardId?: string,
) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;

  let params = '';
  params = schemeId ? `?scheme=${schemeId}` : '';
  if (cardId) {
    params += params === '' ? `?stampId=${cardId}` : `&stampId=${cardId}`;
  }

  const formData = new FormData();
  formData.append('image', file);

  fetch(`${API_URL}/partners/${partnerId}/images/${fileDestination}${params}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: token,
    },
    body: formData,
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

export const uploadImageBatch = (
  partnerId: string,
  files: File[],
  fileDestination: ImageType,
  schemeId?: string,
): Promise<string[]> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  let params = '';
  params = schemeId ? `?scheme=${schemeId}` : '';

  const formData = new FormData();
  files.forEach((file) => {
    formData.append('image', file);
  });

  fetch(
    `${API_URL}/partners/${partnerId}/images-batch/${fileDestination}${params}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: token,
      },
      body: formData,
    },
  )
    .then((response) => response.json())
    .then((json: UploadImageBatchResponse) => {
      if (json[0]?.statusCode && json[0].statusCode !== 200) {
        reject(json);
      }
      const urls = json.map((entry) => entry.url);
      resolve(urls);
    })
    .catch((error) => {
      reject(error);
    });
});

const getSignUpInfo = (partnerId: string): Promise<AdditionalInfo> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/partners/${partnerId}/stampcard`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const getBillingInfo = (partnerId: string): Promise<LocationBilling[]> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/billing/adminDetails/${partnerId}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

export const updateBilling = (
  partnerId: string,
  locationIds: string[],
): Promise<{ updated: boolean }> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/billing/updatePlan`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ businessId: partnerId, locationIds, plan: 'usageBased' }),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const BusinessService = {
  activateBusiness,
  deactivateBusiness,
  getSignUpInfo,
  fuzzyBusinessSearch,
  getBusiness,
  updateBusiness,
  uploadImage,
  uploadImageBatch,
  getBillingInfo,
  updateBilling,
};

export default BusinessService;
