import React from 'react';

import {
  AiFillTags,
  AiFillIdcard,
} from 'react-icons/ai';
import { RiUserFill, RiBookmark3Fill } from 'react-icons/ri';
import { FaStoreAlt } from 'react-icons/fa';
import { MdAdminPanelSettings, MdCampaign } from 'react-icons/md';
import { IoNotifications } from 'react-icons/io5';

const sidebarRouting = [
  {
    title: 'Tags',
    path: '/tags',
    icon: <AiFillTags />,
  },
  {
    title: 'Users',
    path: '/users',
    icon: <RiUserFill />,
  },
  {
    title: 'Businesses',
    path: '/businesses',
    icon: <FaStoreAlt />,
  },
  {
    title: 'Schemes',
    path: '/schemes',
    icon: <AiFillIdcard />,
  },
  {
    title: 'Promos',
    path: '/promos',
    icon: <RiBookmark3Fill />,
  },
  {
    title: 'Campaigns',
    path: '/campaigns',
    icon: <MdCampaign />,
  },
  {
    title: 'Notifications',
    path: '/notifications',
    icon: <IoNotifications />,
  },
  {
    title: 'Accounts',
    path: '/admin',
    icon: <MdAdminPanelSettings />,
  },
];

export default sidebarRouting;
