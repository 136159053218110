import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { styled } from '@mui/system';
import { GiCancel, GiConfirmed } from 'react-icons/gi';
import { Locations } from '../../../Services/LocationService';

const StyledTitle = styled(Typography, {})({
  alignSelf: 'center',
  paddingTop: '20px',
  paddingLeft: '20px',
  paddingRight: '20px',
});

const StyledConfirm = styled(Button)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 'bold',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const StyledCancel = styled(Button)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 'bold',
  color: theme.palette.primary.white,
  backgroundColor: theme.palette.warning.main,
  '&:hover': {
    background: theme.palette.warning.main,
  },
}));

const StyledControlLabel = styled(FormControlLabel, {})({
  marginTop: '5px',
});

type Props = {
  isEdit: boolean
  selectedLocations: string[],
  setSelectedLocations: (keys: string[]) => void,
  locationData: Locations,
  open: boolean,
  handleCancel: () => void,
  handleConfirm: () => void,
};

function LocationDialog(props: Props) {
  const {
    isEdit,
    selectedLocations,
    setSelectedLocations,
    locationData,
    open,
    handleCancel,
    handleConfirm,
  } = props;

  const handleClose = (event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick' && 'escapeKeyDown') return;
    handleCancel();
  };

  return (
    <Dialog
      aria-label="location-dialog"
      onClose={(event, reason) => handleClose(event, reason)}
      open={open}
      fullWidth
      maxWidth="sm"
      sx={{ zIndex: '3000' }}
    >
      <StyledTitle variant="h1">{`${isEdit ? 'Edit' : 'Select'} Location(s) the Account can Access`}</StyledTitle>
      <DialogContent>
        <Grid item container xs={12} justifyContent="center">
          <Typography>
            If you select &apos;All&apos; they will have access to all present and future locations of this business.
            If you select one or more specific locations they will only have access to those.
          </Typography>

          <FormGroup>
            <StyledControlLabel
              control={(
                <Checkbox
                  checked={selectedLocations.includes('ALL')}
                  onChange={() => {
                    if (selectedLocations.includes('ALL')) {
                      setSelectedLocations([]);
                    } else {
                      setSelectedLocations(['ALL']);
                    }
                  }}
                />
              )}
              label="All Locations"
            />

            {Object.keys(locationData).length > 0 && Object.keys(locationData).map((locationKey) => (
              <StyledControlLabel
                key={locationKey}
                control={(
                  <Checkbox
                    checked={selectedLocations.includes(locationKey)}
                    disabled={selectedLocations.includes('ALL')}
                    onChange={() => {
                      const newLocations = selectedLocations;
                      const index = selectedLocations.indexOf(locationKey);
                      if (index === -1) newLocations.push(locationKey);
                      else {
                        newLocations.splice(index, 1);
                      }
                      setSelectedLocations([...newLocations]);
                    }}
                  />
                )}
                label={`${locationKey} : ${locationData[locationKey].Address}`}
              />
            ))}
          </FormGroup>
        </Grid>
      </DialogContent>

      <DialogActions>
        <StyledCancel
          variant="contained"
          fullWidth
          startIcon={<GiCancel />}
          onClick={handleCancel}
        >
          <Typography variant="body2">CANCEL</Typography>
        </StyledCancel>
        <StyledConfirm
          variant="contained"
          disabled={selectedLocations.length === 0}
          fullWidth
          startIcon={<GiConfirmed />}
          onClick={handleConfirm}
        >
          <Typography variant="body2">CONFIRM</Typography>
        </StyledConfirm>
      </DialogActions>
    </Dialog>
  );
}

export default LocationDialog;
