import React, { useState } from 'react';
import {
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  Button,
  Dialog,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { GiCancel, GiConfirmed } from 'react-icons/gi';
import ErrorDialog from '../../../Components/BasicComponents/ErrorDialog';
import UserService from '../../../Services/UserService';

const StyledTitle = styled(Typography, {})({
  alignSelf: 'center',
  paddingTop: '25px',
});

const StyledConfirm = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const StyledCancel = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.white,
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

type Props = {
  dateOfBirth: string,
  gender: string,
  name: string,
  open: boolean,
  sendOffers: boolean,
  setOpen: (toggle: boolean) => void,
  userId: string,
  updateUser: (id: string, user: {}) => void,
};

function UserEditDialog(props: Props) {
  const {
    dateOfBirth,
    gender,
    name,
    open,
    sendOffers,
    setOpen,
    updateUser,
    userId,
  } = props;

  const [newName, setNewName] = useState<string>(name);
  const [dateOfBirthPicker, setDateOfBirthPicker] = useState<string>('');
  const [newDateOfBirth, setNewDateOfBirth] = useState<string>(dateOfBirth);
  const [newGender, setNewGender] = useState<string>(gender);
  const [newSendOffers, setNewSendOffers] = useState<boolean>(sendOffers);
  const [openError, setOpenError] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');

  const genderOptions = ['male', 'female', 'other', 'rather'];
  const handleClose = () => {
    setOpen(false);
  };

  const formatDate = (date: string) => {
    const dateArray = date.split('-');
    const newDOB = `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
    setNewDateOfBirth(newDOB);
  };

  const handleEdit = async () => {
    try {
      await UserService.updateUser(
        userId,
        newName,
        newGender,
        newDateOfBirth,
        newSendOffers,
      );
      try {
        // TODO user data type
        const updatedUser: any = await UserService.getUser(userId);
        updateUser(userId, updatedUser);
        setOpen(false);
      } catch (e:any) {
        setOpenError(true);
        setErrorText(e.message);
      }
    } catch (e:any) {
      setOpenError(true);
      setErrorText(e.message);
    }
  };

  const yourDate = new Date();
  const maxDate = yourDate.toISOString().split('T')[0];

  const handleClosed = (event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick' && 'escapeKeyDown') return;
    handleClose();
  };

  return (
    <Dialog
      aria-label="confirm-dialog"
      onClose={(event, reason) => handleClosed(event, reason)}
      open={open}
      fullWidth
      maxWidth="md"
      sx={{ zIndex: '3000' }}
    >
      <StyledTitle variant="h1">Edit Basic Business Information</StyledTitle>
      <DialogContent>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="user-name-entry"
              label="User Name"
              variant="outlined"
              color="primary"
              value={newName}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                setNewName(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              select
              id="gender-select"
              label="Gender"
              variant="outlined"
              color="primary"
              value={newGender}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                setNewGender(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
            >
              {genderOptions.map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              type="date"
              id="date-of-birth-entry"
              label="Date of Birth"
              variant="outlined"
              color="primary"
              value={dateOfBirthPicker}
              InputLabelProps={{ shrink: true }}
              InputProps={{ autoComplete: 'off', inputProps: { max: maxDate } }}
              onChange={(event) => {
                setDateOfBirthPicker(event.target.value);
                formatDate(event.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              select
              id="send-offers-select"
              label="Send Offers"
              variant="outlined"
              color="primary"
              value={newSendOffers ? 'yes' : 'no'}
              InputLabelProps={{ shrink: true }}
              InputProps={{ autoComplete: 'off' }}
              onChange={(event) => {
                if (event.target.value === 'yes') setNewSendOffers(true);
                else setNewSendOffers(false);
              }}
            >
              <MenuItem key={0} value="yes">Yes</MenuItem>
              <MenuItem key={1} value="no">No</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <StyledCancel
          variant="contained"
          fullWidth
          startIcon={<GiCancel />}
          onClick={handleClose}
        >
          <Typography variant="body2">CANCEL</Typography>
        </StyledCancel>
        <StyledConfirm
          variant="contained"
          fullWidth
          startIcon={<GiConfirmed />}
          onClick={handleEdit}
        >
          <Typography variant="body2">CONFIRM</Typography>
        </StyledConfirm>
      </DialogActions>

      <ErrorDialog
        dialogTitle="Error"
        dialogText={errorText}
        open={openError}
        handleClose={() => {
          setOpenError(false);
          setErrorText('');
        }}
      />

    </Dialog>
  );
}

export default UserEditDialog;
