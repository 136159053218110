/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import NoPermissionPage from '../../Components/BasicComponents/NoPermissionPage';
import NotificationSearch from './components/NotificationSearch';
import PendingNotifications from './components/PendingNotifications';
import RejectedNotifications from './components/RejectedNotifications';
import ApprovedNotifications from './components/ApprovedNotifications';
import ScheduledNotifications from './components/ScheduledNotifications';
import NotificationService, { SentNotifications, Notifications } from '../../Services/NotificationService';

function NotificationPage() {
  const [notificationParam, setNotificationParam] = useSearchParams();
  const [pendingNotifications, setPendingNotifications] = useState<Notifications | undefined>(undefined);
  const [sentNotifications, setSentNotifications] = useState<SentNotifications | undefined>(undefined);
  const [rejectedNotifications, setRejectedNotifications] = useState<Notifications | undefined>(undefined);
  const [scheduledNotifications, setScheduledNotifications] = useState<Notifications | undefined>(undefined);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const permissions = ['loyalty:tags:all', 'loyalty:tags:read'];
  const canView = permissions.some((condition) => localStorage.getItem('userScopes')?.includes(condition));

  const loadSent = async () => {
    setLoading(true);
    try {
      const sent: SentNotifications = await NotificationService.getSentNotifications();
      setSentNotifications(sent);
    } catch (e:any) {
      setSentNotifications(undefined);
      setOpenAlert(true);
    }
    setLoading(false);
  };

  const loadPending = async () => {
    setLoading(true);
    try {
      const pending: Notifications = await NotificationService.getPendingNotifications();
      setPendingNotifications(pending);
    } catch (e:any) {
      setPendingNotifications(undefined);
      setOpenAlert(true);
    }
    setLoading(false);
  };

  const loadScheduled = async () => {
    setLoading(true);
    try {
      const scheduled: Notifications = await NotificationService.getScheduledNotifications();
      setScheduledNotifications(scheduled);
    } catch (e:any) {
      setScheduledNotifications(undefined);
      setOpenAlert(true);
    }
    setLoading(false);
  };

  const loadRejected = async () => {
    setLoading(true);
    try {
      const rejected: Notifications = await NotificationService.getRejectedNotifications();
      setRejectedNotifications(rejected);
    } catch (e:any) {
      setRejectedNotifications(undefined);
      setOpenAlert(true);
    }
    setLoading(false);
  };

  const goBack = () => {
    setPendingNotifications(undefined);
    setRejectedNotifications(undefined);
    setSentNotifications(undefined);
    setScheduledNotifications(undefined);
  };

  useEffect(() => {
    const param = notificationParam.get('notifications') ?? '';
    setNotificationParam(param);
    if (param === 'sent') loadSent();
    else if (param === 'pending') loadPending();
    else if (param === 'rejected') loadRejected();
  }, []);

  if (!canView) return <NoPermissionPage />;
  if (pendingNotifications !== undefined) {
    return (
      <PendingNotifications
        loadPending={loadPending}
        pendingNotifications={pendingNotifications}
        goBack={goBack}
        openAlert={openAlert}
        setOpenAlert={setOpenAlert}
      />
    );
  }
  if (rejectedNotifications !== undefined) {
    return (
      <RejectedNotifications
        rejectedNotifications={rejectedNotifications}
        goBack={goBack}
      />
    );
  }
  if (sentNotifications !== undefined) {
    return (
      <ApprovedNotifications
        sentNotifications={sentNotifications}
        goBack={goBack}
      />
    );
  }
  if (scheduledNotifications !== undefined) {
    return (
      <ScheduledNotifications
        scheduledNotifications={scheduledNotifications}
        goBack={goBack}
      />
    );
  }
  return (
    <NotificationSearch
      getPending={loadPending}
      getSent={loadSent}
      getRejected={loadRejected}
      getScheduled={loadScheduled}
      loading={loading}
      openAlert={openAlert}
      setOpenAlert={setOpenAlert}
    />
  );
}

export default NotificationPage;
