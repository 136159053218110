import React from 'react';
import { styled } from '@mui/system';

import {
  Typography,
} from '@mui/material';

import Login from './components/Login';

const logoMascot = require('../../Images/Clive_rgb.png');
const logo2 = require('../../Images/SQUID.png');

const MascotImage = styled('img', {})({
  justifySelf: 'end',
  alignSelf: 'end',
  width: '60vh',
});

const LogoImage = styled('img', {})({
  height: '130px',
});

const Container = styled('div', {})({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
  margin: 0,
  padding: 0,
});

const ItemContainer = styled('div', {})({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  justifyContent: 'center',
  alignContent: 'center',
});

const TitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: 'bold',
  paddingTop: '10px',
  fontSize: 50,
}));

const CaptionText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: 'bold',
  fontSize: 30,
}));

const StyleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '50%',
  backgroundColor: theme.palette.primary.main,
  height: '100vh',
  margin: 0,
  borderTopLeftRadius: '25px',
  borderBottomLeftRadius: '25px',
}));

const TextContainer = styled('div', {})({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  paddingTop: '15vh',
});

function LoginPage() {
  return (
    <Container>
      <ItemContainer>
        <Login />
      </ItemContainer>
      <StyleContainer>
        <TextContainer>
          <LogoImage src={logo2} alt="mySvgImage" />
          <TitleText>SQUID Loyalty</TitleText>
          <CaptionText>Admin Portal</CaptionText>
        </TextContainer>

        <MascotImage src={logoMascot} />
      </StyleContainer>
    </Container>
  );
}

export default LoginPage;
