/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';

import { styled } from '@mui/system';
import {
  Button,
  Grid,
  TablePagination,
  TextField,
  Typography,
  InputAdornment,
} from '@mui/material';
import { AiOutlineSearch } from 'react-icons/ai';
import { HiDocumentDownload } from 'react-icons/hi';

import fileDownload from 'js-file-download';
import Content from '../../../Components/BasicComponents/Content';
import TitleComponent from '../../../Components/BasicComponents/TitleComponent';
import BackButton from '../../../Components/BasicComponents/BackButton';
import ApprovedCard from './ApprovedCard';

import NotificationService, { SentNotifications } from '../../../Services/NotificationService';
import ValidateEpoch from '../../../util/validateEpoch';

const StyledSearch = styled(TextField)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.common.white,
  justifySelf: 'center',
  width: '400px',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  height: '45px',
  width: '220px',
  backgroundColor: theme.palette.secondary.main,
  position: 'inherit',
  [theme.breakpoints.down('sm')]: {
    height: '35px',
    width: '90px',
  },
}));

const ButtonRow = styled('div', {})({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

type Props = {
  sentNotifications: SentNotifications,
  goBack: () => void,
};

function ApprovedNotifications(props: Props) {
  const {
    sentNotifications,
    goBack,
  } = props;

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(4);
  const [searchText, setSearchText] = useState<string>('');
  const [amount, setAmount] = useState<number>(Object.keys(sentNotifications).length);

  useEffect(() => {
    setAmount(Object.keys(sentNotifications).length);
    NotificationService.getPendingNotificationAmount();
  }, [sentNotifications]);

  // TODO
  // const permissions = ['loyalty:partners:update', 'loyalty:partners:all'];
  // const canEdit = permissions.some((condition) => localStorage.getItem('userScopes')?.includes(condition));

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const downloadFile = () => {
    const keys = Object.keys(sentNotifications);

    const headings: string[] = [
      'Business ID',
      'Business Name',
      'Created At',
      'Internal Description',
      'Notification ID',
      'Notification Header',
      'Notification Body',
      'Has Voucher',
      'Reward Text',
      'Voucher Expiry Days',
      'Has External Link',
      'External Link',
      'Has Criteria',
      'Criteria - Start Age',
      'Criteria - End Age',
      'Criteria - Locations Stamped At',
      'Criteria - Schemes Stamped',
      'Criteria - Top X Percent of Users',
      'Sent At',
      'Number of Users',
      'Total Deliveries',
      'Total Successful Deliveries',
      'Total Failed Deliveries',
      'Number of Opens in-app Inbox',
    ];

    const rows: string[][] = [headings];

    keys.forEach((key) => {
      if (sentNotifications[key].businessId
        && (sentNotifications[key].businessId !== 'CORMACO'
        && sentNotifications[key].businessId !== 'TOMCO'
        && sentNotifications[key].businessId !== 'CUIRCO'
        && sentNotifications[key].businessId !== 'HUGHCO')
      ) {
        const businessName = sentNotifications[key].businessName ?? '';
        const createdAt = sentNotifications[key].createdEpoch
          ? new Date(ValidateEpoch(sentNotifications[key].createdEpoch)).toLocaleString()
          : '';
        const internalDescription = sentNotifications[key].notification?.description ?? '';
        let header = sentNotifications[key].notification?.header ?? '';
        header = sentNotifications[key].notification?.title ?? header;
        const body = sentNotifications[key].notification?.body ?? '';
        const hasVoucher = sentNotifications[key].notification?.voucher === true ? 'Yes' : 'No';
        const voucherText = sentNotifications[key].notification?.voucherText ?? '';
        const voucherExpire = sentNotifications[key]?.notification?.rewardExpiryDays
          ? sentNotifications[key]?.notification?.rewardExpiryDays!.toString()
          : ' ';
        const hasLink = sentNotifications[key].notification?.data?.link ? 'Yes' : 'No';
        const link = sentNotifications[key].notification?.data?.link ?? '';
        const hasCriteria = sentNotifications[key]?.criteria ? 'Yes' : 'No';
        const startAge = sentNotifications[key]?.criteria?.age?.startAge
          ? sentNotifications[key]?.criteria?.age?.startAge?.toString()
          : ' ';
        const endAge = sentNotifications[key]?.criteria?.age?.endAge
          ? sentNotifications[key]?.criteria?.age?.endAge?.toString()
          : ' ';
        const locations = sentNotifications[key]?.criteria?.locationIds
          ? sentNotifications[key].criteria!.locationIds!.join(', ')
          : '';
        const schemes = sentNotifications[key]?.criteria?.schemeIds
          ? sentNotifications[key].criteria!.schemeIds!.join(', ')
          : '';
        const percentUsers = sentNotifications[key]?.criteria?.usagePercentile
          ? sentNotifications[key]?.criteria?.usagePercentile?.toString()
          : '';
        const sentAt = sentNotifications[key]?.sentAt
          ? new Date(ValidateEpoch(sentNotifications[key].sentAt)).toLocaleString()
          : '';
        const numUsers = sentNotifications[key]?.numUsers ? sentNotifications[key]?.numUsers.toString() : '';
        const totalDeliveries = sentNotifications[key]?.totalDeliveries
          ? sentNotifications[key]?.totalDeliveries.toString()
          : '';
        const successfulDeliveries = sentNotifications[key]?.successfulDeliveries
          ? sentNotifications[key]?.successfulDeliveries.toString()
          : '';
        const failedDeliveries = sentNotifications[key]?.failedDeliveries
          ? sentNotifications[key]?.failedDeliveries.toString()
          : '';
        const opened = sentNotifications[key]?.openedNotifications
          ? sentNotifications[key]?.openedNotifications.toString()
          : '';

        const subRow: string[] = [
          sentNotifications[key].businessId,
          businessName,
          createdAt,
          internalDescription,
          sentNotifications[key].notificationId,
          header,
          body,
          hasVoucher,
          voucherText,
          voucherExpire,
          hasLink,
          link,
          hasCriteria,
          startAge!,
          endAge!,
          locations,
          schemes,
          percentUsers!,
          sentAt,
          numUsers,
          totalDeliveries,
          successfulDeliveries,
          failedDeliveries,
          opened,
        ];

        rows.push(subRow);
      }
    });

    const csv = rows.map((row) => row.map((r) => JSON.stringify(r))).join('\r\n');

    fileDownload(csv, 'notifications.csv');
  };

  return (
    <Content>
      <ButtonRow>
        <BackButton onClick={goBack} />

        <StyledSearch
          id="search-entry"
          label="Search by Business ID"
          variant="filled"
          color="primary"
          value={searchText}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            autoComplete: 'off',
            startAdornment: (
              <InputAdornment position="start">
                <AiOutlineSearch />
              </InputAdornment>
            ),
          }}
          onChange={(event) => {
            setSearchText(event.target.value.toUpperCase());
            const keys = Object.keys(sentNotifications)
              .filter((key) => sentNotifications[key].businessId && sentNotifications[key].businessId.toUpperCase()
                .includes(event.target.value.toUpperCase()));
            setAmount(keys.length);
          }}
        />

        <StyledButton
          aria-label="download"
          variant="contained"
          startIcon={<HiDocumentDownload />}
          onClick={downloadFile}
        >
          <Typography variant="body2">Download Notifications</Typography>
        </StyledButton>
      </ButtonRow>

      <TitleComponent text="Sent Notifications" />
      <Grid item container spacing={2} xs={12}>
        {Object.keys(sentNotifications)
          .filter((key) => sentNotifications[key].businessId
            && sentNotifications[key].businessId.toUpperCase().includes(searchText))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((key) => (
            <Grid item xs={6} key={key}>
              <ApprovedCard
                notificationId={key}
                sentData={sentNotifications[key]}
              />
            </Grid>
          ))}
      </Grid>

      <TablePagination
        rowsPerPageOptions={[4, 8, 16]}
        component="div"
        count={amount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Content>
  );
}

export default ApprovedNotifications;
