import React, { useState } from 'react';

import { styled } from '@mui/system';
import {
  Card,
  CardContent,
  Collapse,
  IconButton,
  IconButtonProps,
  Grid,
  Typography,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import { PremiumFields } from '../../../Services/BusinessService';
import OurEthosCard from './OurEthosCard';
import OurFoodCard from './OurFoodCard';
import OurSpaceCard from './OurSpaceCard';
import OurStoryCard from './OurStoryCard';
import OurTeamCard from './OurTeamCard';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  fontSize: '30px',
  color: theme.palette.common.white,
}));

const CardTitle = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary.main,
  height: '40px',
  [theme.breakpoints.down('sm')]: {
    height: '50px',
  },
}));

const CardTitleText = styled(Typography)(({ theme }) => ({
  alignSelf: 'center',
  color: theme.palette.common.white,
}));

const ExpandedContent = styled(CardContent)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  paddingLeft: '50px',
  paddingRight: '50px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
}));

type Props = {
  premiumData: PremiumFields | undefined,
  loadBusinessDetails: (id: string) => void,
  partnerId: string,
};

function PremiumDetails(props: Props) {
  const {
    premiumData,
    loadBusinessDetails,
    partnerId,
  } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const HandleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card key="locations">
      <CardTitle>
        <CardTitleText variant="h4">Extended Profile</CardTitleText>

        <ExpandMore
          expand={isExpanded}
          onClick={HandleExpandClick}
          aria-expanded={isExpanded}
          aria-label="show more"
        >
          <MdExpandMore />
        </ExpandMore>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OurEthosCard
                ethosData={premiumData?.['Our Ethos'] ? premiumData?.['Our Ethos'] : undefined}
                loadBusinessDetails={loadBusinessDetails}
                partnerId={partnerId}
              />
            </Grid>

            <Grid item xs={12}>
              <OurStoryCard
                storyData={premiumData?.['Our Story'] ? premiumData?.['Our Story'] : undefined}
                loadBusinessDetails={loadBusinessDetails}
                partnerId={partnerId}
              />
            </Grid>

            <Grid item xs={12}>
              <OurSpaceCard
                spaceData={premiumData?.['Our Space'] ? premiumData?.['Our Space'] : undefined}
                loadBusinessDetails={loadBusinessDetails}
                partnerId={partnerId}
              />
            </Grid>

            <Grid item xs={12}>
              <OurTeamCard
                teamData={premiumData?.['Our Team'] ? premiumData?.['Our Team'] : undefined}
                loadBusinessDetails={loadBusinessDetails}
                partnerId={partnerId}
              />
            </Grid>

            <Grid item xs={12}>
              <OurFoodCard
                foodData={premiumData?.['Our Food'] ? premiumData?.['Our Food'] : undefined}
                loadBusinessDetails={loadBusinessDetails}
                partnerId={partnerId}
              />
            </Grid>
          </Grid>
        </ExpandedContent>
      </Collapse>
    </Card>
  );
}

export default PremiumDetails;
