import React, { useState } from 'react';

import { styled } from '@mui/system';
import { Button, Grid, Typography } from '@mui/material';
import { AiFillPlusCircle } from 'react-icons/ai';

import Content from '../../../Components/BasicComponents/Content';
import TitleComponent from '../../../Components/BasicComponents/TitleComponent';
import BackButton from '../../../Components/BasicComponents/BackButton';
import SchemeCard from './SchemeCard';
import SchemeEdit from './SchemeEdit';
import ConfirmDialog from '../../../Components/BasicComponents/ConfirmDialog';
import SchemeService, { ReturnedSchemeData } from '../../../Services/SchemeService';

const ButtonRow = styled('div', {})({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const StyledButton = styled(Button)(({ theme }) => ({
  height: '45px',
  position: 'inherit',
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
  [theme.breakpoints.down('sm')]: {
    height: '35px',
  },
}));

type Props = {
  businessData: any,
  schemeData: ReturnedSchemeData[],
  goBack: () => void,
  partnerId: string,
  loadSchemeDetails: (id: string) => void;
  brokenSchemeIds: string[],
};

function SchemeDetails(props: Props) {
  const {
    businessData,
    schemeData,
    goBack,
    partnerId,
    loadSchemeDetails,
    brokenSchemeIds,
  } = props;

  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [editSchemeData, setEditSchemeData] = useState<ReturnedSchemeData>();
  const [schemeId, setSchemeId] = useState<string>('');
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [deleteSchemeId, setDeleteSchemeId] = useState<string>('');

  const startCreateScheme = () => {
    setEditSchemeData(undefined);
    setIsCreate(true);
    setOpenDialog(true);
  };

  const startEdit = (scheme:ReturnedSchemeData) => {
    setIsCreate(false);
    setEditSchemeData(scheme);
    setSchemeId(scheme.schemeId);
    setOpenDialog(true);
  };

  const startDelete = (id: string) => {
    setDeleteSchemeId(id);
    setOpenDelete(true);
  };

  const deleteScheme = async () => {
    await SchemeService.deleteScheme(partnerId, deleteSchemeId).then(() => {
      setDeleteSchemeId('');
      loadSchemeDetails(partnerId);
      setOpenDelete(false);
    });
  };

  const permissions = ['loyalty:schemes:all', 'loyalty:schemes:create', 'schemes:create'];
  const canCreate = permissions.some((condition) => localStorage.getItem('userScopes')?.includes(condition));

  return (
    <Content>

      <ButtonRow>
        <BackButton onClick={goBack} />
        <StyledButton
          aria-label="create"
          variant="contained"
          startIcon={<AiFillPlusCircle />}
          onClick={startCreateScheme}
          disabled={!canCreate}
        >
          <Typography variant="body2">CREATE</Typography>
        </StyledButton>
      </ButtonRow>

      <TitleComponent text={`Schemes for ${businessData['Basic Fields']['Chain Name']}`} />
      <Grid container spacing={2}>
        {brokenSchemeIds.length !== 0 && (
          <Grid item xs={12}>
            <Typography color="red">
              {`The following Schemes IDs are broken, please get in contact with the tech team : ${brokenSchemeIds}`}
            </Typography>
          </Grid>
        )}

        {schemeData.map((scheme: ReturnedSchemeData) => (
          <Grid item xs={12} key={scheme.schemeId}>
            <SchemeCard
              edit={startEdit}
              schemeData={scheme}
              startDelete={startDelete}
              businessData={businessData}
            />
          </Grid>
        ))}
      </Grid>

      <ConfirmDialog
        open={openDelete}
        isDelete
        handleCancel={() => setOpenDelete(false)}
        handleConfirm={deleteScheme}
        dialogTitle="Delete Scheme"
        dialogText={`Are you sure you want to delete this scheme: ${deleteSchemeId} ? 
          Warning all tags associated with the scheme will not function after deletion.`}
      />

      <SchemeEdit
        key={new Date().getTime()}
        businessData={businessData}
        schemeData={editSchemeData}
        schemeId={schemeId}
        isCreate={isCreate}
        open={openDialog}
        setOpen={setOpenDialog}
        partnerId={partnerId}
        loadSchemeDetails={loadSchemeDetails}
      />

    </Content>
  );
}

export default SchemeDetails;
