import React, { useState } from 'react';

import {
  Grid,
  TablePagination,
} from '@mui/material';

import TagPromoCard from './TagPromoCard';
import ConfirmDialog from '../../../Components/BasicComponents/ConfirmDialog';
import PromoService, { ReturnedTagPromo, TagPromoById } from '../../../Services/PromoService';
import TagPromoEdit from './TagPromoEdit';
import ErrorDialog from '../../../Components/BasicComponents/ErrorDialog';

import SchemeService, { ReturnedSchemeData } from '../../../Services/SchemeService';
import BusinessService from '../../../Services/BusinessService';

type Props = {
  goBack: () => void,
  tagPromoData: ReturnedTagPromo[],
  tagPromoAmount: number,
  setSingleTagPromo: (tagPromo: TagPromoById) => void,
  setTagParam: (id: string) => void,
  openError: boolean,
  setOpenError: (toggle: boolean) => void,
  errorMessage: string,
  setErrorMessage: (message: string) => void,
  tagParam: string | null,
  loadAllTags: () => void,
  alwaysExpand?: boolean,
};

function TagPromoDetails(props: Props) {
  const {
    goBack,
    tagPromoData,
    tagPromoAmount,
    setSingleTagPromo,
    setTagParam,
    tagParam,
    openError,
    setOpenError,
    errorMessage,
    setErrorMessage,
    loadAllTags,
    alwaysExpand,
  } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [selectedTag, setSelectedTag] = useState<string>('');
  const [businessData, setBusinessData] = useState<any>({});
  const [schemeData, setSchemeData] = useState<ReturnedSchemeData>();
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [schemeId, setSchemeId] = useState<string>('');
  const [partnerId, setPartnerId] = useState<string>('');
  const [selectedTagPromoData, setSelectedTagPromoData] = useState<ReturnedTagPromo | undefined>();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startDelete = (tagPromoId: string) => {
    setSelectedTag(tagPromoId);
    setOpenDelete(true);
  };

  const startEdit = async (tagPromoId: string, promoData: ReturnedTagPromo) => {
    setSelectedTag(tagPromoId);
    try {
      const pId = promoData.partnerId;
      const business = await BusinessService.getBusiness(pId);
      const scheme: ReturnedSchemeData = await SchemeService.getSpecificScheme(pId, promoData.schemeId);
      setSchemeData(scheme);
      setBusinessData(business);
      setSelectedTagPromoData(promoData);
      setSchemeId(tagPromoId);
      setPartnerId(pId);
      setOpenEdit(true);
    } catch (e: any) {
      setOpenError(true);
      setErrorMessage(e.message);
    }
  };

  const cancelEdit = () => {
    setOpenEdit(false);
    setSchemeId('');
    setSchemeData(undefined);
    setBusinessData({});
    setPartnerId('');
  };

  const deleteTagPromo = async () => {
    try {
      await PromoService.deleteTagPromo(selectedTag);
      setOpenDelete(false);
      if (tagParam !== null) goBack();
      else {
        loadAllTags();
      }
    } catch (e:any) {
      setOpenError(true);
      setErrorMessage(e.message);
    }
  };

  return (
    <div>
      <Grid container spacing={2}>
        {tagPromoData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((promo) => (
            <Grid item xs={12} key={promo.promotionId}>
              <TagPromoCard
                tagPromoId={promo.promotionId}
                tagPromoData={promo}
                startDelete={startDelete}
                startEdit={startEdit}
                alwaysExpand={alwaysExpand}
              />
            </Grid>
          ))}
      </Grid>

      <ConfirmDialog
        open={openDelete}
        isDelete
        handleCancel={() => {
          setOpenDelete(false);
          setSelectedTag('');
        }}
        handleConfirm={deleteTagPromo}
        dialogTitle="Delete Tag Promo"
        dialogText={`Are you sure you want to delete this tag promotion: ${selectedTag} ?
          Warning this action can not be reversed`}
      />

      {tagPromoAmount > 8 ? (
        <TablePagination
          rowsPerPageOptions={[8, 16, 32]}
          component="div"
          count={tagPromoAmount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}

      <TagPromoEdit
        key={new Date().getTime()}
        businessData={businessData}
        schemeData={schemeData}
        isCreate={false}
        open={openEdit}
        partnerId={partnerId}
        setOpen={setOpenEdit}
        schemeId={schemeId}
        cancelCreate={cancelEdit}
        tagPromoData={selectedTagPromoData}
        setSingleTagPromo={setSingleTagPromo}
        setTagParam={setTagParam}
      />

      <ErrorDialog
        dialogTitle={errorMessage}
        open={openError}
        handleClose={() => {
          setOpenError(false);
          setErrorMessage('');
        }}
      />
    </div>
  );
}

export default TagPromoDetails;
