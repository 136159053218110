import React, { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';
import NoPermissionPage from '../../Components/BasicComponents/NoPermissionPage';
import PromoSearch from './components/PromoSearch';
import SingleTagPromo from './components/SingleTagPromo';
import TagPromoAll from './components/TagPromoAll';
import CodePromoDetails from './components/CodePromoDetails';
import PromoService, {
  CodePromos,
  TagPromos,
  TagPromoById,
} from '../../Services/PromoService';

function PromoPage() {
  const [singleTagPromo, setSingleTagPromo] = useState<TagPromoById>();
  const [allTagPromoData, setAllTagPromoData] = useState<TagPromos>();
  const [codePromoData, setCodePromoData] = useState({});
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [tagPromoQuery, setTagPromoQuery] = useSearchParams();
  const [codePromoQuery, setCodePromoQuery] = useSearchParams();
  const [openError, setOpenError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isBatch, setIsBatch] = useState<boolean>(false);

  const goBack = () => {
    setSingleTagPromo(undefined);
    setAllTagPromoData(undefined);
    setCodePromoData({});
    setCodePromoQuery(undefined);
    setTagPromoQuery(undefined);
  };

  const setTagParam = (tagParam: string) => {
    setTagPromoQuery({ tagPromoId: tagParam });
  };

  const setCodeParam = (codeParam: string) => {
    setCodePromoQuery({ codePromoId: codeParam });
  };

  const loadSpecificTagPromo = async (tagPromoId: string) => {
    try {
      const promo:TagPromoById = await PromoService.getTagPromoById(tagPromoId);
      setTagPromoQuery({ tagPromoId });
      setSingleTagPromo(promo);
    } catch (e:any) {
      setOpenError(true);
      setErrorMessage(e.message);
    }
  };

  const loadAllTagPromos = async () => {
    try {
      const promo:TagPromos = await PromoService.getAllTagPromoData();
      setAllTagPromoData(promo);
    } catch (e:any) {
      setOpenError(true);
      setErrorMessage(e.message);
    }
  };

  const loadSpecificCodePromo = async (codePromoId: string) => {
    try {
      const promo:CodePromos = await PromoService.getCodePromoById(codePromoId);
      setCodePromoQuery({ codePromoId });
      setCodePromoData(promo);
    } catch (e:any) {
      setOpenError(true);
      setErrorMessage(e.message);
    }
  };

  const loadAllCodePromos = async () => {
    try {
      const promo:CodePromos = await PromoService.getAllCodePromoData();
      setCodePromoData(promo);
    } catch (e:any) {
      setOpenError(true);
      setErrorMessage(e.message);
    }
  };

  useEffect(() => {
    const tagParam = tagPromoQuery.get('tagPromoId');
    if (tagParam) loadSpecificTagPromo(tagParam);

    const codeParam = codePromoQuery.get('codePromoId');
    if (codeParam) loadSpecificCodePromo(codeParam);
  }, []);

  const permissions = ['loyalty:promo:all', 'loyalty:promo:read', 'loyalty:promo:readAll'];
  const canView = permissions.some((condition) => localStorage.getItem('userScopes')?.includes(condition));

  if (!canView) return <NoPermissionPage />;
  if (singleTagPromo) {
    return (
      <SingleTagPromo
        goBack={goBack}
        tagPromoData={singleTagPromo}
        setSingleTagPromo={setSingleTagPromo}
        setTagParam={setTagParam}
        tagParam={tagPromoQuery.get('tagPromoId')}
        openError={openError}
        setOpenError={setOpenError}
        setErrorMessage={setErrorMessage}
        errorMessage={errorMessage}
        loadAllTags={loadAllTagPromos}
      />
    );
  }

  if (allTagPromoData) {
    return (
      <TagPromoAll
        goBack={goBack}
        tagPromoData={allTagPromoData}
        setSingleTagPromo={setSingleTagPromo}
        setTagParam={setTagParam}
        tagParam={tagPromoQuery.get('tagPromoId')}
        openError={openError}
        setOpenError={setOpenError}
        setErrorMessage={setErrorMessage}
        errorMessage={errorMessage}
        loadAllTags={loadAllTagPromos}
      />
    );
  }

  if (Object.keys(codePromoData).length > 0) {
    return (
      <CodePromoDetails
        goBack={goBack}
        codePromoData={codePromoData}
        codePromoAmount={Object.keys(codePromoData).length}
        openError={openError}
        setOpenError={setOpenError}
        setErrorMessage={setErrorMessage}
        errorMessage={errorMessage}
        codeParam={codePromoQuery.get('codePromoId')}
        setCodeParam={setCodeParam}
        loadAllCodes={loadAllCodePromos}
        setCodePromoData={setCodePromoData}
        isBatch={isBatch}
      />
    );
  }

  return (
    <PromoSearch
      setSingleTagPromo={setSingleTagPromo}
      setTagPromoData={setAllTagPromoData}
      setCodePromoData={setCodePromoData}
      openCreate={openCreate}
      setOpenCreate={setOpenCreate}
      setTagParam={setTagParam}
      setCodeParam={setCodeParam}
      setIsBatch={setIsBatch}
    />
  );
}

export default PromoPage;
