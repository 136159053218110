import React, { useState } from 'react';

import { styled } from '@mui/system';
import {
  Card,
  CardContent,
  Collapse,
  Divider,
  IconButton,
  IconButtonProps,
  Grid,
  Typography,
  Tooltip,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import { FaEdit } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import ValidateEpoch from '../../../util/validateEpoch';

import { CodePromo } from '../../../Services/PromoService';
import UsedByCard from './UsedByCard';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  fontSize: '30px',
  color: theme.palette.common.white,
}));

const CardTitle = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary.main,
  height: '40px',
  [theme.breakpoints.down('sm')]: {
    height: '50px',
  },
}));

const CardTitleText = styled(Typography)(({ theme }) => ({
  alignSelf: 'center',
  color: theme.palette.common.white,
}));

const ExpandedContent = styled(CardContent)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  paddingLeft: '50px',
  paddingRight: '50px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
}));

const StyledEditButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

const StyledDeleteButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  marginRight: '20px',
  '&:hover': {
    color: theme.palette.warning.main,
  },
}));

type Props = {
  codePromoData: CodePromo,
  codePromoId: string,
  deleteCode: (codeId: string) => void,
  startEdit: (codeId: string, data: CodePromo) => void,
};

function CodePromoCard(props: Props) {
  const {
    codePromoData,
    codePromoId,
    deleteCode,
    startEdit,
  } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const HandleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const permissions = ['loyalty:promo:update', 'loyalty:promo:all'];
  const canEdit = permissions.some((condition) => localStorage.getItem('userScopes')?.includes(condition));

  let promoType = codePromoData.Type;
  if (promoType === 'V') promoType = 'voucher';
  if (promoType === 'S') promoType = 'stamp';

  return (
    <Card key={1}>
      <CardTitle>
        <CardTitleText variant="h4">{codePromoId}</CardTitleText>

        <div>
          <Tooltip title="Delete this Code Promotion" placement="bottom">
            <StyledDeleteButton disabled={!canEdit} size="small" onClick={() => deleteCode(codePromoId)}>
              <AiFillDelete />
            </StyledDeleteButton>
          </Tooltip>
          <Tooltip title="Edit this Code Promotion" placement="bottom">
            <StyledEditButton disabled={!canEdit} size="small" onClick={() => startEdit(codePromoId, codePromoData)}>
              <FaEdit />
            </StyledEditButton>
          </Tooltip>
          <ExpandMore
            expand={isExpanded}
            onClick={HandleExpandClick}
            aria-expanded={isExpanded}
            aria-label="show more"
          >
            <MdExpandMore />
          </ExpandMore>
        </div>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <Divider><Typography variant="body2">Store Details</Typography></Divider>
            </Grid>

            {codePromoData.Store ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Store Name :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{`${codePromoData.Store}`}</Typography>
                </Grid>
              </>
            ) : null}

            {codePromoData['Chain ID'] ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Chain Id :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{`${codePromoData['Chain ID']}`}</Typography>
                </Grid>
              </>
            ) : null}

            {codePromoData.Scheme ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Scheme ID :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{`${codePromoData.Scheme}`}</Typography>
                </Grid>
              </>
            ) : null}

            <Grid item xs={12}>
              <Divider><Typography variant="body2">Promotion Details</Typography></Divider>
            </Grid>

            {codePromoData.Type ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Promotion Type :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{promoType}</Typography>
                </Grid>
              </>
            ) : null}

            {codePromoData.Amount ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Amount Added :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{codePromoData.Amount}</Typography>
                </Grid>
              </>
            ) : null}

            {codePromoData.Remaining ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Uses Remaining :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{codePromoData.Remaining}</Typography>
                </Grid>
              </>
            ) : null}

            {codePromoData.Uses ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Amount of Used Codes :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{Object.keys(codePromoData.Uses).length}</Typography>
                </Grid>
              </>
            ) : null}

            {codePromoData.createdAt ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Created At :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    {new Date(ValidateEpoch(codePromoData.createdAt)).toLocaleString()}
                  </Typography>
                </Grid>
              </>
            ) : null}

            {codePromoData['Expiry Timestamp'] ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Expires On :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    {new Date(ValidateEpoch(codePromoData['Expiry Timestamp'])).toLocaleString()}
                  </Typography>
                </Grid>
              </>
            ) : null}

            {codePromoData['Pop Up'] ? (
              <>
                <Grid item xs={12}>
                  <Divider><Typography variant="body2">Pop-Up Details</Typography></Divider>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Title :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{`${codePromoData['Pop Up'].Title}`}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Pop-Up Text :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{`${codePromoData['Pop Up']['Main Text']}`}</Typography>
                </Grid>
              </>
            ) : null}

            {codePromoData.Uses ? (
              <Grid item xs={12}>
                <UsedByCard users={Object.keys(codePromoData.Uses)} usersWithTimeStamp={codePromoData.Uses} />
              </Grid>

            ) : null}
          </Grid>
        </ExpandedContent>
      </Collapse>
    </Card>
  );
}

export default CodePromoCard;
