const API_URL = process.env.REACT_APP_API_URL;

export type Result = {
  account_created: string,
  date_of_birth: string,
  name: string,
  uid: string,
  wallet_email: string,
  gender: string,
};

export type Results = Result[];

export type UserData = {
  'Account Created': number,
  'Date of Birth': string,
  Email: string,
  'FCM Token': string,
  Gender: string,
  'Interaction Log': {}
  Name: string,
};

export type EmailSearch = {
  userData: UserData,
  userId: string,
};

const searchUser = (searchParameters: string): Promise<Results> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/account/search${searchParameters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const getUser = (userId:string) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/account/${userId}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const getUserByEmail = (email:string): Promise<EmailSearch> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/account/email/${email}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const updateUser = (
  userId:string,
  name: string,
  gender: string,
  dateOfBirth: string,
  sendOffers: boolean,
) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/account`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userId, name, dateOfBirth, gender, sendOffers,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const UserService = {
  searchUser,
  getUser,
  updateUser,
  getUserByEmail,
};

export default UserService;
