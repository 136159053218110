import React, { Fragment, useEffect, useState } from 'react';

import { styled } from '@mui/system';
import {
  Card,
  CardContent,
  Collapse,
  IconButton,
  IconButtonProps,
  Grid,
  Divider,
  Typography,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import { LocationBilling } from '../../../Services/BusinessService';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  fontSize: '30px',
  color: theme.palette.common.white,
}));

const CardTitle = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary.main,
  height: '40px',
  [theme.breakpoints.down('sm')]: {
    height: '50px',
  },
}));

const CardTitleText = styled(Typography)(({ theme }) => ({
  alignSelf: 'center',
  color: theme.palette.common.white,
}));

const ExpandedContent = styled(CardContent)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  paddingLeft: '50px',
  paddingRight: '50px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
}));

type Props = {
  businessData: any,
  billingData: LocationBilling[],
  setIsLegacyBilling: (type: boolean) => void,
};

function BillingInfoCard(props: Props) {
  const {
    businessData,
    billingData,
    setIsLegacyBilling,
  } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const HandleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (billingData && billingData.length > 0) {
      billingData.forEach((location) => {
        if (location.billingType === 'usageBased') setIsLegacyBilling(false);
      });
    }
  }, []);

  return (
    <Card key="Billing-Info">
      <CardTitle>
        <CardTitleText variant="h4">Billing Information</CardTitleText>
        <ExpandMore
          expand={isExpanded}
          onClick={HandleExpandClick}
          aria-expanded={isExpanded}
          aria-label="show more"
        >
          <MdExpandMore />
        </ExpandMore>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          <Grid container spacing={2}>
            {billingData && billingData.length > 0 ? (
              <>
                {billingData.map((location) => (
                  <Fragment key={location.locationId}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2">Billing Info :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        {location.billingType === 'usageBased' ? 'Usage Based Pricing' : 'Legacy Pricing'}
                      </Typography>
                    </Grid>

                    {businessData.Locations[location.locationId]['Short Address'] && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="body2">Location Address :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="body1">
                            {businessData.Locations[location.locationId]['Short Address']}
                          </Typography>
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2">Location ID :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">{location.locationId}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2">Country :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">{location.country}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2">Email :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        {location.emailAddress !== '' && location.emailAddress !== null
                          ? location.emailAddress : 'Not Set'}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2">Stripe ID :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">{location.stripeId}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2">Subscription ID :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">{location.stripeSubscription}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider light />
                    </Grid>
                  </Fragment>
                ))}
              </>
            ) : (
              <Grid item xs={12}>
                <Typography variant="body2">
                  No billing data in new system. Business assumed to be on legacy billing.
                </Typography>
              </Grid>
            )}
          </Grid>
        </ExpandedContent>
      </Collapse>
    </Card>
  );
}

export default BillingInfoCard;
