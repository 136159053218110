/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';

import { styled } from '@mui/system';
import {
  Card,
  CardContent,
  Collapse,
  Divider,
  IconButton,
  IconButtonProps,
  Grid,
  Typography,
  Tooltip,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import { AiFillDelete } from 'react-icons/ai';

import ValidateEpoch from '../../../util/validateEpoch';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  fontSize: '30px',
  color: theme.palette.common.white,
}));

const CardTitle = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary.main,
  height: '40px',
  [theme.breakpoints.down('sm')]: {
    height: '50px',
  },
}));

const CardTitleText = styled(Typography)(({ theme }) => ({
  alignSelf: 'center',
  color: theme.palette.common.white,
}));

const ExpandedContent = styled(CardContent)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  paddingLeft: '50px',
  paddingRight: '50px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
}));

const StyledDeleteButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginRight: '20px',
  '&:hover': {
    color: theme.palette.warning.main,
  },
}));

type Props = {
  users: string[],
  usersWithTimeStamp?: any,
};

function UsedByCard(props: Props) {
  const {
    users,
    usersWithTimeStamp,
  } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const HandleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card key={1}>
      <CardTitle>
        <CardTitleText variant="h4">Used By</CardTitleText>

        <div>
          <ExpandMore
            expand={isExpanded}
            onClick={HandleExpandClick}
            aria-expanded={isExpanded}
            aria-label="show more"
          >
            <MdExpandMore />
          </ExpandMore>
        </div>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          {usersWithTimeStamp ? (
            <Grid container spacing={2}>
              {Object.keys(usersWithTimeStamp).map((user) => (
                <Grid item container direction="row" xs={12} key={user}>
                  <Grid item xs={8} justifySelf="center">
                    <Typography variant="body1">{user}</Typography>
                  </Grid>
                  <Grid item xs={3} justifySelf="center">
                    <Typography variant="body1">
                      {new Date(ValidateEpoch(usersWithTimeStamp[user])).toLocaleString()}
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={1}>
                    <Tooltip title="Delete this User" placement="bottom">
                      <StyledDeleteButton size="small" onClick={() => console.log('deleted')}>
                        <AiFillDelete />
                      </StyledDeleteButton>
                    </Tooltip>
                  </Grid> */}
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid container spacing={2}>
              {users.map((user) => (
                <Grid item container direction="row" xs={12} sm={6} key={user}>
                  <Grid item xs={10} justifySelf="center">
                    <Typography variant="body1">{user}</Typography>
                  </Grid>
                  {/* <Grid item xs={2}>
                    <Tooltip title="Delete this User" placement="bottom">
                      <StyledDeleteButton size="small" onClick={() => console.log('deleted')}>
                        <AiFillDelete />
                      </StyledDeleteButton>
                    </Tooltip>
                  </Grid> */}
                </Grid>
              ))}
            </Grid>
          )}
        </ExpandedContent>
      </Collapse>
    </Card>
  );
}

export default UsedByCard;
