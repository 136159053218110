import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import NoPermissionPage from '../../Components/BasicComponents/NoPermissionPage';
import BusinessSearch from './components/BusinessSearch';
import BusinessService, { LocationBilling } from '../../Services/BusinessService';
import BusinessDetails from './components/BusinessDetails';

function BusinessPage() {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasFoundBusiness, setHasFoundBusiness] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [businessData, setBusinessData] = useState({});
  const [billingData, setBillingData] = useState<LocationBilling[]>([]);
  const [partnerId, setPartnerId] = useState<string>('');
  const [partnerQueryParam, setPartnerQueryParam] = useSearchParams();

  const permissions = ['loyalty:partners:read', 'loyalty:partners:all'];
  const canView = permissions.some((condition) => localStorage.getItem('userScopes')?.includes(condition));

  const loadBillingInfo = async (id: string) => {
    try {
      const billing = await BusinessService.getBillingInfo(id);
      setBillingData(billing);
    } catch (e) {
      console.log(e);
    }
  };

  const loadBusinessDetails = async (id:string) => {
    setIsLoading(true);
    setPartnerQueryParam({ partnerId: id });
    try {
      const foundBusiness:any = await BusinessService.getBusiness(id);
      const cacheBustValue = encodeURIComponent(new Date().getTime());
      foundBusiness['Basic Fields'].Logo = `${foundBusiness['Basic Fields'].Logo}&version=${cacheBustValue}`;
      foundBusiness['Basic Fields'].Cover = `${foundBusiness['Basic Fields'].Cover}&version=${cacheBustValue}`;
      setBusinessData(foundBusiness);
      setPartnerId(id);
      setHasFoundBusiness(true);
      loadBillingInfo(id);
    } catch (e:any) {
      setError(true);
      if (e.message) setErrorMessage(e.message);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const goBack = () => {
    setError(false);
    setHasFoundBusiness(false);
    setPartnerQueryParam(undefined);
    setBusinessData({});
  };

  useEffect(() => {
    const partnerParam = partnerQueryParam.get('partnerId');
    if (partnerParam) loadBusinessDetails(partnerParam);
  }, []);

  if (!canView) {
    return <NoPermissionPage />;
  }
  if (hasFoundBusiness) {
    return (
      <BusinessDetails
        businessData={businessData}
        loadBusinessDetails={loadBusinessDetails}
        loadBillingInfo={loadBillingInfo}
        goBack={goBack}
        partnerId={partnerId}
        billingData={billingData}
      />
    );
  }

  return (
    <BusinessSearch
      error={error}
      errorMessage={errorMessage}
      isLoading={isLoading}
      loadBusinessDetails={loadBusinessDetails}
      setError={setError}
    />
  );
}

export default BusinessPage;
