/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/object-curly-spacing */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import {
  TextField,
  MenuItem,
  Typography,
  Grid,
  Autocomplete,
  Box,
} from '@mui/material';

type Props = {
  openingTimes: Times,
  setOpeningTimes: (times: Times) => void,
};

export type Time = {
  openingHour: string,
  openingMinute: string,
  closingHour: string,
  closingMinute: string,
};

export type Times = {
  [key: string]: Time,
};

function OpeningHourSelector(props: Props) {
  const {
    openingTimes,
    setOpeningTimes,
  } = props;

  const hours = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
  ];

  const minutes = [
    '00',
    '15',
    '30',
    '45',
  ];

  const timeKeys = Object.keys(openingTimes);

  const handleChange = (
    day: string,
    isHour:boolean,
    isOpening:boolean,
    newNumber: string,
  ) => {
    const newTimes: Times = {
      ...openingTimes,
    };

    if (isHour) {
      if (isOpening) {
        newTimes[day].openingHour = newNumber;
      } else {
        newTimes[day].closingHour = newNumber;
      }
    } else if (isOpening) {
      newTimes[day].openingMinute = newNumber;
    } else {
      newTimes[day].closingMinute = newNumber;
    }

    setOpeningTimes(newTimes);
  };

  const handleInputChange = (
    day: string,
    isHour:boolean,
    isOpening:boolean,
    number: string,
  ) => {
    if (number.length < 3) {
      handleChange(day, isHour, isOpening, number);
    }
  };

  return (
    <div>
      <Grid direction="column" container spacing={2}>
        <Grid container direction="row" spacing={2} style={{ marginBottom: '20px' }}>
          <Grid item container justifyContent="center" alignContent="center" xs={2}>
            <Typography variant="body2">Day</Typography>
          </Grid>
          <Grid item container justifyContent="center" alignContent="center" xs={5}>
            <Typography variant="body2">Opening Times</Typography>
          </Grid>
          <Grid item container justifyContent="center" alignContent="center" xs={5}>
            <Typography variant="body2">Closing Times</Typography>
          </Grid>
        </Grid>

        {timeKeys.map((day) => (
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1} key={`${day}`} sx={{marginBottom: '10px'}}>
            <Grid item container xs={2} key={`${day}-title`}>
              <Typography variant="body1">{day}</Typography>
            </Grid>
            <Grid item xs={1.5} key={`${day}-opening-hour`}>
              <Autocomplete
                fullWidth
                disablePortal
                options={hours}
                disableClearable
                freeSolo
                autoSelect
                value={openingTimes[day].openingHour}
                getOptionLabel={(option) => option}
                inputValue={openingTimes[day].openingHour}
                isOptionEqualToValue={(option, newValue) => option === newValue}
                onInputChange={(event, value, reason) => {
                  handleInputChange(day, true, true, value.replace(/\D+/g, ''));
                }}
                onChange={(e, hour) => {
                  if (hour !== null && e.type === 'click') {
                    handleChange(day, true, true, hour);
                  }
                }}
                renderOption={(prop, option) => (
                  <Box component="li" {...prop}>
                    <Typography variant="body1">{option}</Typography>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={openingTimes[day].openingHour.length < 2}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item container justifyContent="center" xs={1} key={`${day}-divider1`}>
              <Typography variant="body2">:</Typography>
            </Grid>

            <Grid item xs={1.5} key={`${day}-opening-minute`}>
              <Autocomplete
                fullWidth
                disablePortal
                options={minutes}
                disableClearable
                freeSolo
                autoSelect
                value={openingTimes[day].openingMinute}
                getOptionLabel={(option) => option}
                inputValue={openingTimes[day].openingMinute}
                isOptionEqualToValue={(option, newValue) => option === newValue}
                onInputChange={(event, value, reason) => {
                  handleInputChange(day, false, true, value.replace(/\D+/g, ''));
                }}
                onChange={(e, hour) => {
                  if (hour !== null && e.type === 'click') {
                    handleChange(day, false, true, hour);
                  }
                }}
                renderOption={(prop, option) => (
                  <Box component="li" {...prop}>
                    <Typography variant="body1">{option}</Typography>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={openingTimes[day].openingMinute.length < 2}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item container justifyContent="center" xs={1} key={`${day}-time-divider`}>
              <Typography variant="body2">-</Typography>
            </Grid>

            <Grid item xs={1.5} key={`${day}-closing-hour`}>
              <Autocomplete
                fullWidth
                disablePortal
                options={hours}
                disableClearable
                freeSolo
                autoSelect
                value={openingTimes[day].closingHour}
                getOptionLabel={(option) => option}
                inputValue={openingTimes[day].closingHour}
                isOptionEqualToValue={(option, newValue) => option === newValue}
                onInputChange={(event, value, reason) => {
                  handleInputChange(day, true, false, value.replace(/\D+/g, ''));
                }}
                onChange={(e, hour) => {
                  if (hour !== null && e.type === 'click') {
                    handleChange(day, true, false, hour);
                  }
                }}
                renderOption={(prop, option) => (
                  <Box component="li" {...prop}>
                    <Typography variant="body1">{option}</Typography>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={openingTimes[day].closingHour.length < 2}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={1} container justifyContent="center" key={`${day}-divider2`}>
              <Typography variant="body2">:</Typography>
            </Grid>

            <Grid item xs={1.5} key={`${day}-closing-minute`}>
              <Autocomplete
                fullWidth
                disablePortal
                options={minutes}
                disableClearable
                freeSolo
                autoSelect
                value={openingTimes[day].closingMinute}
                getOptionLabel={(option) => option}
                inputValue={openingTimes[day].closingMinute}
                isOptionEqualToValue={(option, newValue) => option === newValue}
                onInputChange={(event, value, reason) => {
                  handleInputChange(day, false, false, value.replace(/\D+/g, ''));
                }}
                onChange={(e, hour) => {
                  if (hour !== null && e.type === 'click') {
                    handleChange(day, false, false, hour);
                  }
                }}
                renderOption={(prop, option) => (
                  <Box component="li" {...prop}>
                    <Typography variant="body1">{option}</Typography>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={openingTimes[day].closingMinute.length < 2}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default OpeningHourSelector;
