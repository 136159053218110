/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';

import { styled } from '@mui/system';
import {
  Typography,
  Button,
  Grid,
} from '@mui/material';

import { HiCake } from 'react-icons/hi';
import { AiFillPlusCircle, AiFillShopping, AiFillCloud } from 'react-icons/ai';
import { BsFillChatSquareTextFill } from 'react-icons/bs';
import { FaUserPlus, FaChartLine } from 'react-icons/fa';

import Content from '../../../Components/BasicComponents/Content';
import TitleComponent from '../../../Components/BasicComponents/TitleComponent';
import SavingChangesDialog from '../../../Components/BasicComponents/SavingChangesDialog';
import EntryDialog from '../../../Components/BasicComponents/EntryDialog';
import CampaignCreate from './CampaignCreate';
import BusinessService from '../../../Services/BusinessService';

const StyledGetButton = styled(Button)(({ theme }) => ({
  height: '55px',
  fontSize: '18px',
  fontWeight: 'bold',
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
  [theme.breakpoints.down('sm')]: {
    height: '35px',
  },
}));

const StyledCreateButton = styled(Button)(({ theme }) => ({
  height: '55px',
  fontSize: '18px',
  fontWeight: 'bold',
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
  [theme.breakpoints.down('sm')]: {
    height: '35px',
  },
}));

type Props = {
  getCampaigns: (param?: string) => void,
  getCampaignAnalytics: () => void,
  loading: boolean,
};

function CampaignSearch(props: Props) {
  const {
    getCampaigns,
    getCampaignAnalytics,
    loading,
  } = props;

  const [openCreateEntry, setOpenCreateEntry] = useState<boolean>(false);
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [businessId, setBusinessId] = useState<string>('');
  const [businessError, setBusinessError] = useState<boolean>(false);
  const [businessData, setBusinessData] = useState({});
  const [loadingBusiness, setLoadingBusiness] = useState<boolean>(false);

  const searchBusiness = async () => {
    setLoadingBusiness(true);
    try {
      const data = await BusinessService.getBusiness(businessId);
      setBusinessData(data);
      setOpenCreateEntry(false);
      setOpenCreate(true);
    } catch (e:any) {
      setBusinessError(true);
    }
    setLoadingBusiness(false);
  };

  return (
    <Content>
      <TitleComponent text="Campaign Data" />
      <Grid container spacing={2} sx={{ marginTop: '10px', marginBottom: '10px' }}>
        <Grid item xs={6}>
          <StyledGetButton
            aria-label="Get All Birthday Campaigns"
            variant="contained"
            fullWidth
            startIcon={<HiCake />}
            onClick={() => getCampaigns('birthday')}
          >
            <Typography variant="body2">Get Birthday Campaigns</Typography>
          </StyledGetButton>
        </Grid>

        <Grid item xs={6}>
          <StyledGetButton
            aria-label="Get All Retention Campaigns"
            variant="contained"
            fullWidth
            startIcon={<BsFillChatSquareTextFill />}
            onClick={() => getCampaigns('retention')}
          >
            <Typography variant="body2">Get Retention Campaigns</Typography>
          </StyledGetButton>
        </Grid>

        <Grid item xs={6}>
          <StyledGetButton
            aria-label="Get All Acquisition Campaigns"
            variant="contained"
            fullWidth
            startIcon={<FaUserPlus />}
            onClick={() => getCampaigns('acquisition')}
          >
            <Typography variant="body2">Get Acquisition Campaigns</Typography>
          </StyledGetButton>
        </Grid>

        <Grid item xs={6}>
          <StyledGetButton
            aria-label="Get All Custom Campaigns"
            variant="contained"
            fullWidth
            startIcon={<AiFillShopping />}
            onClick={() => getCampaigns('custom')}
          >
            <Typography variant="body2">Get Custom Campaigns</Typography>
          </StyledGetButton>
        </Grid>

        <Grid item xs={6}>
          <StyledGetButton
            aria-label="Get All Campaigns"
            variant="contained"
            fullWidth
            startIcon={<AiFillCloud />}
            onClick={() => getCampaigns()}
          >
            <Typography variant="body2">Get All Campaigns</Typography>
          </StyledGetButton>
        </Grid>

        <Grid item xs={6}>
          <StyledCreateButton
            aria-label="birthday-create"
            variant="contained"
            fullWidth
            startIcon={<AiFillPlusCircle />}
            onClick={() => {
              setBusinessId('');
              setOpenCreateEntry(true);
            }}
          >
            <Typography variant="body2">Create New Campaign</Typography>
          </StyledCreateButton>
        </Grid>
      </Grid>

      <TitleComponent text="Campaign Analytics" />
      <Grid container spacing={2} sx={{ marginTop: '10px', marginBottom: '10px' }}>
        <Grid item xs={12}>
          <StyledGetButton
            aria-label="View Campaign Analytics"
            variant="contained"
            fullWidth
            startIcon={<FaChartLine />}
            onClick={getCampaignAnalytics}
          >
            <Typography variant="body2">View Campaign Analytics</Typography>
          </StyledGetButton>
        </Grid>
      </Grid>

      {loading ? (
        <SavingChangesDialog open={loading} dialogTitle="Loading Campaigns" />
      ) : null}

      {openCreateEntry ? (
        <EntryDialog
          open={openCreateEntry}
          dialogTitle="Business Id"
          dialogText="Please enter the business ID of the business you wish to create a campaign for"
          handleCancel={() => setOpenCreateEntry(false)}
          handleConfirm={searchBusiness}
          value={businessId}
          setValue={setBusinessId}
          error={businessError}
          setError={setBusinessError}
          errorMessage="No Business found with that ID"
          loading={loadingBusiness}
        />
      ) : null}

      {openCreate ? (
        <CampaignCreate
          open={openCreate}
          setOpen={setOpenCreate}
          businessId={businessId}
          businessData={businessData}
          isCreate
        />
      ) : null}

    </Content>
  );
}

export default CampaignSearch;
