/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import {
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  Button,
  Dialog,
  Grid,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Typography,
  FormGroup,
} from '@mui/material';
import { styled } from '@mui/system';
import { GiCancel, GiConfirmed } from 'react-icons/gi';
import PurchaseOptionEntry from './PurchaseOptionEntry';
import ConfirmDialog from '../../../Components/BasicComponents/ConfirmDialog';

import MarketPlaceService, {
  BusinessMarketPlace,
  PurchaseOption,
  MarketPlaceData,
} from '../../../Services/MarketPlaceService';

const StyledTitle = styled(Typography, {})({
  alignSelf: 'center',
  paddingTop: '25px',
});

const StyledConfirm = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const StyledCancel = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.white,
  backgroundColor: theme.palette.warning.main,
  '&:hover': {
    backgroundColor: theme.palette.warning.main,
  },
}));

type Props = {
  businessData: any,
  create: boolean,
  open: boolean,
  setOpen: (toggle: boolean) => void,
  partnerId: string,
  marketPlaceData?: BusinessMarketPlace | undefined,
  schemeOptions: any,
};

function EditMarketPlace(props: Props) {
  const {
    businessData,
    create,
    open,
    setOpen,
    partnerId,
    marketPlaceData,
    schemeOptions,
  } = props;

  const [gifting, setGifting] = useState<boolean>(false);
  const [currency, setCurrency] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [stripeId, setStripeId] = useState<string>('');
  const [terms, setTerms] = useState<string>('');
  const [optionAmount, setOptionAmount] = useState<number>(0);
  const [purchaseOptions, setPurchaseOptions] = useState<PurchaseOption[]>([]);
  const [entryError, setEntryError] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [testStore, setTestStore] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const name = businessData['Basic Fields']['Chain Name'];

  const handleClose = () => {
    setEntryError(false);
    setOpenConfirm(false);
    setGifting(false);
    setCurrency('');
    setDescription('');
    setStripeId('');
    setTerms('');
    setOptionAmount(0);
    setPurchaseOptions([]);
    setOpen(false);
  };

  useEffect(() => {
    if (marketPlaceData && !create) {
      setGifting(marketPlaceData['Has Gift Vouchers']);
      setDescription(marketPlaceData.Description);
      setStripeId(marketPlaceData['Stripe Account']);
      setTerms(marketPlaceData.Terms[0]);
      setCurrency(marketPlaceData.Currency);
      setOptionAmount(marketPlaceData['Purchase Options'].length);
      const opt:PurchaseOption[] = marketPlaceData['Purchase Options']
        .filter((option) => option !== null)
        .map((validOption): PurchaseOption => ({
          Scheme: validOption.Scheme,
          Amount: validOption.Amount,
          Discount: validOption.Discount ? validOption.Discount : 0,
          Title: validOption.Title,
          'Unit Price': validOption['Unit Price'],
        }));
      setPurchaseOptions(opt);
    }
  }, [marketPlaceData]);

  const handleOptionAmount = (amountEntry: string) => {
    const setAmount = amountEntry.replace(/\D+/g, '');
    let amount = parseInt(setAmount, 10);
    if (Number.isNaN(amount)) {
      amount = 0;
    } else if (amount > 6) {
      amount = 5;
    }
    setOptionAmount(amount);

    const newOptions: PurchaseOption[] = [];
    let i = 0;
    while (i < amount) {
      let option: PurchaseOption = {
        Scheme: '',
        Amount: 0,
        Discount: 0,
        Title: '',
        'Unit Price': 0,
      };

      if (!create && marketPlaceData && marketPlaceData['Purchase Options'][i] !== undefined) {
        const existingOption = marketPlaceData['Purchase Options'][i];
        option = {
          Scheme: existingOption.Scheme,
          Amount: existingOption.Amount,
          Discount: existingOption.Discount ? existingOption.Discount : 0,
          Title: existingOption.Title,
          'Unit Price': existingOption['Unit Price'],
        };
      }
      newOptions.push(option);
      i += 1;
    }
    setPurchaseOptions(newOptions);
  };

  const confirmData = ():boolean => {
    let error = false;
    if (currency === '') error = true;
    if (description === '') error = true;
    if (stripeId === '') error = true;
    if (terms === '') error = true;
    if (optionAmount === 0) error = true;
    purchaseOptions.forEach((option) => {
      if (option.Amount === 0) error = true;
      if (option['Unit Price'] === 0) error = true;
      if (option.Scheme === '') error = true;
      if (option.Title === '') error = true;
    });
    setEntryError(error);
    return error;
  };

  const saveMarketPlace = async () => {
    setIsLoading(true);
    if (create) {
      try {
        const data: MarketPlaceData = {
          'MarketPlace Listing Data': {
            Gifting: gifting,
            Business: partnerId,
            Currency: currency,
            Description: description,
            TestBusiness: testStore,
          },
          'Purchase Data': {
            'Purchase Options': purchaseOptions,
            'Stripe Account': stripeId,
            Terms: [terms],
          },
        };
        await MarketPlaceService.createMarketPlace(partnerId, data);
        handleClose();
      } catch (e: any) {
        console.log(e.message);
      }
    } else {
      try {
        const data: MarketPlaceData = {
          'MarketPlace Listing Data': {
            Gifting: gifting,
            Business: partnerId,
            Currency: currency,
            Description: description,
            TestBusiness: testStore,
          },
          'Purchase Data': {
            'Purchase Options': purchaseOptions,
            'Stripe Account': stripeId,
            Terms: [terms],
          },
        };
        await MarketPlaceService.updateMarketPlace(partnerId, data);
        handleClose();
      } catch (e: any) {
        console.log(e.message);
      }
    }
    setIsLoading(false);
  };

  const handleClosed = (event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick' && 'escapeKeyDown') return;
    handleClose();
  };

  return (
    <Dialog
      aria-label="confirm-dialog"
      onClose={(event, reason) => handleClosed(event, reason)}
      open={open}
      fullWidth
      maxWidth="md"
      sx={{ zIndex: '3000' }}
    >
      <StyledTitle variant="h1">
        {create ? `Add ${name} to the Market Place` : `Edit ${name} on the Market Place`}
      </StyledTitle>
      <DialogContent>
        <Grid container spacing={3}>

          <Grid item xs={12}>
            <Typography variant="body2">Stripe Connect Account ID</Typography>
            <Typography variant="body1">
              {'The unique stripe connect account id. Under Account information, usually starting with \'acct_\''}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="stripe-id-entry"
              label="Stripe Connect ID"
              variant="outlined"
              color="primary"
              value={stripeId}
              error={entryError && stripeId === ''}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                setStripeId(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">Description</Typography>
            <Typography variant="body1">
              The text displayed that describes the voucher the user is purchasing
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="description-entry"
              label="Description"
              variant="outlined"
              color="primary"
              value={description}
              error={entryError && description === ''}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">Terms & Conditions</Typography>
            <Typography variant="body1">
              The text displayed that outlines the terms and conditions of the voucher purchase
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="terms-entry"
              label="Terms"
              variant="outlined"
              color="primary"
              value={terms}
              error={entryError && terms === ''}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                setTerms(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">Currency</Typography>
            <Typography variant="body1">Select which currency this vouchers will be purchased in</Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              select
              InputLabelProps={{ shrink: true }}
              id="currency-select"
              label="Currency"
              variant="outlined"
              color="primary"
              error={entryError && currency === ''}
              value={currency}
              onChange={(event) => {
                setCurrency(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
            >
              <MenuItem key={0} value="" disabled>Select one of the below</MenuItem>
              <MenuItem key={1} value="euro">€ - Euro</MenuItem>
              <MenuItem key={2} value="gbp">£ - British Pounds</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="body2">Gift Vouchers</Typography>
            <Typography variant="body1">Allow vouchers to be purchased and sent to other Squid users?</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox checked={gifting} onChange={() => setGifting(true)} />
                }
                label="Yes"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={!gifting} onChange={() => setGifting(false)} />
                }
                label="No"
              />
            </FormGroup>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="body2">Test Business</Typography>
            <Typography variant="body1">
              Is this a store only intended for internal testing ? Will not show on the app
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox checked={testStore} onChange={() => setTestStore(true)} />
                }
                label="Yes"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={!testStore} onChange={() => setTestStore(false)} />
                }
                label="No"
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">Amount of Purchase Options</Typography>
            <Typography variant="body1">
              The amount of purchase options the user will have when buying from the market place.
              Cannot be more than 5
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="options-entry"
              label="Amount of Options"
              variant="outlined"
              color="primary"
              value={optionAmount}
              error={entryError && optionAmount === 0}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                handleOptionAmount(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
            />
          </Grid>

          { purchaseOptions && purchaseOptions.map((option, index) => (
            <Grid item xs={12} key={index}>
              <PurchaseOptionEntry
                key={index}
                options={purchaseOptions}
                setPurchaseOptionArray={setPurchaseOptions}
                index={index}
                schemeOptions={schemeOptions}
                error={entryError}
              />
            </Grid>
          ))}

        </Grid>
      </DialogContent>

      <DialogActions>
        <StyledCancel
          variant="contained"
          fullWidth
          startIcon={<GiCancel />}
          onClick={handleClose}
        >
          <Typography variant="body2">CANCEL</Typography>
        </StyledCancel>
        <StyledConfirm
          variant="contained"
          fullWidth
          startIcon={<GiConfirmed />}
          onClick={async () => {
            const invalidData = await confirmData();
            if (!invalidData) setOpenConfirm(true);
          }}
        >
          <Typography variant="body2">CONFIRM</Typography>
        </StyledConfirm>
      </DialogActions>

      <ConfirmDialog
        open={openConfirm}
        handleCancel={() => setOpenConfirm(false)}
        handleConfirm={() => saveMarketPlace()}
        dialogTitle={create ? 'Add to Market Place' : 'Save Changes'}
        dialogText={create ? `Are you sure you wish to add ${name} to the Market Place ?`
          : `Are you sure you wish to save these changes for ${name} on the Market Place ?`}
        isLoading={isLoading}
      />
    </Dialog>
  );
}

export default EditMarketPlace;
