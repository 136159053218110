import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { GiCancel, GiConfirmed } from 'react-icons/gi';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import CampaignsService, { Campaign, CampaignEditBody } from '../../../Services/CampaignsService';
import ErrorDialog from '../../../Components/BasicComponents/ErrorDialog';
import AlertDialog from '../../../Components/BasicComponents/AlertDialog';

const StyledTitle = styled(Typography, {})({
  alignSelf: 'center',
  paddingTop: '25px',
  paddingBottom: '10px',
});

const StyledConfirm = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const StyledCancel = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.warning.main,
  '&:hover': {
    backgroundColor: theme.palette.warning.main,
  },
}));

type CampaignEditProps = {
  open: boolean,
  setOpen: (toggle: boolean) => void,
  campaignData: Campaign,
  businessId: string,
  loadCampaigns: (param?: string) => void,
};

function CampaignEdit(props: CampaignEditProps) {
  const {
    open,
    setOpen,
    campaignData,
    businessId,
    loadCampaigns,
  } = props;

  const now = dayjs(Date.now());

  const [description, setDescription] = useState<string>('');
  const [rewardExpiry, setRewardExpiry] = useState<string>('');
  const [messageHeader, setMessageHeader] = useState<string>('');
  const [messageBody, setMessageBody] = useState<string>('');
  const [endTime, setEndTime] = useState<Dayjs>(now);
  const [startTime, setStartTime] = useState<Dayjs>(now);
  const [neverEnd, setNeverEnd] = useState<boolean>(false);
  const [inputError, setInputError] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [openAlert, setOpenAlert] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClosed = (event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick' && 'escapeKeyDown') return;
    handleClose();
  };

  const checkInputs = ():boolean => {
    if (
      description === ''
      || (campaignData.campaignType !== 'custom' && (endTime <= now && !neverEnd))
      || (campaignData.campaignType !== 'custom' && endTime <= startTime && !neverEnd)
      || (campaignData.campaignType !== 'acquisition' && messageBody === '')
      || (campaignData.campaignType !== 'acquisition' && messageHeader === '')
      || (campaignData.campaignType !== 'acquisition' && rewardExpiry === '')
    ) {
      return false;
    }

    setInputError(false);
    return true;
  };

  const handleEdit = async () => {
    let campaignEdit: CampaignEditBody = {
      description,
      startEpoch: campaignData.startEpoch ?? 0,
      campaignType: campaignData.campaignType,
    };

    if (campaignData.campaignType !== 'acquisition') {
      campaignEdit = {
        ...campaignEdit,
        messageHeader,
        messageBody,
        rewardExpiryDays: parseInt(rewardExpiry, 10),
      };
    }

    if (campaignData.campaignType !== 'custom') {
      campaignEdit = {
        ...campaignEdit,
        endEpoch: neverEnd ? 0 : dayjs(endTime).valueOf(),
      };
    }

    try {
      await CampaignsService.updateCampaign(campaignData.campaignId, campaignEdit);
      setOpenAlert(true);
    } catch (e:any) {
      setErrorMessage(e.message);
      setOpenError(true);
    }
  };

  useEffect(() => {
    if (campaignData.description) setDescription(campaignData.description);
    if (campaignData.rewardExpiryDays) setRewardExpiry(campaignData.rewardExpiryDays.toString());
    if (campaignData.messageData) {
      const messageId = Object.keys(campaignData.messageData);
      if (messageId[0] && campaignData.messageData[messageId[0]].messageTitle) {
        setMessageHeader(campaignData.messageData[messageId[0]].messageTitle);
      }
      if (messageId[0] && campaignData.messageData[messageId[0]].messageBody) {
        setMessageBody(campaignData.messageData[messageId[0]].messageBody);
      }
    }
    if (campaignData.endEpoch !== undefined && campaignData.endEpoch === 0) {
      setNeverEnd(true);
    } else if (campaignData.endEpoch !== undefined) {
      setEndTime(dayjs.unix(campaignData.endEpoch / 1000));
    }
    if (campaignData.startEpoch) setStartTime(dayjs.unix(campaignData.startEpoch / 1000));
  }, [campaignData]);

  return (
    <Dialog
      aria-label="campaign-dialog"
      onClose={(event, reason) => handleClosed(event, reason)}
      open={open}
      fullWidth
      maxWidth="md"
      sx={{ zIndex: '1100' }}
    >
      <StyledTitle variant="h1">{`Edit Campaign for ${businessId}`}</StyledTitle>

      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="body2">Campaign Name</Typography>
            <Typography variant="body1">
              This is the name that will be used to refer to the campaign.
              This will appear on the businesses campaign page.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              id="description-edit"
              label="Description"
              variant="outlined"
              color="primary"
              value={description}
              error={inputError && description === ''}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
            />
          </Grid>

          {campaignData.campaignType !== 'acquisition' && (
            <>
              <Grid item xs={12}>
                <Typography variant="body2">Reward Expiry</Typography>
                <Typography variant="body1">
                  This is how many days they have to claim the reward and then how long they have to redeem it.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="reward-expiry-entry-edit"
                  label="Reward Expiry"
                  variant="outlined"
                  color="primary"
                  value={rewardExpiry}
                  error={inputError && rewardExpiry === ''}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ autoComplete: 'off' }}
                  onChange={(event) => {
                    const setAmount = event.target.value.replace(/\D+/g, '');
                    setRewardExpiry(setAmount);
                  }}
                />
              </Grid>
            </>
          )}

          {campaignData.campaignType !== 'acquisition' && (
            <>
              <Grid item xs={12}>
                <Typography variant="body2">Message Title</Typography>
                <Typography variant="body1">
                  This is the title of the message which will be displayed in the in-app inbox.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="title-entry-edit"
                  label="Message Title"
                  variant="outlined"
                  color="primary"
                  value={messageHeader}
                  error={inputError && messageHeader === ''}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ autoComplete: 'off' }}
                  onChange={(event) => {
                    setMessageHeader(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">Message Body</Typography>
                <Typography variant="body1">
                  This is the main message content which will be displayed in the in-app inbox.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="body-entry-edit"
                  label="Message Body"
                  variant="outlined"
                  color="primary"
                  value={messageBody}
                  error={inputError && messageBody === ''}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ autoComplete: 'off' }}
                  onChange={(event) => {
                    setMessageBody(event.target.value);
                  }}
                />
              </Grid>
            </>
          )}

          {campaignData.campaignType !== 'custom' && (
            <>
              <Grid item xs={12}>
                <Typography variant="body2">End Date & Time of Campaign</Typography>
                <Typography variant="body1">
                  This will time in which the campaign will end. If you want the campaign to run
                  indefinitely the tick the box on the right.
                </Typography>
              </Grid>
              <Grid item container xs={6} justifyContent="center">
                <DateTimePicker
                  value={endTime}
                  onChange={(value) => {
                    if (value !== null) setEndTime(value);
                  }}
                  slotProps={{
                    textField: {
                      error: inputError && ((endTime <= startTime || endTime <= now)),
                    },
                  }}
                  disabled={neverEnd}
                />
              </Grid>
              <Grid item container xs={6} justifyContent="center">
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={neverEnd}
                      onChange={() => {
                        setNeverEnd(!neverEnd);
                      }}
                    />
                  )}
                  label="Run Indefinitely"
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <StyledCancel
          variant="contained"
          fullWidth
          startIcon={<GiCancel />}
          onClick={() => handleClose()}
        >
          <Typography variant="body2">CANCEL</Typography>
        </StyledCancel>

        <StyledConfirm
          variant="contained"
          fullWidth
          startIcon={<GiConfirmed />}
          onClick={() => {
            const pass = checkInputs();
            if (pass) handleEdit();
            else {
              setErrorMessage('Missing options! please check the required inputs');
              setInputError(true);
              setOpenError(true);
            }
          }}
        >
          <Typography variant="body2">CONFIRM</Typography>
        </StyledConfirm>
      </DialogActions>

      {openAlert && (
        <AlertDialog
          open={openAlert}
          setOpen={setOpenAlert}
          dialogTitle="Success"
          dialogText={`Campaign for ${businessId} has been successfully updated!`}
          handleOk={() => {
            setOpenAlert(false);
            setOpen(false);
            loadCampaigns(campaignData.campaignType);
          }}
        />
      )}

      {openError && (
        <ErrorDialog
          dialogTitle="Error"
          dialogText={errorMessage}
          open={openError}
          handleClose={() => {
            setOpenError(false);
            setErrorMessage('');
          }}
        />
      )}

    </Dialog>
  );
}

export default CampaignEdit;
