import React from 'react';

import {
  Switch,
  SwitchProps,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';

const StatusText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isLive',
}) <{ isLive?: boolean }>(({ theme, isLive }) => ({
  marginLeft: '0px',
  fontWeight: 'bold',
  color: isLive ? theme.palette.secondary.main : theme.palette.warning.main,
  '& .MuiFormControlLabel-label.Mui-disabled': {
    color: isLive ? theme.palette.secondary.main : theme.palette.warning.main,
  },
}));

const SwitchBox = styled('div', {})({
  display: 'flex',
  flexDirection: 'row',
  width: '115px',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  marginLeft: '5px',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.secondary.main,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.warning.main,
    opacity: 1,
  },
}));

type Props = {
  activeText: string,
  disabledText: string,
  disabled?: boolean,
  isLive: boolean,
  activateAction: () => void,
  deactivateAction: () => void,
};

function Toggle(props: Props) {
  const {
    activeText,
    disabledText,
    disabled,
    isLive,
    activateAction,
    deactivateAction,
  } = props;

  return (
    <SwitchBox>
      <StatusText isLive={isLive} variant="body2">{isLive ? activeText : disabledText}</StatusText>
      <IOSSwitch
        disabled={disabled}
        checked={isLive}
        onClick={() => {
          if (isLive) deactivateAction();
          else activateAction();
        }}
      />
    </SwitchBox>
  );
}

export default Toggle;
