import React, { useState } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Box,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';

import TitleComponent from './TitleComponent';

const StyledBox = styled(Box, {})({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  border: '1px solid',
  maxHeight: '510px',
  [theme.breakpoints.down('sm')]: {
    maxHeight: '400px',
  },
}));

const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  fontWeight: 700,
  textAlign: 'center',
}));

const StyledBodyCell = styled(TableCell, {})({
  textAlign: 'center',
  fontWeight: 'inherit',
  padding: '10px',
});

type Props = {
  tableHeaders: string[],
  tableItems: string[][],
  title: string,
};

function PaginationTable(props: Props) {
  const { tableItems, tableHeaders, title } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <StyledBox>
      <TitleComponent text={title} />
      <StyledTableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => (
                <StyledHeaderCell key={index}>{header}</StyledHeaderCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((pageData, rowIndex) => (
                <TableRow key={rowIndex}>
                  {pageData.map((cellData, cellIndex) => (
                    <StyledBodyCell key={cellIndex}>
                      <Typography variant="body1">{cellData}</Typography>
                    </StyledBodyCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </StyledTableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={tableItems.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </StyledBox>
  );
}

export default PaginationTable;
