/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  InputAdornment,
  Button,
} from '@mui/material';
import { styled } from '@mui/system';
import { AiOutlineSearch, AiFillPlusCircle } from 'react-icons/ai';

import CreateNewUser from './CreateNewUser';
import EditUserPermissions from './EditUserPermissions';
import SearchTableRow from './SearchTableRow';
import CreateNewBusinessUser from './CreateNewBusinessUser';
import { Locations } from '../../../Services/LocationService';
import BusinessService from '../../../Services/BusinessService';
import LocationDialog from './LocationDialog';
import AdminService from '../../../Services/AdminService';
import EntryDialog from '../../../Components/BasicComponents/EntryDialog';

const StyledContainer = styled('div')(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.primary.main,
  borderRadius: '5px',
}));

const StyledHeaderRow = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: '20px',
  paddingBottom: '20px',
  paddingLeft: '40px',
  paddingRight: '40px',
  backgroundColor: theme.palette.background.default,
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
}));

const StyledCreateButton = styled(Button)(({ theme }) => ({
  height: '45px',
  fontWeight: 'bold',
  backgroundColor: theme.palette.secondary.main,
  width: '300px',
}));

const StyledSearch = styled(TextField)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.common.white,
  width: '400px',
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  border: 1,
  borderColor: theme.palette.primary.main,
  maxHeight: '100%',
}));

const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  fontWeight: 700,
  textAlign: 'center',
}));

type Props = {
  fetchAdminUsers: () => void,
  tableHeaders: string[],
  tableItems: string[][],
  isAdmin: boolean,
  handleErrorDialog: (title: string, message: string) => void;
};

function SearchTable(props: Props) {
  const {
    fetchAdminUsers,
    tableItems,
    tableHeaders,
    isAdmin,
    handleErrorDialog,
  } = props;

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [searchText, setSearchText] = useState<string>('');
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [openBusinessCreate, setOpenBusinessCreate] = useState<boolean>(false);
  const [openBusinessEdit, setOpenBusinessEdit] = useState<boolean>(false);
  const [openLocationEdit, setOpenLocationEdit] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>('');
  const [userId, setUserId] = useState<string>('');
  const [userType, setUserType] = useState<string>('');
  const [userRole, setUserRole] = useState<string>('');
  const [userLocationAccess, setUserLocationAccess] = useState<string[]>([]);
  const [businessLocations, setBusinessLocations] = useState<Locations>({});

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEdit = (email: string, type: string, role: string, uid: string) => {
    setUserEmail(email);
    setUserType(type);
    setUserRole(role);
    setUserId(uid);
    if (isAdmin)setOpenEdit(true);
    else setOpenBusinessEdit(true);
  };

  const handleBusinessEdit = async (
    email: string,
    role: string,
    businessId: string,
    locationAccess: string,
    uid: string,
  ) => {
    setUserEmail(email);
    setUserRole(role);
    setUserId(uid);
    setUserLocationAccess(locationAccess.split(', '));

    try {
      const business = await BusinessService.getBusiness(businessId);
      if (business.Locations) setBusinessLocations(business.Locations);
      setOpenBusinessEdit(true);
    } catch (e:any) {
      handleErrorDialog(
        'Business does not Exist',
        `The business id ${businessId} does not belong to any business , please enter in a valid business ID.`,
      );
    }
  };

  const handleCancelEdit = () => {
    setUserEmail('');
    setUserRole('');
    setUserId('');
    setUserLocationAccess([]);
    setOpenBusinessEdit(false);
    setOpenLocationEdit(false);
  };

  const handleConfirmEdit = async () => {
    try {
      await AdminService.updateBusinessAccount(userId, userLocationAccess, userRole)
        .then(() => {
          setOpenLocationEdit(false);
          fetchAdminUsers();
        });
    } catch (e:any) {
      handleErrorDialog('Edit Error', e.message);
    }
  };

  return (
    <StyledContainer>
      <StyledHeaderRow>
        <StyledSearch
          id="search-entry"
          label="Search Email Address"
          variant="outlined"
          color="primary"
          value={searchText}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AiOutlineSearch />
              </InputAdornment>
            ),
            autoComplete: 'off',
          }}
          onChange={(event) => {
            setSearchText(event.target.value);
            if (page !== 0) setPage(0);
          }}
        />

        <StyledCreateButton
          aria-label="create"
          variant="contained"
          startIcon={<AiFillPlusCircle />}
          onClick={() => {
            if (isAdmin) setOpenCreate(true);
            else setOpenBusinessCreate(true);
          }}
        >
          {isAdmin ? 'Create New Admin User' : 'Create New Business User'}
        </StyledCreateButton>
      </StyledHeaderRow>

      <StyledTableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => (
                <StyledHeaderCell key={index}>{header}</StyledHeaderCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {tableItems.filter((tableRow) => tableRow[0].includes(searchText))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((rowData, rowIndex) => (
                <SearchTableRow
                  key={rowIndex}
                  fetchAdminUsers={fetchAdminUsers}
                  rowIndex={rowIndex}
                  rowItems={rowData}
                  handleEdit={handleEdit}
                  handleBusinessEdit={!isAdmin ? handleBusinessEdit : undefined}
                  isAdmin={isAdmin}
                />
              ))}
          </TableBody>
        </Table>
      </StyledTableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={(tableItems.filter((tableRow) => tableRow[0].includes(searchText))).length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <CreateNewUser
        fetchAdminUsers={fetchAdminUsers}
        openCreateFlow={openCreate}
        setOpenCreateFlow={setOpenCreate}
      />

      <CreateNewBusinessUser
        fetchAdminUsers={fetchAdminUsers}
        openCreateFlow={openBusinessCreate}
        setOpenCreateFlow={setOpenBusinessCreate}
      />

      <EditUserPermissions
        fetchAdminUsers={fetchAdminUsers}
        userEmail={userEmail}
        userType={userType}
        userRole={userRole}
        userId={userId}
        openEditFlow={openEdit}
        setOpenEditFlow={setOpenEdit}
      />

      {openBusinessEdit && (
        <EntryDialog
          dialogTitle="Edit account business Tier"
          dialogText={`Basic : access to basic profile, locations and dashboard.\n
            Premium : including above, access to premium profile and messages.\n
            Premium-Plus-Read : including above, access to view campaigns, cannot create campaigns.\n
            Premium-Plus : including above, access to campaigns, can create only 1 campaign.\n
            Growth : full access to entire business portal.`}
          open={openBusinessEdit}
          value={userRole}
          setValue={setUserRole}
          handleConfirm={() => {
            setOpenBusinessEdit(false);
            setOpenLocationEdit(true);
          }}
          handleCancel={handleCancelEdit}
          selectOptions={['basic', 'premium', 'premium-plus-read', 'premium-plus', 'growth']}
        />
      )}

      {openLocationEdit && (
        <LocationDialog
          isEdit
          selectedLocations={userLocationAccess}
          setSelectedLocations={setUserLocationAccess}
          locationData={businessLocations}
          open={openLocationEdit}
          handleConfirm={handleConfirmEdit}
          handleCancel={handleCancelEdit}
        />
      )}

    </StyledContainer>
  );
}

export default SearchTable;
