import React, { useState } from 'react';

import { styled } from '@mui/system';
import {
  Card,
  CardContent,
  Collapse,
  IconButton,
  IconButtonProps,
  Grid,
  Divider,
  Typography,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';

import { AdditionalInfo } from '../../../Services/BusinessService';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  fontSize: '30px',
  color: theme.palette.common.white,
}));

const CardTitle = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary.main,
  height: '40px',
  [theme.breakpoints.down('sm')]: {
    height: '50px',
  },
}));

const CardTitleText = styled(Typography)(({ theme }) => ({
  alignSelf: 'center',
  color: theme.palette.common.white,
}));

const ExpandedContent = styled(CardContent)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  paddingLeft: '50px',
  paddingRight: '50px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
}));

const StyledImage = styled('img', {})({
  width: '50%',
  marginBottom: '15px',
});

type Props = {
  info: AdditionalInfo
};

function SignUpInfoCard(props: Props) {
  const {
    info,
  } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const HandleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card key="signUp">
      <CardTitle>
        <CardTitleText variant="h4">Sign Up Information</CardTitleText>
        <ExpandMore
          expand={isExpanded}
          onClick={HandleExpandClick}
          aria-expanded={isExpanded}
          aria-label="show more"
        >
          <MdExpandMore />
        </ExpandMore>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          {info['Stamp Cards'].filter((notNull) => notNull !== null).map((i, index) => (
            <Grid container spacing={2} key={index}>
              <Grid item xs={12}>
                <Divider light><Typography variant="body2">{`Card Option ${index + 1}`}</Typography></Divider>
              </Grid>

              {i.Reward ? (
                <>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2">Reward Text :</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">{i.Reward}</Typography>
                  </Grid>

                </>
              ) : null}

              {i['Stamps Max'] ? (
                <>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2">Max Stamps :</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">{i['Stamps Max']}</Typography>
                  </Grid>
                </>
              ) : null}

              {i['Add Stamp'] ? (
                <>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2">Add Stamp on Redemption :</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">{i['Add Stamp'] === 'true' ? 'Yes' : 'No'}</Typography>
                  </Grid>
                </>
              ) : null}

              {i.Other ? (
                <>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2">Other :</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">{i.Other ? 'Yes' : 'No'}</Typography>
                  </Grid>
                </>
              ) : null}

              {i.Option ? (
                <>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2">Option :</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">{i.Option}</Typography>
                  </Grid>
                </>
              ) : null}

              {i['Color 1'] ? (
                <>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2">Color 1 :</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">{i['Color 1'] ? i['Color 1'] : 'Not specified'}</Typography>
                  </Grid>
                </>
              ) : null}

              {i['Color 2'] ? (
                <>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2">Color 2 :</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">{i['Color 2'] ? i['Color 2'] : 'Not specified'}</Typography>
                  </Grid>
                </>
              ) : null}

              {i['Extra Files'] ? (
                <>
                  <Grid item xs={12}>
                    <Divider light>
                      <Typography variant="body2">{`Option ${index + 1}'s Uploaded Images`}</Typography>
                    </Divider>
                  </Grid>

                  {i['Extra Files'].filter((images) => images !== null).map((image, imageIndex) => (
                    <Grid item container xs={12} alignItems="center" justifyContent="center" key={imageIndex}>
                      <StyledImage src={image} alt="logo" />
                    </Grid>
                  ))}
                </>
              ) : null}
            </Grid>
          ))}
        </ExpandedContent>
      </Collapse>
    </Card>
  );
}

export default SignUpInfoCard;
