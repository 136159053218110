const API_URL = process.env.REACT_APP_API_URL;
// const API_URL = 'http://localhost:3000';

export type MarketPlaceListing = {
  Gifting: boolean,
  Business: string,
  Currency: string,
  Description: string,
  TestBusiness: boolean,
};

export type PurchaseOption = {
  Scheme: string,
  Discount: number,
  Amount: number,
  Title: string,
  'Unit Price': number
  productId?: string,
  Reward?: string,
};

export type PurchaseData = {
  'Purchase Options': PurchaseOption[],
  'Stripe Account': string,
  Terms: string[],
};

export type MarketPlaceData = {
  'MarketPlace Listing Data': MarketPlaceListing,
  'Purchase Data': PurchaseData,
};

export type BusinessPurchaseOption = {
  Scheme: string,
  Discount: number,
  Amount: number,
  Title: string,
  'Unit Price': number
  Reward: string,
  productId: string,
};

export type BusinessMarketPlace = {
  Currency: string,
  Description: string,
  'Has Gift Vouchers': boolean,
  'Stripe Account': string,
  Terms: string[],
  'Purchase Options': BusinessPurchaseOption[],
};

const getMarketPlace = (partnerId: string) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/marketplace/admin/${partnerId}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const updateMarketPlace = (partnerId: string, marketPlace: MarketPlaceData) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/marketplace/admin/${partnerId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(marketPlace),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const createMarketPlace = (partnerId: string, marketPlace: MarketPlaceData) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/marketplace/admin/${partnerId}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(marketPlace),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const deleteMarketPlace = (partnerId: string) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/marketplace/admin/${partnerId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const MarketPlaceService = {
  deleteMarketPlace,
  createMarketPlace,
  updateMarketPlace,
  getMarketPlace,
};

export default MarketPlaceService;
