import React from 'react';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  InputAdornment,
  Button,
  Dialog,
  MenuItem,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/system';

import { TbSortAscendingLetters } from 'react-icons/tb';
import { GiCancel, GiConfirmed } from 'react-icons/gi';

const StyledTitle = styled(DialogTitle, {})({
  fontWeight: 'bold',
  alignSelf: 'center',
});

const StyledConfirm = styled(LoadingButton)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 'bold',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const StyledCancel = styled(Button)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 'bold',
  color: theme.palette.primary.white,
  backgroundColor: theme.palette.warning.main,
  '&:hover': {
    backgroundColor: theme.palette.warning.main,
  },
}));

type Props = {
  error?: boolean,
  errorMessage?: string,
  loading?: boolean,
  handleCancel: () => void,
  handleConfirm: () => void,
  open: boolean,
  setError?: (bool: boolean) => void,
  setLetter: (value: string) => void,
  letter: string,
  setGenCode: (toggle: boolean) => void,
  genCode: boolean,
};

function TagUpdateOptionals(props: Props) {
  const {
    error,
    errorMessage,
    loading,
    handleCancel,
    handleConfirm,
    open,
    setError,
    setLetter,
    letter,
    setGenCode,
    genCode,
  } = props;

  const alphabet = ' ABCDEFGHIJKLMNOPQRSTUVWYXZ';
  const selectOptions = alphabet.split('');

  const handleClosed = (event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick' && 'escapeKeyDown') return;
    handleCancel();
  };

  return (
    <Dialog
      aria-label="confirm-dialog"
      onClose={(event, reason) => handleClosed(event, reason)}
      open={open}
      fullWidth
      maxWidth="sm"
      sx={{ zIndex: '3000' }}
    >
      <StyledTitle>Optional Settings</StyledTitle>
      <DialogContent>

        <DialogContentText sx={{ marginBottom: '30px', whiteSpace: 'pre-line' }}>
          Please select the the letter you wish to assign to the tag. If a letter is not selected it will default to A.
        </DialogContentText>

        <TextField
          fullWidth
          select
          InputLabelProps={{ shrink: true }}
          id="letter-entry"
          label="Tag Letter"
          variant="outlined"
          color="primary"
          value={letter || 'none'}
          helperText={error ? errorMessage : ''}
          error={error}
          onChange={(event) => {
            if (error && setError) setError(false);
            setLetter(event.target.value);
          }}
          InputProps={{
            autoComplete: 'off',
            startAdornment: (
              <InputAdornment position="start">
                <TbSortAscendingLetters />
              </InputAdornment>
            ),
          }}
        >
          <MenuItem value="none" disabled>Select one of the below</MenuItem>
          {selectOptions.map((option) => (
            <MenuItem key={option} value={option}>{option}</MenuItem>
          ))}
        </TextField>

        <DialogContentText sx={{ marginBottom: '10px', marginTop: '20px', whiteSpace: 'pre-line' }}>
          Do you want to generate an activation code for this business ? This can only be done once per business.
        </DialogContentText>
        <FormControlLabel
          key="pop-up-toggle"
          control={
            <Checkbox checked={genCode} onChange={() => setGenCode(!genCode)} />
          }
          label="Generate Activation Code"
        />
      </DialogContent>

      <DialogActions>
        <StyledCancel
          variant="contained"
          fullWidth
          startIcon={<GiCancel />}
          onClick={handleCancel}
        >
          <Typography variant="body2">CANCEL</Typography>
        </StyledCancel>
        <StyledConfirm
          loading={loading}
          variant="contained"
          fullWidth
          startIcon={<GiConfirmed />}
          onClick={handleConfirm}
          disabled={error}
        >
          <Typography variant="body2">NEXT</Typography>
        </StyledConfirm>
      </DialogActions>
    </Dialog>
  );
}

export default TagUpdateOptionals;
