/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';

import { FaUserLock } from 'react-icons/fa';
import EntryDialog from '../../../Components/BasicComponents/EntryDialog';
import ConfirmDialog from '../../../Components/BasicComponents/ConfirmDialog';
import ErrorDialog from '../../../Components/BasicComponents/ErrorDialog';
import LocationDialog from './LocationDialog';

import AdminService from '../../../Services/AdminService';
import BusinessService from '../../../Services/BusinessService';
import { Locations } from '../../../Services/LocationService';

type Props = {
  fetchAdminUsers: () => void;
  openCreateFlow: boolean,
  setOpenCreateFlow: (toggle: boolean) => void;
};

function CreateNewBusinessUser(props: Props) {
  const {
    fetchAdminUsers,
    openCreateFlow,
    setOpenCreateFlow,
  } = props;

  const [userId, setUserId] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [openPassword, setOpenPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [openBusiness, setOpenBusiness] = useState<boolean>(false);
  const [partnerId, setPartnerId] = useState<string>('');
  const [tier, setTier] = useState<string>('basic');
  const [openTier, setOpenTier] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);
  const [errorDialogTitle, setErrorDialogTitle] = useState<string>('');
  const [errorDialogMessage, setErrorDialogMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmText, setConfirmText] = useState<string>('');
  const [locationData, setLocationData] = useState<Locations>({});
  const [openLocationSelect, setOpenLocationSelect] = useState<boolean>(false);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);

  const handleCancel = () => {
    setUserId('');
    setUserEmail('');
    setPassword('');
    setPartnerId('');
    setTier('basic');
    setOpenBusiness(false);
    setOpenCreateFlow(false);
    setOpenConfirm(false);
    setOpenPassword(false);
    setOpenTier(false);
    setLoading(false);
    setConfirmText('');
    setLocationData({});
    setSelectedLocations([]);
    setOpenLocationSelect(false);
  };

  const handleErrorDialog = (title:string, message:string) => {
    setErrorDialogTitle(title);
    setErrorDialogMessage(message);
    setOpenErrorDialog(true);
  };

  const handleErrorDialogClose = () => {
    setOpenErrorDialog(false);
    setErrorDialogTitle('');
    setErrorDialogMessage('');
  };

  const checkBusiness = async () => {
    setLoading(true);
    try {
      const business = await BusinessService.getBusiness(partnerId);
      if (business.Locations) setLocationData(business.Locations);
      setOpenBusiness(false);
      setOpenLocationSelect(true);
    } catch (e:any) {
      handleErrorDialog(
        'Business does not Exist',
        `The business id ${partnerId} does not belong to any business , please enter in a valid business ID.`,
      );
    }
    setLoading(false);
  };

  const getConfirmText = () => {
    const text = `User Email: ${userEmail}
    Business ID: ${partnerId}
    Location(s): ${selectedLocations}
    Business Tier: ${tier}\n`;
    setConfirmText(text);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await AdminService.createBusinessUser(userEmail, password, tier, partnerId, selectedLocations);
      handleCancel();
      fetchAdminUsers();
    } catch (e:any) {
      handleErrorDialog('Error Creating User', e.message);
    }
    setLoading(false);
  };

  return (
    <>
      <EntryDialog
        disableEntryFormat
        dialogTitle="User Email"
        dialogText="Please enter in the users email address"
        handleCancel={handleCancel}
        handleConfirm={() => {
          setOpenCreateFlow(false);
          setOpenPassword(true);
        }}
        open={openCreateFlow}
        value={userEmail}
        setValue={setUserEmail}
        valueIcon={<FaUserLock />}
        loading={loading}
      />

      <EntryDialog
        disableEntryFormat
        dialogTitle="New Account Password"
        dialogText="Please enter in a password for the new account"
        handleCancel={handleCancel}
        handleConfirm={() => {
          setOpenPassword(false);
          setOpenBusiness(true);
        }}
        open={openPassword}
        value={password}
        setValue={setPassword}
        valueIcon={<FaUserLock />}
        loading={loading}
      />

      <EntryDialog
        dialogTitle="Business ID"
        dialogText="Please enter the business ID"
        handleCancel={handleCancel}
        handleConfirm={checkBusiness}
        open={openBusiness}
        value={partnerId}
        setValue={setPartnerId}
        valueIcon={<FaUserLock />}
        loading={loading}
      />

      <LocationDialog
        isEdit={false}
        open={openLocationSelect}
        selectedLocations={selectedLocations}
        setSelectedLocations={setSelectedLocations}
        locationData={locationData}
        handleCancel={handleCancel}
        handleConfirm={() => {
          setOpenLocationSelect(false);
          setOpenTier(true);
        }}
      />

      <EntryDialog
        dialogTitle="Business Tier"
        dialogText={`Basic : access to basic profile, locations and dashboard.\n
        Premium : including above, access to premium profile and messages.\n
        Premium-Plus-Read : including above, access to view campaigns, cannot create campaigns.\n
        Premium-Plus : including above, access to campaigns, can create only 1 campaign.\n
        Growth : full access to entire business portal.`}
        handleCancel={handleCancel}
        handleConfirm={() => {
          getConfirmText();
          setOpenTier(false);
          setOpenConfirm(true);
        }}
        open={openTier}
        value={tier}
        setValue={setTier}
        selectOptions={['basic', 'premium', 'premium-plus-read', 'premium-plus', 'growth']}
        valueIcon={<FaUserLock />}
        loading={loading}
      />

      <ConfirmDialog
        open={openConfirm}
        dialogTitle="Are you sure you want to save this user?"
        dialogText={confirmText}
        handleCancel={handleCancel}
        handleConfirm={handleSave}
        isLoading={loading}
      />

      <ErrorDialog
        open={openErrorDialog}
        dialogTitle={errorDialogTitle}
        dialogText={errorDialogMessage}
        handleClose={handleErrorDialogClose}
      />
    </>
  );
}

export default CreateNewBusinessUser;
