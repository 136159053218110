const AUTH_API_URL = process.env.REACT_APP_AUTH_URL;
const API_URL = process.env.REACT_APP_API_URL;
// const AUTH_API_URL = 'http://localhost:3020';

export type AdminUserData = {
  users: [
    {
      user_email: string,
      user_id: string,
      user_type: string,
      business_id?: string,
      app_role_id?: string | null,
      business_role_id?: string | null,
      internal_role_id?: string | null,
      locationAccess?: string[],
    },
  ]
};

export type UserType = {
  user_types: string,
  description: string,
};

export type UserTypes = UserType[];

export type UserRole = {
  role_id: string,
  description: string,
};

export type UserRoles = UserRole[];

const getUserTypes = async (): Promise<UserTypes> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${AUTH_API_URL}/v1/userTypes`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const getAdminUserById = async (userId: string) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${AUTH_API_URL}/v1/users/${userId}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const getUserRoleById = async (userId: string) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${AUTH_API_URL}/v1/userRole/${userId}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const getAllAdminUsers = async (): Promise<AdminUserData> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${AUTH_API_URL}/v1/users/`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const getRolesByUserType = async (type: string): Promise<UserRoles> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${AUTH_API_URL}/v1/userTypes/${type}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const createUser = async (
  userId: string,
  email: string,
  userType: string,
  userRole: string,
  businessId?: string,
) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${AUTH_API_URL}/v1/users/`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: businessId
      ? (
        JSON.stringify({
          user_id: userId,
          user_email: email,
          user_type: userType,
          role_id: userRole,
          business_id: businessId,
        })
      ) : (
        JSON.stringify({
          user_id: userId,
          user_email: email,
          user_type: userType,
          role_id: userRole,
        })
      ),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const createBusinessUser = async (
  user_email: string,
  password: string,
  role_id: string,
  business_id: string,
  locationAccess: string[],
) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/auth/business/register`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_email,
      password,
      role_id,
      business_id,
      locationAccess,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const updateBusinessAccount = async (
  uid: string,
  locationAccess: string[],
  roleId: string,
) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${AUTH_API_URL}/v1/users/${uid}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      locationAccess,
      roleId,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data !== 'User location access updated successfully') reject(data);
      resolve(data);
    })
    .catch((error) => {
      reject(error);
    });
});

const updateUserTypeById = async (
  userId: string,
  typeId:string,
  roleId: string,
  businessId?: string,
) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${AUTH_API_URL}/v1/userTypes/${userId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: businessId
      ? (
        JSON.stringify({
          user_type: typeId,
          role_id: roleId,
          business_id: businessId,
        })
      ) : (
        JSON.stringify({
          user_type: typeId,
          role_id: roleId,
        })
      ),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const updateBusinessUserTypeById = async (
  user_id: string,
  role_id: string,
  business_id: string,
) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${AUTH_API_URL}/v1/userTypes/${user_id}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_type: 'business_user',
      role_id,
      business_id,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const updateUserRoleById = async (
  userId: string,
  roleId: string,
) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${AUTH_API_URL}/v1/userRole/${userId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      role_id: roleId,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const deleteAdminUserById = async (userId: string, type: 'business' | 'admin') => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${AUTH_API_URL}/v1/users/${userId}?type=${type}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const AdminService = {
  createUser,
  createBusinessUser,
  deleteAdminUserById,
  getAllAdminUsers,
  getAdminUserById,
  getRolesByUserType,
  getUserTypes,
  getUserRoleById,
  updateUserTypeById,
  updateUserRoleById,
  updateBusinessUserTypeById,
  updateBusinessAccount,
};

export default AdminService;
