import React, { useState } from 'react';

import { styled } from '@mui/system';
import {
  Card,
  CardContent,
  Collapse,
  IconButton,
  IconButtonProps,
  Grid,
  Typography,
  Tooltip,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import { FaEdit } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { BsPlusSquareFill } from 'react-icons/bs';

import SchemeDetailsCard from './BusinessSchemeDetailsCard';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  fontSize: '30px',
  color: theme.palette.common.white,
}));

const CardTitle = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary.main,
  height: '40px',
  [theme.breakpoints.down('sm')]: {
    height: '50px',
  },
}));

const CardTitleText = styled(Typography)(({ theme }) => ({
  alignSelf: 'center',
  color: theme.palette.common.white,
}));

const ExpandedContent = styled(CardContent)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  paddingLeft: '50px',
  paddingRight: '50px',
  [theme.breakpoints.down('sm')]: {
    paddingRight: '15px',
    paddingLeft: '15px',
  },
}));

const StyledEditButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

const StyledCreateButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  marginRight: '20px',
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

type Props = {
  partnerId: string,
  schemes: any | undefined,
  openSchemeCreate: (toggle: boolean) => void,
};

function BusinessSchemeCard(props: Props) {
  const { schemes, partnerId, openSchemeCreate } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const navigate = useNavigate();

  const HandleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card key="schemes">
      <CardTitle>
        <CardTitleText variant="h4">Business Schemes</CardTitleText>
        <div>
          <Tooltip title="Create New Scheme" placement="bottom">
            <StyledCreateButton size="small" onClick={() => openSchemeCreate(true)}>
              <BsPlusSquareFill />
            </StyledCreateButton>
          </Tooltip>

          <Tooltip title="Edit Schemes" placement="bottom">
            <StyledEditButton size="small" onClick={() => navigate(`/schemes?partnerId=${partnerId}`)}>
              <FaEdit />
            </StyledEditButton>
          </Tooltip>

          <ExpandMore
            expand={isExpanded}
            onClick={HandleExpandClick}
            aria-expanded={isExpanded}
            aria-label="show more"
          >
            <MdExpandMore />
          </ExpandMore>
        </div>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          {schemes ? (
            <Grid container spacing={2}>
              {Object.entries(schemes).map((scheme: any) => (
                <Grid item xs={12} key={scheme[0]}>
                  <SchemeDetailsCard
                    schemeId={scheme[0]}
                    image={scheme[1].Image}
                    max={scheme[1].Max}
                    name={scheme[1].Name}
                    reward={scheme[1].Reward}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid item container justifyContent="center">
              <Typography variant="body2">No Schemes set up for this business</Typography>
            </Grid>
          )}
        </ExpandedContent>
      </Collapse>
    </Card>
  );
}

export default BusinessSchemeCard;
