/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';

import {
  DialogActions,
  DialogContent,
  TextField,
  Button,
  Dialog,
  Grid,
  Typography,
  MenuItem,
} from '@mui/material';
import { styled } from '@mui/system';
import { GiCancel, GiConfirmed } from 'react-icons/gi';
import ConfirmDialog from '../../../Components/BasicComponents/ConfirmDialog';

import SchemeService, { ReturnedSchemeData } from '../../../Services/SchemeService';
import StampsService from '../../../Services/StampsService';
import UserService from '../../../Services/UserService';

const StyledTitle = styled(Typography, {})({
  alignSelf: 'center',
  paddingTop: '25px',
  paddingBottom: '10px',
});

const StyledConfirm = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const StyledCancel = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.white,
  backgroundColor: theme.palette.warning.main,
  '&:hover': {
    backgroundColor: theme.palette.warning.main,
  },
}));

type Props = {
  uid: string,
  partnerId: string,
  businessData: any,
  openDialog: boolean,
  onClose: () => void,
  schemeOptions: string[],
  updateUser: (id: string, user: {}) => void,
};

function AddStampDialog(props: Props) {
  const {
    uid,
    partnerId,
    businessData,
    openDialog,
    onClose,
    schemeOptions,
    updateUser,
  } = props;

  const [numberOfStamps, setNumberOfStamps] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [locationOptions, setLocationOptions] = useState<string[]>([]);
  const [schemeId, setSchemeId] = useState<string>('');
  const [maxStamps, setMaxStamps] = useState<string>('');
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  const getScheme = async (id: string) => {
    try {
      const scheme:ReturnedSchemeData = await SchemeService.getSpecificScheme(partnerId, id);
      setMaxStamps(scheme.max.toString());
      setLocationOptions(scheme.locations);
    } catch (e:any) {
      console.log(e.message);
    }
  };

  const handleClose = () => {
    setNumberOfStamps('');
    setLocation('');
    setLocationOptions([]);
    setMaxStamps('');
    setSchemeId('');
    onClose();
  };

  const addStamps = async () => {
    try {
      await StampsService.addStamps(uid, partnerId, schemeId, location, parseInt(numberOfStamps, 10));
      const updated:any = await UserService.getUser(uid);
      updateUser(uid, updated);
      handleClose();
    } catch (e:any) {
      console.log(e.message);
    }
  };

  const handleClosed = (event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick' && 'escapeKeyDown') return;
    handleClose();
  };

  return (
    <Dialog
      aria-label="notification-dialog"
      onClose={(event, reason) => handleClosed(event, reason)}
      open={openDialog}
      fullWidth
      maxWidth="md"
      sx={{ zIndex: '1100' }}
    >
      <StyledTitle variant="h1">
        {`Add Stamps and Vouchers for ${businessData['Basic Fields']['Chain Name']}`}
      </StyledTitle>
      <DialogContent>
        <Grid container spacing={3} sx={{ paddingBottom: '20px' }}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              select
              InputLabelProps={{ shrink: true }}
              id="scheme-select"
              label="Scheme ID"
              variant="outlined"
              color="primary"
              value={schemeId || 'none'}
              onChange={(event) => {
                setNumberOfStamps('');
                setLocation('');
                setSchemeId(event.target.value);
                getScheme(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
            >
              <MenuItem key={0} value="none" disabled>Select one of the below</MenuItem>
              {schemeOptions.map((key) => (
                <MenuItem key={key} value={key}>{key}</MenuItem>
              ))}
            </TextField>
          </Grid>

          {locationOptions.length > 0 ? (
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                InputLabelProps={{ shrink: true }}
                id="location-select"
                label="Location"
                variant="outlined"
                color="primary"
                value={location || 'none'}
                onChange={(event) => {
                  setLocation(event.target.value);
                }}
                InputProps={{
                  autoComplete: 'off',
                }}
              >
                <MenuItem key={0} value="none" disabled>Select one of the below</MenuItem>
                {locationOptions.map((key: string) => (
                  <MenuItem key={key} value={key}>{`${key} : ${businessData.Locations[key].Address}`}</MenuItem>
                ))}
              </TextField>
            </Grid>
          ) : null}

          {maxStamps ? (
            <>
              <Grid item xs={12}>
                <Typography variant="body2">{`Schemes Max Stamps : ${maxStamps}`}</Typography>
                <Typography variant="body1">
                  If you add more than the current max stamps , a voucher will be added to the users wallet
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  id="scheme-select"
                  label="Number of Stamps"
                  variant="outlined"
                  color="primary"
                  value={numberOfStamps}
                  onChange={(event) => {
                    setNumberOfStamps(event.target.value.replace(/\D+/g, ''));
                  }}
                  InputProps={{
                    autoComplete: 'off',
                  }}
                />
              </Grid>
            </>
          ) : null}

        </Grid>
        <DialogActions>
          <StyledCancel
            variant="contained"
            fullWidth
            startIcon={<GiCancel />}
            onClick={() => handleClose()}
          >
            <Typography variant="body2">CANCEL</Typography>
          </StyledCancel>
          <StyledConfirm
            variant="contained"
            fullWidth
            startIcon={<GiConfirmed />}
            disabled={numberOfStamps === '' || location === ''}
            onClick={() => setOpenConfirm(true)}
          >
            <Typography variant="body2">CONFIRM</Typography>
          </StyledConfirm>
        </DialogActions>

        <ConfirmDialog
          dialogTitle={`Confirm Stamps for ${businessData['Basic Fields']['Chain Name']}`}
          dialogText={`Number of Stamps : ${numberOfStamps}
          Scheme : ${schemeId}
          Location : ${location}`}
          handleConfirm={addStamps}
          handleCancel={() => setOpenConfirm(false)}
          open={openConfirm}
        />
      </DialogContent>
    </Dialog>
  );
}

export default AddStampDialog;
