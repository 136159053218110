const API_URL = process.env.REACT_APP_CAMPAIGN_API_URL;
// const API_URL = 'http://localhost:3000';

export type TagPromo = {
  amount?: number,
  criteria?: string,
  description?: string,
  endTime?: number,
  location?: string,
  first?: boolean,
  startTime?: number,
  type: string,
  voucherEarnedModal?: {
    companyLogo?: string,
    rewardDetails?: string,
    title?: string,
    voucherInformation?: string,
  },
  vouchersRemaining?: number | null,
  rewardsRemaining?: number | null,
  createTime?: number | null,
  status?: string,
  uses?: {
    [key:string]: string
  },
  schemeId?: string,
  partnerId?: string,
  availabilityDays?: string[],
  promoType?: string,
  promotionId?: string,
};

export type ReturnedTagPromo = {
  amount: number,
  criteria: string,
  description: string | null,
  endTime: number,
  locationIds: string | null,
  startTime: number,
  promoType: string,
  voucherEarnedModal?: {
    companyLogo?: string,
    rewardDetails?: string,
    title?: string,
    voucherInformation?: string,
  } | null,
  vouchersRemaining: number | null,
  uses: {
    [key:string]: string
  } | {} | null,
  schemeId: string,
  partnerId: string,
  availabilityDays: string[] | null,
  promotionId: string,
  rewardsRemaining?: number | null,
  createTime?: number | null,
  status: string,
  analytics: {
    claimedToDate: number,
    claimedVouchers: number,
    claimsOnExpiry: number,
    claimsOnStart: number,
    firstClaimTimestamp: string | null,
    lastClaimTimestamp: string | null,
    firstRedeemTimestamp: string | null,
    lastRedeemTimestamp: string | null,
    newUsers7days?: number,
    newUsers14days?: number,
    newUsers21days?: number,
    newUsers30days?: number,
    newUsersEntirePromoPeriod: number,
    redeemedToDate: number,
    redeemedVouchers: number,
    redemptionOnExpiry: number,
  }
};

export type TagPromoById = {
  tagPromotion: ReturnedTagPromo;
};

export type TagPromos = {
  activePromos: ReturnedTagPromo[],
  expiredPromos: ReturnedTagPromo[],
  notStartedPromos: ReturnedTagPromo[],
  rewardsExceededPromos: ReturnedTagPromo[],
};

export type CodePromo = {
  Amount: number,
  'Chain ID': string,
  Expiry?: string,
  'Expiry Timestamp': number,
  'Pop Up'?: {
    Icon?: string,
    'Main Text'?: string,
    Title?: string,
  },
  Provider?: string,
  Remaining: number,
  Scheme: string,
  Store: string,
  Type: string,
  Version?: number,
  createdAt?: number,
  Uses?: {
    [key:string]: number
  }
};

export type CodePromos = {
  [key:string]: CodePromo,
};

const getAllTagPromoIds = (): Promise<string[]> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/promos/tagPromos`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      if (json.length === 0) reject(json);
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const getTagPromoById = (promoId: string): Promise<TagPromoById> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/promos/tagPromos/${promoId}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const getAllTagPromoData = (): Promise<TagPromos> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/promos/tagPromos/data`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((json) => {
      if (json.statusCode === 404) reject(json);
      const keys = Object.keys(json);
      if (keys.length > 1) {
        resolve(json);
      } else (reject(json));
    })
    .catch((error) => {
      reject(error);
    });
});

const updateTagPromo = (
  promoId: string,
  promoData: any,
): Promise<ReturnedTagPromo> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/promos/tagPromos/${promoId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ promotionData: promoData }),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const createTagPromo = (
  promoData: any,
): Promise<ReturnedTagPromo> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/promos/tagPromos/`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ promotionData: promoData }),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const deleteTagPromo = (promoId: string): Promise<string> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/promos/tagPromos/${promoId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const getAllCodePromoIds = (): Promise<string[]> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/promos/codes`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      if (json.length === 0) reject(json);
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const getCodePromoById = (promoId: string): Promise<CodePromos> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/promos/codes/${promoId}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((json) => {
      if (json.statusCode === 404) reject(json);
      if (json === null) reject(new Error(`No code promotion found with id ${promoId}`));
      const promo = {
        [promoId]: {
          ...json,
        },
      };
      resolve(promo);
    })
    .catch((error) => {
      reject(error);
    });
});

const getAllCodePromoData = (): Promise<CodePromos> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/promos/codes/data`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      if (json.length === 0) reject(json);
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const updateCodePromo = (promoId: string, promoData: any): Promise<CodePromos> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/promos/codes/${promoId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ promotionData: promoData }),
  })
    .then((response) => response.json())
    .then((json) => {
      if (json.statusCode === (404 || 400 || 500)) reject(json);
      const promo = {
        [promoId]: {
          ...json,
        },
      };
      resolve(promo);
    })
    .catch((error) => {
      reject(error);
    });
});

const createCodePromo = (promoId: string, promoData: any): Promise<CodePromos> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/promos/codes/${promoId}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ promotionData: promoData }),
  })
    .then((response) => response.json())
    .then((json) => {
      if (json.statusCode === (404 || 400 || 500)) reject(json);
      const promo = {
        [promoId]: {
          ...json,
        },
      };
      resolve(promo);
    })
    .catch((error) => {
      reject(error);
    });
});

const createBatchCodePromo = (
  prefix: string,
  amount: number,
  promoData: any,
): Promise<CodePromos> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/promos/codes/batch`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      promotionPrefix: prefix,
      promotionAmount: amount,
      promotionData: promoData,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const deleteCodePromo = (promoId: string): Promise<string> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/promos/codes/${promoId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const PromoService = {
  createTagPromo,
  deleteTagPromo,
  updateTagPromo,
  createCodePromo,
  deleteCodePromo,
  updateCodePromo,
  getAllCodePromoIds,
  getCodePromoById,
  getAllCodePromoData,
  getAllTagPromoIds,
  getTagPromoById,
  getAllTagPromoData,
  createBatchCodePromo,
};

export default PromoService;
