function ValidateEpoch(timeStamp: number): number {
  let stringStamp = timeStamp.toString();

  if (stringStamp.length > 13) {
    stringStamp = stringStamp.substring(0, 13);
  } else if (stringStamp.length < 13) {
    stringStamp = stringStamp.padEnd(13, '0');
  }

  return (parseInt(stringStamp, 10));
}

export default ValidateEpoch;
