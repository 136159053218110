import React from 'react';

import { styled } from '@mui/system';
import {
  Grid,
  Typography,
  Button,
} from '@mui/material';
import { HiDocumentDownload } from 'react-icons/hi';
import Content from '../../../Components/BasicComponents/Content';
import BackButton from '../../../Components/BasicComponents/BackButton';
import { TagPromoById } from '../../../Services/PromoService';
import TagPromoDetails from './TagPromoDetails';
import ValidateEpoch from '../../../util/validateEpoch';

const fileDownload = require('js-file-download');

const ButtonRow = styled('div', {})({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const StyledButton = styled(Button)(({ theme }) => ({
  height: '45px',
  width: '280px',
  backgroundColor: theme.palette.secondary.main,
  position: 'inherit',
  [theme.breakpoints.down('sm')]: {
    height: '35px',
    width: '90px',
  },
}));

const StyledGrid = styled(Grid, {})({
  marginTop: '40px',
});

type Props = {
  goBack: () => void,
  tagPromoData: TagPromoById,
  setSingleTagPromo: (tagPromo: TagPromoById) => void,
  setTagParam: (id: string) => void,
  openError: boolean,
  setOpenError: (toggle: boolean) => void,
  errorMessage: string,
  setErrorMessage: (message: string) => void,
  tagParam: string | null,
  loadAllTags: () => void,
};

function SingleTagPromo(props: Props) {
  const {
    goBack,
    tagPromoData,
    setSingleTagPromo,
    setTagParam,
    tagParam,
    openError,
    setOpenError,
    errorMessage,
    setErrorMessage,
    loadAllTags,
  } = props;

  const downloadFile = () => {
    const promo = tagPromoData.tagPromotion;

    const headings: string[] = [
      'Status',
      'Date Created',
      'Tag Promo ID',
      'Business ID',
      'Scheme ID',
      'Type',
      'Stamps Added',
      'Uses Remaining',
      'Criteria',
      'Available Days',
      'Description',
      'Location Specific',
      'Start Date',
      'Expiry Date',
      'Pop-up Title',
      'Pop-up Info',
      'Pop-up Details',
      'Number of Claimed Vouchers',
      'Date of first Claim',
      'Date of last Claim',
      'Claims on Start Day of Promo',
      'Claims on End Day of Promo',
      'Number of Redeemed Vouchers',
      'Date of first Redemption',
      'Date of last Redemption',
      'Redemptions on End Day of Promo',
      'New Users Entire Promo',
      'New Users Day 7',
      'New Users Day 14',
      'New Users Day 21',
      'New Users Day 30',
    ];

    const rows: string[][] = [headings];

    let createdTime = 'Not available';
    const { status } = promo;
    const tagPromoId = promo.promotionId;
    const businessId = promo.partnerId;
    const scheme = promo.schemeId;
    const type = promo.promoType;
    let amountStamps = '';
    let rewardsRemaining = '';
    const { criteria } = promo;
    let days = 'All';
    const description = promo.description ? promo.description : '';
    const location = promo.locationIds ? promo.locationIds : '';
    const startDate = new Date(ValidateEpoch(promo.startTime)).toLocaleString();
    const endDate = new Date(ValidateEpoch(promo.endTime)).toLocaleString();
    let title = '';
    let info = '';
    let details = '';
    let claimedVouchers = '';
    let dateFirstClaim = '';
    let dateLastClaim = '';
    let claimsOnStart = '';
    let claimsOnEnd = '';
    let redeemedVouchers = '';
    let dateFirstRedeem = '';
    let dateLastRedeem = '';
    let redeemOnEnd = '';
    let newUsersTotal = '0';
    let newUsers7 = '0';
    let newUsers14 = '0';
    let newUsers21 = '0';
    let newUsers30 = '0';

    if (promo.createTime) {
      createdTime = new Date(ValidateEpoch(promo.createTime)).toLocaleString();
    }
    if (promo.promoType === 'stamp' && promo.amount) {
      amountStamps = promo.amount.toString();
    }
    if (promo.vouchersRemaining) {
      rewardsRemaining = promo.vouchersRemaining.toString();
    }
    if (promo.rewardsRemaining) {
      rewardsRemaining = promo.rewardsRemaining.toString();
    }
    if (promo.availabilityDays && promo.availabilityDays.length > 0) {
      days = promo.availabilityDays.join(', ');
    }
    if (promo.voucherEarnedModal) {
      title = promo.voucherEarnedModal.title ? title = promo.voucherEarnedModal.title : '';
      info = promo.voucherEarnedModal.voucherInformation ? promo.voucherEarnedModal.voucherInformation : '';
      details = promo.voucherEarnedModal.rewardDetails ? promo.voucherEarnedModal.rewardDetails : '';
    }
    if (promo.analytics.claimedVouchers > 0) {
      claimedVouchers = promo.analytics.claimedVouchers.toString();
      if (promo.analytics.firstClaimTimestamp) {
        dateFirstClaim = promo.analytics.firstClaimTimestamp;
      }
      if (promo.analytics.lastClaimTimestamp) {
        dateLastClaim = promo.analytics.lastClaimTimestamp;
      }
    }
    if (promo.analytics.claimsOnStart) {
      claimsOnStart = promo.analytics.claimsOnStart.toString();
    }
    if (promo.analytics.claimsOnExpiry) {
      claimsOnEnd = promo.analytics.claimsOnExpiry.toString();
    }
    if (promo.analytics.redeemedVouchers && promo.analytics.redeemedVouchers > 0) {
      redeemedVouchers = promo.analytics.redeemedVouchers.toString();
      if (promo.analytics.firstRedeemTimestamp) {
        dateFirstRedeem = promo.analytics.firstRedeemTimestamp;
      }
      if (promo.analytics.lastRedeemTimestamp) {
        dateLastRedeem = promo.analytics.lastRedeemTimestamp;
      }
    }
    if (promo.analytics.redemptionOnExpiry) {
      redeemOnEnd = promo.analytics.redemptionOnExpiry.toString();
    }
    if (promo.analytics.newUsersEntirePromoPeriod) {
      newUsersTotal = promo.analytics.newUsersEntirePromoPeriod.toString();
    }
    if (promo.analytics.newUsers7days) {
      newUsers7 = promo.analytics.newUsers7days.toString();
    }
    if (promo.analytics.newUsers14days) {
      newUsers14 = promo.analytics.newUsers14days.toString();
    }
    if (promo.analytics.newUsers21days) {
      newUsers21 = promo.analytics.newUsers21days.toString();
    }
    if (promo.analytics.newUsers30days) {
      newUsers30 = promo.analytics.newUsers30days.toString();
    }

    const subRow: string[] = [
      status,
      createdTime,
      tagPromoId,
      businessId,
      scheme,
      type,
      amountStamps,
      rewardsRemaining,
      criteria,
      days,
      description,
      location,
      startDate,
      endDate,
      title,
      info,
      details,
      claimedVouchers,
      dateFirstClaim,
      dateLastClaim,
      claimsOnStart,
      claimsOnEnd,
      redeemedVouchers,
      dateFirstRedeem,
      dateLastRedeem,
      redeemOnEnd,
      newUsersTotal,
      newUsers7,
      newUsers14,
      newUsers21,
      newUsers30,
    ];
    rows.push(subRow);

    const csv = rows.map((row) => row.map((r) => JSON.stringify(r))).join('\r\n');

    fileDownload(csv, 'tag_promos.csv');
  };

  return (
    <Content>
      <ButtonRow>
        <BackButton onClick={goBack} />

        <StyledButton
          aria-label="download"
          variant="contained"
          startIcon={<HiDocumentDownload />}
          onClick={downloadFile}
        >
          <Typography variant="body2">Download Promo</Typography>
        </StyledButton>
      </ButtonRow>

      <StyledGrid container spacing={2}>
        <Grid item xs={12}>
          <TagPromoDetails
            goBack={goBack}
            tagPromoData={[tagPromoData.tagPromotion]}
            tagPromoAmount={1}
            setSingleTagPromo={setSingleTagPromo}
            setTagParam={setTagParam}
            openError={openError}
            setOpenError={setOpenError}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            tagParam={tagParam}
            loadAllTags={loadAllTags}
            alwaysExpand
          />
        </Grid>
      </StyledGrid>
    </Content>
  );
}

export default SingleTagPromo;
