import React, { useState } from 'react';

import { styled } from '@mui/system';
import {
  Card,
  CardContent,
  Collapse,
  Divider,
  IconButton,
  IconButtonProps,
  Grid,
  Typography,
  Tooltip,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import { FaEdit } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import { ReturnedSchemeData } from '../../../Services/SchemeService';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  fontSize: '30px',
  color: theme.palette.common.white,
}));

const CardTitle = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary.main,
  height: '40px',
  [theme.breakpoints.down('sm')]: {
    height: '50px',
  },
}));

const CardTitleText = styled(Typography)(({ theme }) => ({
  alignSelf: 'center',
  color: theme.palette.common.white,
}));

const StyledEditButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

const StyledDeleteButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  marginRight: '20px',
  '&:hover': {
    color: theme.palette.warning.main,
  },
}));

const ExpandedContent = styled(CardContent)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  paddingLeft: '50px',
  paddingRight: '50px',
  [theme.breakpoints.down('sm')]: {
    paddingRight: '15px',
    paddingLeft: '15px',
  },
}));

const StyledImage = styled('img')(({ theme }) => ({
  width: '50%',
  boxShadow: '10px 10px 10px rgba(0,0,0,0.6)',
  marginBottom: '15px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    boxShadow: '5px 5px 5px rgba(0,0,0,0.4)',
  },
}));

type Props = {
  edit: (scheme:ReturnedSchemeData) => void,
  schemeData: ReturnedSchemeData,
  startDelete: (id:string) => void,
  businessData: any,
};

function SchemeCard(props: Props) {
  const {
    edit,
    schemeData,
    startDelete,
    businessData,
  } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const HandleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const permissions = ['loyalty:schemes:all', 'loyalty:schemes:update'];
  const canEdit = permissions.some((condition) => localStorage.getItem('userScopes')?.includes(condition));

  return (
    <Card key={`Scheme ${schemeData.schemeId}`}>
      <CardTitle>
        <CardTitleText variant="h4">{schemeData.schemeId}</CardTitleText>
        <div>
          <Tooltip title="Delete this Scheme" placement="bottom">
            <StyledDeleteButton disabled={!canEdit} size="small" onClick={() => startDelete(schemeData.schemeId)}>
              <AiFillDelete />
            </StyledDeleteButton>
          </Tooltip>

          <Tooltip title="Edit this Scheme" placement="bottom">
            <StyledEditButton disabled={!canEdit} size="small" onClick={() => edit(schemeData)}>
              <FaEdit />
            </StyledEditButton>
          </Tooltip>

          <ExpandMore
            expand={isExpanded}
            onClick={HandleExpandClick}
            aria-expanded={isExpanded}
            aria-label="show more"
          >
            <MdExpandMore />
          </ExpandMore>
        </div>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          <Grid container spacing={2}>
            {schemeData?.Type ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Is POS Scheme :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{schemeData.Type === 'POS' ? 'Yes' : 'No'}</Typography>
                </Grid>
              </>
            ) : null}

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Scheme ID :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">{schemeData.schemeId}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Scheme Name :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">{schemeData.name}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">
                {schemeData?.Type && schemeData?.Type === 'POS' ? 'Max Points :' : 'Max Stamps :'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">{schemeData.max}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">
                {`Add ${schemeData?.Type && schemeData?.Type === 'POS' ? 'Points' : 'Stamps'}
                after Voucher Redemption:`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">{schemeData.addStamp ? 'Yes' : 'No'}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Reward :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">{schemeData.reward}</Typography>
            </Grid>

            {schemeData.rewardPlural && schemeData.rewardPlural !== '' ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Reward Plural:</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{schemeData.rewardPlural}</Typography>
                </Grid>
              </>
            ) : null}

            {schemeData.rewardTerms && schemeData.rewardTerms !== '' ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Reward Terms:</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{schemeData.rewardTerms}</Typography>
                </Grid>
              </>
            ) : null}

            {schemeData.restrictions?.Amount ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Restriction Amount :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{schemeData.restrictions?.Amount}</Typography>
                </Grid>
              </>
            ) : null}

            {schemeData.restrictions?.Period ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Restriction Period :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{`${schemeData.restrictions?.Period} seconds`}</Typography>
                </Grid>
              </>
            ) : null}

            {schemeData.CostPrice ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Cost Price :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{schemeData.CostPrice}</Typography>
                </Grid>
              </>
            ) : null}

            {schemeData.SellPrice ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Sell Price :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{schemeData.SellPrice}</Typography>
                </Grid>
              </>
            ) : null}

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Locations :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              {schemeData.locations ? schemeData.locations.map((location) => {
                if (businessData.Locations[location]) {
                  return (
                    <Typography key={location} variant="body1">
                      {`${location} : ${businessData.Locations[location]['Short Address']}`}
                    </Typography>
                  );
                }
                return null;
              }) : null}
            </Grid>

            <Grid item xs={12}>
              <Divider><Typography variant="body2">Loyalty Card Images</Typography></Divider>
            </Grid>

            {schemeData.schemeImages.length > 0
              ? schemeData.schemeImages.map((schemeImage, index) => (
                <Grid item container xs={12} sm={4} justifyContent="center" key={index}>
                  <StyledImage src={schemeImage} />
                </Grid>
              ))
              : (
                <Grid item container xs={6} alignItems="center">
                  <span>Not Set!</span>
                </Grid>
              )}
          </Grid>
        </ExpandedContent>
      </Collapse>
    </Card>
  );
}

export default SchemeCard;
