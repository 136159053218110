/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  InputAdornment,
  Typography,
  MenuItem,
  IconButtonProps,
  IconButton,
  Grid,
} from '@mui/material';
import { styled } from '@mui/system';
import { AiOutlineSearch } from 'react-icons/ai';
import { MdExpandMore } from 'react-icons/md';
import { CampaignAnalytics } from '../../../Services/CampaignsService';
import dynamicSort from '../../../util/dynamicSort';
import tableHeaders from '../../../Constants/analyticsTableHeaders';

interface SortByProps extends IconButtonProps {
  selected: boolean;
  toggle: boolean;
}

const StyledContainer = styled('div')(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.primary.main,
  borderRadius: '5px',
}));

const StyledHeaderRow = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: '20px',
  paddingBottom: '20px',
  paddingLeft: '40px',
  paddingRight: '40px',
  backgroundColor: theme.palette.background.default,
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
}));

const SortBy = styled((props: SortByProps) => {
  const { selected, toggle, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, selected, toggle }) => ({
  transform: toggle ? 'rotate(180deg)' : 'rotate(0deg)',
  background: selected && theme.palette.secondary.main,
  borderRadius: '100%',
  width: '25px',
  height: '25px',
  padding: '0px',
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
  fontSize: '25px',
  color: theme.palette.common.white,
}));

const StyledSearch = styled(TextField)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.common.white,
  width: '400px',
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  border: 1,
  borderColor: theme.palette.primary.main,
  maxHeight: '100%',
}));

const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  fontWeight: 700,
  textAlign: 'center',
  padding: '6px',
  minWidth: '130px',
}));

const StyledBodyCell = styled(TableCell, {})({
  textAlign: 'center',
});

const StyledBodyRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.background.paper,
  },
}));

type Props = {
  tableItems: CampaignAnalytics[],
  filter: string,
  setFilter: (param: string) => void,
  searchText: string,
  setSearchText: (param: string) => void,
};

function AnalyticsTable(props: Props) {
  const {
    tableItems,
    filter,
    setFilter,
    searchText,
    setSearchText,
  } = props;

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sortedBy, setSortedBy] = useState<string>('businessId');
  const [reverseSort, setReverseSort] = useState<boolean>(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onSort = (paramKey: string) => {
    setSortedBy(paramKey);

    const reversing = sortedBy === paramKey && !reverseSort;
    if (reversing) {
      setReverseSort(!reverseSort);
      tableItems.sort(dynamicSort(`-${paramKey}`));
    } else {
      setReverseSort(false);
      tableItems.sort(dynamicSort(paramKey));
    }
  };

  return (
    <StyledContainer>
      <StyledHeaderRow>
        <StyledSearch
          id="search-entry"
          label="Search Business Id"
          variant="outlined"
          color="primary"
          value={searchText}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AiOutlineSearch />
              </InputAdornment>
            ),
          }}
          onChange={(event) => {
            const temp = event.target.value;
            if (temp !== '') setSearchText(temp.toUpperCase());
            else setSearchText('');
          }}
        />

        <StyledSearch
          select
          InputLabelProps={{ shrink: true }}
          id="type-select"
          label="Filter by Campaign Type"
          variant="outlined"
          color="primary"
          value={filter}
          onChange={(event) => {
            setFilter(event.target.value);
          }}
          InputProps={{
            autoComplete: 'off',
          }}
        >
          <MenuItem key={0} value="all">All Campaigns</MenuItem>
          <MenuItem key={1} value="acquisition">Acquisition</MenuItem>
          <MenuItem key={2} value="retention">Retention</MenuItem>
          <MenuItem key={3} value="birthday">Birthday</MenuItem>
          <MenuItem key={4} value="custom">Custom</MenuItem>
        </StyledSearch>
      </StyledHeaderRow>
      <StyledTableContainer>
        <Table stickyHeader style={{ overflowX: 'scroll' }}>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => (
                <StyledHeaderCell key={index}>
                  <Grid container>
                    <Grid item xs={9}>
                      <Typography>{header.display}</Typography>
                    </Grid>

                    <Grid item container xs={3} justifyContent="center" alignContent="center">
                      <SortBy
                        selected={sortedBy.includes(header.value)}
                        toggle={sortedBy.includes(header.value) && reverseSort}
                        onClick={() => onSort(header.value)}
                        aria-expanded={sortedBy.includes(header.value)}
                        aria-label="Sort By"
                      >
                        <MdExpandMore />
                      </SortBy>
                    </Grid>
                  </Grid>
                </StyledHeaderCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {tableItems.filter((campaign) => campaign.businessId.includes(searchText) && (filter !== 'all' ? filter === campaign.campaignType : true))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((rowData, rowIndex) => (
                <StyledBodyRow key={rowIndex}>
                  <StyledBodyCell>
                    <Typography variant="body1">{rowData.businessId}</Typography>
                  </StyledBodyCell>

                  <StyledBodyCell>
                    <Typography variant="body1">{rowData.campaignType}</Typography>
                  </StyledBodyCell>

                  <StyledBodyCell>
                    <Typography variant="body1">{rowData.status}</Typography>
                  </StyledBodyCell>

                  <StyledBodyCell>
                    <Typography variant="body1">{rowData.description}</Typography>
                  </StyledBodyCell>

                  <StyledBodyCell>
                    <Typography variant="body1">{rowData.rewardType}</Typography>
                  </StyledBodyCell>

                  <StyledBodyCell>
                    <Typography variant="body1">{rowData.rewardQuantity}</Typography>
                  </StyledBodyCell>

                  <StyledBodyCell>
                    <Typography variant="body1">{rowData.promotionRewardsDelivered}</Typography>
                  </StyledBodyCell>

                  <StyledBodyCell>
                    <Typography variant="body1">{rowData.readCount}</Typography>
                  </StyledBodyCell>

                  <StyledBodyCell>
                    <Typography variant="body1">{rowData.promotionClaimsCount}</Typography>
                  </StyledBodyCell>

                  <StyledBodyCell>
                    <Typography variant="body1">{rowData.promotionRedemptionsCount}</Typography>
                  </StyledBodyCell>

                  <StyledBodyCell>
                    <Typography variant="body1">{`${rowData.deliveredVsRead}%`}</Typography>
                  </StyledBodyCell>

                  <StyledBodyCell>
                    <Typography variant="body1">{`${rowData.readVsClaimed}%`}</Typography>
                  </StyledBodyCell>

                  <StyledBodyCell>
                    <Typography variant="body1">{`${rowData.claimedVsRedeemed}%`}</Typography>
                  </StyledBodyCell>

                  <StyledBodyCell>
                    <Typography variant="body1">{rowData.openedNotifications ? rowData.openedNotifications : 0}</Typography>
                  </StyledBodyCell>

                  <StyledBodyCell>
                    <Typography variant="body1">{rowData.linkeClickedCount}</Typography>
                  </StyledBodyCell>

                  <StyledBodyCell>
                    <Typography variant="body1">{rowData.seeMoreCount}</Typography>
                  </StyledBodyCell>

                  <StyledBodyCell>
                    <Typography variant="body1">{rowData.rewardCost <= 0 ? '' : rowData.rewardCost}</Typography>
                  </StyledBodyCell>

                  <StyledBodyCell>
                    <Typography variant="body1">{rowData.rewardPrice <= 0 ? '' : rowData.rewardPrice}</Typography>
                  </StyledBodyCell>

                  <StyledBodyCell>
                    <Typography variant="body1">{rowData.subsequentPurchase <= 0 ? 0 : rowData.subsequentPurchase}</Typography>
                  </StyledBodyCell>

                  <StyledBodyCell>
                    <Typography variant="body1">{rowData.returnOnInvestment <= 0 ? '' : rowData.returnOnInvestment}</Typography>
                  </StyledBodyCell>

                  <StyledBodyCell>
                    <Typography variant="body1">{rowData.twelveMonthROI <= 0 ? '' : rowData.twelveMonthROI}</Typography>
                  </StyledBodyCell>
                </StyledBodyRow>
              ))}
          </TableBody>
        </Table>

      </StyledTableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, tableItems.length]}
        component="div"
        count={tableItems.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

    </StyledContainer>
  );
}

export default AnalyticsTable;
