const tableHeaders: { display: string, value:string }[] = [
  {
    display: 'Business Id',
    value: 'businessId',
  },
  {
    display: 'Type',
    value: 'campaignType',
  },
  {
    display: 'Status',
    value: 'status',
  },
  {
    display: 'Description',
    value: 'description',
  },
  {
    display: 'Reward Type',
    value: 'rewardType',
  },
  {
    display: 'Reward Amount',
    value: 'rewardQuantity',
  },
  {
    display: 'Messages Delivered',
    value: 'promotionRewardsDelivered',
  },
  {
    display: 'Messages Read',
    value: 'readCount',
  },
  {
    display: 'Rewards Claimed',
    value: 'promotionClaimsCount',
  },
  {
    display: 'Rewards Redeemed',
    value: 'promotionRedemptionsCount',
  },
  {
    display: 'Delivered vs Read %',
    value: 'deliveredVsRead',
  },
  {
    display: 'Read vs Claimed %',
    value: 'readVsClaimed',
  },
  {
    display: 'Claimed vs Redeemed %',
    value: 'claimedVsRedeemed',
  },
  {
    display: 'Opened Notifications',
    value: 'openedNotifications',
  },
  {
    display: 'Clicked Message Link',
    value: 'linkeClickedCount',
  },
  {
    display: 'Clicked See More',
    value: 'seeMoreCount',
  },
  {
    display: 'Reward Cost',
    value: 'rewardCost',
  },
  {
    display: 'Reward Price',
    value: 'rewardPrice',
  },
  {
    display: 'Subsequent Purchases',
    value: 'subsequentPurchases',
  },
  {
    display: 'ROI',
    value: 'returnOnInvestment',
  },
  {
    display: '12 Month ROI',
    value: 'seeMoreCount',
  },
];

export default tableHeaders;
