import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import NoPermissionPage from '../../Components/BasicComponents/NoPermissionPage';
import UserSearch from './components/UserSearch';
import UserDetails from './components/UserDetails';

import WalletService from '../../Services/WalletService';

function UsersPage() {
  const [hasFoundUser, setHasFoundUser] = useState(false);
  const [userData, setUserData] = useState({});
  const [userId, setUserId] = useState('');
  const [userQueryParam, setUserQueryParam] = useSearchParams();
  const [walletData, setWalletData] = useState({});

  const permissions = ['loyalty:account:all', 'loyalty:account:read'];
  const canView = permissions.some((condition) => localStorage.getItem('userScopes')?.includes(condition));

  const goBack = () => {
    setHasFoundUser(false);
    setUserQueryParam(undefined);
    setUserData({});
    setUserId('');
  };

  const updateUser = async (id: string, data:any) => {
    setUserQueryParam({ userId: id });
    try {
      const wallet = await WalletService.getUserWallet(id);
      if (wallet) await setWalletData(wallet);
    } catch (e:any) {
      console.log(e.message);
    }
    await setUserData(data.userData);
    setHasFoundUser(true);
  };

  if (!canView) {
    return <NoPermissionPage />;
  }
  if (hasFoundUser) {
    return (
      <UserDetails
        goBack={goBack}
        userData={userData}
        userId={userId}
        updateUser={updateUser}
        walletData={walletData}
      />
    );
  }
  return (
    <UserSearch
      userId={userId}
      setUserId={setUserId}
      updateUser={updateUser}
      queryParam={userQueryParam.get('userId')}
    />
  );
}

export default UsersPage;
