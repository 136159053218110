/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';

import { Outlet } from 'react-router-dom';
import { styled } from '@mui/system';

import Sidebar from './Sidebar';

const SidebarDiv = styled('div', {})({
  display: 'flex',
  width: '100%',
});

function Layout() {
  const [sideBarOpen, setSideBarOpen] = useState(true);

  return (
    <SidebarDiv>
      <Sidebar sideBarOpen={sideBarOpen} setSideBarOpen={setSideBarOpen} />
      <Outlet />
    </SidebarDiv>
  );
}

export default Layout;
