import React, { useState } from 'react';

import { styled } from '@mui/system';
import {
  Card,
  CardContent,
  Collapse,
  Divider,
  IconButton,
  IconButtonProps,
  Grid,
  Typography,
  Tooltip,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import { FaEdit } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import ValidateEpoch from '../../../util/validateEpoch';
import { ReturnedTagPromo } from '../../../Services/PromoService';
import NewUsersChart from './NewUsersChart';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  fontSize: '30px',
  color: theme.palette.common.white,
}));

const CardTitle = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary.main,
  height: '40px',
  [theme.breakpoints.down('sm')]: {
    height: '50px',
  },
}));

const CardTitleText = styled(Typography)(({ theme }) => ({
  alignSelf: 'center',
  color: theme.palette.common.white,
}));

const ExpandedContent = styled(CardContent)(({ theme }) => ({
  paddingLeft: '50px',
  paddingRight: '50px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
}));

const StyledEditButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

const StyledDeleteButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  marginRight: '20px',
  '&:hover': {
    color: theme.palette.warning.main,
  },
}));

type Props = {
  tagPromoData: ReturnedTagPromo,
  tagPromoId: string,
  startDelete: (tagId: string) => void,
  startEdit: (tagId: string, data: ReturnedTagPromo) => void,
  alwaysExpand?: boolean,
};

function TagPromoCard(props: Props) {
  const {
    tagPromoData,
    tagPromoId,
    startDelete,
    startEdit,
    alwaysExpand,
  } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const HandleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const permissions = ['loyalty:promo:update', 'loyalty:promo:all'];
  const canEdit = permissions.some((condition) => localStorage.getItem('userScopes')?.includes(condition));

  return (
    <Card key={1}>
      <CardTitle>
        <CardTitleText variant="h4">{tagPromoId}</CardTitleText>
        <div>
          <Tooltip title="Delete this Tag Promotion" placement="bottom">
            <StyledDeleteButton disabled={!canEdit} size="small" onClick={() => startDelete(tagPromoId)}>
              <AiFillDelete />
            </StyledDeleteButton>
          </Tooltip>

          <Tooltip title="Edit this Tag Promotion" placement="bottom">
            <StyledEditButton
              disabled={!canEdit}
              size="small"
              onClick={() => {
                startEdit(tagPromoId, tagPromoData);
              }}
            >
              <FaEdit />
            </StyledEditButton>
          </Tooltip>
          {!alwaysExpand ? (
            <ExpandMore
              expand={isExpanded}
              onClick={HandleExpandClick}
              aria-expanded={isExpanded}
              aria-label="show more"
            >
              <MdExpandMore />
            </ExpandMore>
          ) : null}
        </div>
      </CardTitle>
      <Collapse in={alwaysExpand ? true : isExpanded}>
        <ExpandedContent>
          <Grid container spacing={2}>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Status :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">{`${tagPromoData.status}`}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider><Typography variant="body2">Business Details</Typography></Divider>
            </Grid>

            {tagPromoData.partnerId ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Business Id :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{`${tagPromoData.partnerId}`}</Typography>
                </Grid>
              </>
            ) : null}

            {tagPromoData.schemeId ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Scheme Id :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{`${tagPromoData.schemeId}`}</Typography>
                </Grid>
              </>
            ) : null}

            <Grid item xs={12}>
              <Divider><Typography variant="body2">Promotion Details</Typography></Divider>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Type :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">{`${tagPromoData.promoType}`}</Typography>
            </Grid>

            {tagPromoData.criteria ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Criteria :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{`${tagPromoData.criteria}`}</Typography>
                </Grid>
              </>
            ) : null}

            {tagPromoData.rewardsRemaining || tagPromoData.rewardsRemaining === 0 ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Uses Remaining :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{`${tagPromoData.rewardsRemaining}`}</Typography>
                </Grid>
              </>
            ) : null}

            {tagPromoData.amount ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Amount :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{`${tagPromoData.amount}`}</Typography>
                </Grid>
              </>
            ) : null}

            {tagPromoData.description ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Description :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{`${tagPromoData.description}`}</Typography>
                </Grid>
              </>
            ) : null}

            {tagPromoData.locationIds ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Location :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{`${tagPromoData.locationIds}`}</Typography>
                </Grid>

              </>
            ) : null}

            {tagPromoData.availabilityDays ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Selected Days :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {tagPromoData.availabilityDays.map((day) => (
                    <Typography key={day} variant="body1">
                      {`${day}`}
                    </Typography>
                  ))}
                </Grid>
              </>
            ) : null}

            {tagPromoData.startTime ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Start Time :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    {new Date(ValidateEpoch(tagPromoData.startTime)).toLocaleString()}
                  </Typography>
                </Grid>
              </>
            ) : null}

            {tagPromoData.endTime ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">End Time :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    {new Date(ValidateEpoch(tagPromoData.endTime)).toLocaleString()}
                  </Typography>
                </Grid>
              </>
            ) : null}

            {tagPromoData.voucherEarnedModal ? (
              <>
                <Grid item xs={12}>
                  <Divider><Typography variant="body2">Modal Details</Typography></Divider>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Title :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{`${tagPromoData.voucherEarnedModal.title}`}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Reward Details :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{`${tagPromoData.voucherEarnedModal.rewardDetails}`}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Promotion Text :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{`${tagPromoData.voucherEarnedModal.voucherInformation}`}</Typography>
                </Grid>
              </>
            ) : null}

            {tagPromoData.analytics ? (
              <>
                <Grid item xs={12}>
                  <Divider><Typography variant="body2">Analytics</Typography></Divider>
                </Grid>

                {tagPromoData.analytics.claimedVouchers ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2">Total Claims : </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">{`${tagPromoData.analytics.claimedVouchers}`}</Typography>
                    </Grid>
                  </>
                ) : null}

                {tagPromoData.analytics.claimsOnStart ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2">Claims on Start Day : </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">{`${tagPromoData.analytics.claimsOnStart}`}</Typography>
                    </Grid>
                  </>
                ) : null}

                {tagPromoData.analytics.claimsOnExpiry ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2">Claims on End Day : </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">{`${tagPromoData.analytics.claimsOnExpiry}`}</Typography>
                    </Grid>
                  </>
                ) : null}

                {tagPromoData.analytics.redeemedVouchers ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2">Total Redeemed : </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">{`${tagPromoData.analytics.redeemedVouchers}`}</Typography>
                    </Grid>
                  </>
                ) : null}

                {tagPromoData.analytics.redemptionOnExpiry ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2">Redeems on End Day : </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">{`${tagPromoData.analytics.redemptionOnExpiry}`}</Typography>
                    </Grid>
                  </>
                ) : null}

                {tagPromoData.analytics.firstClaimTimestamp ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2">Date of first Claim :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        {tagPromoData.analytics.firstClaimTimestamp}
                      </Typography>
                    </Grid>
                  </>
                ) : null}

                {tagPromoData.analytics.firstRedeemTimestamp ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2">Date of First Redemption :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        {tagPromoData.analytics.firstRedeemTimestamp}
                      </Typography>
                    </Grid>
                  </>
                ) : null}

                {tagPromoData.analytics.lastClaimTimestamp ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2">Date of last Claim :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        {tagPromoData.analytics.lastClaimTimestamp}
                      </Typography>
                    </Grid>
                  </>
                ) : null}

                {tagPromoData.analytics.lastRedeemTimestamp ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2">Date of last Redemption :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        {tagPromoData.analytics.lastRedeemTimestamp}
                      </Typography>
                    </Grid>
                  </>
                ) : null}

                {tagPromoData.analytics.newUsersEntirePromoPeriod ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2">New Users over Entire Promotion :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        {tagPromoData.analytics.newUsersEntirePromoPeriod}
                      </Typography>
                    </Grid>
                  </>
                ) : null}

                {tagPromoData.analytics.newUsers7days ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2">New Users over 7 Days :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        {tagPromoData.analytics.newUsers7days}
                      </Typography>
                    </Grid>
                  </>
                ) : null}

                {tagPromoData.analytics.newUsers14days ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2">New Users over 14 Days :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        {tagPromoData.analytics.newUsers14days}
                      </Typography>
                    </Grid>
                  </>
                ) : null}

                {tagPromoData.analytics.newUsers21days ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2">New Users over 21 Days :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        {tagPromoData.analytics.newUsers21days}
                      </Typography>
                    </Grid>
                  </>
                ) : null}

                {tagPromoData.analytics.newUsers30days ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2">New Users over 30 Days :</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        {tagPromoData.analytics.newUsers30days}
                      </Typography>
                    </Grid>
                  </>
                ) : null}

                <Grid item xs={12} sm={12} sx={{ height: '400px', marginTop: '20px' }}>
                  <NewUsersChart
                    usersTotal={tagPromoData.analytics.newUsersEntirePromoPeriod
                      ? tagPromoData.analytics.newUsersEntirePromoPeriod
                      : 0}
                    users7={tagPromoData.analytics.newUsers7days
                      ? tagPromoData.analytics.newUsers7days
                      : 0}
                    users14={tagPromoData.analytics.newUsers14days
                      ? tagPromoData.analytics.newUsers14days
                      : 0}
                    users21={tagPromoData.analytics.newUsers21days
                      ? tagPromoData.analytics.newUsers21days
                      : 0}
                    users30={tagPromoData.analytics.newUsers30days
                      ? tagPromoData.analytics.newUsers30days
                      : 0}
                  />
                </Grid>

              </>
            ) : null}
          </Grid>
        </ExpandedContent>
      </Collapse>
    </Card>
  );
}

export default TagPromoCard;
