/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';

type Props = {
  usersTotal: number,
  users7: number,
  users14: number,
  users21: number,
  users30: number,
};

type UserData = {
  name: string,
  uv: number
};

function NewUsersChart(props: Props) {
  const {
    usersTotal,
    users7,
    users14,
    users21,
    users30,
  } = props;

  const userData = [
    { name: 'Day 7', Users: users7 },
    { name: 'Day 14', Users: users14 },
    { name: 'Day 21', Users: users21 },
    { name: 'Day 30', Users: users30 },
  ];

  let yMin = (Math.round(users7 / 10) * 10);
  yMin = yMin > users7 ? yMin - 10 : yMin;
  const yMax = (Math.round(users30 / 10) * 10);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={userData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis domain={[yMin, yMax]} />
        <Tooltip />
        <Legend />
        <Line dataKey="Users" fill="#8884d8" activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default NewUsersChart;
