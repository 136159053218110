const API_URL = process.env.REACT_APP_API_URL;
// const API_URL = 'http://localhost:3000';

export type ReturnedSchemeData = {
  schemeId: string,
  name: string,
  max: number,
  reward: string,
  rewardPlural: string,
  rewardTerms: string,
  schemeImages: string[],
  locations: string[],
  genre?: string | null,
  addStamp: boolean,
  restrictions?: {
    Amount: number,
    Period: number,
  }
  CostPrice?: number,
  SellPrice?: number,
  Type?: string,
};

export type V2Scheme = {
  Chain: string;
  'Add Stamp': boolean;
  Images: {
    [index:string]: string,
  };
  Locations?: {
    [index:string]: boolean
  };
  Max: number;
  Name: string;
  Restrictions?: {
    Amount: number,
    Period: number
  };
  Reward: string;
  'Reward Plural'?: string;
  'Reward Terms'?: string;
  partnerId: string;
  CostPrice?: number;
  SellPrice?: number;
};

const getSpecificScheme = (partnerId:string, schemeId:string)
: Promise<ReturnedSchemeData> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/partners/${partnerId}/schemes/${schemeId}?type=dev`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const getSchemeByBusiness = (partnerId:string) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/partners/${partnerId}/schemes?type=dev`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const updateScheme = (
  partnerId:string,
  schemeId: string,
  scheme: V2Scheme,
  isPOSBusiness:boolean,
) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/partners/${partnerId}/schemes/${schemeId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ scheme, isPOSBusiness }),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const createScheme = (partnerId:string, scheme: V2Scheme, isPOSBusiness: boolean) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/partners/${partnerId}/schemes`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ scheme, isPOSBusiness }),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const deleteScheme = (partnerId:string, schemeId: string) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/partners/${partnerId}/schemes/${schemeId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const excludeTag = (partnerId:string, schemeId: string, tagId: string) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/partners/${partnerId}/schemes/${schemeId}/excludeTag/${tagId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const removeExclusion = (partnerId:string, schemeId: string, tagId: string) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/partners/${partnerId}/schemes/${schemeId}/removeExclusion/${tagId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const SchemeService = {
  excludeTag,
  removeExclusion,
  deleteScheme,
  updateScheme,
  createScheme,
  getSpecificScheme,
  getSchemeByBusiness,
};

export default SchemeService;
