import React, { useEffect, useState } from 'react';

import {
  DialogActions,
  DialogContent,
  Button,
  Dialog,
  Grid,
  Typography,
  TextField,
} from '@mui/material';
import { styled } from '@mui/system';
import { GiCancel, GiConfirmed } from 'react-icons/gi';
import ErrorDialog from '../../../Components/BasicComponents/ErrorDialog';
import ConfirmDialog from '../../../Components/BasicComponents/ConfirmDialog';
import SavingChangesDialog from '../../../Components/BasicComponents/SavingChangesDialog';

import BusinessService, { OurEthos } from '../../../Services/BusinessService';

const StyledTitle = styled(Typography, {})({
  alignSelf: 'center',
  paddingTop: '25px',
  paddingBottom: '10px',
});

const StyledConfirm = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const StyledCancel = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.white,
  backgroundColor: theme.palette.warning.main,
  '&:hover': {
    backgroundColor: theme.palette.warning.main,
  },
}));

type Props = {
  loadBusinessDetails: (id: string) => void,
  ethosData: OurEthos | undefined,
  open: boolean,
  setOpen: (toggle: boolean) => void,
  partnerId: string,
};

function OurEthosEdit(props: Props) {
  const {
    loadBusinessDetails,
    open,
    setOpen,
    ethosData,
    partnerId,
  } = props;

  const [ourEthosDes, setOurEthosDes] = useState<string>('');
  const [ourEthosLink, setOurEthosLink] = useState<string>('');
  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openSaving, setOpenSaving] = useState<boolean>(false);

  const handleClose = () => {
    setOurEthosDes('');
    setOurEthosLink('');
    setOpen(false);
  };

  const throwError = (error:string) => {
    setOpenConfirm(false);
    setOpenErrorDialog(true);
    setErrorText(error);
  };

  const handleSave = async () => {
    setOpenConfirm(false);
    setOpenSaving(true);
    try {
      const premiumFields = {
        'Our Ethos': {
          Description: ourEthosDes,
          Link: ourEthosLink,
        },
      };

      await BusinessService.updateBusiness(partnerId, undefined, premiumFields);
    } catch (e:any) {
      throwError(e.message);
    }

    loadBusinessDetails(partnerId);
    setOpenSaving(false);
    setOpen(false);
  };

  useEffect(() => {
    if (ethosData && ethosData.Description) setOurEthosDes(ethosData.Description);
    if (ethosData && ethosData.Link) setOurEthosLink(ethosData.Link);
  }, [ethosData]);

  const handleClosed = (event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick' && 'escapeKeyDown') return;
    handleClose();
  };

  return (
    <Dialog
      aria-label="ethos-edit"
      onClose={(event, reason) => handleClosed(event, reason)}
      open={open}
      fullWidth
      maxWidth="md"
      sx={{ zIndex: '3000' }}
    >

      <StyledTitle variant="h1">Edit Premium Ethos</StyledTitle>

      <DialogContent>
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body2">
              Ethos Description
            </Typography>
            <Typography variant="body1">
              This is the text which is displayed under the Our Ethos section of the business profile.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="short-address"
              label="Our Ethos Description"
              variant="outlined"
              color="primary"
              value={ourEthosDes}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                setOurEthosDes(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
              multiline
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              Business Ethos Link
            </Typography>
            <Typography variant="body1">
              If the business has its own website with its ethos already layed out then the link should go here.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="short-address"
              label="Our Ethos Link"
              variant="outlined"
              color="primary"
              value={ourEthosLink}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                setOurEthosLink(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
              multiline
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <StyledCancel
          variant="contained"
          fullWidth
          startIcon={<GiCancel />}
          onClick={handleClose}
        >
          <Typography variant="body2">CANCEL</Typography>
        </StyledCancel>
        <StyledConfirm
          variant="contained"
          fullWidth
          startIcon={<GiConfirmed />}
          onClick={() => {
            setOpenConfirm(true);
          }}
        >
          <Typography variant="body2">CONFIRM</Typography>
        </StyledConfirm>
      </DialogActions>

      <ConfirmDialog
        open={openConfirm}
        handleCancel={() => {
          setOpenConfirm(false);
        }}
        handleConfirm={() => {
          handleSave();
        }}
        dialogTitle="Save Changes"
        dialogText="Are you sure you want to save changes?"
      />

      <ErrorDialog
        open={openErrorDialog}
        dialogTitle="Error Saving Changes"
        dialogText={errorText}
        handleClose={() => {
          setOpenErrorDialog(false);
          setErrorText('');
        }}
      />

      <SavingChangesDialog
        open={openSaving}
        dialogTitle="Saving changes to Our Ethos"
      />

    </Dialog>
  );
}

export default OurEthosEdit;
