const API_URL = process.env.REACT_APP_API_URL;
// const API_URL = 'http://localhost:3000';

const addStamps = (
  uid: string,
  businessId: string,
  schemeId: string,
  locationId: string,
  numStamps: number,
) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/stamps/admin`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      uid, businessId, schemeId, locationId, numStamps,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const StampsService = {
  addStamps,
};

export default StampsService;
