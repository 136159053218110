import React from 'react';
import { CircularProgress } from '@mui/material';
import { styled } from '@mui/system';

const StyledLoadingContainer = styled('div', {})({
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: '60px',
});

function PageLoadingSpinner() {
  return (
    <StyledLoadingContainer>
      <CircularProgress />
    </StyledLoadingContainer>
  );
}

export default PageLoadingSpinner;
