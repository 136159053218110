import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: '#E8F8F7',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          zIndex: '5000',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontWeight: 'inherit',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: '#FA7268',
            borderColor: '#FA7268',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 100px #fff inset',
          },
          '&.Mui-disabled': {
            color: 'green',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#00C1C0',
            borderColor: '#00C1C0',
          },
        },
      },
    },
    // MuiCardContent: {
    //   styleOverrides: {
    //     root: {
    //       height: '55px',
    //     },
    //   },
    // },
  },
  status: {
    danger: '#e53e3e',
  },
  palette: {
    common: {
      white: '#FFFFFF',
      black: '#000000',
    },
    primary: {
      main: '#2D2D2D',
      light: '#626262',
    },
    secondary: {
      main: '#00C1C0',
      light: '#E8F8F7',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
    background: {
      default: '#F8F8F8',
      paper: '#f0f0f0',
    },
    text: {
      primary: '#2D2D2D',
      secondary: '#626262',
      disabled: '#d4d4d4',
    },
    warning: {
      main: '#FA7268',
    },
  },
});

theme.typography = {
  ...theme.typography,
  h1: {
    fontSize: '24px',
    fontWeight: '500',
    lineHeight: '1.5',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  h4: {
    fontSize: '18px',
    fontWeight: '550',
    lineHeight: '1.5',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  body1: {
    fontSize: '16px',
    lineHeight: '1.5',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
  },
  body2: {
    fontSize: '16px',
    lineHeight: '1.5',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
  },
};

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: React.CSSProperties['color'];
    };
  }

  interface Palette {
    neutral: Palette['primary'];
  }

  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
  }

  interface PaletteColor {
    darker?: string;
  }

  interface SimplePaletteColorOptions {
    darker?: string;
  }

  interface ThemeOptions {
    status: {
      danger: React.CSSProperties['color'];
    };
  }
}

export default theme;
