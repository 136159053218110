/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';

import { styled } from '@mui/system';
import {
  Grid,
  TablePagination,
  TextField,
  InputAdornment,
} from '@mui/material';
import { AiOutlineSearch } from 'react-icons/ai';

import Content from '../../../Components/BasicComponents/Content';
import TitleComponent from '../../../Components/BasicComponents/TitleComponent';
import BackButton from '../../../Components/BasicComponents/BackButton';
import ScheduledCard from './ScheduledCard';

import NotificationService, { Notifications } from '../../../Services/NotificationService';

const StyledSearch = styled(TextField)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.common.white,
  justifySelf: 'center',
  width: '400px',
}));

type Props = {
  scheduledNotifications: Notifications,
  goBack: () => void,
};

function ScheduledNotifications(props: Props) {
  const {
    scheduledNotifications,
    goBack,
  } = props;

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(4);
  const [searchText, setSearchText] = useState<string>('');
  const [amount, setAmount] = useState<number>(Object.keys(scheduledNotifications).length);
  const [selectedId, setSelectedId] = useState<string>('');

  useEffect(() => {
    setAmount(Object.keys(scheduledNotifications).length);
    NotificationService.getPendingNotificationAmount();
  }, [scheduledNotifications]);

  // TODO
  // const permissions = ['loyalty:partners:update', 'loyalty:partners:all'];
  // const canEdit = permissions.some((condition) => localStorage.getItem('userScopes')?.includes(condition));

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Content>
      <Grid container>
        <Grid item xs={3}>
          <BackButton onClick={goBack} />
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
          <StyledSearch
            id="search-entry"
            label="Search by Business ID"
            variant="filled"
            color="primary"
            value={searchText}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              autoComplete: 'off',
              startAdornment: (
                <InputAdornment position="start">
                  <AiOutlineSearch />
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              if (page > 0) setPage(0);
              setSearchText(event.target.value.toUpperCase());
              const keys = Object.keys(scheduledNotifications)
                .filter((key) => scheduledNotifications[key].businessId
                  && scheduledNotifications[key].businessId.toUpperCase().includes(event.target.value.toUpperCase()));
              setAmount(keys.length);
            }}
          />
        </Grid>
      </Grid>

      <TitleComponent text="Scheduled Notifications" />
      <Grid item container spacing={2} xs={12}>
        {Object.keys(scheduledNotifications)
          .filter((key) => scheduledNotifications[key].businessId
            && scheduledNotifications[key].businessId.toUpperCase().includes(searchText))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((key) => (
            <Grid item xs={6} key={key}>
              <ScheduledCard
                notificationId={key}
                pendingData={scheduledNotifications[key]}
              />
            </Grid>
          ))}
      </Grid>

      <TablePagination
        rowsPerPageOptions={[4, 8, 16]}
        component="div"
        count={amount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

    </Content>
  );
}

export default ScheduledNotifications;
