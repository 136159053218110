import React from 'react';

import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const DivRow = styled('div', {})({
  display: 'flex',
  flexDirection: 'row',
  fontSize: '16px',
  lineHeight: '24px',
  alignItems: 'center',
});

const IconSpan = styled('span')(({ theme }) => ({
  paddingRight: '15px',
  fontSize: '24px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
    paddingRight: '6px',
  },
}));

type Props = {
  description? : string
  icon: JSX.Element,
  text: string,
};

function IconWithText(props: Props) {
  const { description, icon, text } = props;

  return (
    <DivRow>
      <IconSpan>{icon}</IconSpan>
      {description
        ? (
          <Typography variant="body2">
            {`${description} :`}
          </Typography>
        )
        : null}
      <Typography variant="body1" sx={{ paddingLeft: '6px' }}>{text}</Typography>
    </DivRow>
  );
}

export default IconWithText;
