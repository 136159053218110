import React, { useEffect, useState } from 'react';

import {
  DialogActions,
  DialogContent,
  Button,
  Dialog,
  Grid,
  Typography,
  TextField,
  MenuItem,
} from '@mui/material';
import { styled } from '@mui/system';
import { GiCancel, GiConfirmed } from 'react-icons/gi';
import { BsCloudUpload } from 'react-icons/bs';
import ErrorDialog from '../../../Components/BasicComponents/ErrorDialog';
import ConfirmDialog from '../../../Components/BasicComponents/ConfirmDialog';
import BusinessService, { OurFood } from '../../../Services/BusinessService';
import SavingChangesDialog from '../../../Components/BasicComponents/SavingChangesDialog';

const StyledTitle = styled(Typography, {})({
  alignSelf: 'center',
  paddingTop: '25px',
  paddingBottom: '10px',
});

const StyledConfirm = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const StyledCancel = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.white,
  backgroundColor: theme.palette.warning.main,
  '&:hover': {
    backgroundColor: theme.palette.warning.main,
  },
}));

const StyledImage = styled('img', {})({
  height: 'auto',
  width: 'auto',
  maxWidth: '100%',
  objectFit: 'contain',
  marginBottom: '15px',
  borderColor: 'black',
  borderRadius: '5px',
  maxHeight: '250px',
});

const StyledCaption = styled(Typography)(({ theme }) => ({
  color: theme.palette.warning.primary,
  textDecorationColor: theme.palette.warning.primary,
}));

const StyledUploadToggle = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isUploading',
}) <{ isUploading?: boolean }>(({ theme, isUploading }) => ({
  color: theme.palette.common.white,
  backgroundColor: isUploading ? theme.palette.warning.main : theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: isUploading ? theme.palette.warning.main : theme.palette.secondary.main,
  },
})) as typeof Button;

type Props = {
  loadBusinessDetails: (id: string) => void,
  foodData: OurFood | undefined,
  open: boolean,
  setOpen: (toggle: boolean) => void,
  partnerId: string,
};

function OurFoodEdit(props: Props) {
  const {
    loadBusinessDetails,
    open,
    setOpen,
    foodData,
    partnerId,
  } = props;

  const [ourFoodDes, setOurFoodDes] = useState<string>('');
  const [ourFoodMenuLink, setOurFoodMenuLink] = useState<string>('');
  const [ourFoodOrderLink, setOurFoodOrderLink] = useState<string>('');
  const [ourFoodImg, setOurFoodImg] = useState<string[]>([]);
  const [imageUpload, setImageUpload] = useState<File[]>([]);
  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openSaving, setOpenSaving] = useState<boolean>(false);

  const handleClose = () => {
    setOurFoodDes('');
    setOurFoodMenuLink('');
    setOurFoodOrderLink('');
    setOurFoodImg([]);
    setImageUpload([]);
    setOpen(false);
  };

  const throwError = (error:string) => {
    setOpenConfirm(false);
    setOpenErrorDialog(true);
    setErrorText(error);
  };

  const previewImage = (files: FileList) => {
    if (files.length > 0) {
      const previews: string[] = [];
      const uploads: File[] = [];
      Object.values(files).forEach((file) => {
        const image = URL.createObjectURL(file);
        previews.push(image);
        uploads.push(file);
      });

      setOurFoodImg([...previews]);
      setImageUpload([...uploads]);
    }
  };

  const handleSave = async () => {
    setOpenConfirm(false);
    setOpenSaving(true);
    if (imageUpload.length > 1) {
      try {
        const urls = await BusinessService.uploadImageBatch(partnerId, imageUpload, 'food');
        const premiumFields = {
          'Our Food': {
            Description: ourFoodDes,
            Menu: ourFoodMenuLink,
            'Order Link': ourFoodOrderLink,
            Images: urls,
          },
        };

        await BusinessService.updateBusiness(partnerId, undefined, premiumFields);
      } catch (e:any) {
        throwError(e.message);
      }
    } else {
      const premiumFields = {
        'Our Food': {
          Description: ourFoodDes,
          Menu: ourFoodMenuLink,
          'Order Link': ourFoodOrderLink,
          Images: ourFoodImg,
        },
      };
      try {
        await BusinessService.updateBusiness(partnerId, undefined, premiumFields);
      } catch (e:any) {
        throwError(e.message);
      }
    }
    loadBusinessDetails(partnerId);
    setOpenSaving(false);
    setOpen(false);
  };

  useEffect(() => {
    if (foodData && foodData.Description) setOurFoodDes(foodData.Description);
    if (foodData && foodData.Menu) setOurFoodMenuLink(foodData.Menu);
    if (foodData && foodData['Order Link']) setOurFoodOrderLink(foodData['Order Link']);
    if (foodData && foodData.Images) {
      const images = foodData.Images.filter((notNull) => notNull);
      setOurFoodImg(images);
    }
  }, [foodData]);

  const handleClosed = (event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick' && 'escapeKeyDown') return;
    handleClose();
  };

  return (
    <Dialog
      aria-label="food-edit"
      onClose={(event, reason) => handleClosed(event, reason)}
      open={open}
      fullWidth
      maxWidth="md"
      sx={{ zIndex: '3000' }}
    >

      <StyledTitle variant="h1">Edit Our Food</StyledTitle>

      <DialogContent>
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body2">
              Business Food Description
            </Typography>
            <Typography variant="body1">
              This is the text which is displayed under the Our Ethos section of the business profile.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="short-address"
              label="Our Food Description"
              variant="outlined"
              color="primary"
              value={ourFoodDes}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                setOurFoodDes(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
              multiline
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              Business Food Menu Link
            </Typography>
            <Typography variant="body1">
              If the business has its own website with an online menu then the link should go here.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="short-address"
              label="Our Food Menu Link"
              variant="outlined"
              color="primary"
              value={ourFoodMenuLink}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                setOurFoodMenuLink(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
              multiline
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              Business Food Order Link
            </Typography>
            <Typography variant="body1">
              If the business has its own website with an online ordering system then the link should go here.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="short-address"
              label="Our Food Order Here Link"
              variant="outlined"
              color="primary"
              value={ourFoodOrderLink}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                setOurFoodOrderLink(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
              multiline
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              Business Food Images
            </Typography>
            <Typography variant="body1">
              You can upload new images to be displayed in the Our Food section of the business profile.
              You can also select which position each image is presented and if you wish to delete them.
            </Typography>
          </Grid>

          {ourFoodImg.length > 0 ? (
            <Grid container item direction="row" spacing={4}>
              {ourFoodImg.map((image, index) => (
                <Grid
                  item
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignContent="center"
                  xs={6}
                  key={`${image}-${index}`}
                >
                  <StyledImage src={image} alt={image} key={image} />
                  <TextField
                    fullWidth
                    select
                    InputLabelProps={{ shrink: true }}
                    label="Change Image Position"
                    id="type-select"
                    variant="outlined"
                    color="primary"
                    value={index}
                    onChange={(event) => {
                      const newImages = ourFoodImg;
                      if (event.target.value === 'delete') {
                        newImages.splice(index, 1);
                        setOurFoodImg([...newImages]);
                        if (imageUpload.length > 0) {
                          const img = imageUpload;
                          img.splice(index, 1);
                          setImageUpload([...img]);
                        }
                      } else {
                        const from = index;
                        const to = parseInt(event.target.value, 10);
                        newImages.splice(to, 0, newImages.splice(from, 1)[0]);
                        setOurFoodImg([...newImages]);
                        if (imageUpload.length > 0) {
                          const img = imageUpload;
                          img.splice(to, 0, img.splice(from, 1)[0]);
                          setImageUpload([...img]);
                        }
                      }
                    }}
                    InputProps={{
                      autoComplete: 'off',
                    }}
                  >
                    {ourFoodImg.map((i, j) => (
                      <MenuItem key={`food-${i}-${j}-${index}`} value={j}>{`${j + 1}`}</MenuItem>
                    ))}
                    <MenuItem key={`food-delete-${index}`} value="delete">Delete</MenuItem>
                  </TextField>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid item container xs={12} justifyContent="center">
              <Typography variant="body2">
                No images uploaded
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <StyledUploadToggle
              variant="contained"
              component="label"
              fullWidth
              startIcon={<BsCloudUpload />}
            >
              <Typography variant="body2">Upload New Food Images</Typography>
              <input
                type="file"
                hidden
                multiple
                accept=".jpg, .jpeg, .png .pdf"
                onChange={(event) => {
                  if (event.target.files && event.target.files !== null) {
                    previewImage(event.target.files);
                  }
                }}
              />
            </StyledUploadToggle>
          </Grid>

          <Grid item container xs={12} justifyContent="center">
            <StyledCaption variant="body1" color="warning.main">
              Warning uploading new images will overwrite all existing images when you confirm saving changes
            </StyledCaption>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <StyledCancel
          variant="contained"
          fullWidth
          startIcon={<GiCancel />}
          onClick={handleClose}
        >
          <Typography variant="body2">CANCEL</Typography>
        </StyledCancel>
        <StyledConfirm
          variant="contained"
          fullWidth
          startIcon={<GiConfirmed />}
          onClick={() => {
            setOpenConfirm(true);
          }}
        >
          <Typography variant="body2">CONFIRM</Typography>
        </StyledConfirm>
      </DialogActions>

      <ConfirmDialog
        open={openConfirm}
        handleCancel={() => {
          setOpenConfirm(false);
        }}
        handleConfirm={() => {
          handleSave();
        }}
        dialogTitle="Save Changes"
        dialogText="Are you sure you want to save changes?"
      />

      <ErrorDialog
        open={openErrorDialog}
        dialogTitle="Error Saving Changes"
        dialogText={errorText}
        handleClose={() => {
          setOpenErrorDialog(false);
          setErrorText('');
        }}
      />

      <SavingChangesDialog
        open={openSaving}
        dialogTitle="Saving changes to Our Food"
      />

    </Dialog>
  );
}

export default OurFoodEdit;
