import React from 'react';
import 'dayjs/locale/en-gb';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ThemeProvider } from '@mui/material/styles';

import LoginPage from './Pages/Login/LoginPage';
import Layout from './Components/Layout/Layout';
import TagsPage from './Pages/Tag/TagPage';
import UsersPage from './Pages/User/UserPage';
import BusinessPage from './Pages/Business/BusinessPage';
import SchemePage from './Pages/Scheme/SchemePage';
import PromoPage from './Pages/Promo/PromoPage';
import CampaignsPage from './Pages/Campaigns/CampaignPage';
import NotificationPage from './Pages/Notifications/NotificationPage';
import AdminPage from './Pages/Admin/AdminPage';
import PrivateRoutes from './util/PrivateRoutes';
import theme from './Constants/theme';

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <Router>
        <ThemeProvider theme={theme}>
          <Routes>

            <Route element={<PrivateRoutes />}>
              <Route element={<Layout />}>
                <Route element={<TagsPage />} path="/tags" />
                <Route element={<UsersPage />} path="/users" />
                <Route element={<BusinessPage />} path="/businesses" />
                <Route element={<SchemePage />} path="/schemes" />
                <Route element={<PromoPage />} path="/promos" />
                <Route element={<CampaignsPage />} path="/campaigns" />
                <Route element={<NotificationPage />} path="/notifications" />
                <Route element={<AdminPage />} path="/admin" />
              </Route>
            </Route>

            <Route element={<LoginPage />} path="/" />

          </Routes>
        </ThemeProvider>
      </Router>
    </LocalizationProvider>
  );
}

export default App;
