import React from 'react';

import { CircularProgress, Grid } from '@mui/material';
import { styled } from '@mui/system';

import { useJsApiLoader, GoogleMap, MarkerF } from '@react-google-maps/api';

const StyledMap = styled('div', {})({
  border: '1.5px solid',
  height: '45vh',
  width: '100%',
});

type Props = {
  lat: number,
  lng: number,
  zoom: number,
  enableMapOptions: boolean,
  setCoordinates?: (coords: { lat: number, lng: number }) => void,
  isDraggable?: boolean
};

const libraries: Array<'places'> = ['places'];

function GoogleMapContainer(props: Props) {
  const {
    lat,
    lng,
    zoom,
    enableMapOptions,
    isDraggable,
    setCoordinates,
  } = props;

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY!,
    libraries,
  });

  if (!isLoaded) {
    return (
      <Grid item container xs={12} justifyContent="center">
        <CircularProgress />
      </Grid>
    );
  }

  const drag = (e:any) => {
    const newLat = e.latLng.lat();
    const newLong = e.latLng.lng();
    if (newLat && newLong && setCoordinates) {
      setCoordinates({ lat: newLat, lng: newLong });
    }
  };

  return (
    <StyledMap>
      <GoogleMap
        center={{ lat, lng }}
        zoom={zoom}
        mapContainerStyle={{ width: '100%', height: '100%' }}
        options={{
          zoomControl: true,
          streetViewControl: enableMapOptions,
          mapTypeControl: enableMapOptions,
          fullscreenControl: enableMapOptions,
          clickableIcons: false,
          draggable: isDraggable,
        }}
      >
        {(lat && lng) ? (
          <MarkerF
            draggable={isDraggable}
            onDragEnd={drag}
            position={{ lat, lng }}
          />
        ) : null}
      </GoogleMap>
    </StyledMap>
  );
}

export default GoogleMapContainer;
