/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';

import { styled } from '@mui/system';
import {
  Grid,
  TablePagination,
  TextField,
  InputAdornment,
} from '@mui/material';

import { AiOutlineSearch } from 'react-icons/ai';
import BackButton from '../../../Components/BasicComponents/BackButton';
import Content from '../../../Components/BasicComponents/Content';
import CampaignCard from './CampaignCard';

import { Campaign } from '../../../Services/CampaignsService';

const ButtonRow = styled('div', {})({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const StyledSearch = styled(TextField)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.common.white,
  width: '400px',
}));

const SearchRow = styled('div', {})({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  paddingBottom: '20px',
});

type Props = {
  goBack: () => void,
  campaigns: Campaign[],
  campaignAmount: number,
  getCampaigns: (param?: string) => void,
  category: string
};

function Campaigns(props: Props) {
  const {
    goBack,
    campaigns,
    campaignAmount,
    getCampaigns,
    category,
  } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [searchText, setSearchText] = useState<string>('');
  const [amount, setAmount] = useState<number>(campaignAmount);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Content>
      <ButtonRow>
        <BackButton onClick={goBack} />
      </ButtonRow>

      <SearchRow>
        <StyledSearch
          id="search-entry"
          label="Search by Business ID"
          variant="filled"
          color="primary"
          value={searchText}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            autoComplete: 'off',
            startAdornment: (
              <InputAdornment position="start">
                <AiOutlineSearch />
              </InputAdornment>
            ),
          }}
          onChange={(event) => {
            setSearchText(event.target.value.toUpperCase());
            const filtered = campaigns.filter((campaign) => campaign.businessId.toUpperCase()
              .includes(event.target.value.toUpperCase()));
            setAmount(filtered.length);
          }}
        />
      </SearchRow>

      <Grid container spacing={2}>
        {campaigns.filter((filteredCampaign) => filteredCampaign.businessId.includes(searchText))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((campaign) => (
            <Grid item xs={12} key={campaign.campaignId}>
              <CampaignCard campaignData={campaign} loadCampaigns={getCampaigns} />
            </Grid>
          ))}
      </Grid>

      {amount > 8 ? (
        <TablePagination
          rowsPerPageOptions={[8, 16, 32]}
          component="div"
          count={amount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}

    </Content>
  );
}

export default Campaigns;
