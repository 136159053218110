import React, { useState, useEffect } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  InputAdornment,
  Button,
} from '@mui/material';
import { styled } from '@mui/system';
import { AiOutlineSearch, AiFillPlusCircle } from 'react-icons/ai';

import CreateNewBusinessUser from './CreateNewBusinessUser';
import { Locations } from '../../../Services/LocationService';
import BusinessService from '../../../Services/BusinessService';
import LocationDialog from './LocationDialog';
import AdminService from '../../../Services/AdminService';
import EntryDialog from '../../../Components/BasicComponents/EntryDialog';
import BusinessRow, { BusinessRowData } from './BusinessRow';

const StyledContainer = styled('div')(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.primary.main,
  borderRadius: '5px',
}));

const StyledHeaderRow = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: '20px',
  paddingBottom: '20px',
  paddingLeft: '40px',
  paddingRight: '40px',
  backgroundColor: theme.palette.background.default,
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
}));

const StyledCreateButton = styled(Button)(({ theme }) => ({
  height: '45px',
  fontWeight: 'bold',
  backgroundColor: theme.palette.secondary.main,
  width: '300px',
}));

const StyledSearch = styled(TextField)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.common.white,
  width: '400px',
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  border: 1,
  borderColor: theme.palette.primary.main,
  maxHeight: '100%',
}));

const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  fontWeight: 700,
  textAlign: 'center',
}));

type Props = {
  fetchAdminUsers: () => void,
  tableHeaders: string[],
  tableItems: BusinessRowData[],
  handleErrorDialog: (title: string, message: string) => void;
};

function BusinessTable(props: Props) {
  const {
    fetchAdminUsers,
    tableItems,
    tableHeaders,
    handleErrorDialog,
  } = props;

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [emailSearchText, setEmailSearchText] = useState<string>('');
  const [businessSearchText, setBusinessSearchText] = useState<string>('');
  const [openBusinessCreate, setOpenBusinessCreate] = useState<boolean>(false);
  const [openBusinessEdit, setOpenBusinessEdit] = useState<boolean>(false);
  const [openLocationEdit, setOpenLocationEdit] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>('');
  const [userRole, setUserRole] = useState<string>('');
  const [userLocationAccess, setUserLocationAccess] = useState<string[]>([]);
  const [businessLocations, setBusinessLocations] = useState<Locations>({});
  const [rolesDescription, setRolesDescription] = useState<string>('');
  const [rolesOptions, setRolesOptions] = useState<string[]>([]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleBusinessEdit = async (
    email: string,
    role: string,
    businessId: string,
    locationAccess: string,
    uid: string,
  ) => {
    setUserRole(role);
    setUserId(uid);
    setUserLocationAccess(locationAccess.split(', '));

    try {
      const business = await BusinessService.getBusiness(businessId);
      if (business.Locations) setBusinessLocations(business.Locations);
      setOpenBusinessEdit(true);
    } catch (e:any) {
      handleErrorDialog(
        'Business does not Exist',
        `The business id ${businessId} does not belong to any business , please enter in a valid business ID.`,
      );
    }
  };

  const handleCancelEdit = () => {
    setUserRole('');
    setUserId('');
    setUserLocationAccess([]);
    setOpenBusinessEdit(false);
    setOpenLocationEdit(false);
  };

  const handleConfirmEdit = async () => {
    try {
      await AdminService.updateBusinessAccount(userId, userLocationAccess, userRole)
        .then(() => {
          setOpenLocationEdit(false);
          fetchAdminUsers();
        });
    } catch (e:any) {
      handleErrorDialog('Edit Error', e.message);
    }
  };

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const roles = await AdminService.getRolesByUserType('business_user');
        let description = '';
        const options: string[] = [];
        roles.forEach((role) => {
          options.push(role.role_id);
          description += `${role.role_id} : ${role.description}\n\n`;
        });
        setRolesDescription(description);
        setRolesOptions(options);
      } catch (e:any) {
        handleErrorDialog('Failed Fetching business roles', e.message);
      }
    };

    fetchRoles();
  }, []);

  return (
    <StyledContainer>
      <StyledHeaderRow>
        <StyledSearch
          id="search-entry"
          label="Search by Email Address"
          variant="outlined"
          color="primary"
          value={emailSearchText}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AiOutlineSearch />
              </InputAdornment>
            ),
            autoComplete: 'off',
          }}
          onChange={(event) => {
            setEmailSearchText(event.target.value);
            if (page !== 0) setPage(0);
          }}
        />

        <StyledSearch
          id="search-entry-business"
          label="Search by Business ID"
          variant="outlined"
          color="primary"
          value={businessSearchText}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AiOutlineSearch />
              </InputAdornment>
            ),
            autoComplete: 'off',
          }}
          onChange={(event) => {
            setBusinessSearchText(event.target.value.toUpperCase());
            if (page !== 0) setPage(0);
          }}
        />

        <StyledCreateButton
          aria-label="create"
          variant="contained"
          startIcon={<AiFillPlusCircle />}
          onClick={() => {
            setOpenBusinessCreate(true);
          }}
        >
          Create New Business User
        </StyledCreateButton>
      </StyledHeaderRow>

      <StyledTableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => (
                <StyledHeaderCell key={index}>{header}</StyledHeaderCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {tableItems.filter((tableRow) => tableRow.email.includes(emailSearchText)
              && tableRow.businessId.includes(businessSearchText))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((rowData, rowIndex) => (
                <BusinessRow
                  key={rowIndex}
                  fetchAdminUsers={fetchAdminUsers}
                  rowIndex={rowIndex}
                  rowItems={rowData}
                  handleBusinessEdit={handleBusinessEdit}
                />
              ))}
          </TableBody>
        </Table>
      </StyledTableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={(tableItems.filter((tableRow) => tableRow.email.includes(emailSearchText)
          && tableRow.businessId.includes(businessSearchText))).length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <CreateNewBusinessUser
        fetchAdminUsers={fetchAdminUsers}
        openCreateFlow={openBusinessCreate}
        setOpenCreateFlow={setOpenBusinessCreate}
      />

      {openBusinessEdit && (
        <EntryDialog
          dialogTitle="Edit account business Tier"
          dialogText={rolesDescription}
          open={openBusinessEdit}
          value={userRole}
          setValue={setUserRole}
          handleConfirm={() => {
            setOpenBusinessEdit(false);
            setOpenLocationEdit(true);
          }}
          handleCancel={handleCancelEdit}
          selectOptions={rolesOptions}
        />
      )}

      {openLocationEdit && (
        <LocationDialog
          isEdit
          selectedLocations={userLocationAccess}
          setSelectedLocations={setUserLocationAccess}
          locationData={businessLocations}
          open={openLocationEdit}
          handleConfirm={handleConfirmEdit}
          handleCancel={handleCancelEdit}
        />
      )}

    </StyledContainer>
  );
}

export default BusinessTable;
