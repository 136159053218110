import React, { useState, useEffect } from 'react';

import { FaUserLock } from 'react-icons/fa';
import EntryDialog from '../../../Components/BasicComponents/EntryDialog';
import ConfirmDialog from '../../../Components/BasicComponents/ConfirmDialog';
import ErrorDialog from '../../../Components/BasicComponents/ErrorDialog';

import BusinessService from '../../../Services/BusinessService';
import AdminService from '../../../Services/AdminService';

type Props = {
  fetchAdminUsers: () => void;
  userEmail: string,
  userId: string,
  userType: string,
  userRole: string,
  openEditFlow: boolean,
  setOpenEditFlow: (toggle: boolean) => void;
};

function EditUserPermissions(props: Props) {
  const {
    fetchAdminUsers,
    userEmail,
    userId,
    userType,
    userRole,
    openEditFlow,
    setOpenEditFlow,
  } = props;

  const [type, setType] = useState<string>('');
  const [openTypeEdit, setOpenTypeEdit] = useState<boolean>(false);
  const [userTypeOptions, setUserTypeOptions] = useState<string[]>([]);
  const [userTypeText, setUserTypeText] = useState<string>('');
  const [role, setRole] = useState<string>('');
  const [openRoleEdit, setOpenRoleEdit] = useState<boolean>(false);
  const [userRoleOptions, setUserRoleOptions] = useState<string[]>([]);
  const [userRoleText, setUserRoleText] = useState<string>('');
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);
  const [errorDialogTitle, setErrorDialogTitle] = useState<string>('');
  const [errorDialogMessage, setErrorDialogMessage] = useState<string>('');
  const [openBusiness, setOpenBusiness] = useState<boolean>(false);
  const [partnerId, setPartnerId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmText, setConfirmText] = useState<string>('');

  const handleCancel = () => {
    setType('');
    setRole('');
    setPartnerId('');
    setOpenEditFlow(false);
    setOpenTypeEdit(false);
    setOpenRoleEdit(false);
    setOpenConfirm(false);
    setOpenBusiness(false);
  };

  const handleErrorDialog = (title:string, message:string) => {
    setErrorDialogTitle(title);
    setErrorDialogMessage(message);
    setOpenErrorDialog(true);
  };

  const handleErrorDialogClose = () => {
    setOpenErrorDialog(false);
    setErrorDialogTitle('');
    setErrorDialogMessage('');
  };

  const getConfirmText = () => {
    let text = `User Email: ${userEmail}
    User ID: ${userId}
    User Type: ${type}
    User Role: ${role}\n`;

    if (partnerId !== '') {
      text += `Business ID: ${partnerId}`;
    }

    setConfirmText(text);
  };

  const parseAllUserRoles = async () => {
    try {
      const roles = await AdminService.getRolesByUserType(type);
      const options: string[] = [];
      let typeText: string = '';
      roles.forEach((r) => {
        if (r.role_id !== 'business_user') {
          options.push(r.role_id);
          typeText += `\n${r.role_id}: ${r.description}\n`;
        }
      });
      setUserRoleText(typeText);
      setUserRoleOptions(options);
    } catch (e:any) {
      handleErrorDialog('Cannot fetch user permissions', e.message);
    }
  };

  const parseAllUserTypes = async () => {
    try {
      const types = await AdminService.getUserTypes();
      const options: string[] = [];
      let typeText: string = '';
      types.forEach((t) => {
        if (t.user_types !== 'business_user') {
          options.push(t.user_types);
          typeText += `\n${t.user_types}: ${t.description}\n`;
        }
      });
      setUserTypeText(typeText);
      setUserTypeOptions(options);
    } catch (e:any) {
      handleErrorDialog('Cannot fetch user types', e.message);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      if (type === userType) await AdminService.updateUserRoleById(userId, role);
      else if (partnerId !== '') {
        await AdminService.updateUserTypeById(userId, type, role, partnerId);
      } else {
        await AdminService.updateUserTypeById(userId, type, role);
      }
    } catch (e:any) {
      handleErrorDialog('Cannot Save user details', e.message);
    }
    setLoading(false);
    handleCancel();
    fetchAdminUsers();
  };

  const handleEditConfirm = () => {
    setOpenEditFlow(false);
    setOpenTypeEdit(true);
  };

  const handleTypeConfirm = async () => {
    if (type !== userType && type !== 'no_access') setRole('none');
    // TODO handle business ID ENTRY
    await parseAllUserRoles();
    setOpenTypeEdit(false);
    if (userType !== 'business_user' && type === 'business_user') {
      setOpenBusiness(true);
    } else if (type !== 'no_access') {
      setOpenRoleEdit(true);
    } else {
      setOpenConfirm(true);
    }
  };

  const checkBusiness = async () => {
    setLoading(true);
    try {
      await BusinessService.getBusiness(partnerId);
      setOpenBusiness(false);
      setOpenRoleEdit(true);
    } catch (e:any) {
      handleErrorDialog(
        'Business does not Exist',
        `The business id ${partnerId} does not belong to any business , please enter in a valid business ID.`,
      );
    }
    setLoading(false);
  };

  const handleRoleConfirm = () => {
    getConfirmText();
    setOpenRoleEdit(false);
    setOpenConfirm(true);
  };

  useEffect(() => {
    parseAllUserTypes();
  }, []);

  useEffect(() => {
    setRole(userRole);
    setType(userType);
  }, [openEditFlow]);

  return (
    <>
      <ConfirmDialog
        open={openEditFlow}
        dialogTitle="Are you sure you want to edit this user?"
        dialogText={`User Email: ${userEmail}
          User ID: ${userId}
          User Type: ${userType}
          User Role: ${userRole}`}
        handleCancel={() => setOpenEditFlow(false)}
        handleConfirm={handleEditConfirm}
      />

      <EntryDialog
        dialogTitle="Please select a user type"
        dialogText={userTypeText}
        handleCancel={handleCancel}
        handleConfirm={handleTypeConfirm}
        open={openTypeEdit}
        value={type}
        setValue={setType}
        selectOptions={userTypeOptions}
        valueIcon={<FaUserLock />}
      />

      <EntryDialog
        dialogTitle="Business ID"
        dialogText="Please enter the business ID"
        handleCancel={handleCancel}
        handleConfirm={checkBusiness}
        open={openBusiness}
        value={partnerId}
        setValue={setPartnerId}
        valueIcon={<FaUserLock />}
        loading={loading}
      />

      <EntryDialog
        dialogTitle="Please select a user role"
        dialogText={userRoleText}
        handleCancel={handleCancel}
        handleConfirm={handleRoleConfirm}
        open={openRoleEdit}
        value={role}
        setValue={setRole}
        selectOptions={userRoleOptions}
        valueIcon={<FaUserLock />}
      />

      <ConfirmDialog
        open={openConfirm}
        dialogTitle="Are you sure you want to save these changes?"
        dialogText={confirmText}
        handleCancel={handleCancel}
        handleConfirm={handleSave}
        isLoading={loading}
      />

      <ErrorDialog
        open={openErrorDialog}
        dialogTitle={errorDialogTitle}
        dialogText={errorDialogMessage}
        handleClose={handleErrorDialogClose}
      />
    </>
  );
}

export default EditUserPermissions;
