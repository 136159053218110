import React, { useState } from 'react';

import { styled } from '@mui/system';
import {
  Card,
  CardContent,
  Collapse,
  IconButton,
  IconButtonProps,
  Grid,
  Typography,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  fontSize: '30px',
  color: theme.palette.common.white,
}));

const CardTitle = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary.main,
  height: '40px',
  [theme.breakpoints.down('sm')]: {
    height: '50px',
  },
}));

const CardTitleText = styled(Typography)(({ theme }) => ({
  alignSelf: 'center',
  color: theme.palette.common.white,
}));

const ExpandedContent = styled(CardContent)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  paddingLeft: '50px',
  paddingRight: '50px',
  [theme.breakpoints.down('sm')]: {
    paddingRight: '15px',
    paddingLeft: '15px',
  },
}));

const StyledImage = styled('img')(({ theme }) => ({
  width: '50%',
  boxShadow: '10px 10px 10px rgba(0,0,0,0.6)',
  marginBottom: '15px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    boxShadow: '5px 5px 5px rgba(0,0,0,0.4)',
  },
}));

type Props = {
  schemeId: string,
  image: string,
  max: number,
  name: string
  reward: string,
};

function BusinessSchemeDetailsCard(props: Props) {
  const {
    schemeId,
    image,
    max,
    name,
    reward,
  } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const HandleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card key={`Scheme ${schemeId}`}>
      <CardTitle>
        <CardTitleText variant="h4">{schemeId}</CardTitleText>
        <ExpandMore
          expand={isExpanded}
          onClick={HandleExpandClick}
          aria-expanded={isExpanded}
          aria-label="show more"
        >
          <MdExpandMore />
        </ExpandMore>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Scheme ID :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">{schemeId}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Name :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">{name}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Max Stamps :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">{max}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Reward :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">{reward}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Loyalty Card :</Typography>
            </Grid>
            {image ? (
              <Grid item container xs={12} sm={6} alignItems="center">
                <StyledImage src={image} alt="loyaltyCard" />
              </Grid>
            ) : (
              <Grid item container xs={6} alignItems="center">
                <span>Not Set!</span>
              </Grid>
            )}

          </Grid>
        </ExpandedContent>
      </Collapse>
    </Card>
  );
}

export default BusinessSchemeDetailsCard;
