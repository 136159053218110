const API_URL = process.env.REACT_APP_CAMPAIGN_API_URL;
// const API_URL = 'http://localhost:3000';

export type NotificationData = {
  body: string,
  title: string,
  header?: string,
  data?: {
    link: string,
    type: string,
    webLinkButtonTitle: string,
  }
  usersEmail?: string,
  description?: string,
  voucher?: boolean,
  voucherText?: string,
  rewardExpiryDays?: number,
};

export type Criteria = {
  schemeIds?: string[],
  locationIds?: string[],
  usagePercentile?: number,
  noUsageInPastXDays?: number,
  age?:{
    startAge?: string,
    endAge?: string
  },
};

export type NotificationUserData = {
  age: number,
  fcmToken: string,
  name: string,
  purchaseCount: number,
  uid: string,
  walletEmail: string,
  dateOfBirth: string,
  dateLastPurchase: string,
};

export type SentNotificationUserData = NotificationUserData & {
  sent_at: number,
  response: any,
};

export type Notification = {
  businessId: string,
  createdDateTime: string,
  createdEpoch: number
  criteria?: Criteria,
  notificationId: string,
  notification: NotificationData,
  numUsers: number,
  percentileCount: number,
  status: string,
  users: {
    [key: string]: NotificationUserData,
  },
  sendTime?: number,
};

export type Notifications = {
  [key: string]: Notification,
};

export type SentNotification = Omit<Notification, 'users'> & {
  businessName: string,
  sentAt: number,
  openedNotifications: number,
  failedDeliveries: number,
  successfulDeliveries: number,
  totalDeliveries: number,
  users: {
    [key: string]: SentNotificationUserData,
  },
};

export type SentNotifications = {
  [key: string]: SentNotification,
};

export type NotificationIds = {
  pending: string[],
  sent: string[],
  rejected: string[],
};

export type CalculatedReach = {
  calculatedReach: string,
  criteria: Criteria,
};

const getPendingNotificationAmount = () => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/notifications`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((json) => {
      if (json.pending.length > 0) {
        localStorage.setItem('notificationAmount', json.pending.length.toString());
        window.dispatchEvent(new Event('storage'));
        resolve(json);
      } else {
        localStorage.setItem('notificationAmount', '0');
        reject(json);
      }
      reject(json);
    })
    .catch((error) => {
      localStorage.setItem('notificationAmount', '0');
      reject(error);
    });
});

const getSentNotifications = (): Promise<SentNotifications> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/notifications/sent`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const getPendingNotifications = (): Promise<Notifications> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/notifications/pending`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const getScheduledNotifications = (): Promise<Notifications> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/notifications/scheduled`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const getRejectedNotifications = (): Promise<Notifications> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/notifications/rejected`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const approveNotification = (notificationId: string) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/notifications/approve`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ notificationId }),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const rejectNotification = (notificationId: string) => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/notifications/reject`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ notificationId }),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const estimateReach = (
  partnerId: string,
  criteria: Criteria,
): Promise<CalculatedReach> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/notifications/estimateReach`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ businessId: partnerId, criteria }),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const createNotification = (
  partnerId: string,
  notification: NotificationData,
  criteria: Criteria,
  sendTime: string,
): Promise<CalculatedReach> => new Promise((resolve, reject) => {
  const token = `Bearer ${localStorage.getItem('userToken')}`;
  fetch(`${API_URL}/notifications`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      businessId: partnerId,
      notification,
      criteria,
      sendTime,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(JSON.stringify(error.message));
        });
      }
      return response.json();
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const NotificationService = {
  getSentNotifications,
  getRejectedNotifications,
  getPendingNotifications,
  getScheduledNotifications,
  getPendingNotificationAmount,
  approveNotification,
  rejectNotification,
  estimateReach,
  createNotification,
};

export default NotificationService;
