import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

function PrivateRoutes() {
  const auth = localStorage.getItem('userToken');
  return (
    auth ? <Outlet /> : <Navigate to="/" />
  );
}

export default PrivateRoutes;
