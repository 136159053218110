import React, { useState } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Box,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/system';
import { BsArrowRightCircleFill } from 'react-icons/bs';
import { Results } from '../../../Services/UserService';

const StyledBox = styled(Box, {})({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  border: '1px solid',
  maxHeight: '510px',
  [theme.breakpoints.down('sm')]: {
    maxHeight: '400px',
  },
}));

const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  fontWeight: 700,
  textAlign: 'center',
}));

const StyledBodyCell = styled(TableCell, {})({
  textAlign: 'center',
  fontWeight: 'inherit',
  padding: '10px',
});

const StyledView = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.main,
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

type Props = {
  results: Results,
  searchForUser: (uid: string) => void,
};

function SearchResultsTable(props: Props) {
  const { results, searchForUser } = props;

  const tableHeaders = ['Name', 'Email', 'Uid', 'Gender', 'Date of Birth', 'Account Created', 'View User'];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <StyledBox>
      <StyledTableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => (
                <StyledHeaderCell key={index}>{header}</StyledHeaderCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((cell, rowIndex) => (
                <TableRow key={rowIndex}>
                  <StyledBodyCell key={`${rowIndex}-0`}>
                    {cell.name ? (
                      <Typography variant="body1">{cell.name}</Typography>
                    ) : (
                      <Typography variant="body1">Not Set</Typography>
                    )}
                  </StyledBodyCell>

                  <StyledBodyCell key={`${rowIndex}-1`}>
                    {cell.wallet_email ? (
                      <Typography variant="body1">{cell.wallet_email}</Typography>
                    ) : (
                      <Typography variant="body1">Not Set</Typography>
                    )}
                  </StyledBodyCell>

                  <StyledBodyCell key={`${rowIndex}-2`}>
                    {cell.uid ? (
                      <Typography variant="body1">{cell.uid}</Typography>
                    ) : (
                      <Typography variant="body1">Not Set</Typography>
                    )}
                  </StyledBodyCell>

                  <StyledBodyCell key={`${rowIndex}-3`}>
                    {cell.gender ? (
                      <Typography variant="body1">{cell.gender}</Typography>
                    ) : (
                      <Typography variant="body1">Not Set</Typography>
                    )}
                  </StyledBodyCell>

                  <StyledBodyCell key={`${rowIndex}-4`}>
                    {cell.date_of_birth ? (
                      <Typography variant="body1">{cell.date_of_birth.split('T')[0]}</Typography>
                    ) : (
                      <Typography variant="body1">Not Set</Typography>
                    )}
                  </StyledBodyCell>

                  <StyledBodyCell key={`${rowIndex}-5`}>
                    {cell.account_created ? (
                      <Typography variant="body1">{cell.account_created.split('T')[0]}</Typography>
                    ) : (
                      <Typography variant="body1">Not Set</Typography>
                    )}
                  </StyledBodyCell>

                  <StyledBodyCell key={`${rowIndex}-6`}>
                    <Tooltip title="View this User" placement="bottom">
                      <StyledView size="small" onClick={() => searchForUser(cell.uid)}>
                        <BsArrowRightCircleFill />
                      </StyledView>
                    </Tooltip>
                  </StyledBodyCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </StyledTableContainer>

      <TablePagination
        rowsPerPageOptions={[7, 14, 28]}
        component="div"
        count={results.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </StyledBox>
  );
}

export default SearchResultsTable;
