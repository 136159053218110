/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import TitleComponent from '../../Components/BasicComponents/TitleComponent';
import Content from '../../Components/BasicComponents/Content';
import NoPermissionPage from '../../Components/BasicComponents/NoPermissionPage';
import ErrorDialog from '../../Components/BasicComponents/ErrorDialog';

import PageLoadingSpinner from '../../Components/BasicComponents/PageLoadingSpinner';
import AdminService, { AdminUserData } from '../../Services/AdminService';
import SearchTable from './components/SearchTable';
import BusinessTable from './components/BusinessTable';
import { BusinessRowData } from './components/BusinessRow';

function AdminPage() {
  const [adminTableRows, setAdminTableRows] = useState<string[][]>([]);
  const [businessTableRows, setBusinessTableRows] = useState<BusinessRowData[]>([]);
  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);
  const [errorDialogTitle, setErrorDialogTitle] = useState<string>('');
  const [errorDialogMessage, setErrorDialogMessage] = useState<string>('');

  const canView = localStorage.getItem('userScopes')?.includes('auth:rolesAdmin:all');

  const adminTableHeaders = ['Email', 'Type', 'Role', 'User ID', 'Password Reset', 'Edit', 'Delete'];
  const businessTableHeaders = [
    'Email', 'Tier', 'Business ID', 'Location Access', 'User ID', 'Password Reset', 'Edit', 'Delete',
  ];

  const handleErrorDialog = (title:string, message:string) => {
    setErrorDialogTitle(title);
    setErrorDialogMessage(message);
    setOpenErrorDialog(true);
  };

  const handleErrorDialogClose = () => {
    setOpenErrorDialog(false);
    setErrorDialogTitle('');
    setErrorDialogMessage('');
  };

  const fetchAdminUsers = async () => {
    try {
      const userData:AdminUserData = await AdminService.getAllAdminUsers();
      if (userData) {
        const adminRows:string[][] = [];
        const businessRows:BusinessRowData[] = [];

        userData.users.forEach((user) => {
          if (user.user_type === 'business_user') {
            let businessId = 'Not Set';
            if (user.business_id) businessId = user.business_id;
            let role = 'basic';
            if (user.business_role_id) role = user.business_role_id;
            const locationAccess = user.locationAccess ? user.locationAccess.join(', ') : 'Not Set';
            const businessRow: BusinessRowData = {
              email: user.user_email,
              tier: role,
              businessId,
              locationAccess,
              userId: user.user_id,
            };
            businessRows.push(businessRow);
          } else {
            let role: string;
            if (user.user_type === 'app_user' && user.app_role_id) role = user.app_role_id;
            else if (
              (user.user_type === 'internal_user' || user.user_type === 'no_access')
              && user.internal_role_id
            ) role = user.internal_role_id;
            else role = 'No role assigned';

            const row = [user.user_email, user.user_type, role, user.user_id];
            adminRows.push(row);
          }
        });
        setAdminTableRows(adminRows);
        setBusinessTableRows(businessRows);
      }
    } catch (e:any) {
      handleErrorDialog('Cannot fetch users', e.message);
    }
  };

  useEffect(() => {
    if (canView) {
      fetchAdminUsers();
    }
  }, []);

  if (!canView) {
    return <NoPermissionPage />;
  }

  return (
    <Content>
      <TitleComponent text="Business Accounts" />

      {businessTableRows.length > 1 ? (
        <BusinessTable
          fetchAdminUsers={fetchAdminUsers}
          tableHeaders={businessTableHeaders}
          tableItems={businessTableRows}
          handleErrorDialog={handleErrorDialog}
        />
      ) : (
        <PageLoadingSpinner />
      )}

      <TitleComponent text="Admin Accounts" />
      {adminTableRows.length > 1 ? (
        <SearchTable
          fetchAdminUsers={fetchAdminUsers}
          tableHeaders={adminTableHeaders}
          tableItems={adminTableRows}
          isAdmin
          handleErrorDialog={handleErrorDialog}
        />
      ) : (
        <PageLoadingSpinner />
      )}

      <ErrorDialog
        open={openErrorDialog}
        dialogTitle={errorDialogTitle}
        dialogText={errorDialogMessage}
        handleClose={handleErrorDialogClose}
      />

    </Content>
  );
}

export default AdminPage;
