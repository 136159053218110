import React, { useEffect, useState } from 'react';

import { styled } from '@mui/system';
import {
  TextField, InputAdornment, Typography, Grid,
} from '@mui/material';
import { DateField } from '@mui/x-date-pickers/DateField';
import { LoadingButton } from '@mui/lab';
import { IoSearchOutline } from 'react-icons/io5';
import { Dayjs } from 'dayjs';

import Content from '../../../Components/BasicComponents/Content';
import TitleComponent from '../../../Components/BasicComponents/TitleComponent';
import SearchResultsTable from './SearchResultsTable';
import UserService, { Results } from '../../../Services/UserService';

const SearchContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  height: '55px',
  maxWidth: '150px',
  marginLeft: '15px',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    marginLeft: '0px',
    marginTop: '20px',
    height: '35px',
  },
}));

const StyledLoadingButton2 = styled(LoadingButton, {})({
  height: '55px',
});

const StyledWarningText = styled(Typography)(({ theme }) => ({
  color: theme.palette.warning.main,
}));

type Props = {
  updateUser: (id: string, user: {}) => void,
  userId: string,
  setUserId: (id: string) => void,
  queryParam: string | null;
};

function UserSearch(props: Props) {
  const {
    updateUser,
    userId,
    setUserId,
    queryParam,
  } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [dateOfBirth, setDateOfBirth] = useState<Dayjs>();
  const [searchResults, setSearchResults] = useState<Results>([]);
  const [searchError, setSearchError] = useState<boolean>(false);

  // handle the Search For User functionality
  const searchForUser = async (id:string) => {
    setLoading(true);
    try {
      const foundUserData:any = await UserService.getUser(id);

      if (foundUserData.userData !== null) {
        setUserId(id);
        if (error) setError(false);
        updateUser(id, foundUserData);
      } else {
        setError(true);
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  const searchByName = async () => {
    setSearchResults([]);
    setLoading(true);
    try {
      let searchParameters = '?';
      if (firstName !== '') searchParameters = `${searchParameters}firstName=${firstName}`;
      if (lastName !== '') {
        if (searchParameters === '') {
          searchParameters = `${searchParameters}lastName=${lastName}`;
        } else {
          searchParameters = `${searchParameters}&lastName=${lastName}`;
        }
      }
      if (email !== '') {
        if (searchParameters === '') {
          searchParameters = `${searchParameters}email=${email}`;
        } else {
          searchParameters = `${searchParameters}&email=${email}`;
        }
      }
      if (dateOfBirth !== undefined && dateOfBirth.isValid()) {
        if (searchParameters === '') {
          searchParameters = `${searchParameters}dateOfBirth=${dateOfBirth.format('YYYY-MM-DD')}`;
        } else {
          searchParameters = `${searchParameters}&dateOfBirth=${dateOfBirth.format('YYYY-MM-DD')}`;
        }
      }

      const results = await UserService.searchUser(searchParameters);

      if (results.length === 0) {
        setSearchError(true);
      }
      setSearchResults(results);
    } catch (e:any) {
      console.log(e.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (queryParam) {
      searchForUser(queryParam);
    }
  }, []);

  return (
    <Content>
      <TitleComponent text="Search For A User by ID" />
      <SearchContainer>
        <TextField
          autoComplete="off"
          fullWidth
          id="user-search"
          label="User ID"
          variant="outlined"
          color="primary"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IoSearchOutline />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ shrink: true }}
          error={error}
          helperText={error ? 'No user found with that ID' : ''}
          onChange={(event) => {
            if (error) setError(false);
            setUserId(event.target.value);
          }}
          onKeyDown={(key) => {
            if (key.key === 'Enter') searchForUser(userId);
          }}
        />

        <StyledLoadingButton
          loading={loading}
          color="primary"
          disabled={userId === ''}
          variant="contained"
          fullWidth
          onClick={() => { searchForUser(userId); }}
        >
          <Typography variant="body2">SEARCH</Typography>
        </StyledLoadingButton>
      </SearchContainer>

      <TitleComponent text="General Search For Users" />
      <Typography variant="body1">Search for a user by using one or more of the following fields</Typography>
      <Grid item container direction="column" xs={12} spacing={2} sx={{ paddingTop: '20px' }}>
        <Grid item container direction="row" xs={12} spacing={2}>
          <Grid item xs={6}>
            <TextField
              autoComplete="off"
              fullWidth
              id="user-first-name-search"
              label="First Name"
              variant="outlined"
              color="primary"
              error={searchError}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                if (searchError) setSearchError(false);
                setFirstName(event.target.value);
              }}
              onKeyDown={(key) => {
                if (key.key === 'Enter') searchByName();
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              autoComplete="off"
              fullWidth
              id="user-last-name-search"
              label="Last Name"
              variant="outlined"
              color="primary"
              InputLabelProps={{ shrink: true }}
              error={searchError}
              onChange={(event) => {
                if (searchError) setSearchError(false);
                setLastName(event.target.value);
              }}
              onKeyDown={(key) => {
                if (key.key === 'Enter') searchByName();
              }}
            />
          </Grid>
        </Grid>

        <Grid item container direction="row" xs={12} spacing={2}>
          <Grid item xs={6}>
            <TextField
              autoComplete="off"
              fullWidth
              id="user-search"
              label="User Email"
              variant="outlined"
              color="primary"
              error={searchError}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                if (searchError) setSearchError(false);
                setEmail(event.target.value);
              }}
              onKeyDown={(key) => {
                if (key.key === 'Enter') searchByName();
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <DateField
              label="Date of Birth"
              format="DD-MM-YYYY"
              value={dateOfBirth}
              onChange={(newValue) => {
                if (searchError) setSearchError(false);
                if (newValue !== null) setDateOfBirth(newValue);
                else setDateOfBirth(undefined);
              }}
              InputLabelProps={{ shrink: true }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: ((dateOfBirth !== undefined && !dateOfBirth.isValid()) || searchError),
                },
              }}
            />
          </Grid>

        </Grid>

        <Grid container item direction="row">
          <StyledLoadingButton2
            loading={loading}
            color="primary"
            disabled={
              lastName === ''
              && firstName === ''
              && email === ''
              && (dateOfBirth === undefined || !dateOfBirth.isValid())
            }
            variant="contained"
            fullWidth
            onClick={() => searchByName()}
          >
            <Typography variant="body2">SEARCH</Typography>
          </StyledLoadingButton2>
        </Grid>
      </Grid>

      {searchError ? (
        <Grid item container xs={12} justifyContent="center" sx={{ marginTop: '10px' }}>
          <StyledWarningText variant="body1">No Users found!</StyledWarningText>
        </Grid>
      ) : null}

      {searchResults.length > 0 ? (
        <Grid container item xs={12} sx={{ paddingTop: '20px' }}>
          <SearchResultsTable results={searchResults} searchForUser={searchForUser} />
        </Grid>
      ) : null}
    </Content>
  );
}

export default UserSearch;
