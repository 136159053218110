import React, { useState } from 'react';

import { AiFillIdcard } from 'react-icons/ai';
import { FaStoreAlt } from 'react-icons/fa';
import { GrMultiple } from 'react-icons/gr';
import { MdConfirmationNumber } from 'react-icons/md';

import EntryDialog from '../../../Components/BasicComponents/EntryDialog';
import PromoService, { TagPromoById, CodePromos } from '../../../Services/PromoService';
import TagPromoEdit from './TagPromoEdit';
import CodePromoEdit from './CodePromoEdit';
import BusinessService from '../../../Services/BusinessService';
import SchemeService, { ReturnedSchemeData } from '../../../Services/SchemeService';

type Props = {
  openCreate: boolean,
  isTagPromo: boolean,
  setOpenCreate: (toggle: boolean) => void,
  setSingleTagPromo: (tagPromo: TagPromoById) => void,
  setCodePromoData: (tagPromo: CodePromos) => void,
  setTagParam: (id: string) => void,
  setCodeParam: (id: string) => void,
  setIsBatch: (toggle: boolean) => void,
};

function PromoCreate(props: Props) {
  const {
    openCreate,
    isTagPromo,
    setOpenCreate,
    setSingleTagPromo,
    setCodePromoData,
    setTagParam,
    setCodeParam,
    setIsBatch,
  } = props;

  const [partnerId, setPartnerId] = useState<string>('');
  const [entryError, setEntryError] = useState<boolean>(false);
  const [entryErrorMessage, setEntryErrorMessage] = useState<string>('');
  const [schemeOptions, setSchemeOptions] = useState<string[]>([]);
  const [selectedScheme, setSelectedScheme] = useState<string>('');
  const [openScheme, setOpenScheme] = useState<boolean>(false);
  const [businessData, setBusinessData] = useState<any>({});
  const [businessLoading, setBusinessLoading] = useState<boolean>(false);
  const [schemeData, setSchemeData] = useState<ReturnedSchemeData>();
  const [openTagEdit, setOpenTagEdit] = useState<boolean>(false);
  const [openCodeEdit, setOpenCodeEdit] = useState<boolean>(false);
  const [openBatchSelect, setOpenBatchSelect] = useState<boolean>(false);
  const [openBatchEntry, setOpenBatchEntry] = useState<boolean>(false);
  const [codeCreateOption, setCodeCreateOption] = useState<string>('');
  const [batchAmount, setBatchAmount] = useState<string>('');
  const [openCodeEntry, setOpenCodeEntry] = useState<boolean>(false);
  const [couponCode, setCouponCode] = useState<string>('');

  const searchBusiness = async () => {
    setBusinessLoading(true);
    try {
      const business:any = await BusinessService.getBusiness(partnerId);
      if (business.Schemes && Object.keys(business.Schemes).length > 0) {
        setBusinessData(business);
        setSchemeOptions(Object.keys(business.Schemes));
        setOpenCreate(false);
        setOpenScheme(true);
      } else {
        setEntryError(true);
        setEntryErrorMessage('There are no Schemes tied to this business');
      }
    } catch (e:any) {
      setEntryError(true);
      setEntryErrorMessage(e.message);
    }
    setBusinessLoading(false);
  };

  const getScheme = async () => {
    try {
      const scheme:any = await SchemeService.getSpecificScheme(partnerId, selectedScheme);
      setSchemeData(scheme);
      setOpenScheme(false);
      setOpenTagEdit(true);
    } catch (e:any) {
      setEntryError(true);
      setEntryErrorMessage(e.message);
    }
  };

  const codeGetScheme = async () => {
    try {
      const scheme:any = await SchemeService.getSpecificScheme(partnerId, selectedScheme);
      setSchemeData(scheme);
      setOpenScheme(false);
      setOpenBatchSelect(true);
    } catch (e:any) {
      setEntryError(true);
      setEntryErrorMessage(e.message);
    }
  };

  const checkCode = async () => {
    try {
      await PromoService.getCodePromoById(couponCode);
      setEntryError(true);
      setEntryErrorMessage('That code already exists');
    } catch (e:any) {
      setOpenCodeEdit(true);
      setOpenCodeEntry(false);
    }
  };

  const parseBatchAmount = (amount: string) => {
    const setAmount = amount.replace(/\D+/g, '');
    setBatchAmount(setAmount);
    const number = parseInt(setAmount, 10);
    if (number < 1) {
      setEntryError(true);
      setEntryErrorMessage('Amount cannot be less than 1');
    }
    if (number > 200) {
      setEntryError(true);
      setEntryErrorMessage('Can not create more than 200 codes at a time');
    }
  };

  const parseCoupon = (code: string) => {
    setCouponCode(code.replace(/[^\w]/g, '').toUpperCase());
  };

  const cancelCreate = () => {
    setBusinessData({});
    setSchemeData(undefined);
    setPartnerId('');
    setSelectedScheme('');
    setBatchAmount('');
    setCouponCode('');
    setCodeCreateOption('');
    setOpenCreate(false);
    setOpenScheme(false);
    setOpenBatchSelect(false);
    setOpenBatchEntry(false);
    setOpenCodeEntry(false);
    setIsBatch(false);
  };

  return (
    <>
      <EntryDialog
        dialogTitle="Business ID"
        dialogText="Please enter in the business ID of the store you want this promotion tied too"
        handleCancel={() => {
          cancelCreate();
        }}
        handleConfirm={() => searchBusiness()}
        open={openCreate}
        setValue={setPartnerId}
        value={partnerId}
        valueIcon={<FaStoreAlt />}
        error={entryError}
        setError={setEntryError}
        errorMessage={entryErrorMessage}
        loading={businessLoading}
      />

      <EntryDialog
        dialogTitle="Scheme ID"
        dialogText="Please select the scheme you want this promotion tied too"
        handleCancel={() => {
          cancelCreate();
        }}
        handleConfirm={() => {
          if (isTagPromo) getScheme();
          else codeGetScheme();
        }}
        open={openScheme}
        setValue={setSelectedScheme}
        selectOptions={schemeOptions}
        value={selectedScheme}
        valueIcon={<AiFillIdcard />}
        error={entryError}
        setError={setEntryError}
        errorMessage={entryErrorMessage}
      />

      <EntryDialog
        dialogTitle="Single Code or Batch Code Creation"
        dialogText="A single code will allow you to enter in any code you'd like but is only done one at a time.
        Batch code creation will allow you to generate multiple codes at once but will have randomly generate codes."
        handleCancel={() => {
          cancelCreate();
        }}
        handleConfirm={() => {
          setOpenBatchSelect(false);
          if (codeCreateOption === 'single') {
            setOpenCodeEntry(true);
            setIsBatch(false);
          } else if (codeCreateOption === 'batch') {
            setOpenBatchEntry(true);
            setIsBatch(true);
          }
        }}
        open={openBatchSelect}
        setValue={setCodeCreateOption}
        selectOptions={['single', 'batch']}
        value={codeCreateOption}
        valueIcon={<GrMultiple />}
        error={entryError}
        setError={setEntryError}
        errorMessage={entryErrorMessage}
      />

      <EntryDialog
        dialogTitle="Batch Create Amount"
        dialogText="Please enter the amount of codes you wish to create"
        handleCancel={() => {
          cancelCreate();
        }}
        handleConfirm={() => {
          setOpenCodeEdit(true);
          setOpenBatchEntry(false);
        }}
        open={openBatchEntry}
        setValue={parseBatchAmount}
        value={batchAmount}
        valueIcon={<MdConfirmationNumber />}
        error={entryError}
        setError={setEntryError}
        errorMessage={entryErrorMessage}
      />

      <EntryDialog
        dialogTitle="Coupon Code"
        dialogText="Please enter code for the coupon"
        handleCancel={() => {
          cancelCreate();
        }}
        handleConfirm={checkCode}
        open={openCodeEntry}
        setValue={parseCoupon}
        value={couponCode}
        valueIcon={<MdConfirmationNumber />}
        error={entryError}
        setError={setEntryError}
        errorMessage={entryErrorMessage}
      />

      <TagPromoEdit
        businessData={businessData}
        schemeData={schemeData}
        open={openTagEdit}
        setOpen={setOpenTagEdit}
        isCreate
        partnerId={partnerId}
        schemeId={selectedScheme}
        cancelCreate={cancelCreate}
        setSingleTagPromo={setSingleTagPromo}
        setTagParam={setTagParam}
      />

      <CodePromoEdit
        key={new Date().getTime()}
        businessData={businessData}
        open={openCodeEdit}
        setOpen={setOpenCodeEdit}
        isCreate
        partnerId={partnerId}
        schemeId={selectedScheme}
        cancelCreate={cancelCreate}
        setCodePromoData={setCodePromoData}
        setCodeParam={setCodeParam}
        createBatch={batchAmount !== ''}
        batchAmount={batchAmount !== '' ? parseInt(batchAmount, 10) : 0}
        couponCode={couponCode}
      />
    </>
  );
}

export default PromoCreate;
