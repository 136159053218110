import React, { useState } from 'react';
import {
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  Button,
  Dialog,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { GiCancel, GiConfirmed } from 'react-icons/gi';
import { BsCloudUpload } from 'react-icons/bs';
import SavingChangesDialog from '../../../Components/BasicComponents/SavingChangesDialog';
import ConfirmDialog from '../../../Components/BasicComponents/ConfirmDialog';
import BusinessService from '../../../Services/BusinessService';
import ErrorDialog from '../../../Components/BasicComponents/ErrorDialog';

import DiscoveryService from '../../../Services/DiscoveryService';

const StyledTitle = styled(Typography, {})({
  alignSelf: 'center',
  paddingTop: '25px',
  paddingBottom: '10px',
});

const StyledImage = styled('img')(({ theme }) => ({
  width: '80%',
  boxShadow: '10px 10px 10px rgba(0,0,0,0.6)',
  marginBottom: '15px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    boxShadow: '5px 5px 5px rgba(0,0,0,0.4)',
  },
}));

const StyledUploadToggle = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isUploading',
}) <{ isUploading?: boolean }>(({ theme, isUploading }) => ({
  color: theme.palette.common.white,
  backgroundColor: isUploading ? theme.palette.warning.main : theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: isUploading ? theme.palette.warning.main : theme.palette.secondary.main,
  },
})) as typeof Button;

const StyledConfirm = styled(Button)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 'bold',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const StyledCancel = styled(Button)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 'bold',
  color: theme.palette.primary.white,
  backgroundColor: theme.palette.warning.main,
  '&:hover': {
    backgroundColor: theme.palette.warning.main,
  },
}));

type Props = {
  category: string,
  chainName: string,
  cover: string,
  description: string,
  loadBusinessDetails: (id: string) => void,
  logo: string,
  open: boolean,
  partnerId: string,
  setOpen: (toggle: boolean) => void;
  socialLinks?:{
    Facebook?: string,
    Instagram?: string,
    LinkedIn?: string,
    Twitter?: string,
    TikTok?: string,
    Website?: string,
  },
};

function BusinessEditDialog(props: Props) {
  const {
    category,
    chainName,
    cover,
    description,
    loadBusinessDetails,
    logo,
    open,
    partnerId,
    setOpen,
    socialLinks,
  } = props;

  const [newCategory, setNewCategory] = useState<string>(category);
  const [newName, setNewName] = useState<string>(chainName);
  const [newDescription, setNewDescription] = useState<string>(description);
  const [newFacebookLink, setNewFacebookLink] = useState<string>(socialLinks?.Facebook ? socialLinks.Facebook : '');
  const [newInstagramLink, setNewInstagramLink] = useState<string>(socialLinks?.Instagram ? socialLinks.Instagram : '');
  const [newLinkedinLink, setNewLinkedinLink] = useState<string>(socialLinks?.LinkedIn ? socialLinks.LinkedIn : '');
  const [newTwitterLink, setNewTwitterLink] = useState<string>(socialLinks?.Twitter ? socialLinks.Twitter : '');
  const [newTiktokLink, setNewTiktokLink] = useState<string>(socialLinks?.TikTok ? socialLinks.TikTok : '');
  const [newWebsiteLink, setNewWebsiteLink] = useState<string>(socialLinks?.Website ? socialLinks.Website : '');
  const [hasNewLogo, setHasNewLogo] = useState<boolean>(false);
  const [hasNewCover, setHasNewCover] = useState<boolean>(false);
  // image preview and upload , index 0 for logo , index 1 for cover image
  const [imagePreview, setImagePreview] = useState<string[]>([...Array(2)]);
  const [imageUpload, setImageUpload] = useState<File[]>([...Array(2)]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');

  const allCategories = [
    'Activities',
    'Automotive',
    'Bakery',
    'Bar',
    'Barber',
    'Cafe',
    'Cinema',
    'Classes + Activities',
    'Coffee + Bites',
    'Coffee + Lunch',
    'Entertainment',
    'Florist',
    'Gift Shop',
    'Grocery',
    'Gym',
    'Hair Dresser',
    'Juices + Smoothies',
    'Leisure',
    'Lunch',
    'Lunch Spots',
    'Nightlife',
    'Pub',
    'Restaurant',
    'Salon',
    'Shopping',
    'Spa',
    'Speciality Coffee',
    'Street Food',
    'Sustainable Store',
    'Tasty Treats',
    'Theatre',
  ];

  const handleClose = () => {
    if (newCategory !== category) setNewCategory(category);
    if (newName !== chainName) setNewName(chainName);
    if (description !== newDescription) setNewDescription(description);
    setImagePreview([...Array(2)]);
    setImageUpload([...Array(2)]);
    setHasNewLogo(false);
    setHasNewCover(false);
    setIsConfirm(false);
    setIsSaving(false);
    setOpenError(false);
    setErrorText('');
    setOpen(false);
  };

  const uploadImages = async () => {
    const urlArray:string[] = [...Array(2)];
    if (imagePreview[0] !== undefined) {
      try {
        const res: any = await BusinessService.uploadImage(partnerId, imageUpload[0], 'logo');
        urlArray[0] = res.url;
      } catch (e:any) {
        setOpenError(true);
        setErrorText(e.message);
      }
    }

    if (imagePreview[1] !== undefined) {
      try {
        const res: any = await BusinessService.uploadImage(partnerId, imageUpload[1], 'cover');
        urlArray[1] = res.url;
      } catch (e:any) {
        setOpenError(true);
        setErrorText(e.message);
      }
    }

    return urlArray;
  };

  const previewImage = (file: File, isLogo: boolean) => {
    const image = URL.createObjectURL(file);
    if (isLogo) {
      imagePreview[0] = image;
      imageUpload[0] = file;
      setHasNewLogo(true);
    } else {
      imagePreview[1] = image;
      imageUpload[1] = file;
      setHasNewCover(true);
    }
  };

  const handleEdit = async () => {
    setIsSaving(true);
    const newImages = await uploadImages();
    const basicFields = {
      Category: newCategory,
      'Chain Name': newName,
      Cover: newImages[1] !== '' ? newImages[1] : cover,
      Description: newDescription,
      Logo: newImages[0] !== '' ? newImages[0] : logo,
      'Social Links': {
        ...(newFacebookLink) && { Facebook: newFacebookLink },
        ...(newInstagramLink) && { Instagram: newInstagramLink },
        ...(newLinkedinLink) && { LinkedIn: newLinkedinLink },
        ...(newTwitterLink) && { Twitter: newTwitterLink },
        ...(newTiktokLink) && { TikTok: newTiktokLink },
        ...(newWebsiteLink) && { Website: newWebsiteLink },
      },
    };

    try {
      BusinessService.updateBusiness(partnerId, basicFields)
        .then(async () => {
          await DiscoveryService.resetCache();
          loadBusinessDetails(partnerId);
          setIsSaving(false);
          handleClose();
        });
    } catch (e: any) {
      setOpenError(true);
      setErrorText(e.message);
    }
  };

  const handleClosed = (event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick' && 'escapeKeyDown') return;
    handleClose();
  };

  return (
    <Dialog
      aria-label="business-edit-dialog"
      onClose={(event, reason) => handleClosed(event, reason)}
      open={open}
      fullWidth
      maxWidth="md"
      sx={{ zIndex: '3000' }}
    >
      <StyledTitle variant="h1">Edit Basic Business Information</StyledTitle>
      <DialogContent>
        <Grid container spacing={4}>

          <Grid item xs={12}>
            <Divider><Typography variant="body2">Basic Information</Typography></Divider>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="chain-name-entry"
              label="Business Name"
              variant="outlined"
              color="primary"
              value={newName}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                setNewName(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              id="business-description-entry"
              label="Business Description"
              variant="outlined"
              color="primary"
              value={newDescription}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                setNewDescription(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              select
              id="business-category-entry"
              label="Business Category"
              variant="outlined"
              color="primary"
              value={newCategory}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                setNewCategory(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
            >
              {allCategories.map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <Divider><Typography variant="body2">Images</Typography></Divider>
          </Grid>

          {hasNewLogo ? (
            <Grid item xs={12}>
              <StyledCancel
                variant="contained"
                startIcon={<GiCancel />}
                fullWidth
                onClick={() => {
                  setHasNewLogo(false);
                  imagePreview[0] = '';
                }}
              >
                <Typography variant="body2">Cancel New Logo Upload</Typography>
              </StyledCancel>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <StyledUploadToggle
                variant="contained"
                component="label"
                fullWidth
                startIcon={<BsCloudUpload />}
              >
                <Typography variant="body2">Upload New Logo Image</Typography>
                <input
                  type="file"
                  hidden
                  accept=".jpg, .jpeg, .png, .pdf"
                  onChange={(event) => {
                    if (event.target.files && event.target.files !== null) {
                      previewImage(event.target.files[0], true);
                    }
                  }}
                />
              </StyledUploadToggle>
            </Grid>
          )}

          {hasNewLogo ? (
            <Grid container item xs={12} justifyContent="center">
              <StyledImage src={imagePreview[0]} />
            </Grid>
          ) : (
            <Grid container item xs={12} justifyContent="center">
              <StyledImage src={logo} />
            </Grid>
          )}

          {hasNewCover ? (
            <Grid item xs={12}>
              <StyledCancel
                variant="contained"
                startIcon={<GiCancel />}
                fullWidth
                onClick={() => {
                  setHasNewCover(false);
                  imagePreview[1] = '';
                }}
              >
                <Typography variant="body2">Cancel New Cover Upload</Typography>
              </StyledCancel>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <StyledUploadToggle
                variant="contained"
                component="label"
                fullWidth
                startIcon={<BsCloudUpload />}
              >
                <Typography variant="body2">Upload New Cover Image</Typography>
                <input
                  type="file"
                  hidden
                  accept=".jpg, .jpeg, .png, .pdf"
                  onChange={(event) => {
                    if (event.target.files && event.target.files !== null) {
                      previewImage(event.target.files[0], false);
                    }
                  }}
                />
              </StyledUploadToggle>
            </Grid>
          )}

          {hasNewCover ? (
            <Grid container item xs={12} justifyContent="center">
              <StyledImage src={imagePreview[1]} />
            </Grid>
          ) : (
            <Grid container item xs={12} justifyContent="center">
              <StyledImage src={cover} />
            </Grid>
          )}

          <Grid item xs={12}>
            <Divider><Typography variant="body2">Social Links</Typography></Divider>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="business-facebook-entry"
              label="Facebook Link"
              variant="outlined"
              color="primary"
              value={newFacebookLink}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                setNewFacebookLink(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="business-instagram-entry"
              label="Instagram Link"
              variant="outlined"
              color="primary"
              value={newInstagramLink}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                setNewInstagramLink(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="business-linkedin-entry"
              label="LinkedIn Link"
              variant="outlined"
              color="primary"
              value={newLinkedinLink}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                setNewLinkedinLink(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="business-twitter-entry"
              label="Twitter Link"
              variant="outlined"
              color="primary"
              value={newTwitterLink}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                setNewTwitterLink(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="business-tiktok-entry"
              label="TikTok Link"
              variant="outlined"
              color="primary"
              value={newTiktokLink}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                setNewTiktokLink(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="business-website-entry"
              label="Website Link"
              variant="outlined"
              color="primary"
              value={newWebsiteLink}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                setNewWebsiteLink(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
            />
          </Grid>

        </Grid>
      </DialogContent>

      <DialogActions>
        <StyledCancel
          variant="contained"
          fullWidth
          startIcon={<GiCancel />}
          onClick={handleClose}
        >
          <Typography variant="body2">CANCEL</Typography>
        </StyledCancel>
        <StyledConfirm
          variant="contained"
          fullWidth
          startIcon={<GiConfirmed />}
          onClick={() => setIsConfirm(true)}
        >
          <Typography variant="body2">CONFIRM</Typography>
        </StyledConfirm>
      </DialogActions>

      <SavingChangesDialog
        dialogTitle={`Saving changes to ${newName}`}
        open={isSaving}
      />
      <ConfirmDialog
        dialogTitle="Save Changes"
        dialogText={`Are you sure you wish to save these changes to ${newName}`}
        open={isConfirm}
        handleConfirm={() => {
          setIsConfirm(false);
          handleEdit();
        }}
        handleCancel={() => setIsConfirm(false)}
      />

      <ErrorDialog
        open={openError}
        dialogTitle={errorText}
        handleClose={() => {
          setOpenError(false);
          setErrorText('');
        }}
      />

    </Dialog>
  );
}

export default BusinessEditDialog;
