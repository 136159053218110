import React from 'react';

import PaginationTable from '../../../Components/BasicComponents/PaginationTable';

type Props = {
  activityLog: any;
};

function ActivityTable(props :Props) {
  const { activityLog } = props;

  const headers = ['Type', 'Chain', 'Date', 'Time', 'Offline'];
  const title = 'Activity Log';
  const rows:string[][] = [];

  // convert interaction log into a String[][] to be displayed on a table
  Object.entries(activityLog).forEach((entry) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [timeStamp, data]:any = entry;

    if (data.Scheme) {
      const type = String(data.Type);
      const chain = String(data.Location.split('-')[0]);
      const date = String(data['Date Time'].split(' ')[0]);
      const time = String(data['Date Time'].split(' ')[1]);
      const offline = data.Offline === true ? 'yes' : 'no';

      const row = [type, chain, date, time, offline];
      rows.push(row);
    } else {
      const type = String(data.Type);
      const chain = String(data.Chain);
      const date = String(data.Date);
      const time = String(data.Time);
      const offline = data.Offline === true ? 'yes' : 'no';

      const row = [type, chain, date, time, offline];
      rows.push(row);
    }
  });

  // activity log starts at first entry log. Reversing array to show most recent log first
  rows.reverse();

  return (
    <PaginationTable tableItems={rows} tableHeaders={headers} title={title} />
  );
}

export default ActivityTable;
