import React, { useState } from 'react';

import { styled } from '@mui/system';
import {
  Card,
  CardContent,
  Collapse,
  IconButton,
  IconButtonProps,
  Grid,
  Divider,
  Typography,
  Tooltip,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import {
  FaLinkedin,
  FaFacebookSquare,
  FaInstagramSquare, FaTwitterSquare,
  FaElementor,
  FaTiktok,
  FaEdit,
} from 'react-icons/fa';
import IconLinkButton from '../../../Components/BasicComponents/IconLinkButton';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  fontSize: '30px',
  color: theme.palette.common.white,
}));

const CardTitle = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary.main,
  height: '40px',
  [theme.breakpoints.down('sm')]: {
    height: '50px',
  },
}));

const CardTitleText = styled(Typography)(({ theme }) => ({
  alignSelf: 'center',
  color: theme.palette.common.white,
}));

const ExpandedContent = styled(CardContent)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  paddingLeft: '50px',
  paddingRight: '50px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
}));

const StyledEditButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

const StyledImage = styled('img', {
  shouldForwardProp: (prop) => prop !== 'isCover',
}) <{ isCover?: boolean }>(({ theme, isCover }) => ({
  width: isCover ? '80%' : '50%',
  marginBottom: '15px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    boxShadow: '5px 5px 5px rgba(0,0,0,0.4)',
  },
}));

type Props = {
  category: string,
  chainName: string,
  coverImage: string,
  description: string,
  logo: string,
  socialLinks?:{
    Facebook?: string,
    Instagram?: string,
    LinkedIn?: string,
    Twitter?: string,
    TikTok?: string,
    Website?: string,
  },
  setOpenEdit: (toggle: boolean) => void,
  canEdit: boolean,
};

function BusinessInfoCard(props: Props) {
  const {
    category,
    chainName,
    coverImage,
    description,
    logo,
    socialLinks,
    setOpenEdit,
    canEdit,
  } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const HandleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const iconArray = {
    Facebook: <FaFacebookSquare />,
    Instagram: <FaInstagramSquare />,
    LinkedIn: <FaLinkedin />,
    Twitter: <FaTwitterSquare />,
    TikTok: <FaTiktok />,
    Website: <FaElementor />,
  };

  return (
    <Card key={chainName}>
      <CardTitle>
        <CardTitleText variant="h4">Basic Information</CardTitleText>
        <div>
          <Tooltip title="Edit Basic Info" placement="bottom">
            <StyledEditButton disabled={!canEdit} size="small" onClick={() => setOpenEdit(true)}>
              <FaEdit />
            </StyledEditButton>
          </Tooltip>
          <ExpandMore
            expand={isExpanded}
            onClick={HandleExpandClick}
            aria-expanded={isExpanded}
            aria-label="show more"
          >
            <MdExpandMore />
          </ExpandMore>
        </div>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Chain Name :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">{chainName}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Category :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">{category}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Description :</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>{description}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2">Logo & Cover Image:</Typography>
            </Grid>

            <Grid item container xs={12} sm={6} alignItems="center" justifyContent="center">
              <StyledImage src={logo} alt="logo" />
            </Grid>
            <Grid item container xs={12} sm={6} alignItems="center" justifyContent="center">
              <StyledImage isCover src={coverImage} alt="cover" />
            </Grid>

            <Grid item xs={12}>
              <Divider light />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Social Media Links :</Typography>
            </Grid>

            {socialLinks ? (
              <>
                {Object.entries(socialLinks).map((link) => {
                  let icon;
                  if (link[0] === 'Facebook') icon = iconArray.Facebook;
                  if (link[0] === 'Instagram') icon = iconArray.Instagram;
                  if (link[0] === 'LinkedIn') icon = iconArray.LinkedIn;
                  if (link[0] === 'Twitter') icon = iconArray.Twitter;
                  if (link[0] === 'TikTok') icon = iconArray.TikTok;
                  if (link[0] === 'Website') icon = iconArray.Website;

                  return (
                    <Grid key={link[0]} item xs={12}>
                      <IconLinkButton key={link[0]} text={link[0]} link={link[1]} icon={icon} />
                    </Grid>
                  );
                })}
              </>
            ) : (
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">No Links Configured</Typography>
              </Grid>
            )}

          </Grid>
        </ExpandedContent>
      </Collapse>
    </Card>
  );
}

export default BusinessInfoCard;
