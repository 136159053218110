import React from 'react';
import { styled } from '@mui/system';

const ContentDiv = styled('div', {})({
  height: '100vh',
  width: '100%',
  overflowY: 'scroll',
});

const ShadowContainer = styled('div', {})({
  padding: '15px',
});

const ContentContainer = styled('div', {})({
  padding: '15px',
  boxShadow: '0px 0px 20px rgba(0,0,0,0.25)',
  borderRadius: '7px',
  position: 'relative',
});

// main container used for all our pages.
function Content({ children }:any) {
  return (
    <ContentDiv>
      <ShadowContainer>
        <ContentContainer>
          {children || 'Page not found'}
        </ContentContainer>
      </ShadowContainer>
    </ContentDiv>
  );
}

export default Content;
