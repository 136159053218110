import React, { useState } from 'react';

import { styled } from '@mui/system';
import {
  Button,
  Typography,
  Grid,
} from '@mui/material';
import { MdCancelPresentation, MdOutlinePendingActions } from 'react-icons/md';
import { AiOutlineSchedule } from 'react-icons/ai';
import { RiChatNewFill } from 'react-icons/ri';
import { FaStoreAlt, FaRegEnvelopeOpen } from 'react-icons/fa';
import SavingChangesDialog from '../../../Components/BasicComponents/SavingChangesDialog';
import Content from '../../../Components/BasicComponents/Content';
import TitleComponent from '../../../Components/BasicComponents/TitleComponent';
import EntryDialog from '../../../Components/BasicComponents/EntryDialog';
import NotificationCreate from './NotificationCreate';

import BusinessService from '../../../Services/BusinessService';
import AlertDialog from '../../../Components/BasicComponents/AlertDialog';

const StyledGetAllButton = styled(Button)(({ theme }) => ({
  height: '55px',
  fontSize: '18px',
  fontWeight: 'bold',
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
  [theme.breakpoints.down('sm')]: {
    height: '35px',
  },
}));

const StyledCreateButton = styled(Button)(({ theme }) => ({
  height: '55px',
  fontSize: '18px',
  fontWeight: 'bold',
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
  [theme.breakpoints.down('sm')]: {
    height: '35px',
  },
}));

type Props = {
  getPending: () => void,
  getRejected: () => void,
  getSent: () => void,
  getScheduled: () => void,
  loading: boolean,
  openAlert: boolean,
  setOpenAlert: (open: boolean) => void,
};

function NotificationSearch(props: Props) {
  const {
    getPending,
    getRejected,
    getSent,
    getScheduled,
    loading,
    openAlert,
    setOpenAlert,
  } = props;

  const [openBusinessEntry, setOpenBusinessEntry] = useState<boolean>(false);
  const [businessLoading, setBusinessLoading] = useState<boolean>(false);
  const [partnerId, setPartnerId] = useState<string>('');
  const [entryError, setEntryError] = useState<boolean>(false);
  const [entryErrorMessage, setEntryErrorMessage] = useState<string>('');
  const [businessData, setBusinessData] = useState<any>({});
  const [openCreate, setOpenCreate] = useState<boolean>(false);

  const cancelCreate = () => {
    setOpenBusinessEntry(false);
    setPartnerId('');
    setEntryError(false);
    setEntryErrorMessage('');
  };

  const searchBusiness = async () => {
    setBusinessLoading(true);
    try {
      const business:any = await BusinessService.getBusiness(partnerId);
      if (business.Schemes && Object.keys(business.Schemes).length > 0) {
        setBusinessData(business);
        setOpenBusinessEntry(false);
        setOpenCreate(true);
      } else {
        setEntryError(true);
        setEntryErrorMessage('There are no Schemes tied to this business');
      }
    } catch (e:any) {
      setEntryError(true);
      setEntryErrorMessage(e.message);
    }
    setBusinessLoading(false);
  };

  return (
    <Content>
      <TitleComponent text="Notifications" />
      <Grid container spacing={2} sx={{ marginTop: '10px' }}>
        <Grid item xs={6}>
          <StyledGetAllButton
            aria-label="Get All Pending Notifications"
            variant="contained"
            fullWidth
            startIcon={<MdOutlinePendingActions />}
            onClick={() => getPending()}
          >
            <Typography variant="body2">Pending</Typography>
          </StyledGetAllButton>
        </Grid>

        <Grid item xs={6}>
          <StyledGetAllButton
            aria-label="Get All Sent Notifications"
            variant="contained"
            fullWidth
            startIcon={<FaRegEnvelopeOpen />}
            onClick={() => getSent()}
          >
            <Typography variant="body2">Sent</Typography>
          </StyledGetAllButton>
        </Grid>

        <Grid item xs={6}>
          <StyledGetAllButton
            aria-label="Get All Scheduled Notifications"
            variant="contained"
            fullWidth
            startIcon={<AiOutlineSchedule />}
            onClick={() => getScheduled()}
          >
            <Typography variant="body2">Scheduled</Typography>
          </StyledGetAllButton>
        </Grid>

        <Grid item xs={6}>
          <StyledGetAllButton
            aria-label="Get All Rejected Notifications"
            variant="contained"
            fullWidth
            startIcon={<MdCancelPresentation />}
            onClick={() => getRejected()}
          >
            <Typography variant="body2">Rejected</Typography>
          </StyledGetAllButton>
        </Grid>

        <Grid item xs={12}>
          <StyledCreateButton
            aria-label="promo-code-create"
            variant="contained"
            fullWidth
            startIcon={<RiChatNewFill />}
            onClick={() => setOpenBusinessEntry(true)}
          >
            <Typography variant="body2">Create New Notification</Typography>
          </StyledCreateButton>
        </Grid>
      </Grid>

      <EntryDialog
        dialogTitle="Business ID"
        dialogText="Please enter in the business ID of the store you want this promotion tied too"
        handleCancel={() => {
          cancelCreate();
        }}
        handleConfirm={() => searchBusiness()}
        open={openBusinessEntry}
        setValue={setPartnerId}
        value={partnerId}
        valueIcon={<FaStoreAlt />}
        error={entryError}
        setError={setEntryError}
        errorMessage={entryErrorMessage}
        loading={businessLoading}
      />

      {openCreate ? (
        <NotificationCreate
          key={new Date().getTime()}
          open={openCreate}
          setOpen={setOpenCreate}
          partnerId={partnerId}
          businessData={businessData}
          cancelCreate={cancelCreate}
          getPending={getPending}
        />
      ) : null}

      <SavingChangesDialog
        open={loading}
        dialogTitle="Loading Notifications..."
      />

      {openAlert && (
        <AlertDialog
          dialogTitle="No Notifications Found"
          open={openAlert}
          setOpen={setOpenAlert}
          handleOk={() => {
            setOpenAlert(false);
          }}
        />
      )}

    </Content>
  );
}

export default NotificationSearch;
