/* eslint-disable @typescript-eslint/indent */
import React, { useState, useEffect } from 'react';

import { styled } from '@mui/system';
import {
  Grid,
  TablePagination,
  TextField,
  InputAdornment,
} from '@mui/material';
import { AiOutlineSearch } from 'react-icons/ai';

import Content from '../../../Components/BasicComponents/Content';
import TitleComponent from '../../../Components/BasicComponents/TitleComponent';
import BackButton from '../../../Components/BasicComponents/BackButton';
import PendingCard from './PendingCard';
import ConfirmDialog from '../../../Components/BasicComponents/ConfirmDialog';
import ErrorDialog from '../../../Components/BasicComponents/ErrorDialog';
import SavingChangesDialog from '../../../Components/BasicComponents/SavingChangesDialog';
import NotificationService, { Notifications } from '../../../Services/NotificationService';
import AlertDialog from '../../../Components/BasicComponents/AlertDialog';

const StyledSearch = styled(TextField)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.common.white,
  justifySelf: 'center',
  width: '400px',
}));

type Props = {
  pendingNotifications: Notifications,
  loadPending: () => void,
  goBack: () => void,
  openAlert: boolean,
  setOpenAlert: (open: boolean) => void,
};

function PendingNotifications(props: Props) {
  const {
    pendingNotifications,
    loadPending,
    goBack,
    openAlert,
    setOpenAlert,
  } = props;

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(4);
  const [searchText, setSearchText] = useState<string>('');
  const [amount, setAmount] = useState<number>(Object.keys(pendingNotifications).length);
  const [selectedId, setSelectedId] = useState<string>('');
  const [openApprove, setOpenApprove] = useState<boolean>(false);
  const [openReject, setOpenReject] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const [openError, setOpenError] = useState<boolean>(false);
  const [openLoading, setOpenLoading] = useState<boolean>(false);

  useEffect(() => {
    setErrorText('');
    setOpenError(false);
    setAmount(Object.keys(pendingNotifications).length);
    NotificationService.getPendingNotificationAmount();
  }, [pendingNotifications]);

  // TODO
  // const permissions = ['loyalty:partners:update', 'loyalty:partners:all'];
  // const canEdit = permissions.some((condition) => localStorage.getItem('userScopes')?.includes(condition));

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startApprove = (notificationId: string) => {
    setSelectedId(notificationId);
    setOpenApprove(true);
  };

  const startReject = (notificationId: string) => {
    setSelectedId(notificationId);
    setOpenReject(true);
  };

  const approve = async () => {
    const id = selectedId;
    setSelectedId('');
    setOpenLoading(true);
    try {
      await NotificationService.approveNotification(id);
      await loadPending();
      setOpenLoading(false);
    } catch (e: any) {
      setOpenApprove(false);
      setOpenError(true);
      setErrorText(e.message);
    }
  };

  const reject = async () => {
    const id = selectedId;
    setSelectedId('');
    setOpenLoading(true);
    try {
      await NotificationService.rejectNotification(id);
      await loadPending();
      setOpenLoading(false);
    } catch (e: any) {
      setOpenReject(false);
      setOpenError(true);
      setErrorText(e.message);
    }
  };

  return (
    <Content>
      <Grid container>
        <Grid item xs={3}>
          <BackButton onClick={goBack} />
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
          <StyledSearch
            id="search-entry"
            label="Search by Business ID"
            variant="filled"
            color="primary"
            value={searchText}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              autoComplete: 'off',
              startAdornment: (
                <InputAdornment position="start">
                  <AiOutlineSearch />
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              if (page > 0) setPage(0);
              setSearchText(event.target.value.toUpperCase());
              const keys = Object.keys(pendingNotifications)
                .filter((key) => pendingNotifications[key].businessId
                  && pendingNotifications[key].businessId.toUpperCase().includes(event.target.value.toUpperCase()));
              setAmount(keys.length);
            }}
          />
        </Grid>
      </Grid>

      <TitleComponent text="Pending Notifications" />
      <Grid item container spacing={2} xs={12}>
        {Object.keys(pendingNotifications)
          .filter((key) => pendingNotifications[key].businessId
            && pendingNotifications[key].businessId.toUpperCase().includes(searchText))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((key) => (
            <Grid item xs={6} key={key}>
              <PendingCard
                startApprove={startApprove}
                startReject={startReject}
                notificationId={key}
                pendingData={pendingNotifications[key]}
              />
            </Grid>
          ))}
      </Grid>

      <TablePagination
        rowsPerPageOptions={[4, 8, 16]}
        component="div"
        count={amount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {selectedId !== '' ? (
        <ConfirmDialog
          dialogTitle={`Approve this Notification for ${pendingNotifications[selectedId].businessId}`}
          dialogText={`Title : ${pendingNotifications[selectedId].notification.title}\n
            Body : ${pendingNotifications[selectedId].notification.body}\n
            ${pendingNotifications[selectedId].notification.data
          ? `Link : ${pendingNotifications[selectedId].notification.data?.link}`
          : ''}`}
          open={openApprove}
          handleCancel={() => {
            setOpenApprove(false);
            setSelectedId('');
          }}
          handleConfirm={() => {
            setOpenApprove(false);
            approve();
          }}
        />
      ) : null}

      {selectedId !== '' ? (
        <ConfirmDialog
          dialogTitle={`Reject this Notification for ${pendingNotifications[selectedId].businessId}`}
          dialogText={`Title : ${pendingNotifications[selectedId].notification.title}\n
            Body : ${pendingNotifications[selectedId].notification.body}\n
            ${pendingNotifications[selectedId].notification.data
          ? `Link : ${pendingNotifications[selectedId].notification.data?.link}`
          : ''}`}
          open={openReject}
          handleCancel={() => {
            setOpenReject(false);
            setSelectedId('');
          }}
          handleConfirm={() => {
            setOpenReject(false);
            reject();
          }}
        />
      ) : null}

      {openLoading && (
        <SavingChangesDialog
          dialogTitle="Saving Changes, please wait."
          open={openLoading}
        />
      )}

      {openError ? (
        <ErrorDialog
          open={openError}
          dialogTitle="Notification Error"
          dialogText={errorText}
          handleClose={() => {
            setErrorText('');
            setOpenError(false);
          }}
        />
      ) : null}

      {openAlert && (
        <AlertDialog
          dialogTitle="No more Pending Notifications"
          open={openAlert}
          setOpen={setOpenAlert}
          handleOk={() => {
            setOpenAlert(false);
          }}
        />
      )}

    </Content>
  );
}

export default PendingNotifications;
