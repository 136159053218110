/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

// import { useSearchParams } from 'react-router-dom';
import CampaignsService, { Campaign, CampaignAnalytics } from '../../Services/CampaignsService';

import NoPermissionPage from '../../Components/BasicComponents/NoPermissionPage';
import CampaignSearch from './components/CampaignSearch';
import Campaigns from './components/Campaigns';
import ErrorDialog from '../../Components/BasicComponents/ErrorDialog';
import AnalyticsPage from './components/AnalyticsPage';

function CampaignsPage() {
  const [birthdayCampaigns, setBirthdayCampaigns] = useState<Campaign[]>([]);
  const [retentionCampaigns, setRetentionCampaigns] = useState<Campaign[]>([]);
  const [acquisitionCampaigns, setAcquisitionCampaigns] = useState<Campaign[]>([]);
  const [customCampaigns, setCustomCampaigns] = useState<Campaign[]>([]);
  const [allCampaigns, setAllCampaigns] = useState<Campaign[]>([]);
  const [filter, setFilter] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [analytics, setAnalytics] = useState<any[]>([]);

  const goBack = () => {
    setOpenError(false);
    setErrorMessage('');
    if (birthdayCampaigns) setBirthdayCampaigns([]);
    if (retentionCampaigns) setRetentionCampaigns([]);
    if (acquisitionCampaigns) setAcquisitionCampaigns([]);
    if (customCampaigns) setCustomCampaigns([]);
    if (allCampaigns) setAllCampaigns([]);
    if (analytics) setAnalytics([]);
  };

  const getCampaigns = async (param?: string) => {
    setLoading(true);
    try {
      if (birthdayCampaigns) setBirthdayCampaigns([]);
      if (retentionCampaigns) setRetentionCampaigns([]);
      if (acquisitionCampaigns) setAcquisitionCampaigns([]);
      if (customCampaigns) setCustomCampaigns([]);
      if (allCampaigns) setAllCampaigns([]);

      setFilter(param || 'all');
      const campaigns: Campaign[] = await CampaignsService.getAllCampaigns();

      setAllCampaigns(campaigns);
      if (param === 'birthday') {
        setBirthdayCampaigns(campaigns.filter((camp) => camp.campaignType === 'birthday'));
      } else if (param === 'retention') {
        setRetentionCampaigns(campaigns.filter((camp) => camp.campaignType === 'retention'));
      } else if (param === 'acquisition') {
        setRetentionCampaigns(campaigns.filter((camp) => camp.campaignType === 'acquisition'));
      } else if (param === 'custom') {
        setCustomCampaigns(campaigns.filter((camp) => camp.campaignType === 'custom'));
      } else {
        setAllCampaigns(campaigns);
      }
    } catch (e:any) {
      setErrorMessage(e.message);
      setOpenError(true);
    }
    setLoading(false);
  };

  const getCampaignAnalytics = async () => {
    setLoading(true);
    try {
      const campaigns: Campaign[] = await CampaignsService.getAllCampaigns();
      const stats: CampaignAnalytics[] = [];
      const validCampaigns = campaigns.filter(
        (campaign) => !['CUIRCO', 'CORMACO', 'TOMCO', 'ELLACO', 'POSTO', 'ANDCOTEST', 'SAC'].includes(
          campaign.businessId,
        ),
      );
      let i = 0;
      while (i < validCampaigns.length) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const tempAnalytics = (await CampaignsService.getCampaignAnalytics(validCampaigns[i].campaignId)).data;
          tempAnalytics.deliveredVsRead = (tempAnalytics.promotionRewardsDelivered !== 0 && tempAnalytics.readCount !== 0)
            ? parseFloat(((tempAnalytics.readCount / tempAnalytics.promotionRewardsDelivered) * 100).toFixed(3))
            : 0;
          tempAnalytics.readVsClaimed = (tempAnalytics.promotionClaimsCount !== 0 && tempAnalytics.readCount !== 0)
            ? parseFloat(((tempAnalytics.promotionClaimsCount / tempAnalytics.readCount) * 100).toFixed(3))
            : 0;
          tempAnalytics.claimedVsRedeemed = (tempAnalytics.promotionRedemptionsCount !== 0 && tempAnalytics.promotionClaimsCount !== 0)
            ? parseFloat(((tempAnalytics.promotionRedemptionsCount / tempAnalytics.promotionClaimsCount) * 100).toFixed(3))
            : 0;
          stats.push(tempAnalytics);
        } catch (e:any) {
          setErrorMessage(e.message);
          setOpenError(true);
        }
        i += 1;
      }

      setAnalytics(stats);
    } catch (e:any) {
      setErrorMessage(e.message);
      setOpenError(true);
    }
    setLoading(false);
  };

  const permissions = ['loyalty:promo:all', 'loyalty:promo:read', 'loyalty:promo:readAll'];
  const canView = permissions.some((condition) => localStorage.getItem('userScopes')?.includes(condition));

  if (!canView) return <NoPermissionPage />;

  if (analytics.length > 0) {
    return (
      <AnalyticsPage
        getAnalytics={getCampaignAnalytics}
        goBack={goBack}
        analytics={analytics}
      />
    );
  }

  if (birthdayCampaigns.length > 0) {
    return (
      <Campaigns
        goBack={goBack}
        campaigns={birthdayCampaigns}
        campaignAmount={birthdayCampaigns.length}
        getCampaigns={getCampaigns}
        category={filter}
      />
    );
  }

  if (birthdayCampaigns.length > 0) {
    return (
      <Campaigns
        goBack={goBack}
        campaigns={birthdayCampaigns}
        campaignAmount={birthdayCampaigns.length}
        getCampaigns={getCampaigns}
        category={filter}
      />
    );
  }

  if (retentionCampaigns.length > 0) {
    return (
      <Campaigns
        goBack={goBack}
        campaigns={retentionCampaigns}
        campaignAmount={retentionCampaigns.length}
        getCampaigns={getCampaigns}
        category={filter}
      />
    );
  }

  if (acquisitionCampaigns.length > 0) {
    return (
      <Campaigns
        goBack={goBack}
        campaigns={acquisitionCampaigns}
        campaignAmount={acquisitionCampaigns.length}
        getCampaigns={getCampaigns}
        category={filter}
      />
    );
  }

  if (customCampaigns.length > 0) {
    return (
      <Campaigns
        goBack={goBack}
        campaigns={customCampaigns}
        campaignAmount={customCampaigns.length}
        getCampaigns={getCampaigns}
        category={filter}
      />
    );
  }

  if (allCampaigns.length > 0) {
    return (
      <Campaigns
        goBack={goBack}
        campaigns={allCampaigns}
        campaignAmount={allCampaigns.length}
        getCampaigns={getCampaigns}
        category={filter}
      />
    );
  }

  return (
    <>
      <CampaignSearch
        loading={loading}
        getCampaigns={getCampaigns}
        getCampaignAnalytics={getCampaignAnalytics}
      />

      <ErrorDialog
        dialogTitle="Error"
        dialogText={errorMessage}
        open={openError}
        handleClose={() => {
          setErrorMessage('');
          setOpenError(false);
        }}
      />

    </>
  );
}

export default CampaignsPage;
