import React, { useState } from 'react';

import { styled } from '@mui/system';
import {
  Grid,
  Typography,
  Button,
  Divider,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MdOutlineStorefront } from 'react-icons/md';
import { FaFileSignature, FaArrowAltCircleUp } from 'react-icons/fa';
import { GiCancel, GiConfirmed } from 'react-icons/gi';

import { useNavigate } from 'react-router-dom';
import Content from '../../../Components/BasicComponents/Content';
import BackButton from '../../../Components/BasicComponents/BackButton';
import BusinessInfoCard from './BusinessInfoCard';
import LocationCard from './LocationCard';
import BusinessSchemeCard from './BusinessSchemeCard';
import BusinessEditDialog from './BusinessEditDialog';
import MarketPlaceCard from './MarketPlaceCard';
import ConfirmDialog from '../../../Components/BasicComponents/ConfirmDialog';
import ErrorDialog from '../../../Components/BasicComponents/ErrorDialog';
import SignUpInfoCard from './SignUpInfoCard';
import PremiumDetails from './PremiumDetails';
import BillingInfoCard from './BillingInfoCard';
import SavingChangesDialog from '../../../Components/BasicComponents/SavingChangesDialog';
import MarketPlaceService, { BusinessMarketPlace } from '../../../Services/MarketPlaceService';
import BusinessService, { AdditionalInfo, LocationBilling } from '../../../Services/BusinessService';
import EditMarketPlace from './EditMarketPlace';
import SchemeEdit from '../../Scheme/components/SchemeEdit';

const ButtonRow = styled('div', {})({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const ButtonRow2 = styled('div', {})({
  display: 'flex',
  flexDirection: 'row',
});

const StyledActivateButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isDeactivate',
}) <{ isDeactivate?: boolean }>(({ theme, isDeactivate }) => ({
  height: '45px',
  marginRight: '15px',
  backgroundColor: isDeactivate ? theme.palette.warning.main : theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: isDeactivate ? theme.palette.warning.main : theme.palette.secondary.main,
  },
  [theme.breakpoints.down('sm')]: {
    height: '35px',
  },
}));

const StyledMarketButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isDelete',
}) <{ isDelete?: boolean }>(({ theme, isDelete }) => ({
  height: '65px',
  backgroundColor: isDelete ? theme.palette.warning.main : theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: isDelete ? theme.palette.warning.main : theme.palette.secondary.main,
  },
  [theme.breakpoints.down('sm')]: {
    height: '35px',
  },
}));

const StyledFetch = styled(LoadingButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.main,
  height: '65px',
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const BusinessTitle = styled(Typography, {})({
  fontWeight: 'bold',
});

const TextBox = styled('div', {})({
  display: 'flex',
  flexDirection: 'row',
});

const StatusText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isLive',
}) <{ isLive?: boolean }>(({ theme, isLive }) => ({
  marginLeft: '10px',
  fontWeight: 'bold',
  color: isLive ? theme.palette.secondary.main : theme.palette.warning.main,
}));

type Props = {
  businessData: any,
  billingData: LocationBilling[],
  loadBusinessDetails: (id: string) => void,
  loadBillingInfo: (id: string) => void,
  goBack: () => void,
  partnerId: string,
};

function BusinessDetails(props: Props) {
  const {
    billingData,
    businessData,
    loadBusinessDetails,
    loadBillingInfo,
    goBack,
    partnerId,
  } = props;

  const permissions = ['loyalty:partners:update', 'loyalty:partners:all'];
  const canEdit = permissions.some((condition) => localStorage.getItem('userScopes')?.includes(condition));

  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState<boolean>(false);
  const [openMarketPlace, setOpenMarketPlace] = useState<boolean>(false);
  const [createMarketPlace, setCreateMarketPlace] = useState<boolean>(false);
  const [marketPlaceData, setMarketPlaceData] = useState<BusinessMarketPlace | undefined>(undefined);
  const [openError, setOpenError] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const [additionalInfo, setAdditionalInfo] = useState<AdditionalInfo | undefined>(undefined);
  const [isLoadingInfo, setIsLoadingInfo] = useState<boolean>(false);
  const [openDeactivate, setOpenDeactivate] = useState<boolean>(false);
  const [openActivate, setOpenActivate] = useState<boolean>(false);
  const [makeLocationsLive, setMakeLocationsLive] = useState<boolean>(false);
  const [openLocations, setOpenLocations] = useState<boolean>(false);
  const [openSchemeCreate, setOpenSchemeCreate] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLegacyBilling, setIsLegacyBilling] = useState<boolean>(true);
  const [openBilling, setOpenBilling] = useState<boolean>(false);
  const [isBillingLoading, setIsBillingLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const upgradeBilling = async () => {
    setIsBillingLoading(true);
    try {
      const activeLocations = Object.keys(businessData.Locations)
        .filter((key) => businessData.Locations[key].isLive)
        .map((key) => key);
      const updatedBilling = await BusinessService.updateBilling(partnerId, activeLocations);
      if (updatedBilling.updated) {
        setOpenBilling(false);
      }
      loadBillingInfo(partnerId);
      setIsLegacyBilling(false);
    } catch (e:any) {
      setErrorText(e.message);
      setOpenError(true);
    }
    setIsBillingLoading(false);
  };

  const removeMarketPlace = async () => {
    try {
      await MarketPlaceService.deleteMarketPlace(partnerId);
      await loadBusinessDetails(partnerId);
    } catch (e:any) {
      setErrorText(e.message);
      setOpenError(true);
    }
    setOpenDeleteConfirm(false);
  };

  const editMarketPlace = () => {
    setCreateMarketPlace(false);
    setMarketPlaceData(businessData['Market Place']);
    setOpenMarketPlace(true);
  };

  const fetchInfo = async () => {
    setIsLoadingInfo(true);
    try {
      const info = await BusinessService.getSignUpInfo(partnerId);
      setAdditionalInfo(info);
    } catch (e:any) {
      setErrorText('No sign up information found for this business');
      setOpenError(true);
    }
    setIsLoadingInfo(false);
  };

  const deactivate = async () => {
    setOpenDeactivate(false);
    setIsLoading(true);
    try {
      await BusinessService.deactivateBusiness(partnerId);
      goBack();
    } catch (e:any) {
      setErrorText(e.message);
      setOpenError(true);
    }
    setIsLoading(false);
  };

  const activate = async () => {
    setOpenActivate(false);
    setIsLoading(true);
    try {
      await BusinessService.activateBusiness(partnerId, makeLocationsLive);
    } catch (e:any) {
      setErrorText(e.message);
      setOpenError(true);
    }
    setIsLoading(false);
  };

  return (
    <Content>
      <ButtonRow>
        <BackButton onClick={goBack} />
        <ButtonRow2>
          <StyledActivateButton
            aria-label="add"
            variant="contained"
            fullWidth
            startIcon={businessData.isLive ? <GiCancel /> : <GiConfirmed />}
            isDeactivate={businessData.isLive}
            onClick={() => {
              if (businessData.isLive) {
                setOpenDeactivate(true);
              } else {
                setOpenLocations(true);
              }
            }}
            disabled={!canEdit}
          >
            <Typography variant="body2">
              {businessData.isLive ? 'Deactivate Business' : 'Activate Business'}
            </Typography>
          </StyledActivateButton>
        </ButtonRow2>
      </ButtonRow>

      <Grid container spacing={1} sx={{ marginTop: '10px', marginBottom: '20px' }}>
        <Grid item xs={12} alignContent="center">
          <Divider light />
        </Grid>

        <Grid item container xs={6} justifyContent="center">
          <TextBox>
            <BusinessTitle variant="h1">{'Business Name : '}</BusinessTitle>
            <Typography variant="h1" sx={{ marginLeft: '10px' }}>
              {`${businessData['Basic Fields']['Chain Name']}`}
            </Typography>
          </TextBox>
        </Grid>

        <Grid item container xs={6} justifyContent="center">
          <TextBox>
            <BusinessTitle variant="h1">{'Business ID : '}</BusinessTitle>
            <Typography variant="h1" sx={{ marginLeft: '10px' }}>{`${partnerId}`}</Typography>
          </TextBox>
        </Grid>

        {businessData.NFC && businessData.NFC['Activation Code'] ? (
          <Grid item container xs={6} justifyContent="center">
            <TextBox>
              <BusinessTitle variant="h1">{'Activation Code : '}</BusinessTitle>
              <Typography variant="h1" sx={{ marginLeft: '10px' }}>
                {`${Object.keys(businessData.NFC['Activation Code'])[0]}`}
              </Typography>
            </TextBox>
          </Grid>
        ) : null}

        <Grid item container xs={6} justifyContent="center">
          <TextBox>
            <BusinessTitle variant="h1">{'Status : '}</BusinessTitle>
            <StatusText isLive={businessData.isLive} variant="h1">
              {`${businessData.isLive ? 'Live' : 'Not Live'}`}
            </StatusText>
          </TextBox>
        </Grid>

        <Grid item xs={12}>
          <Divider light />
        </Grid>
      </Grid>

      <Grid container spacing={2}>

        <Grid item xs={12}>
          <StyledFetch
            disabled={!isLegacyBilling}
            aria-label="fetch"
            variant="contained"
            fullWidth
            startIcon={<FaArrowAltCircleUp />}
            onClick={() => setOpenBilling(true)}
            loading={isBillingLoading}
          >
            <Typography variant="body2">Upgrade to Usage Based Billing</Typography>
          </StyledFetch>
        </Grid>

        <Grid item xs={12}>
          <BillingInfoCard
            businessData={businessData}
            billingData={billingData}
            setIsLegacyBilling={setIsLegacyBilling}
          />
        </Grid>

        <Grid item xs={12}>
          <BusinessInfoCard
            category={businessData['Basic Fields'].Category}
            chainName={businessData['Basic Fields']['Chain Name']}
            coverImage={businessData['Basic Fields'].Cover}
            description={businessData['Basic Fields'].Description}
            logo={businessData['Basic Fields'].Logo}
            socialLinks={businessData['Basic Fields']['Social Links']}
            setOpenEdit={setOpenEditDialog}
            canEdit={canEdit}
          />
        </Grid>

        <Grid item xs={12}>
          <PremiumDetails
            premiumData={businessData['Premium Fields'] ? businessData['Premium Fields'] : undefined}
            loadBusinessDetails={loadBusinessDetails}
            partnerId={partnerId}
          />
        </Grid>

        <Grid item xs={12}>
          <LocationCard
            loadBusinessDetails={loadBusinessDetails}
            isLive={businessData.isLive}
            partnerId={partnerId}
            locations={businessData.Locations ? businessData.Locations : undefined}
            nfcList={businessData.NFC ? businessData.NFC.Locations : undefined}
          />
        </Grid>

        <Grid item xs={12}>
          <BusinessSchemeCard
            partnerId={partnerId}
            schemes={businessData.Schemes ? businessData.Schemes : undefined}
            openSchemeCreate={setOpenSchemeCreate}
          />
        </Grid>

        {businessData['Market Place'] ? (
          <Grid item xs={12}>
            <MarketPlaceCard
              marketPlace={businessData['Market Place']}
              canEdit={canEdit}
              editMarketPlace={editMarketPlace}
              schemeOptions={Object.keys(businessData.Schemes)}
            />
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <StyledMarketButton
            aria-label="add"
            variant="contained"
            fullWidth
            startIcon={<MdOutlineStorefront />}
            isDelete={!!businessData['Market Place']}
            onClick={() => {
              if (businessData['Market Place']) {
                setOpenDeleteConfirm(true);
              } else {
                setMarketPlaceData(undefined);
                setCreateMarketPlace(true);
                setOpenMarketPlace(true);
              }
            }}
            disabled={!canEdit || !businessData.isLive}
          >
            <Typography variant="body2">
              {businessData['Market Place'] ? 'Remove from Market Place' : 'Add to Market Place'}
            </Typography>
          </StyledMarketButton>
        </Grid>

        {additionalInfo ? (
          <Grid item xs={12}>
            <SignUpInfoCard info={additionalInfo} />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <StyledFetch
              aria-label="fetch"
              variant="contained"
              fullWidth
              startIcon={<FaFileSignature />}
              onClick={fetchInfo}
              loading={isLoadingInfo}
            >
              <Typography variant="body2">Fetch Sign Up Information</Typography>
            </StyledFetch>
          </Grid>
        )}
      </Grid>

      <BusinessEditDialog
        key={new Date().getTime()}
        category={businessData['Basic Fields'].Category}
        chainName={businessData['Basic Fields']['Chain Name']}
        cover={businessData['Basic Fields'].Cover}
        description={businessData['Basic Fields'].Description}
        loadBusinessDetails={loadBusinessDetails}
        logo={businessData['Basic Fields'].Logo}
        open={openEditDialog}
        partnerId={partnerId}
        setOpen={setOpenEditDialog}
        socialLinks={businessData['Basic Fields']['Social Links']}
      />

      <ConfirmDialog
        isDelete
        dialogTitle="Confirm Removal from Market Place"
        dialogText="All data pertaining to this store on the market place will be permantly deleted.
         Are you sure you wish to continue ?"
        open={openDeleteConfirm}
        handleConfirm={removeMarketPlace}
        handleCancel={() => setOpenDeleteConfirm(false)}
      />

      <ConfirmDialog
        isDelete
        dialogTitle="Warning Deactivation"
        dialogText="Deactivating a business will remove all live data, cease stripe payments and update Monday.
        Are you sure you want to continue ?"
        open={openDeactivate}
        handleConfirm={deactivate}
        handleCancel={() => setOpenDeactivate(false)}
      />

      <ConfirmDialog
        dialogTitle="Warning Activation"
        dialogText="Activating a business will push business data live, start stripe subscription and update Monday.
        Are you sure you want to continue ?"
        open={openActivate}
        handleConfirm={activate}
        handleCancel={() => setOpenActivate(false)}
      />

      <ConfirmDialog
        dialogTitle="Change to Usage Based Billing"
        dialogText="This action is non reversible. All currently active locations will now be switched to
          usage based billing. Are you sure you want to continue?"
        open={openBilling}
        handleConfirm={upgradeBilling}
        handleCancel={() => setOpenBilling(false)}
      />

      <ConfirmDialog
        yesNo
        dialogTitle="Activating Locations"
        dialogText="Do you wish to activate ALL current locations upon activating this business ?"
        open={openLocations}
        handleConfirm={() => {
          setMakeLocationsLive(true);
          setOpenLocations(false);
          setOpenActivate(true);
        }}
        handleCancel={() => {
          setMakeLocationsLive(false);
          setOpenLocations(false);
          setOpenActivate(true);
        }}
      />

      {isLoading ? (
        <SavingChangesDialog
          dialogTitle="Saving Changes, these may take a few moments..."
          open={isLoading}
        />
      ) : null}

      {openMarketPlace ? (
        <EditMarketPlace
          businessData={businessData}
          create={createMarketPlace}
          open={openMarketPlace}
          setOpen={setOpenMarketPlace}
          partnerId={partnerId}
          marketPlaceData={marketPlaceData}
          schemeOptions={businessData.Schemes}
        />
      ) : null}

      {openError ? (
        <ErrorDialog
          open={openError}
          dialogTitle={errorText}
          handleClose={() => setOpenError(false)}
        />
      ) : null}

      {openSchemeCreate ? (
        <SchemeEdit
          isCreate
          businessData={businessData}
          open={openSchemeCreate}
          setOpen={setOpenSchemeCreate}
          schemeData={undefined}
          partnerId={partnerId}
          schemeId={partnerId}
          loadSchemeDetails={() => navigate(`/schemes?partnerId=${partnerId}`)}
        />
      ) : null}

    </Content>
  );
}

export default BusinessDetails;
