/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */

import React, { useEffect, useState } from 'react';

import {
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  Dialog,
  Grid,
  Typography,
  MenuItem,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { styled } from '@mui/system';
import { GiCancel, GiConfirmed } from 'react-icons/gi';
import dayjs, { Dayjs } from 'dayjs';
import ErrorDialog from '../../../Components/BasicComponents/ErrorDialog';
import ConfirmDialog from '../../../Components/BasicComponents/ConfirmDialog';
import PromoService, { CodePromos, CodePromo } from '../../../Services/PromoService';

const StyledTitle = styled(Typography, {})({
  alignSelf: 'center',
  paddingTop: '25px',
  paddingBottom: '10px',
});

const StyledConfirm = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const StyledCancel = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.white,
  backgroundColor: theme.palette.warning.main,
  '&:hover': {
    backgroundColor: theme.palette.warning.main,
  },
}));

type Props = {
  businessData: any,
  isCreate: boolean,
  open: boolean,
  partnerId: string,
  setOpen: (toggle: boolean) => void,
  schemeId: string,
  cancelCreate: () => void,
  codePromoData?: CodePromo,
  setCodePromoData: (codePromo: CodePromos) => void,
  setCodeParam: (id: string) => void,
  createBatch: boolean,
  batchAmount: number,
  couponCode: string,
};

function CodePromoEdit(props: Props) {
  const {
    businessData,
    isCreate,
    open,
    partnerId,
    setOpen,
    schemeId,
    cancelCreate,
    codePromoData,
    setCodePromoData,
    setCodeParam,
    createBatch,
    batchAmount,
    couponCode,
  } = props;

  const now = dayjs(Date.now());

  const [codePrefix, setCodePrefix] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [amount, setAmount] = useState<string>('');
  const [remaining, setRemaining] = useState<string>('');
  const [endTime, setEndTime] = useState<Dayjs>(now);
  const [rewardDetails, setRewardDetails] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [usePopup, setUsePopup] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (codePromoData && !isCreate) {
      if (codePromoData.Type) {
        if (codePromoData.Type === 'S') setType('stamp');
        else if (codePromoData.Type === 'V') setType('voucher');
        else {
          setType(codePromoData.Type);
        }
      }
      if (codePromoData.Amount) setAmount(codePromoData.Amount.toString());
      if (codePromoData.Remaining) setRemaining(codePromoData.Remaining.toString());
      if (codePromoData['Expiry Timestamp']) {
        const miliStamp = codePromoData['Expiry Timestamp'].toString();
        const secondStamp = parseInt(miliStamp.slice(0, -3), 10);
        setEndTime(dayjs.unix(secondStamp));
      }
      if (codePromoData['Pop Up']) {
        setUsePopup(true);
        if (codePromoData['Pop Up'].Title) setTitle(codePromoData['Pop Up'].Title);
        if (codePromoData['Pop Up']['Main Text']) setRewardDetails(codePromoData['Pop Up']['Main Text']);
      }
    }
  }, [codePromoData]);

  const handleClose = () => {
    setOpen(false);
    setCodePrefix('');
    setType('');
    setAmount('');
    setRemaining('');
    setEndTime(now);
    setRewardDetails('');
    setTitle('');
    setUsePopup(false);
    cancelCreate();
  };

  const checkInputs = ():boolean => {
    if (type === '') return false;
    if (amount === '') return false;
    if (remaining === '') return false;
    if (endTime <= now) return false;
    if (usePopup) {
      if (rewardDetails === '') return false;
      if (title === '') return false;
    }
    return true;
  };

  const handleEdit = async () => {
    const continueEdit = await checkInputs();
    setError(!continueEdit);
    if (continueEdit) {
      let newCoupon: CodePromo = {
        Amount: parseInt(amount, 10),
        'Chain ID': partnerId,
        Scheme: schemeId,
        'Expiry Timestamp': dayjs(endTime).valueOf(),
        Remaining: parseInt(remaining, 10),
        Store: businessData['Basic Fields']['Chain Name'],
        Type: type,
      };

      if (usePopup) {
        newCoupon = {
          ...newCoupon,
          'Pop Up': {
            Title: title,
            'Main Text': rewardDetails,
          },
        };
      }

      if (isCreate && codePromoData && codePromoData.Version) {
        const versionNumber = codePromoData.Version + 1;
        newCoupon = {
          ...newCoupon,
          Version: versionNumber,
        };
      } else {
        newCoupon = {
          ...newCoupon,
          Version: 1,
        };
      }

      if (isCreate) {
        if (!createBatch && couponCode) {
          try {
            const promo = await PromoService.createCodePromo(couponCode, newCoupon);
            setCodePromoData(promo);
            setOpen(false);
            setCodeParam(couponCode);
          } catch (e:any) {
            setOpenError(true);
            setErrorMessage(e.message);
          }
        } else {
          try {
            const promos = await PromoService.createBatchCodePromo(codePrefix, batchAmount, newCoupon);
            setCodePromoData(promos);
            setOpen(false);
          } catch (e:any) {
            setOpenError(true);
            setErrorMessage(e.message);
          }
        }
      } else {
        try {
          const promo = await PromoService.updateCodePromo(couponCode, newCoupon);
          setCodePromoData(promo);
          setOpen(false);
          setCodeParam(couponCode);
        } catch (e:any) {
          setOpenError(true);
          setErrorMessage(e.message);
        }
      }
    }
  };

  const handleClosed = (event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick' && 'escapeKeyDown') return;
    handleClose();
  };

  return (
    <Dialog
      aria-label="code-promo-dialog"
      onClose={(event, reason) => handleClosed(event, reason)}
      open={open}
      fullWidth
      maxWidth="md"
      sx={{ zIndex: '1100' }}
    >
      {isCreate ? (
        <StyledTitle variant="h1">
          {createBatch ? `Create ${batchAmount} Code Promos for scheme ${schemeId}` : `Create Code Promo ${couponCode} for scheme ${schemeId}`}
        </StyledTitle>
      ) : (
        <StyledTitle variant="h1">{`Edit Code Promo ${couponCode} for scheme ${schemeId}`}</StyledTitle>
      )}

      <DialogContent>
        <Grid container spacing={4}>

          <Grid item xs={12}>
            <Divider><Typography variant="body2">Required Information</Typography></Divider>
          </Grid>

          {createBatch ? (
            <>
              <Grid item xs={12}>
                <Typography variant="body2">Code Prefix</Typography>
                <Typography variant="body1">
                  Prefix attached to the start of the random code, can only be 3 characters long
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="promo-type-entry"
                  label="Promo Prefix"
                  variant="outlined"
                  color="primary"
                  value={codePrefix}
                  error={error && codePrefix === ''}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ autoComplete: 'off' }}
                  onChange={(event) => {
                    if (event.target.value.length < 4) {
                      setCodePrefix(event.target.value.replace(/[^\w]/g, '').toUpperCase());
                    }
                  }}
                />
              </Grid>
            </>
          ) : null}
          <Grid item xs={12}>
            <TextField
              fullWidth
              select
              InputLabelProps={{ shrink: true }}
              id="type-select"
              label="Promo Type"
              variant="outlined"
              color="primary"
              value={type}
              error={error && type === ''}
              onChange={(event) => {
                setType(event.target.value);
              }}
              InputProps={{
                autoComplete: 'off',
              }}
            >
              <MenuItem key={0} value="" disabled>Select one of the below</MenuItem>
              <MenuItem key={1} value="stamp">Stamp</MenuItem>
              <MenuItem key={2} value="voucher">Voucher</MenuItem>
            </TextField>
          </Grid>

          {type !== '' ? (
            <>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="promo-type-entry"
                  label={type === 'stamp' ? 'Amount of Stamps added' : 'Amount of vouchers added'}
                  variant="outlined"
                  color="primary"
                  value={amount}
                  error={error && amount === ''}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ pattern: '[1-9]*', autoComplete: 'off' }}
                  onChange={(event) => {
                    setAmount(event.target.value.replace(/\D+/g, ''));
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2">Number of Uses</Typography>
                <Typography variant="body1">
                  a user can still only use the coupon code once, this
                  decides how many times the code is used before it expires
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="vouchers-remaining-entry"
                  label="Number of uses of Coupon"
                  variant="outlined"
                  color="primary"
                  value={remaining}
                  error={error && remaining === ''}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ pattern: '[1-9]*', autoComplete: 'off' }}
                  onChange={(event) => {
                    setRemaining(event.target.value.replace(/\D+/g, ''));
                  }}
                />
              </Grid>
            </>
          ) : null}

          <Grid container item spacing={2} xs={12}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2">End Time of Promotion</Typography>
              <Typography variant="body1">Coupon will not be available after this date</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <DateTimePicker
                value={endTime}
                onChange={(value) => {
                  if (value !== null) setEndTime(value);
                }}
                slotProps={{
                  textField: {
                    error: error && (endTime <= now),
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider><Typography variant="body2">Optional Information</Typography></Divider>
            </Grid>
          </Grid>

          <Grid container item spacing={2} xs={12}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Customized Pop-up</Typography>
              <Typography variant="body1">
                You can customize the pop-up shown on the app when a user activates a promotion
              </Typography>
            </Grid>
            <Grid container item xs={12} sm={6} alignContent="center">
              <FormControlLabel
                key="pop-up-toggle"
                control={
                  <Checkbox checked={usePopup} onChange={() => setUsePopup(!usePopup)} />
                }
                label="Use custom pop-up"
              />
            </Grid>
          </Grid>

          {usePopup ? (
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="title-entry"
                  label="Promo Pop-up Title"
                  variant="outlined"
                  color="primary"
                  value={title}
                  error={(error && usePopup) && title === ''}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ autoComplete: 'off' }}
                  onChange={(event) => {
                    setTitle(event.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  sx={{ marginTop: '10px' }}
                  fullWidth
                  id="reward-entry"
                  label="Promo Pop-up Reward Details"
                  variant="outlined"
                  color="primary"
                  value={rewardDetails}
                  error={(error && usePopup) && rewardDetails === ''}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ autoComplete: 'off' }}
                  onChange={(event) => {
                    setRewardDetails(event.target.value);
                  }}
                />
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </DialogContent>

      <DialogActions>
        <StyledCancel
          variant="contained"
          fullWidth
          startIcon={<GiCancel />}
          onClick={() => handleClose()}
        >
          <Typography variant="body2">CANCEL</Typography>
        </StyledCancel>
        <StyledConfirm
          variant="contained"
          fullWidth
          startIcon={<GiConfirmed />}
          onClick={() => {
            if (checkInputs()) setOpenConfirm(true);
            else setError(true);
          }}
        >
          <Typography variant="body2">CONFIRM</Typography>
        </StyledConfirm>
      </DialogActions>

      <ConfirmDialog
        open={openConfirm}
        handleCancel={() => setOpenConfirm(false)}
        handleConfirm={handleEdit}
        dialogTitle={isCreate ? (createBatch ? `Create ${batchAmount} new Code Promotions for ${schemeId}` : `Create new Code Promotion ${couponCode} for ${schemeId}`) : `Save Changes for ${couponCode}`}
        dialogText={isCreate
          ? 'Are you sure you wish to save this new Promotion ?'
          : 'Are you sure you wish to save these changes to this existing promotion ?'}
      />

      <ErrorDialog
        dialogTitle={errorMessage}
        open={openError}
        handleClose={() => {
          setOpenError(false);
          setErrorMessage('');
        }}
      />
    </Dialog>
  );
}

export default CodePromoEdit;
