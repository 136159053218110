/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';

import { styled } from '@mui/system';
import {
  Card,
  CardContent,
  Collapse,
  IconButton,
  IconButtonProps,
  Grid,
  Typography,
  Tooltip,
  Divider,
  Link,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import { FaEdit } from 'react-icons/fa';
import { OurEthos } from '../../../Services/BusinessService';
import OurEthosEdit from './OurEthosEdit';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  fontSize: '30px',
  color: theme.palette.common.white,
}));

const CardTitle = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary.main,
  height: '40px',
  [theme.breakpoints.down('sm')]: {
    height: '50px',
  },
}));

const CardTitleText = styled(Typography)(({ theme }) => ({
  alignSelf: 'center',
  color: theme.palette.common.white,
}));

const ExpandedContent = styled(CardContent)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  paddingLeft: '50px',
  paddingRight: '50px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
}));

const StyledCreateButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

type Props = {
  ethosData: OurEthos | undefined,
  loadBusinessDetails: (id: string) => void,
  partnerId: string,
};

function OurEthosCard(props: Props) {
  const {
    ethosData,
    loadBusinessDetails,
    partnerId,
  } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);

  const HandleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };
  const permissions = ['loyalty:partners:all', 'loyalty:partners:create'];
  const canCreate = permissions.some((condition) => localStorage.getItem('userScopes')?.includes(condition));

  return (
    <Card key="ethos">
      <CardTitle>
        <CardTitleText variant="h4">Our Ethos</CardTitleText>
        <div>
          <Tooltip title="Edit Our Ethos" placement="bottom">
            <StyledCreateButton disabled={!canCreate} size="small" onClick={() => setOpenEdit(true)}>
              <FaEdit />
            </StyledCreateButton>
          </Tooltip>
          <ExpandMore
            expand={isExpanded}
            onClick={HandleExpandClick}
            aria-expanded={isExpanded}
            aria-label="show more"
          >
            <MdExpandMore />
          </ExpandMore>
        </div>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          <Grid container spacing={2}>
            {ethosData ? (
              <>
                <Grid item xs={6}>
                  <Typography variant="body2">Description Text : </Typography>
                </Grid>

                {ethosData.Description ? (
                  <Grid item xs={6}>
                    <Typography variant="body1">{`${ethosData.Description}`}</Typography>
                  </Grid>
                ) : (
                  <Grid item xs={6}>
                    <Typography variant="body1">Not Set</Typography>
                  </Grid>
                )}

                <Grid item xs={6}>
                  <Typography variant="body2">Link : </Typography>
                </Grid>

                {ethosData.Link ? (
                  <Grid item xs={6}>
                    <Link
                      style={{ color: 'blue' }}
                      href={`${ethosData.Link}`}
                      target="_blank"
                      rel="noreferrer"
                      underline="hover"
                    >
                      {`${ethosData.Link}`}
                    </Link>
                  </Grid>
                ) : (
                  <Grid item xs={6}>
                    <Typography variant="body1">Not Set</Typography>
                  </Grid>
                )}
              </>
            ) : (
              <Grid item container xs={12} justifyContent="center">
                <Typography variant="body1">Not Configured</Typography>
              </Grid>
            )}
          </Grid>
        </ExpandedContent>
      </Collapse>

      <OurEthosEdit
        key={new Date().getTime()}
        loadBusinessDetails={loadBusinessDetails}
        partnerId={partnerId}
        setOpen={setOpenEdit}
        open={openEdit}
        ethosData={ethosData}
      />

    </Card>
  );
}

export default OurEthosCard;
