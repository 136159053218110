import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IconButton, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { BiChevronsLeft, BiChevronsRight, BiLogOutCircle } from 'react-icons/bi';
import SidebarRouting from '../../configs/sidebarRouteConfig';
import AuthService from '../../Services/AuthService';

const SidebarDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  backgroundColor: theme.palette.primary.main,
  top: 0,
  position: 'sticky',
  zIndex: 1000,
}));

const TitleRow = styled('div', {})({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingTop: '40px',
});

const StyledCollapseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: '30px',
  padding: '10px',
  borderRadius: '0px',
  margin: '0px',
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
}));

const SquidTitleSpan = styled('span')(({ theme }) => ({
  fontSize: '18px',
  fontWeight: 'bold',
  color: theme.palette.common.white,
  whiteSpace: 'nowrap',
}));

const ModeText = styled('span')(({ theme }) => ({
  fontSize: '18px',
  fontWeight: 'bold',
  color: theme.palette.warning.main,
  alignSelf: 'center',
}));

const ButtonContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'open',
}) <{ open?: boolean }>(({ open }) => ({
  paddingTop: open ? '48px' : '70px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: open ? 'center' : 'flex-start',
}));

const StyledLinkContainer = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'selected',
}) <{ selected: boolean }>(({ theme, selected }) => ({
  textDecoration: 'none',
  display: 'flex',
  padding: '10px',
  alignItems: 'center',
  flexDirection: 'row',
  cursor: 'pointer',
  backgroundColor: selected ? theme.palette.primary.light : theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
}));

const StyledLinkIcon = styled('span')(({ theme }) => ({
  fontSize: '30px',
  color: theme.palette.common.white,
  textDecoration: 'none',
  lineHeight: 0,
}));

const StyledLinkText = styled('span')(({ theme }) => ({
  fontSize: '18px',
  fontWeight: 'bold',
  paddingLeft: '15px',
  color: theme.palette.common.white,
}));

const LogoutButton = styled(Link)(({ theme }) => ({
  display: 'flex',
  textDecoration: 'none',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '10px',
  cursor: 'pointer',
  marginTop: 'auto',
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
}));

const VersionText = styled(Typography)(({ theme }) => ({
  color: theme.palette.warning.main,
  borderRadius: '0px',
  alignSelf: 'center',
  paddingBottom: '5px',
  margin: '0px',
}));

const NotificationContainer = styled('div', {})({
  position: 'relative',
});

const NotificationText = styled('span')(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.warning.main,
  fontWeight: 'bold',
  borderRadius: '50%',
  position: 'absolute',
  left: '12px',
  top: '-5px',
  height: '20px',
  width: '20px',
  padding: '2px',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
}));

type Props = {
  sideBarOpen: boolean,
  setSideBarOpen: (toggle: boolean) => void,
};

function Sidebar(props: Props) {
  const { sideBarOpen, setSideBarOpen } = props;

  const notifyNumber = localStorage.getItem('notificationAmount');
  const notifications = notifyNumber !== null ? notifyNumber : '0';

  const [notificationAmount, setNotificationAmount] = useState<string>(notifications);
  const location = useLocation();

  const getModeText = ():string => {
    if (process.env.REACT_APP_API_URL === 'http://localhost:3000') return 'LocalHost Mode';
    if (process.env.REACT_APP_API_URL === 'https://dev.api.loyalty.squidloyalty.app') return 'Staging Mode';
    return '';
  };

  useEffect(() => {
    const listenStorageChange = () => {
      const amount = localStorage.getItem('notificationAmount');
      const amountNumber = amount !== null ? amount : '0';
      setNotificationAmount(amountNumber);
    };
    window.addEventListener('storage', listenStorageChange);
    return () => window.removeEventListener('storage', listenStorageChange);
  }, []);

  const getNavItems = () => (
    SidebarRouting.map((menu, index) => (
      <StyledLinkContainer
        to={menu.path}
        key={index}
        selected={location.pathname === menu.path}
      >
        {menu.title === 'Notifications' ? (
          <NotificationContainer>
            <StyledLinkIcon>{menu.icon}</StyledLinkIcon>
            {notificationAmount !== '0' ? (
              <NotificationText>{notificationAmount}</NotificationText>
            ) : null}
          </NotificationContainer>
        ) : (
          <StyledLinkIcon>{menu.icon}</StyledLinkIcon>
        )}

        {sideBarOpen
          ? <StyledLinkText>{menu.title}</StyledLinkText>
          : null }
      </StyledLinkContainer>

    ))
  );

  return (
    <SidebarDiv>
      <TitleRow>
        <StyledCollapseButton onClick={() => setSideBarOpen(!sideBarOpen)}>
          {sideBarOpen ? <SquidTitleSpan>SQUID Admin Portal</SquidTitleSpan> : null }
          {sideBarOpen ? <BiChevronsLeft /> : <BiChevronsRight />}
        </StyledCollapseButton>
      </TitleRow>
      {sideBarOpen ? <ModeText>{getModeText()}</ModeText> : null }

      <ButtonContainer open={sideBarOpen}>
        {getNavItems()}
      </ButtonContainer>

      <LogoutButton to="/" onClick={() => AuthService.logout()}>
        <StyledLinkIcon>
          <BiLogOutCircle />
        </StyledLinkIcon>
        {sideBarOpen
          ? <StyledLinkText>Logout</StyledLinkText>
          : null }
      </LogoutButton>
      {sideBarOpen ? <VersionText variant="body2">Beta v0.10.0</VersionText> : null }
    </SidebarDiv>
  );
}

export default Sidebar;
